import { FC } from 'react';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import { IHost } from 'src/models/IHost';
import { IErrorsHostData } from '../index';

interface IProps {
  host: IHost;
  setHost: any;
  check: boolean;
  errors: IErrorsHostData;
}

const HostFormModal: FC<IProps> = ({ host, setHost, check, errors }) => {
  const _renderInput = (
    name: keyof IHost,
    title: string = name,
    length: number = 50
  ) => (
    <SimpleInput
      data={host[name]!}
      setData={setHost(name)}
      title={title}
      length={length}
    />
  );

  return (
    <div className='col-1'>
      <div className='col-2'>
        <SimpleInput
          data={host['first_name']!}
          setData={setHost('first_name')}
          title={'first name'}
          check={check}
          error={errors.first_name}
          length={50}
          isHelperText={true}
        />
        <SimpleInput
          data={host['last_name']!}
          setData={setHost('last_name')}
          title={'last name'}
          check={check}
          error={errors.last_name}
          length={50}
          isHelperText={true}
        />
        <SimpleInput
          data={host['email']!}
          setData={setHost('email')}
          title={'email'}
          check={check}
          error={errors.email}
          length={50}
          isHelperText={true}
        />
        <SimpleInput
          data={host['phone']!}
          setData={setHost('phone')}
          title={'phone'}
          check={check}
          error={errors.phone}
          length={50}
          isHelperText={true}
        />
        {_renderInput('country')}
        {_renderInput('state')}
        {_renderInput('city')}
        {_renderInput('home_address', 'home address')}
        {_renderInput('zip_code', 'zip code')}
        {_renderInput('nearest_airport', 'nearest airport')}
        {_renderInput('company_name', 'company name')}
        <SimpleInput
          data={host['host_tag']!}
          setData={setHost('host_tag')}
          title={'host tag'}
          check={check}
          error={errors.host_tag}
          length={50}
          isHelperText={true}
        />
      </div>
      <div className='col-3'>
        <SimpleSelect
          data={host['delivers']!}
          setData={setHost('delivers')}
          dataArray={['Yes', 'No']}
          placeholder='delivers'
          title='delivers'
          check={check}
        />
        <SimpleSelect
          data={host['rents_at_home']!}
          setData={setHost('rents_at_home')}
          dataArray={['Yes', 'No']}
          placeholder='rents at home'
          title='rents at home'
          check={check}
        />
        {_renderInput('bookimg_free', 'bookimg free')}
      </div>
      <div className='col-2'>
        {_renderInput('stripe_connect_id', 'stripe connect')}
        <SimpleInput
          data={host['password']!}
          setData={setHost('password')}
          type="password"
          required
          title={'password'}
          check={check}
          error={errors.password}
          isHelperText={true}
          notAutocomplete
        />
      </div>
    </div>
  );
};

export default HostFormModal;
