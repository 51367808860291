import { FC, useMemo, useState } from 'react';
import lodash from 'lodash';
import { CircularProgress, Tooltip } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import DriverListModal from './Modal/List';
import { OrderNotificationToGuestAutomaticallyEnum } from 'src/store/reducers/order/types';
import { IOrderFetch } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import DeleteModalForm from 'src/components/Items/DeleteModalForm';
import './styles.scss';

interface IProps {
  order: IOrderFetch;
}

const DriverData: FC<IProps> = ({ order }) => {
  const { updateOrder, showLicense, showInsurance } = useActions();
  const isMobile = useMemo(() => window.outerWidth < 520, [window.outerWidth]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<number>();

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleOpenDelete = (id: number) => {
    setSelectedDriver(id);
    setOpenDelete(true);
  };

  const handleDeleteDriver = (id: number) => {
    const newDriverList = lodash.filter(order.additional_driver, (driver) =>
      driver.id !== id
    );
    updateOrder({ id: order.id, additional_driver: newDriverList });
  };

  const { sentEmail } = useActions();
  const { isLoading } = useTypedSelector(
    (state) => state.order.notification
  );
  const _item = (title: string, links?: string | string[], s3?: string[]) => (
    <div className='order-drivers__item__documents__license'>
      {Array.isArray(links) ? (
        links.map((link, index) => (
          <Tooltip title={title} key={index}>
            {title === 'Insurance' ? (
              <div
                className='order-docs__item__data icon_stroke'
                onClick={() => showInsurance(s3![2])}
              >
                <Icons.LilPic />
              </div>
            ) : (
              <div
                className='order-docs__item__data icon_stroke'
                onClick={() => showLicense(s3![index])}
              >
                <Icons.LilPic />
              </div>
            )}
          </Tooltip>
        ))
      ) : (
        'missing'
      )}
    </div>
  );
  const handleSentNotification =
    (
      typeNotification: any,
      notification: keyof OrderNotificationToGuestAutomaticallyEnum,
      driverId?: number
    ) =>
      () =>
        typeNotification(order.id, notification, driverId);
  return (
    <>
      <div className='order-show__title'>
        Additional Drivers
        <div className='order-show__title__action icon_stroke'>
          <Tooltip title='Add driver'>
            <div onClick={handleOpenEdit}>
              <Icons.Plus />
            </div>
          </Tooltip>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div className='order-drivers'>
          <div className='order-drivers__item'>
            <div className='order-drivers__item__title'>Driver</div>
            {order.additional_driver.map((driver, index) => (
              <div
                key={index}
                className='order-drivers__item__data'
              >
                {`${driver.first_name} ${driver.last_name}`}
              </div>
            ))}
          </div>
          <div className='order-drivers__item'>
            <div className='order-drivers__item__title'>Documents</div>
            {order.additional_driver.map((driver) =>
              <div className='order-drivers__item__documents'>
                <div className='order-drivers__item__documents__container'>
                  {(
                    isMobile &&
                    !driver.driver_license?.photos?.length &&
                    !driver.driver_license?.insurance
                  ) ? (
                    <div>missing</div>
                  ) : (
                    <>
                      <div className='order-drivers__item__subtitle'>License:</div>
                      {_item('License', driver.driver_license?.photos as string[], driver.driver_license?.s3_keys)}
                      <div className='order-drivers__item__subtitle'>Insurance:</div>
                      {_item('Insurance', driver.driver_license?.insurance as string, driver.driver_license?.s3_keys)}
                    </>
                  )}
                </div>
                {(driver.driver_license?.photos?.length ||
                  driver.driver_license?.insurance) && (
                    <Tooltip title='Delete documents'>
                      <div
                        onClick={() => handleOpenDelete(driver.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        <Icons.Delete />
                      </div>
                    </Tooltip>
                  )}
              </div>
            )}
          </div>
        </div >
        <div className='order-drivers__item' style={{ marginTop: '30px' }}>
          {order.additional_driver.map((driver, index) => (
            <div key={index} className='order-drivers__item__data'>
              <div className='order-drivers__item__data__action'>
                {isLoading.email ? (
                  <CircularProgress size={15} />
                ) : (
                  <Tooltip title='Sent email'>
                    <AlternateEmailIcon
                      onClick={handleSentNotification(
                        sentEmail,
                        'Upload Docs/DL' as keyof OrderNotificationToGuestAutomaticallyEnum,
                        driver.id
                      )}
                    />
                  </Tooltip>
                )}
                <Tooltip title='Delete driver'>
                  <div
                    onClick={() => handleDeleteDriver(driver.id)}
                  >
                    <Icons.Delete />
                  </div>
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div >
      <DriverListModal
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        order={order}
        client={order.client}
      />
      <DeleteModalForm
        id={selectedDriver!}
        type="additional driver license"
        handleClose={() => setOpenDelete(false)}
        open={openDelete}
      />
    </>
  );
};

export default DriverData;
