import { FC, useState } from "react";
import { Button, Tab, Tabs } from "@mui/material";
import HostTable from "./Tables/HostTable";
import LocationsTable from "./Tables/LocationsTable";
import PickupDetailsTable from "./Tables/PickupDetailsTable";
import HostModal from "src/pages/Admin/Modals/AddHost";
import LocationModal from "src/pages/Admin/Modals/AddLocation";
import PickupDetailsModal from "src/pages/Admin/Modals/AddPickupDetails";
import SeasonPriceTable from "./Tables/SeasonPriceTable";
import SeasonPriceModal from "src/pages/Admin/Modals/SeasonPriceModal";
import OnewayModal from "src/pages/Admin/Modals/AddOneway";
import OnewayTable from "./Tables/OnewayTable";
import SubPriceTable from "./Tables/SubPriceTable";
import SubPriceModal from "./Modals/SubPrice";
import SearchInput from "src/components/Items/input/SearchInput";
import { useTypedSelector } from 'src/hooks/useTypeSelector';

const AdminPage: FC = () => {

  const { role } = useTypedSelector((state) => state.auth);
  const [tabValue, setTabValue] = useState(0);
  const [openEl, setOpenEl] = useState([false, false, false, false]);
  const [searchByLocation, setSearchByLocation] = useState('');
  const filterData = ['hosts', 'locations', 'pickup details', 'oneways', 'season price', 'sub price'];
  // const filterData = ['hosts', 'locations', 'pickup details', 'oneways', 'season price'];

  const filterChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  const _renderFilterButton = () =>
    filterData.map((i, index) => <Tab label={i} key={index} />);

  const handleOpenEl = (event: any) => {
    const elements = [...openEl];
    elements[event.target.id] = true;
    setOpenEl(elements);
  };

  const handleCloseHost = () => {
    const elements = [...openEl];
    elements[0] = false;
    setOpenEl(elements);
  };

  const handleCloseLocation = () => {
    const elements = [...openEl];
    elements[1] = false;
    setOpenEl(elements);
  };

  const handleClosePD = () => {
    const elements = [...openEl];
    elements[2] = false;
    setOpenEl(elements);
  };

  const handleCloseOneway = () => {
    const elements = [...openEl];
    elements[3] = false;
    setOpenEl(elements);
  };

  const handleCloseSeasonPrice = () => {
    const elements = [...openEl];
    elements[4] = false;
    setOpenEl(elements);
  };

  const handleCloseSubPrice = () => {
    const elements = [...openEl];
    elements[5] = false;
    setOpenEl(elements);
  };

  return (
    <>
      {role === 'admin' ? (
        <>
          {tabValue === 0 && <HostModal handleClose={handleCloseHost} open={openEl[0]} />}
          {tabValue === 1 && <LocationModal handleClose={handleCloseLocation} open={openEl[1]} />}
          {tabValue === 2 && <PickupDetailsModal handleClose={handleClosePD} open={openEl[2]} />}
          {tabValue === 3 && <OnewayModal handleClose={handleCloseOneway} open={openEl[3]} />}
          {tabValue === 4 && <SeasonPriceModal handleClose={handleCloseSeasonPrice} open={openEl[4]} />}
          {tabValue === 5 && <SubPriceModal handleClose={handleCloseSubPrice} open={openEl[5]} />}
          <div className='order-table__filter'>
            <Tabs
              selectionFollowsFocus
              onChange={filterChange}
              value={tabValue}
              indicatorColor='secondary'
            >
              {_renderFilterButton()}
            </Tabs>
            <div
              className='action-block'
              style={{ gap: '10px' }}
            >
              {tabValue === 5 && (
                <SearchInput
                  setSearch={setSearchByLocation}
                  placeholder='Enter email'
                />
              )}
              <Button variant='contained'
                onClick={handleOpenEl}
                id={String(tabValue)}
              >
                Add {
                  (tabValue === 0 || tabValue === 1)
                    ? filterData[tabValue].slice(0, -1)
                    : filterData[tabValue]
                }
              </Button>
            </div>
          </div>
          {tabValue === 0 && <HostTable />}
          {tabValue === 1 && <LocationsTable />}
          {tabValue === 2 && <PickupDetailsTable />}
          {tabValue === 3 && <OnewayTable />}
          {tabValue === 4 && <SeasonPriceTable />}
          {tabValue === 5 && <SubPriceTable search={searchByLocation} />}
        </>
      ) : null}
    </>
  );
};
export default AdminPage;
