import { put, takeLatest } from 'redux-saga/effects';
import { DriverActionCreator } from 'src/store/reducers/driver/action-creators';
import { DriverActionEnum } from 'src/store/reducers/driver/types';
import instance from '../set';

function* fetchAddDriver({
  driver,
  id,
}: ReturnType<typeof DriverActionCreator.addDriver>) {
  try {
    const { status }: Response = yield instance.post(`driver/add/${id}`, {
      driver,
    });
    yield put(DriverActionCreator.resultAddDriver(status));
  } catch (error) {
    console.log(error);
  }
}
export function* actionWatcherAddDriver() {
  yield takeLatest(DriverActionEnum.REQUEST_ADD_DRIVER, fetchAddDriver);
}
