import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { HostStaffActionCreators } from "src/store/reducers/hostStaff/action-creators";
import { HostStaffActionEnum } from "src/store/reducers/hostStaff/types";


function* fetchHostStaffVans({
    data,
}: ReturnType<typeof HostStaffActionCreators.addHostStaff>) {
  try {
    const { status }: Response = yield instance.post('staff', data);
    yield put(HostStaffActionCreators.resultAddHostStaff(status));
  } catch (e) {
    console.log(e);
    yield put(HostStaffActionCreators.resultAddHostStaff(400));
  }
}

export function* actionWatcherAddHostStaff() {
  yield takeLatest(HostStaffActionEnum.REQUEST_ADD_HOSTSTAFF, fetchHostStaffVans);
}