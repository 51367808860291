import { FC } from "react";
import Icons from "src/common/icons";
import FormIcon from "../../FormIcon";

export interface IProps {
  classWrapper?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  title: string;
}

const IconSet: FC<IProps> = ({ classWrapper, onEdit, onDelete, title }) => (
  <div className={classWrapper}>
    {onEdit && (
      <FormIcon
        icon={<Icons.Edit classes="icon_stroke" />}
        handleClick={onEdit}
        title={`Edit ${title}`}
      />
    )}
    {onDelete && (
      <FormIcon
        icon={<Icons.Delete classes="icon_stroke" />}
        handleClick={onDelete}
        title={`Delete ${title}`}
      />
    )}
  </div>
);

export default IconSet;
