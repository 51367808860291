import { FC } from "react";
import { InputAdornment, TextField, styled } from "@mui/material";
import { colorSet, typeForm } from "src/theme";

interface IProps {
  check?: boolean;
  data: string | number;
  setData: (event: any) => void;
  title?: string;
  placeholder?: string;
  length?: number;
  type?: string;
  rows?: number;
  multiline?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  index?: boolean;
  name?: string;
  style?: {};
  inputStyle?: {};
  variant?: "standard" | "filled" | "outlined";
  error?: string;
  isHelperText?: boolean;
  adornment?: string;
  notAutocomplete?: boolean;
  disabled?: boolean;
}

const StyledInput = styled(TextField)({
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      'WebkitAppearance': 'none',
      margin: 0
    },
    'input[type=number]': {
      'MozAppearance': 'textfield'
    }
});

const SimpleInput: FC<IProps> = ({
  data,
  setData,
  check,
  title,
  length,
  type,
  index,
  rows,
  multiline,
  fullWidth,
  placeholder = title,
  required,
  style,
  inputStyle,
  variant,
  error,
  isHelperText,
  adornment,
  notAutocomplete,
  disabled
}) => {
  const helperError = () => {
    if (check) {
      if (error) return true;
      else if (!data) return true;
      else return false;
    }
  };
  const helperText = () => {
    if (check) {
      if (error) return error;
      else if (!data) return "Fill the field";
      else return;
    } else return placeholder;
  };

  // const keyListener = (event: { code: string; preventDefault: () => void; target: any}) => {
  //   const cursorPosition = event.target.selectionStart;
  //   if ((event.code === "Enter" || event.code === "NumpadEnter") && multiline) {
  //     event.preventDefault();
  //     setData(`${String(data).substr(0, cursorPosition)}\n${String(data).substr(cursorPosition)}`);
  //   }
  // };

  return (
    <div style={style}>
      {title && (
        <div
          className="filter__title"
          style={{ marginBottom: variant === "outlined" ? "10px" : "3px" }}
        >
          {title}
        </div>
      )}
      <StyledInput
        inputProps={{
          maxLength: length,
        }}
        sx={{
          ...inputStyle,
          "&.MuiFormControl-root": {
            height: multiline ? "auto" : "40px",
          },
          input: {
            "::placeholder": {
              color:
                check && !data ? colorSet.primaryRed : colorSet.primaryGrey,
              opacity: 1,
            },
          },
        }}
        error={helperError()}
        placeholder={helperText()}
        variant={variant ?? typeForm}
        type={type ?? "text"}
        value={data ?? ""}
        onChange={index ? setData : (event) => setData(event.target.value)}
        // onKeyDown={keyListener}
        autoComplete={notAutocomplete ? 'new-password' : 'on'}
        rows={rows}
        multiline={multiline}
        fullWidth={fullWidth}
        required={required}
        data-title={title}
        helperText={isHelperText && error}
        disabled={disabled}
        InputProps={{
          endAdornment: adornment && <InputAdornment position="end">{adornment}</InputAdornment>,
        }}
      />
    </div>
  );
};

export default SimpleInput;
