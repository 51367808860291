import { put, takeLatest } from 'redux-saga/effects';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import instance from '../set';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';

function* fetchUpdateOrder({
  currentOrder,
}: ReturnType<typeof OrderActionCreators.updateOrder>) {
  const { id, ...order } = currentOrder;
  try {
    const { status } = yield instance.patch(`order/${id}`, {
      order,
    });
    yield put(OrderActionCreators.resultUpdateOrder(status));
  } catch (e) {
    console.log(e);
    yield put(OrderActionCreators.resultUpdateOrder(400));
  }
}

export function* actionWatcherUpdateOrder() {
  yield takeLatest(OrderActionEnum.REQUEST_UPDATE_ORDER, fetchUpdateOrder);
}

export function* actionWatcherUpdateExtraOrder() {
  yield takeLatest(
    OrderActionEnum.REQUEST_UPDATE_EXTRA_ORDER,
    fetchUpdateOrder
  );
}
