import { Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import Icons from 'src/common/icons';
import { IOrderFetch } from 'src/models/IOrder';
import UpdateOrderModal from '../../Modal/Update';

interface IProps {
  order: IOrderFetch;
}

const Note: FC<IProps> = ({ order }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({} as IOrderFetch);

  const handleOpenEdit = (order: IOrderFetch) => {
    setOpenEdit(true);
    setSelectedOrder(order);
  };
  const handleCloseEdit = () => setOpenEdit(false);

  return (
    <>
      <div className='order-show__title'>
        <Tooltip title='Edit notes'>
          <div
            onClick={() => handleOpenEdit(order)}
            className='order-show__title__action icon_stroke'
          >
            <Icons.Edit />
          </div>
        </Tooltip>
        Note
      </div>
      {order.note}
      <UpdateOrderModal
        order={selectedOrder}
        open={openEdit}
        handleClose={handleCloseEdit}
        fields={['note']}
      />
    </>
  );
};

export default Note;
