import { put, takeLatest } from "redux-saga/effects";
import { NearVanActionCreators } from "src/store/reducers/nearVan/action-creators";
import { NearVanActionEnum } from "src/store/reducers/nearVan/types";
import instance from "../set";

function* fetchGetNearVan({
  id,
}: ReturnType<typeof NearVanActionCreators.getNearVan>) {
  try {
    const { data } = yield instance.get(`nearVan/${id}`);
    yield put(NearVanActionCreators.resultGetNearVan(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetNearVan() {
  yield takeLatest(NearVanActionEnum.REQUEST_NEARVAN, fetchGetNearVan);
}
