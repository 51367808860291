import { put, takeLatest } from "redux-saga/effects";
import { OnewayActionCreators } from "src/store/reducers/oneway/action-creators";
import { OnewayActionEnum } from "src/store/reducers/oneway/types";
import instance from "../set";

function* fetchGetOneways() {
  try {
    const { data } = yield instance.get('oneway');
    yield put(OnewayActionCreators.resultGetOneways(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetOneways() {
  yield takeLatest(OnewayActionEnum.REQUEST_ONEWAYS, fetchGetOneways);
}
