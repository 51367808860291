import {
  HostReportFilter,
  ReportActionEnum,
  ReportHostOrder,
  NewReportHostOrder,
  ReportHostStatistic,
  RequestReport,
  ResultReport,
  ErrorReport,
  RequestNewReport,
  ResultNewReport,
} from './types';

export const ReportActionCreators = {
  getHostReport: (filter: HostReportFilter): RequestReport => ({
    type: ReportActionEnum.REQUEST_REPORT,
    filter,
  }),
  resultHostReport: (data: {
    orders: ReportHostOrder[];
    statistic: ReportHostStatistic;
    link: string;
  }, status: number
  ): ResultReport => ({ type: ReportActionEnum.RESULT_REPORT, data, status }),
  errorHostReport: (): ErrorReport => ({ type: ReportActionEnum.ERROR_REPORT }),

  getNewHostReport: (filter: HostReportFilter): RequestNewReport => ({
    type: ReportActionEnum.REQUEST_NEW_REPORT,
    filter,
  }),
  resultNewHostReport: (data: {
    orders: NewReportHostOrder[];
    statistic: ReportHostStatistic;
  }, status: number
  ): ResultNewReport => ({ type: ReportActionEnum.RESULT_NEW_REPORT, data, status }),
};
