import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders, TimelineMarkers, TodayMarker } from 'react-calendar-timeline';
import { DateTime } from 'luxon';
import { orderBy } from 'natural-orderby';
import moment from 'moment';
import { CircularProgress, LinearProgress, Switch, Tooltip } from '@mui/material';
import ExpandIcon from '@mui/icons-material/Expand';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import AddBlockModal from './AddBlockModal';
import UpdateVanModal from '../Van/Modal/Update';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import SimpleMultiSelect from 'src/components/Items/select/SimpleMultiSelect';
import OrderInfo from './OrderInfo';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import useIsMobile from 'src/hooks/useIsMobile';
import { FetchCalendarDate } from 'src/store/reducers/calendar/types';
import { colorThemeOrder, getNames } from 'src/common/utils/others';
import { dineroFormat } from 'src/common/utils/dataFormat';
import { CalendarGraph } from 'src/entity/CalendarGraph';
import { ITag } from 'src/store/reducers/tag/types';
import { ICalendarFilter, IOrderFetch } from 'src/models/IOrder';
import { colorSet, toolTipStyle } from 'src/theme';
import 'react-calendar-timeline/lib/Timeline.css';
import './styles.scss';


interface ItextColorProps {
  oneway: string,
  time: string
}

const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const CalendarPage: FC = () => {
  const {
    getCalendar,
    getLocations,
    updateCalendar,
    getHosts, getTags,
    getFeatures,
    getVan
  } = useActions();
  const isMobile = useIsMobile();
  const {
    calendar: { data, isLoading },
    location: { locationList },
    host: { hostList },
    van: { van },
    auth,
  } = useTypedSelector((state) => state);
  const tags = useTypedSelector((state) => state.tag.tagList);
  const isLoadingVan = useTypedSelector((state) => state.van.isLoading.get);
  const RegionTags = tags.filter(
    (tag: ITag) => (tag.category as any) === 'region'
  );
  const leads = ['Default', 'Leads', 'All'];
  const [filter, setFilter] = useState<ICalendarFilter>({
    start_location: [],
    end_location: [],
    host_tag: [],
    region_tag: [],
    order_status: leads[0],
    all: false
  });
  const [itemMove, setItemMove] = useState<FetchCalendarDate>({
    orderList: [],
    vanList: [],
  });
  const [openBlock, setOpenBlock] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isBigCards, setIsBigCards] = useState(false);
  const [vanId, setVanId] = useState<number | null>();
  const [textColor, SetTextColor] = useState<ItextColorProps>({ oneway: '#7d879c', time: '#7d879c' });

  useEffect(() => {
    getLocations();
    getHosts();
    getTags();
    getFeatures();
  }, []);

  useEffect(() => {
    if (data) {
      setItemMove(data);
    }
  }, [data]);

  useEffect(() => {
    hostList.sort(function (a, b) {
      const nameA = a.host_tag[0].toLowerCase(),
        nameB = b.host_tag[0].toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }, [hostList]);

  useEffect(() => {
    getCalendar({
      ...filter,
      order_status: filter.order_status!.toLowerCase(),
    });
  }, [filter]);

  const handleOpenBlockModal = (id: number) => {
    setVanId(id);
    setOpenBlock(true);
  };

  const handleOpenEditVanModal = (id: number) => {
    getVan(id);
    setOpenEdit(true);
  };

  const handleChange =
    (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setFilter({
        ...filter,
        [name]: typeof event === 'string' ? event : event.target.value,
      });
    };

  const handleItemMove = (
    itemId: number,
    drugTime: number,
    newGroupOrder: number
  ) => {
    const newVanId = itemMove.vanList[newGroupOrder].id;

    setItemMove({
      ...itemMove,
      orderList: itemMove.orderList.map((order) =>
        order.id === itemId ? { ...order, van_id: newVanId } : order
      ),
    });
    updateCalendar({ id: itemId, van_id: newVanId }, { ...filter, 'order_status': filter.order_status!.toLowerCase() });
  };

  function chengeOnewaySwitch(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    checked ? (
      SetTextColor({ ...textColor, oneway: '#ff7990' }),
      setFilter({
        ...filter,
        order_status: 'oneway'
      })
    ) : (
      SetTextColor({ ...textColor, oneway: '#7d879c' }),
      setFilter({
        ...filter,
        order_status: 'default'
      })
    );
  }

  function chengeTimeSwitch(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    checked ? (
      SetTextColor({ ...textColor, time: 'rgb(152 217 26)' }),
      setFilter({
        ...filter,
        all: checked
      })
    ) : (
      SetTextColor({ ...textColor, time: '#7d879c' }),
      setFilter({
        ...filter,
        all: checked
      })
    );
  }

  const itemRenderer = ({ item, itemContext, getItemProps }: any) => {
    const order: IOrderFetch = item.order;

    const extras = order.fee_list.filter((ex) => (
      ex.id !== 1
      && ex.id !== 4
      && ex.id !== 5
      && ex.id !== 6
      && ex.id !== 7
      && ex.id !== 8
      && ex.id !== 9
      && ex.id !== 11
      && ex.id !== 16
    ));

    return (
      <>
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: toolTipStyle,
            },
          }}
          title={<OrderInfo order={order} extras={extras} />}
          open={itemContext.selected}
        >
          <div
            {...getItemProps({
              'aria-hidden': true,
              className: `calendar__item ${isBigCards ? 'big' : 'small'}`,
              style: {
                ...colorThemeOrder(order),
                whiteSpace: 'nowrap',
                color: colorSet.primaryDarkBlack,
                border: `1px solid ${colorSet.primaryBlue}`,
              },
            })}
            title=''
          >
            <div className='calendar__item__container'>
              {isBigCards ? (
                <div>
                  <div>
                    {order.delivery_address ?
                      order.delivery_address.length > 10 ?
                        `${order.delivery_address.substring(0, 10)}...` : order.delivery_address
                      : `${order.location}${order.sub_location && ` | ${order.sub_location}`}`
                    }&nbsp;
                    {DateTime.fromISO(order.start_date, { zone: 'UTC' })
                      .plus({ minutes: order.timeOffset, }).toFormat('t')
                    }
                  </div>
                  <div>
                    {order.return_at ?
                      order.return_at.length > 10 ?
                        `${order.return_at.substring(0, 10)}...` : order.return_at
                      : `${order.end_location || order.location}${order.sub_location_end && ` | ${order.sub_location_end}`}`
                    }&nbsp;
                    {DateTime.fromISO(order.end_date, { zone: 'UTC' })
                      .plus({ minutes: order.timeOffset, }).toFormat('t')
                    }
                  </div>
                </div>
              ) : (
                <div>
                  {`${order.delivery_address ?
                    order.delivery_address.length > 10 ?
                      `${order.delivery_address.substring(0, 10)}...` : order.delivery_address
                    : order.location
                    } | 
                  ${order.return_at ?
                      order.return_at.length > 10 ?
                        `${order.return_at.substring(0, 10)}...` : order.return_at
                      : order.end_location || order.location
                    }`
                  }
                </div>
              )}
            </div>
            <div className={`calendar__item__container__bottom-line ${isBigCards ? 'big' : 'small'}`}>
              <div>
                {`${order.id} ${order.amount_seats ? order.amount_seats : ''} `}
                <span style={{ textTransform: 'capitalize' }}>
                  {order.client.last_name}
                </span>
              </div>
              <div>
                {(order.note && order.note.length > 20) ? `${order.note.substring(0, 20)}...` : order.note}
              </div>
              <div style={{ display: isBigCards ? 'block' : 'none' }}>
                Extras: {`[ ${extras.map(({ name }) => name).join(' , ')} ]`}
              </div>
            </div>
          </div>
        </Tooltip>
      </>
    );
  };

  const calendarData = useMemo(() => {
    return itemMove.orderList.length > 0 && new CalendarGraph(itemMove, isBigCards);
  }, [filter.start_location, filter.end_location, itemMove, isBigCards]);

  const groupRenderer = ({ group }: any) => (
    <>
      <div
        className={
          group.isSectionHeader ? 'calendar__group' : 'calendar__group__item'
        }
      >
        <div onClick={() => handleOpenEditVanModal(group.id)}>
          <div>{isMobile ? group.title.slice(-3) : group.title}</div>
          {!isMobile && (
            <div className='calendar__group__info'>
              <span>{group.make && group.make.slice(0, 4)}</span>
              <span>{group.seats}</span>
              <span>{dineroFormat(group.price, group.currency)}</span>
            </div>
          )}
        </div>
      </div>
      <EventBusyIcon fontSize="small" className='calendar__lockIcon' onClick={() => handleOpenBlockModal(group.id)} />
    </>
  );

  const sortedLocation = orderBy(
    locationList,
    [(location) => location.country, (location) => location.name],
    ['asc', 'asc']
  );

  if (isLoading.all && !data) return <LinearProgress />;
  else if (data)
    return (
      <>
        {isLoadingVan &&
          <CircularProgress size={200}
            style={{ position: 'fixed', top: '40%', left: '45%', zIndex: 100 }}
          />
        }
        <AddBlockModal
          handleClose={() => setOpenBlock(false)}
          open={openBlock}
          van={vanId!}
          filter={filter}
        />
        {!isLoadingVan && van &&
          <UpdateVanModal van={van}
            handleClose={() => setOpenEdit(false)}
            open={openEdit}
          />
        }
        <div className='calendar'>
          <div className='calendar-filter'>
            <SimpleMultiSelect
              data={filter.start_location!}
              setData={handleChange('start_location')}
              dataArray={getNames(sortedLocation)}
              placeholder='Select start location'
              variant='outlined'
            />
            {auth.role === 'admin' && (
              <SimpleMultiSelect
                data={filter.host_tag!}
                setData={handleChange('host_tag')}
                placeholder='Select host '
                variant='outlined'
                dataArray={hostList}
                propsShow={['first_name', 'last_name']}
              />
            )}
            <SimpleSelect
              data={filter.order_status}
              setData={handleChange('order_status')}
              dataArray={leads}
              placeholder='start type'
              variant='outlined'
              disabled={filter.order_status === 'oneway' ? true : false}
            />
            {auth.role === 'admin' && (
              <SimpleMultiSelect
                data={filter.region_tag!}
                setData={handleChange('region_tag')}
                dataArray={RegionTags.map((tag) => tag.name)}
                placeholder='Select region'
                variant='outlined'
              />
            )}
            <div className='calendar-filter__oneway'>
              <div className='calendar-filter__oneway__item'>
                <Switch {...label} onChange={chengeOnewaySwitch} />
                <p style={{ color: `${textColor.oneway}` }}>1way only</p>
              </div>
              <div className='calendar-filter__oneway__item'>
                <Switch {...label} onChange={chengeTimeSwitch} />
                <p style={{ color: `${textColor.time}` }}>30day | All</p>
              </div>
            </div>
            {/* <SimpleMultiSelect
              data={endLocation}
              setData={handleChangeLocation(setEndLocation)}
              dataArray={getNames(locationList)}
              placeholder="Select end location"
              variant="outlined"
            /> */}
          </div>
          {calendarData && (
            <Timeline
              groups={calendarData.groups}
              items={calendarData.items}
              sidebarWidth={isMobile ? 67 : 140}
              buffer={1}
              canMove={true}
              canResize={false}
              canChangeGroup={true}
              onItemClick={(itemId) => window.open(`/order/${itemId}`, '_blank')}
              defaultTimeStart={moment().add(isMobile ? -2 : -5, 'days')}
              defaultTimeEnd={moment().add(isMobile ? 10 : 15, 'days')}
              itemRenderer={itemRenderer}
              groupRenderer={groupRenderer}
              onItemMove={handleItemMove}
              visibleTimeStart={new Date()}
            >
              <TimelineMarkers>
                <TodayMarker date={new Date()} />
              </TimelineMarkers>
              <TimelineHeaders>
                <SidebarHeader>
                  {({ getRootProps }) => {
                    return (
                      <div
                        {...getRootProps()}
                        className='calendar-sidebar'
                      >
                        <Tooltip title='Change items display'>
                          <ExpandIcon
                            fontSize='large'
                            onClick={() => setIsBigCards(!isBigCards)}
                          />
                        </Tooltip>
                      </div>
                    );
                  }}
                </SidebarHeader>
                <DateHeader unit="primaryHeader" />
                <DateHeader />
              </TimelineHeaders>
            </Timeline>
          )}
        </div>
      </>
    );
  else return null;
};
export default CalendarPage;
