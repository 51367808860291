import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.scss";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { secondColor } from "src/theme";
SwiperCore.use([Pagination, Navigation]);

interface IProps {
  data: any[];
  onClick?: any;
}

const CustomSwiper: FC<IProps> = ({ data, onClick }) => {
  return (
    <>
      <Swiper
        style={{
          //@ts-ignore
          "--swiper-navigation-color": secondColor,
          "--swiper-pagination-color": secondColor,
        }}
        breakpoints={{
          "320": {
            slidesPerView: 2,
          },
          "1024": {
            slidesPerView: 3,
          },
          "1440": {
            slidesPerView: 4,
          },
          "1920": {
            slidesPerView: 6,
          },
        }}
        spaceBetween={20}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
      >
        {data.map((photo, index) => (
          <SwiperSlide key={index}>
            <img
              src={photo}
              alt="swiper"
              loading='lazy'
              onClick={() => onClick(index)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CustomSwiper;
