import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Currency } from 'dinero.js';
import { Backdrop, Modal, Tooltip } from '@mui/material';
import NumberInput from 'src/components/Items/input/NumberInput';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import AllModal from 'src/components/Items/modal/AllModal';
import MultiplyUploadFiles from 'src/components/Items/MultiplyUploadFiles';
import SelectBaseOnId from 'src/components/Items/select/SelectBaseOnId';
import SimpleMultiSelect from 'src/components/Items/select/SimpleMultiSelect';
import SublocationsSelect from 'src/components/Items/select/SublocationsSelect';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import CustomCheckbox from 'src/components/Items/button/CustomCheckbox';
import LocationsNearby from './LocationsNearby';
import TagModule from '../Tag';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { INearVanFetch } from 'src/models/INearVan';
import { IVan } from 'src/models/IVan';
import { getNames } from 'src/common/utils/others';
import Icons from 'src/common/icons';
import './LocationsNearby/styles.scss';

interface IProps {
  id?: number;
  van: IVan;
  setVan: any;
  interiorImages: any[];
  setInteriorImages: any;
  exteriorImages: any[];
  setExteriorImages: any;
  deletedImages: string[];
  setDeletedImages: any;
  setNewInteriorImages?: any;
  newInteriorImages?: any;
  newExteriorImages?: any;
  setNewExteriorImages?: any;
}

const currencyTypeArray: Currency[] = ['USD', 'EUR'];
const statusArray = ['free', 'rent', 'break'];
const typeVanArray = ['passenger', 'cargo'];

const VanFormModal: FC<IProps> = ({
  id,
  van,
  setVan,
  interiorImages,
  setInteriorImages,
  exteriorImages,
  setExteriorImages,
  deletedImages,
  setDeletedImages,
  setNewInteriorImages,
  newInteriorImages,
  newExteriorImages,
  setNewExteriorImages,
}) => {
  const {
    price, status, seats, location, type,
    description, features, currency, tags
  } = van;
  const {
    tag: { tagList },
    feature: { featureList },
    host: { hostList },
    location: { locationList },
    nearVan: { nearVanList, result },
    auth,
  } = useTypedSelector((state) => state);

  const { addFeature, deleteFeature, getNearVans } = useActions();
  const [nearVans, setNearVans] = useState<INearVanFetch[]>([]);

  const sortedTags = tags.sort(function (a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const subTags = sortedTags.filter((tag) => (tag.category as any) === 'sublocation');

  useEffect(() => {
    id && getNearVans();
  }, [result]);

  useEffect(() => {
    const newArray = nearVanList.filter((el) => el.van_id === id);
    setNearVans(newArray);
  }, [nearVanList]);

  const handleChangeMultiSelect = (
    event: ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const {
      target: { value },
    } = event;
    setVan(type)(typeof value === 'string' ? value.split(',') : value);
  };
  const handleMultipleUploadFile =
    (images: string[], setImages: any) => (files: File[] | File) => {
      const newPhotos = [...images];
      Object.values(files).forEach((file) => newPhotos.push(file));
      setImages(newPhotos);
    };

  const handleDeleteFile =
    (images: string[], setImages: any) => (url: string, index: number) => {
      setImages([...images.slice(0, index), ...images.slice(index + 1)]);
      setDeletedImages([...deletedImages, url]);
    };

  const [openExtraModel, setOpenExtraModel] = useState(false);
  const handleOpenExtraModel = () => setOpenExtraModel(true);
  const handleCloseExtraModel = () => setOpenExtraModel(false);

  const actionsExtra = {
    add: addFeature,
    delete: deleteFeature,
  };

  const _renderInput = (
    name: keyof Pick<
      IVan,
      | 'cargo_space'
      | 'description'
      | 'license_plate'
      | 'mark'
      | 'model'
      | 'currency'
      | 'status'
      | 'type'
      | 'vin_code'
      | 'keycode'
      | 'link'
      | 'year'
      | 'make'
    >,
    title: string = name,
    length: number = 70
  ) => (
    <SimpleInput
      data={van[name]!}
      setData={setVan(name)}
      title={title}
      length={length}
      disabled={name === 'model' && auth.role !== 'admin'}
    />
  );

  return (
    <div className='col-2'>
      {auth.role === 'admin' ? (
        <div className='col-3' style={{ gridColumn: '1/3' }}>
          {_renderInput('mark', 'header')}
          {_renderInput('model', 'name code')}
          <SelectBaseOnId
            dataId={van.host_id}
            setDataId={setVan('host_id')}
            title='host'
            dataArray={hostList}
            propsShow={['first_name', 'last_name']}
          />
        </div>
      ) : (
        <div className='col-2' style={{ gridColumn: '1/3' }}>
          {_renderInput('mark', 'header')}
          {_renderInput('model', 'name code')}
        </div>
      )}
      <div className='col-4'>
        <NumberInput data={seats} setData={setVan('seats')} title='Seats' />
        <SimpleSelect
          data={type}
          setData={setVan('type')}
          title='type'
          dataArray={typeVanArray}
        />
        <SimpleInput data={price} setData={setVan('price')} title='price' />
        <SimpleSelect
          data={currency}
          setData={setVan('currency')}
          title='currency'
          dataArray={currencyTypeArray}
        />
      </div>
      <div>
        <div style={{ position: 'relative' }}>
          <SimpleMultiSelect
            data={features}
            setData={(event) => handleChangeMultiSelect(event, 'features')}
            dataArray={getNames(featureList)}
            title='features'
          />
          {auth.role === 'admin' && (
            <Tooltip title='Add features'>
              <div
                className='icon_delete-row adding icon_stroke'
                onClick={handleOpenExtraModel}
              >
                <Icons.Plus />
              </div>
            </Tooltip>
          )}
          <Modal
            open={openExtraModel}
            onClose={handleCloseExtraModel}
            closeAfterTransition
            BackdropComponent={Backdrop}
          >
            <>
              <AllModal
                handleClose={handleCloseExtraModel}
                data={featureList}
                title='feature'
                actions={actionsExtra}
              />
            </>
          </Modal>
        </div>
      </div>
      {_renderInput('make')}
      {_renderInput('vin_code', 'vin code')}
      <div style={{ gridColumn: '1/3' }}>
        {_renderInput('keycode', 'Lockbox location & code', 1000)}
      </div>
      <div style={{ gridColumn: '1/3' }}>
        {_renderInput('link', 'Custom Link', 1000)}
      </div>
      <div style={{ position: 'relative', gridColumn: '1/3' }}>
        <TagModule
          tags={sortedTags
            .filter((tag) =>
              auth.role === 'admin'
                ? tag
                : (tag.category as any) !== 'host' && tag
            )}
          setTags={setVan('tags')}
          tagList={tagList}
          role={auth.role}
        />
      </div>
      <div className='col-2'>
        <SimpleSelect
          data={status}
          setData={setVan('status')}
          title='status'
          dataArray={statusArray}
        />
        <SublocationsSelect
          data={location}
          setData={setVan('location')}
          title='Home location'
          dataArray={locationList}
          isChooseLocations
        />
      </div>
      <div className='col-3'>
        {_renderInput('cargo_space', 'cargo space')}
        {_renderInput('license_plate', 'license plate')}
        {_renderInput('year')}
      </div>
      <SimpleInput
        data={description}
        setData={setVan('description')}
        title='description'
        style={{ gridColumn: '1/3' }}
        placeholder="Maximum 220 symbols will show at client's page"
        rows={2}
        fullWidth
        multiline
        variant='outlined'
      />
      {subTags && (
        <div style={{ gridColumn: '1/3' }}>
          <div className='filter__title nearby-locations__title'>
            Sublocations
          </div>
          <div className='nearby-locations__container'>
            {subTags.map((tag, key) => (
              <div
                className="modal__item van-card__tags__item"
                key={key}
                title={tag.name}
              >
                {tag.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {/* {id && <LocationsNearby nearVan={nearVans} vanId={id} currency={van.currency} />} */}
      {auth.role === 'admin' && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomCheckbox
            onChange={setVan('isVirtual')}
            checked={van.isVirtual ? van.isVirtual : false}
            name={'virtual'}
          />
          <p className='filter__title'
            style={{ marginBottom: 0, marginLeft: '10px' }}
          >
            Virtual
          </p>
        </div>
      )}
      <MultiplyUploadFiles
        nameUploader='interiorPhotos'
        previousFiles={van.photos.interior}
        changeOrder={setVan('interiorPhotos')}
        onChangeField={newInteriorImages ? (
          handleMultipleUploadFile(newInteriorImages, setNewInteriorImages)
        ) : (
          handleMultipleUploadFile(interiorImages, setInteriorImages)
        )}
        handleDeleteFile={handleDeleteFile(interiorImages, setInteriorImages)}
        label='Browse interior photos'
      />
      <MultiplyUploadFiles
        nameUploader='exteriorPhotos'
        previousFiles={van.photos.exterior}
        changeOrder={setVan('exteriorPhotos')}
        onChangeField={newExteriorImages ? (
          handleMultipleUploadFile(newExteriorImages, setNewExteriorImages)
        ) : (
          handleMultipleUploadFile(exteriorImages, setExteriorImages)
        )}
        handleDeleteFile={handleDeleteFile(exteriorImages, setExteriorImages)}
        label='Browse exterior photos'
      />
    </div>
  );
};
export default VanFormModal;