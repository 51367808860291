import { ChangeEvent, FC } from "react";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import SimpleMultiSelect from "../../select/SimpleMultiSelect";
import { useActions } from "src/hooks/useActions";
import { getNames } from "src/common/utils/others";

const Feature: FC = () => {
  const { setFeatureFilter } = useActions();
  const state = useTypedSelector((state) => state);
  const { featureList, isLoading } = state.feature;
  const filters = state.van.filters;

  const setFeatures = (features: string[]) => setFeatureFilter(features);

  const handleChangeMultiSelect = (
    event: ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    const {
      target: { value },
    } = event;
    type(typeof value === "string" ? value.split(",") : value);
  };

  return !isLoading.all ? (
    <div className="filter-features">
      <div>
        <SimpleMultiSelect
          data={filters.features!}
          setData={(e) => handleChangeMultiSelect(e, setFeatures)}
          dataArray={getNames(featureList)}
          title="features"
        />
      </div>
    </div>
  ) : null;
};

export default Feature;
