import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { SubPriceActionCreators } from "src/store/reducers/subPrice/action-creators";
import { SubPriceActionEnum } from "src/store/reducers/subPrice/types";

function* fetchDeleteAllSubPrices({
  id,
}: ReturnType<typeof SubPriceActionCreators.deleteAllSubPrices>) {
  try {
    const { status }: Response = yield instance.delete(`priceForSub/all/${id}`);
    yield put(SubPriceActionCreators.resultDeleteAllSubPrices(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteAllSubPrices() {
  yield takeLatest(SubPriceActionEnum.REQUEST_DELETE_ALL_SUBPRICES, fetchDeleteAllSubPrices);
}
