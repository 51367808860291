import { put, takeLatest } from 'redux-saga/effects';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';
import instance from '../../set';

function* fetchUpdatePaymentOrder({
  order_id,
  charges
}: ReturnType<typeof OrderActionCreators.updatePaymentOrder>) {
  try {
    const { data, status } = yield instance.post(`stripe/after_charge/hold/${order_id}`, {
      charges
    });
    yield put(OrderActionCreators.resultUpdatePaymentOrder(data, status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdatePaymentOrder() {
  yield takeLatest(
    OrderActionEnum.REQUEST_UPDATE_PAYMENT_ORDER,
    fetchUpdatePaymentOrder
  );
}
