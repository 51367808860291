import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { HostActionCreators } from "src/store/reducers/host/action-creators";
import { HostActionEnum } from "src/store/reducers/host/types";


function* fetchDeleteHost({
  id,
}: ReturnType<typeof HostActionCreators.deleteHost>) {
  try {
    const { status }: Response = yield instance.delete(`host/${id}`);
    yield put(HostActionCreators.resultDeleteHost(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteHost() {
  yield takeLatest(HostActionEnum.REQUEST_DELETE_HOST, fetchDeleteHost);
}
