import Tab from "@mui/material/Tab";
import { FC } from "react";
import { Link } from "react-router-dom";
import { colorSet, mainColor } from "src/theme";

interface IProps {
  label?: string;
  link: string;
  iconPosition?: "bottom" | "top" | "start" | "end";
  icon?: JSX.Element;
  selected?: boolean;
  title?: string;
}
const LinkTab: FC<IProps> = (props) => {
  const hover = props.selected && {
    ".icon-tab path": {
      fill: mainColor,
    },
    ".icon-tab circle": {
      fill: mainColor,
    },
  };

  return (
    <Link
      to={props.link}
      title={props.title}
      style={{
        textDecoration: "none",
        backgroundColor: props.selected ? colorSet.primaryLightGrey : "",
        border: props.title === 'Admin' ? `solid ${colorSet.primaryGreen}` : 'none',
      }}
    >
      <Tab
        icon={props.icon}
        iconPosition={props.iconPosition}
        {...props}
        sx={{
          justifyContent: "start",
          width: "100%",
          textTransform: "none",
          minHeight: "50px",
          transition: "0.5s all",
          padding: props.title === 'Chat' ? '10px 12px' : '12px 16px',
          "&:hover": {
            color: mainColor,
            background: colorSet.primaryLightGrey,
            paddingLeft: props.title === 'Chat' ? '15px' : '20px',
            ".icon-tab path": {
              fill: mainColor,
            },
            ".icon-tab circle": {
              fill: mainColor,
            },
          },
          ...hover,
        }}
      />
    </Link>
  );
};
export default LinkTab;
