import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { OrderActionCreators } from "src/store/reducers/order/action-creators";
import { OrderActionEnum } from "src/store/reducers/order/types";

function* fetchDeleteOrder({
  id,
}: ReturnType<typeof OrderActionCreators.deleteOrder>) {
  try {
    const { status }: Response = yield instance.delete(`order/${id}`);
    yield put(OrderActionCreators.resultDeleteOrder(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteOrder() {
  yield takeLatest(OrderActionEnum.REQUEST_DELETE_ORDER, fetchDeleteOrder);
}
