import { put, takeLatest } from "redux-saga/effects";
import { OrderActionCreators } from "src/store/reducers/order/action-creators";
import { OrderActionEnum } from "src/store/reducers/order/types";
import instance from "../set";

function* fetchGetOrders({
  filter,
}: ReturnType<typeof OrderActionCreators.getOrders>) {
  try {
    const {
      data: { count, orderList },
    } = yield instance.post(`order`, { filters: filter });
    yield put(OrderActionCreators.resultGetOrders(count, orderList));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetOrders() {
  yield takeLatest(OrderActionEnum.REQUEST_ORDERS, fetchGetOrders);
}
