import { ISeasonPrice, ISeasonPriceFetch } from "src/models/ISeasonPrice";
import { 
  AddSeasonPriceAction, 
  GetSeasonPriceAction, 
  SeasonPriceActionEnum, 
  ResultAddSeasonPriceAction, 
  ResultGetSeasonPriceAction, 
  ResultUpdateSeasonPriceAction, 
  UpdateSeasonPriceAction, 
  DeleteSeasonPriceAction, 
  ResultDeleteSeasonPriceAction, 
  ClearSeasonPriceResultAction
} from "./types";

export const SeasonPriceActionCreators = {
  getSeasonPrice: (): GetSeasonPriceAction => ({
    type: SeasonPriceActionEnum.REQUEST_SEASONPRICE,
  }),
  resultGetSeasonPrice: (data: ISeasonPriceFetch[]): ResultGetSeasonPriceAction => ({
    type: SeasonPriceActionEnum.RESULT_SEASONPRICE,
    data,
  }),
  addSeasonPrice: (data: ISeasonPrice): AddSeasonPriceAction => ({
    type: SeasonPriceActionEnum.REQUEST_ADD_SEASONPRICE,
    data,
  }),
  resultAddSeasonPrice: (status: number): ResultAddSeasonPriceAction => ({
    type: SeasonPriceActionEnum.RESULT_ADD_SEASONPRICE,
    status,
  }),
  updateSeasonPrice: (currentSeasonPrice: ISeasonPriceFetch, id: string | number): UpdateSeasonPriceAction => ({
    type: SeasonPriceActionEnum.REQUEST_UPDATE_SEASONPRICE,
    currentSeasonPrice,
    id
  }),
  resultUpdateSeasonPrice: (status: number): ResultUpdateSeasonPriceAction => ({
    type: SeasonPriceActionEnum.RESULT_UPDATE_SEASONPRICE,
    status,
  }),
  deleteSeasonPrice: (id: number): DeleteSeasonPriceAction => ({
    type: SeasonPriceActionEnum.REQUEST_DELETE_SEASONPRICE,
    id,
  }),
  resultDeleteSeasonPrice: (status: number): ResultDeleteSeasonPriceAction => ({
    type: SeasonPriceActionEnum.RESULT_DELETE_SEASONPRICE,
    status,
  }),
  clearSeasonPriceResult: (): ClearSeasonPriceResultAction => ({
    type: SeasonPriceActionEnum.CLEAR_SEASONPRICE_RESULT
  }),
};
