import { put, takeLatest } from "redux-saga/effects";
import { TagActionCreators } from "src/store/reducers/tag/action-creators";
import { TagActionEnum } from "src/store/reducers/tag/types";
import instance from "../set";

function* fetchAddTag({ data }: ReturnType<typeof TagActionCreators.addTag>) {
  try {
    const { status }: Response = yield instance.post(`tag/add`, {
      data,
    });
    yield put(TagActionCreators.resultAddTag(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddTag() {
  yield takeLatest(TagActionEnum.REQUEST_ADD_TAG, fetchAddTag);
}
