import lodash from "lodash";
import validator from "validator";

const validateEmail = (val: string): string => {
  if (val.trim() === "") return "Email can not be empty";
  if (!validator.isEmail(val)) {
    return "Email is not valid";
  }
  return "";
};

const validatePassword = (val: string, username: string): string => {
  const PassRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/;
  if (val.trim() === "") return "Password can not be empty";
  if (!PassRegex.test(val.trim())) {
    return "Min 8 characters, at least 1 uppercase and 1 lowercase letter, 1number and 1special character";
  }
  if (val.includes("password")) {
    return "Can not be Password";
  }
  if (val === username) {
    return "Password can not be same as email";
  }
  return "";
};

const validateField = (val: string): string => {
  if (val.trim() === "") return "Field can not be empty";
  if (val.length < 2) return "Value should be at least 2 chars long";
  return "";
};
const validateBirthday = (val: string | null): string => {
  if (!val) return "Date of birth can not be empty";
  return "";
};

const validatePhone = (val: string): string => {
  if (val.trim() === "") return "Field can not be empty";
  if (!validator.isMobilePhone(val)) return "Invalid field format";
  return "";
};

const compareValues = (val1: string, val2: string): string => {
  if (val1 !== val2) return `Passwords didn't match`;
  return "";
};

const compareNumbers = (val1: string | number, val2: string | number): string => {
  if (String(val1).trim() === "") return "Field can not be empty";
  if (lodash.gt(Number(val1), Number(val2))) return "Refund cannot exceed the price.";
  return "";
};

const validateAddress = (val: string): string => {
  if (val.trim() === "") return "Field can not be empty";
  if (val.includes("CT") || val.includes("NY") || val.includes("NJ")) return "";
  return "Address should be in NY, NJ or CT state";
};

const validateSsn = (val: string): string => {
  const PassRegex =
    /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
  if (val.trim() === "") return "Filed can not be empty";
  if (!PassRegex.test(val.trim())) {
    return "Incorrect SSN format";
  }
  return "";
};

const validateDate = (val?: Date) => {
  function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d as any);
  }
  if (val && !isValidDate(val)) {
    return "Select right date";
  } else if (!val) return "Select date";
  else return "";
};

const validateIs = (name: string, val?: string | null) =>
  val ? "" : `Please, select ${name}`;

const validateEndLocation = (start: string, end: string): string => {
  if (end.trim() === "") return "Field can not be empty";
  if (start === end) return "Locations shouldn't match";
  return "";
};

export {
  compareValues,
  validateAddress,
  validateSsn,
  validateIs,
  validatePhone,
  validateBirthday,
  validateEmail,
  validatePassword,
  validateField,
  validateDate,
  validateEndLocation,
  compareNumbers
};
