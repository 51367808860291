import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { NearVanActionCreators } from "src/store/reducers/nearVan/action-creators";
import { NearVanActionEnum } from "src/store/reducers/nearVan/types";

function* fetchAddNearVans({
    data,
}: ReturnType<typeof NearVanActionCreators.addNearVans>) {
  try {
    const { status }: Response = yield instance.post('nearVan', data);
    yield put(NearVanActionCreators.resultAddNearVans(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddNearVans() {
  yield takeLatest(NearVanActionEnum.REQUEST_ADD_NEARVANS, fetchAddNearVans);
}