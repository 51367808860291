import { FC, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

interface IProps {
    setSearch: any;
    width?: string;
    placeholder: string;
}

const SearchInput: FC<IProps> = ({ setSearch, width, placeholder }) => {

    const [searchParam, setSearchParam] = useState<string>('');

    useEffect(() => {
        const listener = (event: { code: string; preventDefault: () => void; }) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                setSearch(searchParam);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [searchParam]);

    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: width || 200 }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={placeholder}
                onChange={(e) => setSearchParam(e.target.value)}
            />
            <IconButton
                type="button"
                sx={{ p: '10px' }}
                onClick={() => setSearch(searchParam)}
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    );
};
export default SearchInput;
