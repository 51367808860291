import { FC } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { DateTime } from 'luxon';
import { useSnackbar } from "notistack";
import { Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IExtra, IOrderFetch } from 'src/models/IOrder';

const OrderInfo: FC<{ order: IOrderFetch, extras: IExtra[] }> = ({
  order: {
    id,
    start_date,
    end_date,
    location,
    delivery_address,
    return_at,
    note,
    end_location,
    timeOffset,
    van,
    client
  },
  extras
}) => {
  const clipboard = useClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const text = `
  Order: #${id}
  Plates: ${van.license_plate}
  Client: ${client.last_name}
  Phone: ${client.phone}
  Out: ${DateTime.fromISO(start_date, { zone: 'UTC' })
      .plus({
        minutes: timeOffset,
      }).toFormat('ff')} | ${location}
  End: ${DateTime.fromISO(end_date, { zone: 'UTC' })
      .plus({
        minutes: timeOffset,
      }).toFormat('ff')} | ${end_location || location}
  ${delivery_address && (`Delivery to: ${delivery_address}`)}
  ${extras && (
      `Extras: ${`[ ${extras.map(({ name }) => name).join(' , ')} ]`}`
    )}
  ${note && (`Note: ${note}`)}
  `;

  const handleClick = () => {
    clipboard.copy(text);
    enqueueSnackbar('Info copied successfully', { variant: 'success' });
  };

  return (
    <div className='calendar__order-info'>
      <div className='calendar__order-info__container'>
        <div>
          <b>Order: </b>{`#${id}`} | <b>Plate: </b>{van.license_plate}
        </div>
        <Tooltip title='Copy info'>
          <div
            className='calendar__order-info__icon'
            onClick={handleClick}
          >
            <ContentCopyIcon fontSize='small' />
          </div>
        </Tooltip>
      </div>
      <div>
        <span style={{ textTransform: 'capitalize' }}>
          {client.last_name}
        </span> | <b>T: </b>{client.phone}
      </div>
      <div>
        <b>Out: </b>
        {`${DateTime.fromISO(start_date, { zone: 'UTC' })
          .plus({
            minutes: timeOffset,
          })
          .toFormat('ff')} | ${location}`}
      </div>
      {delivery_address && (
        <div>
          {delivery_address}
        </div>
      )}
      <div>
        <b>End: </b>
        {`${DateTime.fromISO(end_date, { zone: 'UTC' })
          .plus({
            minutes: timeOffset,
          })
          .toFormat('ff')} | ${end_location || location}`}
      </div>
      {return_at && (
        <div>
          {return_at}
        </div>
      )}
      {extras && (
        <div>
          <b>Extras: </b>
          {`[ ${extras
            .map(({ name }) => name)
            .join(' , ')} ]`}
        </div>
      )}

      {note && (
        <div>
          <b>Note: </b>
          {note}
        </div>
      )}
    </div>
  );
};

export default OrderInfo;
