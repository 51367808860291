import { put, takeLatest } from 'redux-saga/effects';
import { ClientActionEnum } from 'src/store/reducers/client/types';
import instance from '../set';
import { ClientActionCreators } from 'src/store/reducers/client/action-creators';
import { Client } from 'src/entity/Client';
import { CleaningServices } from '@mui/icons-material';

function* fetchUpdateClient({
  currentClient,
}: ReturnType<typeof ClientActionCreators.updateClient>) {
  const { id, ...client } = currentClient;
  try {
    const { status }: Response = yield instance.patch(`client/${id}`, {
      client
    });
    yield put(ClientActionCreators.resultUpdateClient(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdateClient() {
  yield takeLatest(ClientActionEnum.REQUEST_UPDATE_CLIENT, fetchUpdateClient);
}
