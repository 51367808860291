import { put, takeLatest } from "redux-saga/effects";
import { SeasonPriceActionCreators } from "src/store/reducers/seasonPrice/action-creators";
import { SeasonPriceActionEnum } from "src/store/reducers/seasonPrice/types";
import instance from "../set";

function* fetchGetSeasonPrice() {
  try {
    const { data } = yield instance.get('seasonPrice');
    yield put(SeasonPriceActionCreators.resultGetSeasonPrice(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetSeasonPrice() {
  yield takeLatest(SeasonPriceActionEnum.REQUEST_SEASONPRICE, fetchGetSeasonPrice);
}
