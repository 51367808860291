import { put, takeLatest } from 'redux-saga/effects';
import { SublocationActionCreators } from "src/store/reducers/sublocation/action-creators";
import { SublocationActionEnum } from "src/store/reducers/sublocation/types";
import instance from '../set';

function* fetchUpdateSublocation({
  currentSublocation, id
}: ReturnType<typeof SublocationActionCreators.updateSublocation>) {
  try {
    const { status }: Response = yield instance.patch(
      `sublocation/${id}`,
      {'data': {'name': currentSublocation}}
    );
    yield put(SublocationActionCreators.resultUpdateSublocation(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdateSublocation() {
  yield takeLatest(SublocationActionEnum.REQUEST_UPDATE_SUBLOCATION, fetchUpdateSublocation);
}