import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import { Outlet } from 'react-router-dom';
import VerticalTabs from '../Navigator/Tabs/VerticalTabs';
import useIsMobile from 'src/hooks/useIsMobile';
import Logo from '../Items/Logo';
import { useTypedSelector } from 'src/hooks/useTypeSelector';

const MainLayout: FC = () => {
  // const { hideBarMenu } = useTypedSelector((state) => state.global);
  // const width = useMemo(() => (hideBarMenu ? '60px' : '200px'), [hideBarMenu]);

  const isMobile = useIsMobile();

  return (
    <Box sx={{ borderRadius: 0 }}>
      <AppBar
        position='fixed'
        sx={{
          width: '60px'
        }}
      />
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            transition: '0.5s all',
            width: '60px',
            boxSizing: 'border-box',
            borderRadius: 0,
            display: isMobile ? 'none' : 'block'
          },
        }}
        variant='permanent'
        anchor='left'
      >
        {/* <Logo hideBarMenu={true} /> */}
        <div className="logo abbr">SR</div>
        <VerticalTabs hideBarMenu={true} />
      </Drawer>
      <Box
        component='main'
        className={'active'}
        sx={{ margin: '0 0 0 auto', padding: '16px', minHeight: '100vh' }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
