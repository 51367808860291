import { put, takeLatest } from 'redux-saga/effects';
import { CalendarActionCreators } from 'src/store/reducers/calendar/action-creators';
import {
  CalendarActionEnum,
  FetchCalendarDate,
} from 'src/store/reducers/calendar/types';
import instance from '../set';

function* fetchGetCalendar({
  filter
}: ReturnType<typeof CalendarActionCreators.getCalendar>) {
  try {
   
    const { data }: { data: FetchCalendarDate } = yield instance.post(
      `calendar`,
      {
        filter: filter,
      }
    );
    yield put(CalendarActionCreators.resultGetCalendar(data));
  } catch (error) {
    console.log('error :>> ', error);
  }
}
export function* actionWatcherGetCalendar() {
  yield takeLatest(CalendarActionEnum.REQUEST_CALENDAR, fetchGetCalendar);
}
