import { ISubPrice, ISubPriceFetch } from "src/models/ISubPrice";

export enum SubPriceActionEnum {
  REQUEST_SUBPRICE = "REQUEST_SUBPRICE",
  RESULT_SUBPRICE = "RESULT_SUBPRICE",
  ERROR_SUBPRICE = "ERROR_SUBPRICE",

  REQUEST_ADD_SUBPRICE = "REQUEST_ADD_SUBPRICE",
  RESULT_ADD_SUBPRICE = "RESULT_ADD_SUBPRICE",

  REQUEST_UPDATE_SUBPRICE = "REQUEST_UPDATE_SUBPRICE",
  RESULT_UPDATE_SUBPRICE = "RESULT_UPDATE_SUBPRICE",

  REQUEST_DELETE_SUBPRICE = "REQUEST_DELETE_SUBPRICE",
  RESULT_DELETE_SUBPRICE = "RESULT_DELETE_SUBPRICE",

  REQUEST_DELETE_ALL_SUBPRICES = 'REQUEST_DELETE_ALL_SUBPRICES',
  RESULT_DELETE_ALL_SUBPRICES = 'RESULT_DELETE_ALL_SUBPRICES',

  CLEAR_SUBPRICE_RESULT = 'CLEAR_SUBPRICE_RESULT'
}

export interface SubPriceState {
    subPriceList: ISubPriceFetch[];
    isLoading: {
        all: boolean;
        get: boolean;
        add: boolean;
        delete: boolean;
        update: boolean;
    };
    result: {
        add?: number | string;
        update?: number | string;
        delete?: number | string;
    };
}

export interface GetSubPriceAction {
  type: SubPriceActionEnum.REQUEST_SUBPRICE;
}

export interface ResultGetSubPriceAction {
  type: SubPriceActionEnum.RESULT_SUBPRICE;
  data: ISubPriceFetch[];
}

export interface ErrorSubPriceAction {
  type: SubPriceActionEnum.ERROR_SUBPRICE;
  payload?: any;
}

export interface AddSubPriceAction {
  type: SubPriceActionEnum.REQUEST_ADD_SUBPRICE;
  data: ISubPrice[];
}
export interface ResultAddSubPriceAction {
  type: SubPriceActionEnum.RESULT_ADD_SUBPRICE;
  status: number;
}

export interface UpdateSubPriceAction {
  type: SubPriceActionEnum.REQUEST_UPDATE_SUBPRICE;
  currentSubPrice: ISubPriceFetch;
  id: string | number;
}
export interface ResultUpdateSubPriceAction {
  type: SubPriceActionEnum.RESULT_UPDATE_SUBPRICE;
  status: number;
}
export interface DeleteSubPriceAction {
  type: SubPriceActionEnum.REQUEST_DELETE_SUBPRICE;
  id: number;
}
export interface ResultDeleteSubPriceAction {
  type: SubPriceActionEnum.RESULT_DELETE_SUBPRICE;
  status: number;
}

export interface DeleteAllSubPricesAction {
  type: SubPriceActionEnum.REQUEST_DELETE_ALL_SUBPRICES;
  id: number;
}
export interface ResultDeleteAllSubPricesAction {
  type: SubPriceActionEnum.RESULT_DELETE_ALL_SUBPRICES;
  status: number;
}

export interface ClearSubPricesResultAction {
  type: SubPriceActionEnum.CLEAR_SUBPRICE_RESULT;
}

export type SubPriceAction =
  | GetSubPriceAction
  | ResultGetSubPriceAction
  | ErrorSubPriceAction
  | AddSubPriceAction
  | ResultAddSubPriceAction
  | UpdateSubPriceAction
  | ResultUpdateSubPriceAction
  | DeleteSubPriceAction
  | ResultDeleteSubPriceAction
  | DeleteAllSubPricesAction
  | ResultDeleteAllSubPricesAction
  | ClearSubPricesResultAction;
