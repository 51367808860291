import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import SearchModal from './SearchModal';
import { useActions } from "src/hooks/useActions";
import useIsMobile from 'src/hooks/useIsMobile';
import { useTypedSelector } from 'src/hooks/useTypeSelector';

const roleLinks = [
  {
    name: 'Chat',
    path: '/host-support',
    icon: <LiveHelpIcon className='icon-tab-chat' />,
  },
  {
    name: 'Calendar',
    path: '/calendar',
    icon: <CalendarMonthIcon className='icon-tab' />,
  },
  {
    name: 'Order',
    path: '/order',
    icon: <AssignmentOutlinedIcon className='icon-tab' />,
  },
  {
    name: 'Next Out',
    path: '/order/next-out',
    icon: <DoubleArrowIcon className='icon-tab' />,
  },
  {
    name: 'Client',
    path: '/client',
    icon: <PeopleAltIcon className='icon-tab' />,
  },
  {
    name: 'Host report',
    path: '/report/host',
    icon: <ReceiptLongIcon className='icon-tab' />,
  },
  {
    name: 'Admin',
    path: '/admin',
    icon: <SettingsIcon className='icon-tab admin-icon' fontSize='medium' />,
  },
  {
    name: 'Van',
    path: '/van',
    icon: <AirportShuttleIcon className='icon-tab' />,
  },
  {
    name: 'Log Out',
    path: '',
    icon: <ExitToAppOutlinedIcon className='icon-tab' />,
  },
];

const PlaygroundSpeedDial: FC = () => {

  const navigate = useNavigate();
  const { logOut } = useActions();
  const isMobile = useIsMobile();
  const isLogin = useTypedSelector((state) => state.auth.isLogin);
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const handleClick = (path: string) => {
    path ? navigate(path) : logOut();
    setOpen(false);
  };

  return (
    <>
      <SpeedDial
        ariaLabel='SpeedDial'
        hidden={!isMobile || !isLogin}
        icon={<SpeedDialIcon />}
        direction={'up'}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <SpeedDialAction
          key='Search order'
          icon={<SearchIcon />}
          tooltipTitle='Search order'
          onClick={() => setOpenSearch(true)}
        />
        {roleLinks.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action.path)}
          />
        ))}
      </SpeedDial>
      <SearchModal
        handleClose={() => setOpenSearch(false)}
        open={openSearch}
      />
    </>
  );
};

export default PlaygroundSpeedDial;