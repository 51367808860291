import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "src/components/App";
import "src/translation/init";
import { store } from "./store";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
