import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import "./styles.scss";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import SimpleInput from "src/components/Items/input/SimpleInput";
import Logo from "src/components/Items/Logo";
import { useActions } from "src/hooks/useActions";

const LoginPage: FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useActions();
  const { result } = useTypedSelector((state) => state.auth);
  const { t } = useTranslation();
  const loginHandler = () => login({ email, password });

  return (
    <div className="login">
      <div className="login__wrapper">
        <div className="login__content col-1">
          <Logo />
          <div className="login__header">{t("login.header")}</div>
          <div className="login__form">
            <SimpleInput
              data={email}
              setData={setEmail}
              fullWidth
              type="email"
              required
              title="Email"
              style={{ width: `100%` }}
            />
            <SimpleInput
              data={password}
              setData={setPassword}
              fullWidth
              type="password"
              required
              title="Password"
              style={{ width: `100%` }}
            />
          </div>
          <Button onClick={loginHandler}>{t("Login")}</Button>
          {result && <div className="login__result">{result}</div>}
          <div className="conditions">
            <div className="conditions__iconsContainer">
              <CircleIcon fontSize='large' className="conditions__circleIcon" />
              <CheckIcon fontSize='medium' className="conditions__checkIcon" />
            </div>
            <p className="conditions__text">
              By logging in I agree to sprinter-rentals<br />
              <a
                href='https://www.sprinter-rentals.com/tos'
                target='_blank'>
                terms & conditions
              </a>
            </p>
          </div>
          <div className="login__text">
            TG1 {t("login.poweredBy")} Sprinter Rentals(©)
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
