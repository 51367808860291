import { FC } from 'react';
import './styles.scss';
import Filters from '..';
import SecondTypeButton from '../../button/SecondTypeButton';
import { Button } from '@mui/material';
import { useTypedSelector } from 'src/hooks/useTypeSelector';

interface IProps {
  handleClearFilter: any;
  handleSetFilter: any;
}

const AnotherFilter: FC<IProps> = ({ handleClearFilter, handleSetFilter }) => {
  const { role } = useTypedSelector((state) => state.auth);
  return (
    <>
      <div className='filter__aside'>
        {role === 'admin' && (
          <>
            <Filters.Host />
            <div className='filter-line' />
          </>
        )}
        <Filters.Type />
        <div className='filter-line' />
        <Filters.Price />
        <div className='filter-line' />
        <Filters.Feature />
        {/* <div className="filter-line" />
      <Filters.Color /> */}
        <div className='filter-actions'>
          <SecondTypeButton
            onClick={handleClearFilter}
            children='Clear'
            sx={{ marginTop: '30px' }}
          />
          <Button
            variant='contained'
            onClick={handleSetFilter}
            children='Apply'
            sx={{ marginTop: '30px' }}
          />
        </div>
      </div>
    </>
  );
};
export default AnotherFilter;
