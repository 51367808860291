import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { NearVanActionCreators } from "src/store/reducers/nearVan/action-creators";
import { NearVanActionEnum } from "src/store/reducers/nearVan/types";

function* fetchDeleteNearVan({
  id,
}: ReturnType<typeof NearVanActionCreators.deleteNearVan>) {
  try {
    const { status }: Response = yield instance.delete(`nearVan/${id}`);
    yield put(NearVanActionCreators.resultDeleteNearVan(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteNearVan() {
  yield takeLatest(NearVanActionEnum.REQUEST_DELETE_NEARVAN, fetchDeleteNearVan);
}
