import { FC } from 'react';
import { Badge, CircularProgress, Tooltip } from '@mui/material';
import CustomCheckbox from 'src/components/Items/button/CustomCheckbox';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';
import { IOrderFetch } from 'src/models/IOrder';

interface IProps {
  title: string;
  name: string;
  order: IOrderFetch;
}

const ActionsItem: FC<IProps> = ({ title, name, order }) => {

  const { actionOrder } = useActions();
  const { isLoading } = useTypedSelector((state) => state.order);

  function changeCheckbox(actionType: string) {
    actionOrder(order.id, actionType);
  }

  return (
    <div className='order-notification__list__item'>
      <div style={{ lineHeight: '14px' }}>{title}</div>
      <div className='order-notification__action'>
        <div
          className={`
            order-notification__action__item 
            ${order.stripe_refund_id && 'disabled'}`
          }
          style={{ cursor: 'pointer' }}
        >
          {isLoading.action ? (
            <CircularProgress size={15} />
          ) : (
            <Tooltip title='Sent email'>
              <CustomCheckbox
                onChange={(e) => changeCheckbox(e.target.name)}
                checked={
                  order.stripe_refund_id && order.status === 'deleted'
                    ? true
                    : false
                }
                disabled={order.stripe_refund_id ? true : false}
                name={name}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActionsItem;
