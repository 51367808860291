import { FC } from 'react';
import { Backdrop, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import './styles.scss';

const ShowDocModal: FC<{
  doc: string;
  open: boolean;
  handleClose: () => void;
}> = ({ doc, handleClose, open }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='doc-modal'>
          <img
            src={`data:image/png;base64, ${doc}`}
            alt='document'
            loading='lazy'
          />
          <div className='doc-modal__button'>
            <SecondTypeButton
              children='Close'
              onClick={() => handleClose()}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
export default ShowDocModal;
