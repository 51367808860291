import { put, takeLatest } from "redux-saga/effects";
import { SublocationActionCreators } from "src/store/reducers/sublocation/action-creators";
import { SublocationActionEnum } from "src/store/reducers/sublocation/types";
import instance from "../set";

function* fetchGetSublocation() {
  try {
    const { data, status } = yield instance.get('subLocation');
    yield put(SublocationActionCreators.resultGetSublocations(data, status));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetSublocation() {
  yield takeLatest(SublocationActionEnum.REQUEST_SUBLOCATIONS, fetchGetSublocation);
}
