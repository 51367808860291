import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

interface IProps extends CheckboxProps {
	onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
	name: string;
	checked: boolean;
	disabled?: boolean;
}

const BpIcon = styled('span')(() => ({
	width: 20,
	height: 20,
	border: '3px solid #c1ff45',
	borderRadius: '4px',
	backgroundColor: '#fff',
	'input:hover ~ &': {
		borderColor: '#4b566b',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		borderColor: '#7d879c57',
	},
	'&.Mui-checked': {
		borderColor: '#4b566b',
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#c1ff45',
	'&:before': {
		display: 'inline-block',
		width: 20,
		height: 20,
		padding: 0,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right 5.5px bottom 5px',
		backgroundImage:
			"url(\"data:image/svg+xml,%3Csvg height='14px' width='14px' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 17.837 17.837' xml:space='preserve' fill='%23c1ff45'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cg%3E%3Cpath style='fill:%234b566b;' d='M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
		content: '""',
	},
	'input:hover ~ &': {
		borderColor: '#4b566b',
	},
});

function BpCheckbox(props: CheckboxProps): JSX.Element {
	return (
		<Checkbox
			disableRipple
			color='default'
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			inputProps={{ 'aria-label': 'Checkbox' }}
			{...props}
		/>
	);
}

const CustomCheckbox: FC<IProps> = ({ onChange, name, checked, disabled }): JSX.Element => {
	return <BpCheckbox onChange={onChange} name={name} checked={checked} disabled={disabled} />;
};

export default CustomCheckbox;
