import { requestState, responseState } from "../utils";
import { OnewayAction, OnewayActionEnum, OnewayState } from "./types";

const initialState: OnewayState = {
  onewaysList: [],
  total: null,
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
  },
  result: {},
};
const oneway = (state = initialState, action: OnewayAction): OnewayState => {
  switch (action.type) {
    case OnewayActionEnum.REQUEST_ONEWAYS:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case OnewayActionEnum.RESULT_ONEWAYS:
      return {
        ...state,
        onewaysList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
        result: {}
      };
    case OnewayActionEnum.REQUEST_ADD_ONEWAY:
    return requestState(state, "add");

    case OnewayActionEnum.RESULT_ADD_ONEWAY:
      return responseState(state, "add", action);

    case OnewayActionEnum.REQUEST_UPDATE_ONEWAY:
      return requestState(state, "update");

    case OnewayActionEnum.RESULT_UPDATE_ONEWAY:
      return responseState(state, "update", action);

    case OnewayActionEnum.CLEAR_ONEWAY_RESULT:
      return { ...state, result: {} };

    default:
      return state;
  }
};
export default oneway;
