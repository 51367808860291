import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { VanActionEnum } from "src/store/reducers/van/types";
import { VanActionCreators } from "src/store/reducers/van/action-creators";

function* fetchAddVan({
  van,
}: ReturnType<typeof VanActionCreators.addVan>) {
  try {
    const { status, data } = yield instance.post(`van/add`, {
      van,
    });
    yield put(VanActionCreators.resultAddVan(status, data));
  } catch (e: any) {
    console.log("error", e);
    yield put(VanActionCreators.resultAddVan("ERROR"));
  }
}

export function* actionWatcherAddVan() {
  yield takeLatest(VanActionEnum.REQUEST_ADD_VAN, fetchAddVan);
}
