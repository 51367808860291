import { FC, useEffect, useState } from "react";
import { Button } from "@mui/material";
import AddVanModal from "./Modal/Add";
import VanItems from "./items";
import PreviewVan from "src/components/Items/previewPages/PreviewVan";
import Aside from "src/components/Aside";
import NoResult from "src/components/Items/another/NoResult";
import { initFilter } from "src/store/reducers/van";
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import "./styles.scss";
import "./media.scss";

const VanPage: FC = () => {
  const { getVans, getHosts, getFeatures, getTags, clearFilter, getLocations } =
    useActions();
  const { vanList, total, isLoading, result, filters } = useTypedSelector(
    (state) => state.van
  );
  const resultTag = useTypedSelector((state) => state.tag.result);
  const resultFeature = useTypedSelector((state) => state.feature.result);

  const [filter, setFilter] = useState({ ...initFilter });
  const onPaginationChange = (page: number) => setFilter({ ...filter, page });
  const onTakeChange = (take: number) => setFilter({ ...filter, take });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getHosts();
    getLocations();
  }, []);

  useEffect(() => {
    getTags();
  }, [resultTag]);

  useEffect(() => {
    getFeatures();
  }, [resultFeature]);

  useEffect(() => {
    !Object.values(isLoading).find((el) => el) && getVans(filter);
  }, [filter, result]);

  const handleSetFilter = () => setFilter({ ...initFilter, ...filters });

  const handleClearFilter = () => {
    setFilter({ ...initFilter });
    clearFilter();
  };

  const _renderVans = () => {
    if (isLoading.all && !vanList.length) return <PreviewVan />;
    if (vanList.length) {
      return (
        <VanItems
          vans={vanList}
          filters={filter}
          onPaginationChange={onPaginationChange}
          onTakeChange={onTakeChange}
          total={total!}
        />
      );
    }
    if (!vanList.length && !isLoading.all) return <NoResult />;
    else return null;
  };

  return (
    <>
      <div className="vans-header">
        <div className="vans-header__title">
          Vans
          <span className="header-amount">{` (${total})`}</span>
        </div>
        <Button children="+ Add Van" variant="contained" onClick={handleOpen} />
        <AddVanModal open={open} handleClose={handleClose} />
      </div>
      <div className="main">
        <div className="aside">
          <Aside
            handleClearFilter={handleClearFilter}
            handleSetFilter={handleSetFilter}
          />
        </div>
        <div className="vans-time">{_renderVans()}</div>
      </div>
    </>
  );
};
export default VanPage;
