import { IPickupDetails } from "src/models/IPickupDetails";

export class PickupDetails implements IPickupDetails {
  description = "";
  location = "";
  return_at = "";
  address_take = "";
  address_drop = "";
  host_id = null;
  for_all = false;
}