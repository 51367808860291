import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { HostStaffActionCreators } from "src/store/reducers/hostStaff/action-creators";
import { HostStaffActionEnum } from "src/store/reducers/hostStaff/types";

function* fetchDeleteHostStaff({
  id,
}: ReturnType<typeof HostStaffActionCreators.deleteHostStaff>) {
  try {
    const { status }: Response = yield instance.delete(`staff/${id}`);
    yield put(HostStaffActionCreators.resultDeleteHostStaff(status));
  } catch (e) {
    console.log(e);
    yield put(HostStaffActionCreators.resultDeleteHostStaff(400));
  }
}
export function* actionWatcherDeleteHostStaff() {
  yield takeLatest(HostStaffActionEnum.REQUEST_DELETE_HOSTSTAFF, fetchDeleteHostStaff);
}
