import { GlobalActionEnum } from "./types";

export const GlobalActionCreators = {
  initHideBar: () => ({
    type: GlobalActionEnum.INIT_HIDE_BAR,
  }),
  toggleHideBar: (statusHideBar: boolean) => ({
    type: GlobalActionEnum.TOGGLE_HIDE_BAR,
    statusHideBar,
  }),
};
