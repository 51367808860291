import { FC, useEffect, useState } from "react";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { useActions } from "src/hooks/useActions";
import SimpleSelect from "src/components/Items/select/SimpleSelect";

const Type: FC = () => {
  const { setSeatFilter, setTypeFilter } = useActions();
  const typeArray = {
    passenger: [8, 9, 12, 15],
    cargo: [2, 5],
  };
  const [seatsArray, setSeatsArray] = useState<string[] | number[]>([
    ...typeArray.cargo,
    ...typeArray.passenger,
  ]);

  const { seats, type } = useTypedSelector((state) => state.van.filters);

  const setType = (vanType: string) => setTypeFilter(vanType);
  const setSeats = (seat?: number) => setSeatFilter(seat);

  useEffect(() => {
    if (type) {
      if (type === "passenger") {
        setSeatsArray(typeArray.passenger);
      } else setSeatsArray(typeArray.cargo);
      if (
        seats &&
        typeArray[type as keyof typeof typeArray].indexOf(seats) < 0
      ) {
        setSeats(undefined);
      }
    }
  }, [type]);

  return (
    <>
      <div className="filter__title">van type and seats' amount</div>
      <div className="filter-type col-2">
        <SimpleSelect
          data={type}
          setData={setType}
          dataArray={Object.keys(typeArray)}
          placeholder="type"
        />
        {/* <div className="filter-line" /> */}
        <SimpleSelect
          data={seats!}
          setData={setSeats}
          dataArray={seatsArray}
          placeholder="seats"
          notShowSelect
        />
      </div>
    </>
  );
};

export default Type;
