import { FC, useState } from "react";
import { deepmerge } from "@mui/utils";
import {
    FormControl,
    Select,
    createTheme,
    ThemeProvider,
    List,
    ListItemButton,
    ListItemText,
    Collapse
} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ILocationFetch } from "src/models/ILocation";
import theme, { colorSet, typeForm } from "src/theme";
import './styles.scss';

interface ISelect {
    check?: boolean;
    data?: string | number;
    setData: any;
    title?: string;
    dataArray: ILocationFetch[];
    style?: {};
    placeholder?: string;
    notShowSelect?: boolean;
    isChooseLocations?: boolean;
    type?: string;
}

const themeMultiSelect = createTheme(
    deepmerge(theme, {
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        maxHeight: '380px !important',
                    },
                },
            },
        },
    })
);

const SublocationsSelect: FC<ISelect> = ({
    data,
    setData,
    dataArray,
    check,
    title,
    style,
    placeholder = title,
    notShowSelect,
    isChooseLocations,
    type
}) => {

    const [open, setOpen] = useState(false);
    const [openSub, setOpenSub] = useState(false);
    const [index, setIndex] = useState(0);

    const handleOpenSub = (i: number) => {
        setIndex(i);
        setOpenSub(!openSub);
    };

    const handleChange = (name: string) => {
        setData(name);
        setOpen(false);
    };

    const handleChangeWithId = (id: number, name: string) => {
        setData({ 'id': id, 'name': name });
        setOpen(false);
    };

    const handleChangeWithLocation = (location: string, name: string) => {
        setData({ 'location': location, 'name': name });
        setOpen(false);
    };

    return (
        <div>
            <ThemeProvider theme={themeMultiSelect}>
                {title && <div className="filter__title">{title}</div>}
                <FormControl sx={style} size="small">
                    <Select
                        variant={typeForm}
                        displayEmpty
                        value={data ?? ""}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        renderValue={(n) =>
                            !n || !data ? (
                                check ? (
                                    <div style={{ color: colorSet.primaryRed }}>
                                        Must be selected
                                    </div>
                                ) : (
                                    <div style={{ color: colorSet.primaryGrey }}>
                                        {!notShowSelect && "Select"} {placeholder}
                                    </div>
                                )
                            ) : (
                                n
                            )
                        }
                        title={`${data!}`}
                    >
                        {dataArray.map((location, i) => (
                            <div>
                                <List
                                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    key={i}
                                >
                                    {isChooseLocations ? (
                                        <ListItemButton>
                                            <ListItemText
                                                primary={location.name}
                                                onClick={() => handleChange(location.name)}
                                            />
                                            {location.sub_location.length ? (
                                                <div
                                                    onClick={() => handleOpenSub(i)}
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    {(openSub && index === i) ? <ExpandLess /> : <ExpandMore />}
                                                </div>
                                            ) : <></>}
                                        </ListItemButton>
                                    ) : (
                                        <ListItemButton onClick={() => handleOpenSub(i)}>
                                            <ListItemText primary={location.name} />
                                            {(openSub && index === i) ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    )}
                                    <Collapse in={openSub && index === i} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {location.sub_location.map((sub, i) => (
                                                <ListItemButton sx={{ pl: 4 }} key={i}>
                                                    <ListItemText
                                                        primary={sub.name}
                                                        onClick={() => (
                                                            type === 'with id'
                                                                ? handleChangeWithId(sub.id, sub.name)
                                                                : type === 'with location'
                                                                    ? handleChangeWithLocation(location.name, sub.name)
                                                                    : handleChange(sub.name)
                                                        )} />
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                </List>
                            </div>
                        ))}
                    </Select>
                </FormControl>
            </ThemeProvider>
        </div>
    );
};

export default SublocationsSelect;