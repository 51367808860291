import { put, takeLatest } from "redux-saga/effects";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";
import { ClientActionEnum } from "src/store/reducers/client/types";
import instance from "../set";

function* fetchGetClients({
  filter,
}: ReturnType<typeof ClientActionCreators.getClients>) {
  try {
    const {
      data: { count, clientList },
    } = yield instance.post(`client`, { filters: filter });

    yield put(ClientActionCreators.resultGetClients(count, clientList));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetClients() {
  yield takeLatest(ClientActionEnum.REQUEST_CLIENTS, fetchGetClients);
}
