
import { ISubLocation, ISubLocationFetch } from "src/models/ILocation";
import { 
    AddSublocationsAction, 
    DeleteSublocationAction, 
    SublocationActionEnum, 
    ResultAddSublocationsAction, 
    ResultDeleteSublocationAction, 
    ResultUpdateSublocationAction, 
    UpdateSublocationAction, 
    GetSublocationsAction,
    ResultGetSublocationsAction
} from "./types";


export const SublocationActionCreators = {
  getSublocations: (): GetSublocationsAction => ({
    type: SublocationActionEnum.REQUEST_SUBLOCATIONS,
  }),
  resultGetSublocations: (data: ISubLocationFetch[], status: number): ResultGetSublocationsAction => ({
    type: SublocationActionEnum.RESULT_SUBLOCATIONS,
    data,
    status,
  }),
  addSublocations: (data: ISubLocation[]): AddSublocationsAction => ({
    type: SublocationActionEnum.REQUEST_ADD_SUBLOCATIONS,
    data,
  }),
  resultAddSublocations: (status: number): ResultAddSublocationsAction => ({
    type: SublocationActionEnum.RESULT_ADD_SUBLOCATIONS,
    status,
  }),
  updateSublocation: (currentSublocation: string, id: string | number): UpdateSublocationAction => ({
    type: SublocationActionEnum.REQUEST_UPDATE_SUBLOCATION,
    currentSublocation,
    id
  }),
  resultUpdateSublocation: (status: number): ResultUpdateSublocationAction => ({
    type: SublocationActionEnum.RESULT_UPDATE_SUBLOCATION,
    status,
  }),
  deleteSublocation: (id: number): DeleteSublocationAction => ({
    type: SublocationActionEnum.REQUEST_DELETE_SUBLOCATION,
    id,
  }),
  resultDeleteSublocation: (status: number): ResultDeleteSublocationAction => ({
    type: SublocationActionEnum.RESULT_DELETE_SUBLOCATION,
    status,
  }),
};
