import "./styles.scss";
import Host from "./Item/Host";
import ClearFilters from "./Item/ClearFilters";
import Search from "./Item/Search";
import Type from "./Item/Type";
import Color from "./Item/Color";
import Feature from "./Item/Feature";
import HostRating from "./Item/HostRating";
import Price from "./Item/Price";
import Location from "./Item/Location";

const Filters = {
  Location,
  Type,
  Color,
  Feature,
  HostRating,
  Price,
  Host,
  ClearFilters,
  Search,
};

export default Filters;
