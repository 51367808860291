import { FC, Fragment, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Backdrop, Button, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import { useActions } from 'src/hooks/useActions';
import Icons from 'src/common/icons';

interface IProps {
    locationId: number;
    locationName: string;
    close: boolean;
    handleClose: () => void;
}

const AddSublocationModal: FC<IProps> = ({ handleClose, locationName, locationId, close }) => {

    const { enqueueSnackbar } = useSnackbar();
    const { addSublocations } = useActions();

    const [data, setData] = useState<string[]>(['']);
    const [item, setItem] = useState('');
    const [check, setCheck] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleChange = (index: number) =>
        (value: string) => {
            const editedArr: any[] = [...data];
            editedArr[index] = value;
            setData(editedArr);
        };

    useEffect(() => {
        setIsError(false);
        data.map((sub) => !sub && setIsError(true));
    }, [data]);
    
    const removeItem = (index: number) => () =>
        setData([...data.slice(0, index), ...data.slice(index + 1)]);
    const handleAddMore = () => setData([ ...data, item ]);

    const handleSubmit = () => {
        if (isError) setCheck(true);
        else {
            enqueueSnackbar('Adding Sublocations...');
            const sublocations = data.map((sub) => {
                return { 
                    'name': sub, 
                    'location_id': locationId, 
                    'main_location_name': locationName 
                };
            });
            addSublocations(sublocations);
            setItem('');
            setData([]);
            handleClose();
        }
    };

    return (
        <Modal
            open={close}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            closeAfterTransition
        >
            <div className='modal modal_litle modal-tag'>
                <div className='modal__title'>Sublocations</div>
                <div className='modal__fields'>
                    {data.map((sublocation, index: number) => (
                        <Fragment key={index}>
                            <div className='modal__field'>
                                {index > 0 && (
                                    <div
                                        className='modal__field_icon icon_stroke'
                                        onClick={removeItem(index)}
                                    >
                                        <Icons.DeleteOneRow />
                                    </div>
                                )}
                                <SimpleInput
                                    data={sublocation}
                                    setData={handleChange(index)}
                                    placeholder='sublocation'
                                    key={index}
                                    check={check}
                                    style={{width: '100%'}}
                                />
                            </div>
                        </Fragment>
                    ))}
                </div>
                <div className='col-2'>
                    <SecondTypeButton 
                        onClick={handleAddMore} 
                        children='Add more' 
                        style={{ marginTop: '30px' }} 
                    />
                    {data.length && (
                        <Button
                            variant='contained'
                            children='Save'
                            onClick={handleSubmit}
                            sx={{ marginTop: '30px' }}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AddSublocationModal;
