import { put, takeLatest } from 'redux-saga/effects';
import { OnewayActionCreators } from "src/store/reducers/oneway/action-creators";
import { OnewayActionEnum } from "src/store/reducers/oneway/types";
import instance from '../set';

function* fetchUpdateOneway({
  currentOneway, id
}: ReturnType<typeof OnewayActionCreators.updateOneway>) {
  try {
    const { status }: Response = yield instance.patch(
      `oneway/${id}`,
      currentOneway
    );
    yield put(OnewayActionCreators.resultUpdateOneway(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdateOneway() {
  yield takeLatest(OnewayActionEnum.REQUEST_UPDATE_ONEWAY, fetchUpdateOneway);
}