import { put, takeLatest } from "redux-saga/effects";
import { FeatureActionEnum } from "src/store/reducers/feature/types";
import { FeatureActionCreators } from "src/store/reducers/feature/action-creators";
import instance from "../set";

function* fetchDeleteFeature({
  id,
}: ReturnType<typeof FeatureActionCreators.deleteFeature>) {
  try {
    const { status }: Response = yield instance.delete(`feature/${id}`);
    yield put(FeatureActionCreators.resultDeleteFeature(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteFeature() {
  yield takeLatest(
    FeatureActionEnum.REQUEST_DELETE_FEATURE,
    fetchDeleteFeature
  );
}
