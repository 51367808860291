import { FC } from 'react';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import { BadgeThemeOrderEnum, IOrderFetch } from 'src/models/IOrder';

interface IProps {
  order: IOrderFetch;
  setOrder: any;
  table?: boolean;
  fields: any[];
}

const FormOrderModal: FC<IProps> = ({ order, setOrder, table, fields }) => {
  const _renderInput = (
    name: keyof Pick<IOrderFetch, 'price' | 'note' | 'payment_link'>,
    type: string,
    title: string = name,
    length: number = 300
  ) => (
    <SimpleInput
      data={order[name]}
      setData={setOrder(name)}
      title={title}
      length={length}
      type={type}
    />
  );

  const _renderSelect = (
    name: keyof Pick<IOrderFetch, 'status'>,
    items: string[] | number[]
  ) => (
    <SimpleSelect
      dataArray={items}
      setData={setOrder(name)}
      data={order[name]}
      title={name}
    />
  );
  return (
    <div className={`col-${(table && '2') || '1'}`}>
      {table && _renderSelect('status', Object.keys(BadgeThemeOrderEnum))}
      {fields.map((i) => _renderInput(i, 'text'))}
    </div>
  );
};
export default FormOrderModal;
