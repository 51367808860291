import { put, takeLatest } from 'redux-saga/effects';
import instance from 'src/sagas/set';
import { ReportActionCreators } from 'src/store/reducers/report/host/action-creators';
import { ReportActionEnum } from 'src/store/reducers/report/host/types';
function* getHostReport({
  filter,
}: ReturnType<typeof ReportActionCreators.getHostReport>) {
  try {
    const { data, status } = yield instance.post('report/host', { filter });
    yield put(ReportActionCreators.resultHostReport(data, status));
  } catch (error) {
    yield put(ReportActionCreators.errorHostReport());
    console.log('error :>> ', error);
  }
}
export function* actionWatcherGetHostReport() {
  yield takeLatest(ReportActionEnum.REQUEST_REPORT, getHostReport);
}
