import { ISubPrice, ISubPriceFetch } from "src/models/ISubPrice";
import { 
  AddSubPriceAction, 
  GetSubPriceAction, 
  SubPriceActionEnum, 
  ResultAddSubPriceAction, 
  ResultGetSubPriceAction, 
  ResultUpdateSubPriceAction, 
  UpdateSubPriceAction, 
  DeleteSubPriceAction, 
  ResultDeleteSubPriceAction, 
  DeleteAllSubPricesAction,
  ResultDeleteAllSubPricesAction,
  ClearSubPricesResultAction
} from "./types";

export const SubPriceActionCreators = {
  getSubPrice: (): GetSubPriceAction => ({
    type: SubPriceActionEnum.REQUEST_SUBPRICE,
  }),
  resultGetSubPrice: (data: ISubPriceFetch[]): ResultGetSubPriceAction => ({
    type: SubPriceActionEnum.RESULT_SUBPRICE,
    data,
  }),
  addSubPrice: (data: ISubPrice[]): AddSubPriceAction => ({
    type: SubPriceActionEnum.REQUEST_ADD_SUBPRICE,
    data,
  }),
  resultAddSubPrice: (status: number): ResultAddSubPriceAction => ({
    type: SubPriceActionEnum.RESULT_ADD_SUBPRICE,
    status,
  }),
  updateSubPrice: (currentSubPrice: ISubPriceFetch, id: string | number): UpdateSubPriceAction => ({
    type: SubPriceActionEnum.REQUEST_UPDATE_SUBPRICE,
    currentSubPrice,
    id
  }),
  resultUpdateSubPrice: (status: number): ResultUpdateSubPriceAction => ({
    type: SubPriceActionEnum.RESULT_UPDATE_SUBPRICE,
    status,
  }),
  deleteSubPrice: (id: number): DeleteSubPriceAction => ({
    type: SubPriceActionEnum.REQUEST_DELETE_SUBPRICE,
    id,
  }),
  resultDeleteSubPrice: (status: number): ResultDeleteSubPriceAction => ({
    type: SubPriceActionEnum.RESULT_DELETE_SUBPRICE,
    status,
  }),
  deleteAllSubPrices: (id: number): DeleteAllSubPricesAction => ({
    type: SubPriceActionEnum.REQUEST_DELETE_ALL_SUBPRICES,
    id,
  }),
  resultDeleteAllSubPrices: (status: number): ResultDeleteAllSubPricesAction => ({
    type: SubPriceActionEnum.RESULT_DELETE_ALL_SUBPRICES,
    status,
  }),
  clearSubPriceResult: (): ClearSubPricesResultAction => ({
    type: SubPriceActionEnum.CLEAR_SUBPRICE_RESULT
  }),
};
