import { AuthAction, AuthActionEnum, AuthState } from "./types";

const initialState: AuthState = {
  isLogin: localStorage.getItem("token") ? true : false,
  result: "",
  role: "",
  id: null,
};

const auth = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionEnum.REQUEST_LOGIN:
      return { ...state, data: action.login, result: "" };
    case AuthActionEnum.RESULT_LOGIN:
      return {
        ...state,
        isLogin: !!action.token,
        result: action.status,
        role: action.role,
        id: action.id,
        data: undefined,
      };
    case AuthActionEnum.ERROR_LOGIN:
      return {
        ...state,
        isLogin: false,
        data: {
          email: "",
          password: "",
        },
        result: action.result,
      };
    case AuthActionEnum.EXIT:
      return {
        ...state,
        isLogin: false,
        result: action.result ?? "",
      };
    default:
      return state;
  }
};
export default auth;
