import { FC, useEffect, useState } from 'react';
import Decimal from 'decimal.js';
import { Currency } from 'dinero.js';
import { useSnackbar } from 'notistack';
import { Backdrop, CircularProgress, Modal } from '@mui/material';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import ModalAction from 'src/components/Items/modal/Actions';
import ChargeItem from './ChargeItem';
import { ICharge } from 'src/models/IOrder';


interface IProps {
    fees: ICharge[];
    currency: Currency;
    open: boolean;
    handleClose: () => void;
    order_id: number;
}

const AfterTripChargesModal: FC<IProps> = ({ fees, currency, open, handleClose, order_id }) => {
    
    const { getAfterTripCharges, updatePaymentOrder } = useActions();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const ChargesList = useTypedSelector((state) => state.order.afterTripCharges.result.get);
    const { isLoading, result } = useTypedSelector((state) => state.order);
    const [charges, setCharges] = useState<ICharge[]>(fees);

    useEffect(() => {
        !fees[0] && !ChargesList && getAfterTripCharges();
    }, [fees]);

    useEffect(() => {
        ChargesList && setCharges(ChargesList);
    }, [ChargesList]);

    useEffect(() => {
        (isLoading.payment === false && result.payment) && (
            result.payment ? (
                result.payment === 'Amount can`t be great $250' ? (
                    enqueueSnackbar(`${result.payment}`, {
                        variant: 'info',
                        persist: true
                    })
                ) : (
                    enqueueSnackbar(`${result.payment}`, {
                        variant: 'success',
                        persist: true
                    })
                ),
                setTimeout(() => closeSnackbar(), 10000)
            ) : enqueueSnackbar('After trip charges failed', { variant: 'error' })
        );
    }, [isLoading.payment, result]);
      
    const onSubmit = () => {
        updatePaymentOrder(order_id, charges);
        handleClose();
    };

    const handleChangeCharges = (index: number, price: number) => {
        const charge = charges[index!];
        const chargesList = [...charges];
        chargesList[index!] = { ...charge, amount: Number(Decimal.mul(price, 100)) };
        setCharges(chargesList);
    };

    const handleDeleteCharge = (index: number) => {
        const chargesList = charges.filter((charge) => charge !== charges[index]);
        setCharges(chargesList);
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
            <>
                <div className='modal' style={{ width: '650px', marginTop: '15px' }}>
                    <div className='modal__title'>After trip charges</div>
                    {ChargesList ? (
                        charges.map((charge, i) => (
                            <ChargeItem
                                item={charge}
                                currency={currency}
                                index={i}
                                setCharge={handleChangeCharges}
                                deleteCharge={handleDeleteCharge}
                            />
                        ))
                    ) : (
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <CircularProgress size={100} />
                        </div>
                    )}
                    <div style={{
                        textAlign: 'center',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '23px',
                        color: '#d24057',
                        marginTop: '30px'
                    }}>
                        CAREFUL<br />
                        This emails the guest a summery of those charges above.<br />
                        This charges the card on hold and releases the rest of the hold.<br />
                        THIS IS FINAL
                    </div>
                    <ModalAction
                        handleSubmit={onSubmit}
                        handleClose={handleClose}
                        type='Send & Charge'
                        action='Add'
                        disable={fees[0] ? true : false}
                    />
                </div>
            </>
        </Modal>
    );
};
export default AfterTripChargesModal;
