import { FC } from 'react';
import './styles.scss';

const HelpChatPage: FC = () => {
  return (
      <div className='chat'>
          <div className='chat__titles'>
              <h1>Need help? Ask the help chat.</h1>
              <h3>Make the help chat better! Thumps up or down every time. </h3>
          </div>
          <div className='chat__window'>
              <iframe style={{ height: '90vh', width: '800px' }}
                  frameBorder="0"
                  src="https://widget.writesonic.com/CDN/index.html?service-base-url=https://api.botsonic.ai&token=571383e2-fd56-4430-b789-e7865c94dc5f&base-origin=https://bot.writesonic.com&instancename=Botsonic&standalone=true&pageurl=https://bot.writesonic.com/8ef02ffe-7711-4f7f8e9a-6deed5fd3ee3?t=connect&workspace_id=c4405e34-eb65-49a1-b215-dd1f1d2ece97"
              />
          </div>
          <div className='chat__description'>
              <p>
                  Search <b>How To Videos</b> on our Telegram Channel
                  <a href='https://t.me/+ORzkftlc7YYxMzcx'
                      target="_blank"
                  >
                      here
                  </a>.
              </p>
              <p>For everything else feel free to Telegram chat Sven any time. </p>
              <p>Thank you so much.</p>
          </div>
      </div>
  );
};
export default HelpChatPage;
