import { FC, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SecondTypeButton from "src/components/Items/button/SecondTypeButton";
import { SearchOrder, SmallSearchOrder } from "../SearchOrder";
import TabList from "../TabList";
import { useActions } from "src/hooks/useActions";
import useIsMobile from "src/hooks/useIsMobile";
import "./styles.scss";

interface IProps {
  hideBarMenu: boolean;
}

const VerticalTabs: FC<IProps> = ({ hideBarMenu }) => {
  const { logOut } = useActions();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [orderNumber, setOrderNumber] = useState('');
  const toExit = () => logOut();

  const handleClick = () => {
    if (orderNumber !== '') {
      navigate(`/order/${orderNumber}`),
      setOrderNumber('');
    }
  };

  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void; }) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (orderNumber !== '') handleClick();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [orderNumber]);

  return (
    <Box className='tabs-wrapper'>
      {isMobile ? (
        <SmallSearchOrder
          placeholder='Order#'
          value={orderNumber}
          onChange={(e) => setOrderNumber(e.target.value)}
          InputProps={{
            endAdornment:
              <button
                onClick={handleClick}
                className='search-button'
              >
                <ArrowForwardIosIcon />
              </button>,
            type: "number"
          }}
        />
      ) : (
        <SearchOrder
          placeholder='Enter Order#'
          value={orderNumber}
          onChange={(e) => setOrderNumber(e.target.value)}
          InputProps={{
            endAdornment:
              <button
                onClick={handleClick}
                className='search-button'
              >
                <ArrowForwardIosIcon />
              </button>,
            type: "number"
          }}
        />
      )}
      <TabList hideBarMenu={hideBarMenu} />
      <Box className='exit-button-wrapper'>
        <SecondTypeButton
          className='exit-button'
          title="Exit"
          startIcon={<ExitToAppOutlinedIcon />}
          onClick={toExit}
          children={hideBarMenu ? "" : "Log Out"}
        />
      </Box>
    </Box>
  );
};
export default VerticalTabs;
