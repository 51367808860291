import { put, takeLatest } from 'redux-saga/effects';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';
import instance from '../../set';

function* fetchAfterChargePayment({
  order_id, amount, note
}: ReturnType<typeof OrderActionCreators.afterChargePayment>) {
  const afterCharge = { amount, note };
  try {
    const { data, status } = yield instance.post(`stripe/after_charge/${order_id}`, {
      afterCharge
    });
    yield put(OrderActionCreators.resultAfterChargePayment(data, status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAfterChargePayment() {
  yield takeLatest(
    OrderActionEnum.REQUEST_AFTER_CHARGE_PAYMENT,
    fetchAfterChargePayment
  );
}
