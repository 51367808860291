import { AuthActionCreators } from 'src/store/reducers/auth/action-creators';
import { put, takeLatest } from 'redux-saga/effects';
import { AuthActionEnum } from 'src/store/reducers/auth/types';
import axios from 'axios';

function* fetchSignIn({ login }: ReturnType<typeof AuthActionCreators.login>) {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      const { data, headers, status } = yield axios.post(
        `${process.env.REACT_APP_API}/auth/login`,
        {
          ...login!,
        }
      );
      if (headers.authorization) {
        localStorage.setItem('token', headers.authorization);
        yield put(
          AuthActionCreators.resultLogin(headers.authorization, status)
        );
      }
    } else yield put(AuthActionCreators.resultLogin(token, 'INIT_TOKEN'));
  } catch (err: any) {
    yield put(AuthActionCreators.errorLogin(err.response.data));
  }
}
export function* actionWatcherSignIn() {
  yield takeLatest(AuthActionEnum.REQUEST_LOGIN, fetchSignIn);
}
