import jwt_decode from "jwt-decode";

import {
  AuthActionEnum,
  ErrorLoginAction,
  LoginAction,
  ResultLoginAction,
} from "./types";

export const AuthActionCreators = {
  login: (login?: { email: string; password: string }): LoginAction => ({
    type: AuthActionEnum.REQUEST_LOGIN,
    login,
  }),
  resultLogin: (token: string, status: number | string): ResultLoginAction => {
    const { permission, id } = jwt_decode(token) as { [key: string]: any };
    return {
      type: AuthActionEnum.RESULT_LOGIN,
      token,
      status,
      role: permission,
      id,
    };
  },
  errorLogin: (result: string): ErrorLoginAction => ({
    type: AuthActionEnum.ERROR_LOGIN,
    result,
  }),
  logOut: () => ({ type: AuthActionEnum.EXIT }),
};
