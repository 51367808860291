import { AuthActionCreators } from './auth/action-creators';
import { CalendarActionCreators } from './calendar/action-creators';
import { ClientActionCreators } from './client/action-creators';
import { DriverActionCreator } from './driver/action-creators';
import { ExtraActionCreators } from './extra/action-creators';
import { FeatureActionCreators } from './feature/action-creators';
import { GlobalActionCreators } from './global/action-creators';
import { HostActionCreators } from './host/action-creators';
import { LocationActionCreators } from './location/action-creators';
import { NearVanActionCreators } from './nearVan/action-creators';
import { OnewayActionCreators } from './oneway/action-creators';
import {
  OrderActionCreators,
  OrderNotificationActionCreators,
  OrderPaymentLinkActionCreators,
} from './order/action-creators';
import { PickupDetailsActionCreators } from './pickupDetails/action-creators';
import { ReportActionCreators } from './report/host/action-creators';
import { SeasonPriceActionCreators } from './seasonPrice/action-creators';
import { HostStaffActionCreators } from './hostStaff/action-creators';
import { TagActionCreators } from './tag/action-creators';
import {
  VanActionCreators,
  VanFilterActionCreators,
} from './van/action-creators';
import { SublocationActionCreators } from './sublocation/action-creators';
import { SubPriceActionCreators } from './subPrice/action-creators';

export const allActionCreators = {
  ...AuthActionCreators,
  ...ClientActionCreators,
  ...FeatureActionCreators,
  ...HostActionCreators,
  ...TagActionCreators,
  ...VanActionCreators,
  ...VanFilterActionCreators,
  ...OrderActionCreators,
  ...OrderNotificationActionCreators,
  ...OrderPaymentLinkActionCreators,
  ...CalendarActionCreators,
  ...LocationActionCreators,
  ...ExtraActionCreators,
  ...GlobalActionCreators,
  ...DriverActionCreator,
  ...ReportActionCreators,
  ...PickupDetailsActionCreators,
  ...OnewayActionCreators,
  ...SeasonPriceActionCreators,
  ...NearVanActionCreators,
  ...HostStaffActionCreators,
  ...SublocationActionCreators,
  ...SubPriceActionCreators,
};
