import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { store } from "src/store";
import { AuthActionEnum } from "src/store/reducers/auth/types";
import { GlobalActionEnum } from "src/store/reducers/global/types";

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API}/admin/`,
};
const instance: AxiosInstance = axios.create(config);

instance.interceptors.request.use((config) => {
  config.headers!.Authorization = `${localStorage.getItem("token")}`;
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("error", error);
    error.response.data === "Unauthorized"
      ? store.dispatch({
        type: AuthActionEnum.EXIT,
        result: error.response.data,
      })
      : store.dispatch({
        type: GlobalActionEnum.ERROR,
        result: error.response.data,
        status: error.response.status,
      });
  }
);

export default instance;
