import { put, takeLatest } from "redux-saga/effects";
import instance from "src/sagas/set";
import { OrderActionCreators } from "src/store/reducers/order/action-creators";
import { OrderActionEnum } from "src/store/reducers/order/types";

function* fetchDeletePaymentOrder({
  id,
}: ReturnType<typeof OrderActionCreators.deletePaymentOrder>) {
  try {
    const { status }: Response = yield instance.delete(`order/history/${id}`);
    yield put(OrderActionCreators.resultDeletePaymentOrder(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeletePaymentOrder() {
  yield takeLatest(
    OrderActionEnum.REQUEST_DELETE_PAYMENT_ORDER,
    fetchDeletePaymentOrder
  );
}
