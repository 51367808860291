import { put, takeLatest } from "redux-saga/effects";
import { TagActionCreators } from "src/store/reducers/tag/action-creators";
import { TagActionEnum } from "src/store/reducers/tag/types";
import instance from "../set";

function* fetchGetTags() {
  try {
    const { data } = yield instance.get(`tag`);
    yield put(TagActionCreators.resultGetTags(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetTags() {
  yield takeLatest(TagActionEnum.REQUEST_TAGS, fetchGetTags);
}
