import { IClient, IClientFetch, ISearchClient } from "src/models/IClient";
import { IPagination } from "src/models/IPagination";
import {
  AddClientAction,
  ClientActionEnum,
  DeleteClientAction,
  GetClientAction,
  GetClientsAction,
  ResultAddClientAction,
  ResultDeleteClientAction,
  ResultGetClientAction,
  ResultGetClientsAction,
  ResultSearchClientsAction,
  ResultUpdateClientAction,
  SearchClientsAction,
  UpdateClientAction,
} from "./types";

export const ClientActionCreators = {
  getClients: (filter: IPagination): GetClientsAction => ({
    type: ClientActionEnum.REQUEST_CLIENTS,
    filter,
  }),
  resultGetClients: (
    count: number,
    clientList: IClient[]
  ): ResultGetClientsAction => ({
    type: ClientActionEnum.RESULT_CLIENTS,
    count,
    clientList,
  }),

  getClient: (id: number): GetClientAction => ({
    type: ClientActionEnum.REQUEST_CLIENT,
    id,
  }),
  resultGetClient: (data: IClient): ResultGetClientAction => ({
    type: ClientActionEnum.RESULT_CLIENT,
    data,
  }),

  deleteClient: (id: number): DeleteClientAction => ({
    type: ClientActionEnum.REQUEST_DELETE_CLIENT,
    id,
  }),
  resultDeleteClient: (status: number): ResultDeleteClientAction => ({
    type: ClientActionEnum.RESULT_DELETE_CLIENT,
    status,
  }),

  updateClient: (currentClient: Partial<IClientFetch>): UpdateClientAction => ({
    type: ClientActionEnum.REQUEST_UPDATE_CLIENT,
    currentClient,
  }),
  resultUpdateClient: (status: number): ResultUpdateClientAction => ({
    type: ClientActionEnum.RESULT_UPDATE_CLIENT,
    status,
  }),

  addClient: (client: IClient): AddClientAction => ({
    type: ClientActionEnum.REQUEST_ADD_CLIENT,
    client,
  }),
  resultAddClient: (status: number): ResultAddClientAction => ({
    type: ClientActionEnum.RESULT_ADD_CLIENT,
    status,
  }),

  searchClients: (search: string): SearchClientsAction => ({
    type: ClientActionEnum.REQUEST_SEARCH_CLIENTS,
    search,
  }),
  resultSearchClients: (
    clients: ISearchClient[]
  ): ResultSearchClientsAction => ({
    type: ClientActionEnum.RESULT_SEARCH_CLIENTS,
    clients
  }),
};
