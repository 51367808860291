import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useActions } from "src/hooks/useActions";

interface IProps {}

const ClientShow: FC<IProps> = () => {
  const { getClient } = useActions();
  const { id } = useParams();

  useEffect(() => {
    id && getClient(+id);
  }, [id]);

  return <>Single page of client #{id}</>;
};

export default ClientShow;
