import "./styles.scss";
import "src/style/variables.scss";
import { colorSet, mainColor } from "src/theme";
interface IIcon {
  classes?: string;
}
const colorIcon = mainColor;
export const GradientIcon = ({
  name,
  color1,
  color2,
}: {
  name: string;
  color1: string;
  color2: string;
}) => (
  <defs>
    <linearGradient id={name} gradientTransform="rotate(35)">
      <stop offset="0%" stopColor={color1} />
      <stop offset="100%" stopColor={color2} />
    </linearGradient>
  </defs>
);
const Touch = ({ classes }: IIcon) => (
  <svg
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 487.887 487.887"
  >
    <g>
      <path
        d="M374.925,426.945H66.549c-2.762,0-5,2.238-5,5s2.238,5,5,5h308.376c2.762,0,5-2.238,5-5S377.686,426.945,374.925,426.945z"
        fill={colorIcon}
      />
      <path
        d="M374.925,451.526H66.549c-2.762,0-5,2.238-5,5s2.238,5,5,5h308.376c2.762,0,5-2.238,5-5S377.686,451.526,374.925,451.526z"
        fill={colorIcon}
      />
      <path
        d="M465.136,295.564l-20.693-26.197c-0.392-0.496-0.874-0.901-1.41-1.213c2.938-10.562,0.985-22.338-6.315-31.58
		l-16.768-21.227V5c0-2.762-2.238-5-5-5H85.01C50.087,0,21.675,28.412,21.675,63.335v379.733c0,24.713,20.105,44.818,44.818,44.818
		h348.456c0.018,0,0.034-0.005,0.051-0.005c0.283-0.003,0.565-0.034,0.843-0.085c0.053-0.009,0.107-0.014,0.16-0.026
		c0.255-0.055,0.505-0.137,0.75-0.232c0.075-0.029,0.15-0.056,0.223-0.088c0.224-0.1,0.44-0.219,0.65-0.353
		c0.077-0.049,0.152-0.1,0.226-0.153c0.195-0.14,0.384-0.292,0.561-0.462c0.07-0.067,0.131-0.14,0.197-0.211
		c0.058-0.063,0.12-0.121,0.175-0.188c0.036-0.043,0.058-0.092,0.092-0.136c0.14-0.179,0.265-0.365,0.38-0.563
		c0.055-0.094,0.107-0.188,0.155-0.285c0.095-0.19,0.175-0.387,0.245-0.59c0.038-0.11,0.073-0.22,0.103-0.332
		c0.052-0.196,0.091-0.395,0.12-0.599c0.018-0.126,0.03-0.251,0.038-0.379c0.007-0.105,0.031-0.206,0.031-0.313V403.25v-65.619
		l44.362-35.043C466.478,300.876,466.847,297.731,465.136,295.564z M31.675,443.068c0-19.199,15.619-34.818,34.818-34.818h338.139
		c-5.813,8.237-13.319,21.46-13.319,34.818c0,15.313,6.585,27.301,12.286,34.818H66.493
		C47.294,477.887,31.675,462.268,31.675,443.068z M401.312,443.068c0-8.73,4.315-17.822,8.637-24.74v51.194
		C405.668,463.231,401.312,454.121,401.312,443.068z M409.949,398.25H81.453v-15.463c0-2.762-2.238-5-5-5s-5,2.238-5,5v15.463h-4.96
		c-14.044,0-26.595,6.499-34.818,16.641V63.335c0-24.726,16.914-45.57,39.778-51.584v332.512c0,2.762,2.238,5,5,5s5-2.238,5-5
		V10.132C82.63,10.054,83.813,10,85.01,10h324.939v192.688l-11.071-14.016l-25.004-31.654c-5.552-7.028-13.509-11.474-22.404-12.518
		c-8.886-1.045-17.664,1.439-24.692,6.99l-25.822,20.397l-25.467,20.117l-27.668-35.027c-9.079-11.491-25.871-13.94-37.438-5.453
		c-5.967,4.377-9.798,10.819-10.79,18.138c-0.991,7.322,0.984,14.548,5.563,20.346l77.468,98.069
		c-5.991-0.977-12.252,0.142-17.571,3.2c-6.037,3.472-10.306,9.013-12.018,15.604c-3.504,13.486,4.564,27.515,17.985,31.271
		l52.295,14.642c3.134,0.878,6.342,1.308,9.536,1.308c6.262,0,12.459-1.659,17.91-4.825l17.82,22.559
		c0.987,1.249,2.45,1.9,3.927,1.9c1.086,0,2.179-0.352,3.097-1.076l34.345-27.13V398.25z M373.329,361.714l-17.595-22.274
		c-1.713-2.167-4.857-2.533-7.023-0.824c-6.381,5.043-14.867,6.742-22.701,4.55l-52.295-14.642
		c-8.21-2.299-13.146-10.88-11.003-19.127c1.032-3.972,3.634-7.328,7.324-9.45c3.848-2.211,8.476-2.8,12.698-1.62l11.819,3.309
		c2.081,0.587,4.31-0.241,5.509-2.043c1.2-1.802,1.104-4.172-0.237-5.871l-86.822-109.912c-2.882-3.648-4.126-8.195-3.501-12.804
		c0.623-4.605,3.037-8.66,6.796-11.418c7.272-5.336,17.894-3.729,23.674,3.59l30.766,38.948c0.001,0.001,0.001,0.002,0.002,0.003
		l27.544,34.869c0.196,0.248,0.415,0.465,0.644,0.665c0.035,0.03,0.068,0.063,0.104,0.092c0.222,0.184,0.458,0.343,0.704,0.483
		c0.048,0.027,0.095,0.052,0.143,0.078c0.25,0.132,0.507,0.244,0.773,0.331c0.039,0.013,0.079,0.022,0.119,0.034
		c0.267,0.08,0.538,0.141,0.814,0.175c0.019,0.002,0.038,0.007,0.057,0.01c0.189,0.021,0.379,0.033,0.57,0.033
		c0.069,0,0.138-0.014,0.208-0.017c0.119-0.005,0.236-0.015,0.354-0.029c0.184-0.021,0.366-0.053,0.548-0.095
		c0.115-0.026,0.229-0.051,0.342-0.086c0.193-0.059,0.38-0.135,0.566-0.218c0.095-0.042,0.192-0.077,0.285-0.125
		c0.275-0.143,0.542-0.308,0.794-0.507c2.167-1.712,2.535-4.856,0.824-7.023l-24.445-30.946l21.544-17.018l24.445,30.946
		c0.196,0.248,0.415,0.465,0.644,0.665c0.035,0.03,0.068,0.063,0.104,0.092c0.222,0.184,0.458,0.343,0.704,0.483
		c0.048,0.027,0.095,0.052,0.143,0.078c0.25,0.132,0.507,0.244,0.773,0.331c0.039,0.013,0.079,0.022,0.119,0.034
		c0.267,0.08,0.538,0.141,0.814,0.175c0.019,0.002,0.038,0.007,0.057,0.01c0.189,0.021,0.379,0.033,0.57,0.033
		c0.069,0,0.138-0.014,0.208-0.017c0.119-0.005,0.236-0.015,0.354-0.029c0.184-0.021,0.366-0.053,0.548-0.095
		c0.115-0.026,0.229-0.051,0.342-0.086c0.193-0.059,0.38-0.135,0.566-0.218c0.095-0.042,0.192-0.077,0.285-0.125
		c0.275-0.143,0.542-0.308,0.794-0.507c2.167-1.712,2.535-4.856,0.824-7.023l-24.445-30.946l17.975-14.198l24.445,30.946
		c1.715,2.168,4.858,2.535,7.023,0.824c2.167-1.712,2.536-4.856,0.824-7.023l-24.117-30.53c4.02-1.974,8.525-2.754,13.077-2.222
		c6.242,0.732,11.826,3.853,15.722,8.785l25.004,31.654l37.839,47.902c4.227,5.35,6.115,12.023,5.321,18.795
		c-0.795,6.769-4.177,12.823-9.525,17.049l-52.841,41.74c-2.167,1.712-2.535,4.856-0.824,7.023c1.714,2.168,4.858,2.535,7.023,0.824
		l52.83-41.732c0.002-0.002,0.005-0.003,0.007-0.005c0.008-0.006,0.015-0.012,0.022-0.019l8.811-6.96l14.495,18.351L373.329,361.714
		z"
        fill={colorIcon}
      />
    </g>

    <g>
      <path
        d="M309.445,317.61c-1.408,0-2.809-0.592-3.797-1.745l-13.542-15.79c-1.798-2.096-1.557-5.253,0.54-7.05
			c2.096-1.799,5.253-1.557,7.05,0.54l13.542,15.79c1.798,2.096,1.557,5.253-0.54,7.05
			C311.755,317.215,310.598,317.61,309.445,317.61z"
        fill={colorIcon}
      />
    </g>
  </svg>
);

const Point = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="18.5"
      cy="18.5"
      r="18.25"
      fill="#F7F9FC"
      stroke="#7D879C"
      strokeWidth="0.5"
    />
    <path
      d="M24.75 16.5C24.75 21.75 18 26.25 18 26.25C18 26.25 11.25 21.75 11.25 16.5C11.25 14.7098 11.9612 12.9929 13.227 11.727C14.4929 10.4612 16.2098 9.75 18 9.75C19.7902 9.75 21.5071 10.4612 22.773 11.727C24.0388 12.9929 24.75 14.7098 24.75 16.5Z"
      fill="#2B3445"
    />
    <path
      d="M18 18.75C19.2426 18.75 20.25 17.7426 20.25 16.5C20.25 15.2574 19.2426 14.25 18 14.25C16.7574 14.25 15.75 15.2574 15.75 16.5C15.75 17.7426 16.7574 18.75 18 18.75Z"
      fill="#F7F9FC"
    />
  </svg>
);
const Bagadge = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="17"
    height="17"
    viewBox="0 0 17 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0.766667C4 0.563334 4.07902 0.368329 4.21967 0.224551C4.36032 0.0807735 4.55109 0 4.75 0H12.25C12.4489 0 12.6397 0.0807735 12.7803 0.224551C12.921 0.368329 13 0.563334 13 0.766667C13 0.969999 12.921 1.165 12.7803 1.30878C12.6397 1.45256 12.4489 1.53333 12.25 1.53333H11.875V3.06667H13.375C14.2701 3.06667 15.1285 3.43015 15.7615 4.07715C16.3944 4.72415 16.75 5.60167 16.75 6.51667V18.0167C16.7502 18.8654 16.4443 19.6844 15.8908 20.3171C15.3373 20.9497 14.5751 21.3517 13.75 21.446V22.2333C13.75 22.4367 13.671 22.6317 13.5303 22.7754C13.3897 22.9192 13.1989 23 13 23C12.8011 23 12.6103 22.9192 12.4697 22.7754C12.329 22.6317 12.25 22.4367 12.25 22.2333V21.4667H4.75V22.2333C4.75 22.4367 4.67098 22.6317 4.53033 22.7754C4.38968 22.9192 4.19891 23 4 23C3.80109 23 3.61032 22.9192 3.46967 22.7754C3.32902 22.6317 3.25 22.4367 3.25 22.2333V21.446C2.42486 21.3517 1.66267 20.9497 1.10921 20.3171C0.555743 19.6844 0.249841 18.8654 0.25 18.0167V6.51667C0.25 5.60167 0.605579 4.72415 1.23851 4.07715C1.87145 3.43015 2.72989 3.06667 3.625 3.06667H5.5V1.53333H4.75C4.55109 1.53333 4.36032 1.45256 4.21967 1.30878C4.07902 1.165 4 0.969999 4 0.766667ZM7 1.53333V3.06667H10.375V1.53333H7ZM4.75 7.66667C4.55109 7.66667 4.36032 7.74744 4.21967 7.89122C4.07902 8.035 4 8.23 4 8.43333C4 8.63667 4.07902 8.83167 4.21967 8.97545C4.36032 9.11923 4.55109 9.2 4.75 9.2H12.25C12.4489 9.2 12.6397 9.11923 12.7803 8.97545C12.921 8.83167 13 8.63667 13 8.43333C13 8.23 12.921 8.035 12.7803 7.89122C12.6397 7.74744 12.4489 7.66667 12.25 7.66667H4.75Z"
      fill={colorIcon}
    />
  </svg>
);

const Bycicle = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="22"
    height="17"
    viewBox="0 0 22 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0.800029C10.0536 0.786029 10.0536 2.21403 11 2.20003H13.1V3.97243L9.1758 7.24003L7.8122 3.60003H8.2C9.1464 3.61403 9.1464 2.18603 8.2 2.20003H5.4C4.4536 2.18603 4.4536 3.61403 5.4 3.60003H6.3128L7.5602 6.92503C6.89075 6.58444 6.15109 6.40468 5.4 6.40003C2.7022 6.40003 0.5 8.60223 0.5 11.3C0.5 13.9978 2.7022 16.2 5.4 16.2C8.0978 16.2 10.3 13.9978 10.3 11.3C10.3 10.362 10.02 9.49263 9.558 8.74643L13.5074 5.45643L14.1556 7.08043C12.694 7.93163 11.7 9.49543 11.7 11.3C11.7 13.9978 13.9022 16.2 16.6 16.2C19.2978 16.2 21.5 13.9978 21.5 11.3C21.5 8.60223 19.2978 6.40003 16.6 6.40003C16.2038 6.40003 15.8244 6.46023 15.4548 6.54983L14.5 4.16283V1.50003C14.5 1.31438 14.4263 1.13633 14.295 1.00505C14.1637 0.873779 13.9857 0.800029 13.8 0.800029H11ZM5.4 7.80003C6.19277 7.80199 6.961 8.07519 7.577 8.57423L4.952 10.761C4.2366 11.3588 5.1312 12.434 5.848 11.839L8.473 9.65083C8.7362 10.1436 8.9 10.7008 8.9 11.3C8.9 13.2418 7.3418 14.8 5.4 14.8C3.4582 14.8 1.9 13.2418 1.9 11.3C1.9 9.35823 3.4582 7.80003 5.4 7.80003ZM16.6 7.80003C18.5418 7.80003 20.1 9.35823 20.1 11.3C20.1 13.2418 18.5418 14.8 16.6 14.8C14.6582 14.8 13.1 13.2418 13.1 11.3C13.0987 10.7202 13.2421 10.1493 13.5171 9.63886C13.7921 9.12844 14.1901 8.69467 14.675 8.37683L15.949 11.5604C16.2934 12.4326 17.601 11.9104 17.251 11.0396L15.9798 7.86163C16.18 7.82803 16.3872 7.80003 16.6 7.80003Z"
      fill={colorIcon}
    />
  </svg>
);

const Seat = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="15"
    height="17"
    viewBox="0 0 15 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 16C3 16 0 8 0 4C0 0 2 0 2 0H3C3 0 4 0 4 1C4 2 3 2 3 4C3 6 6 8 6 11C6 14 3 16 3 16ZM8 15C7 15 4 17.5 4 17.5C3.7 17.7 3.8 18 4 18.3C4 18.3 5 20.1 7 20.1H13C14.1 20.1 15 19.2 15 18.1V17.1C15 16 14.1 15.1 13 15.1H8V15Z"
      fill={colorIcon}
    />
  </svg>
);

const DoubleArrows = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="23"
    height="16"
    viewBox="0 0 23 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3535 9.30002L11.319 5.70002C11.2069 5.60002 11.1277 5.49169 11.0814 5.37502C11.0343 5.25836 11.0108 5.13336 11.0108 5.00002C11.0108 4.86669 11.0343 4.74169 11.0814 4.62502C11.1277 4.50836 11.2069 4.40002 11.319 4.30002L15.3535 0.700024C15.5776 0.500024 15.8391 0.400024 16.138 0.400024C16.4368 0.400024 16.6983 0.500024 16.9224 0.700024C17.1466 0.900024 17.2586 1.13736 17.2586 1.41202C17.2586 1.68736 17.1466 1.92502 16.9224 2.12502L14.8211 4.00002H21.7414C22.0589 4.00002 22.3249 4.09569 22.5393 4.28702C22.7545 4.47902 22.8621 4.71669 22.8621 5.00002C22.8621 5.28336 22.7545 5.52069 22.5393 5.71202C22.3249 5.90402 22.0589 6.00002 21.7414 6.00002H14.8211L16.9224 7.87502C17.1466 8.07502 17.2586 8.30836 17.2586 8.57502C17.2586 8.84169 17.1466 9.07502 16.9224 9.27502C16.6983 9.47502 16.4417 9.58336 16.1525 9.60003C15.8626 9.61669 15.5963 9.51669 15.3535 9.30002ZM6.38796 15.275C6.6121 15.475 6.87359 15.579 7.17244 15.587C7.47129 15.5957 7.73279 15.5 7.95692 15.3L11.9914 11.7C12.1035 11.6 12.183 11.4917 12.2301 11.375C12.2764 11.2584 12.2996 11.1334 12.2996 11C12.2996 10.8667 12.2764 10.7417 12.2301 10.625C12.183 10.5084 12.1035 10.4 11.9914 10.3L7.95692 6.70002C7.73279 6.50002 7.47129 6.40002 7.17244 6.40002C6.87359 6.40002 6.6121 6.50002 6.38796 6.70002C6.16382 6.90002 6.05175 7.13736 6.05175 7.41202C6.05175 7.68736 6.16382 7.92502 6.38796 8.12502L8.48925 10H1.56899C1.25146 10 0.985487 10.0957 0.771062 10.287C0.55589 10.479 0.448303 10.7167 0.448303 11C0.448303 11.2834 0.55589 11.5207 0.771062 11.712C0.985487 11.904 1.25146 12 1.56899 12H8.48925L6.38796 13.875C6.16382 14.075 6.05175 14.3084 6.05175 14.575C6.05175 14.8417 6.16382 15.075 6.38796 15.275Z"
      fill={colorIcon}
    />
  </svg>
);

const Arrow = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="8"
    height="5"
    viewBox="0 0 8 5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z" />
  </svg>
);

const BigArrow = ({ classes }: IIcon) => (
  <svg
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47 47"
  >
    <g>
      <path
        d="M46.255,35.941c-0.256,0-0.512-0.098-0.707-0.293l-21.921-21.92l-21.92,21.92c-0.391,0.391-1.023,0.391-1.414,0
		c-0.391-0.391-0.391-1.023,0-1.414L22.92,11.607c0.391-0.391,1.023-0.391,1.414,0l22.627,22.627c0.391,0.391,0.391,1.023,0,1.414
		C46.767,35.844,46.511,35.941,46.255,35.941z"
      />
    </g>
  </svg>
);

const Folder = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00043 31.7332C1.00043 34.0895 2.90336 35.9999 5.25043 35.9999H30.7504C33.0975 35.9999 35.0004 34.0895 35.0004 31.7332V13.5999C35.0004 11.2436 33.0975 9.33321 30.7504 9.33321H21.1879C17.4033 9.33321 18.0004 3.99988 12.2236 3.99988H5.25043C2.90336 3.99988 1.00043 5.91028 1.00043 8.26655V31.7332Z"
      fill={colorSet.primaryGreen}
    />
    <path
      d="M35.1796 13.9999H28.2435C22.4956 13.9999 22.5537 19.238 18.7876 19.238H9.27462C6.93971 19.238 4.8553 21.1143 4.61748 23.4284L4.09743 27.4869L3.54357 31.8094L3.52243 31.8073C3.40087 32.4422 2.81212 32.8539 2.14833 32.8539C1.46868 32.8539 0.979284 32.3227 1.01311 31.6564C1.01099 31.7078 1.00043 31.757 1.00043 31.8094C1.00043 33.9088 2.56267 35.6311 4.59422 35.936C4.78131 35.9758 4.98743 35.9999 5.22842 35.9999H32.7104C35.0453 35.9999 37.1308 34.1236 37.3676 31.8094L38.9784 18.1904C39.2152 15.8762 37.5145 13.9999 35.1796 13.9999Z"
      fill={colorSet.primaryGreen}
    />
  </svg>
);
const Close = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00004 0.333374C3.31337 0.333374 0.333374 3.31337 0.333374 7.00004C0.333374 10.6867 3.31337 13.6667 7.00004 13.6667C10.6867 13.6667 13.6667 10.6867 13.6667 7.00004C13.6667 3.31337 10.6867 0.333374 7.00004 0.333374ZM9.86671 9.86671C9.60671 10.1267 9.18671 10.1267 8.92671 9.86671L7.00004 7.94004L5.07337 9.86671C4.81337 10.1267 4.39337 10.1267 4.13337 9.86671C3.87337 9.60671 3.87337 9.18671 4.13337 8.92671L6.06004 7.00004L4.13337 5.07337C3.87337 4.81337 3.87337 4.39337 4.13337 4.13337C4.39337 3.87337 4.81337 3.87337 5.07337 4.13337L7.00004 6.06004L8.92671 4.13337C9.18671 3.87337 9.60671 3.87337 9.86671 4.13337C10.1267 4.39337 10.1267 4.81337 9.86671 5.07337L7.94004 7.00004L9.86671 8.92671C10.12 9.18004 10.12 9.60671 9.86671 9.86671Z"
      fill={colorIcon}
    />
  </svg>
);
const BiGCamera = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="90"
    height="74"
    viewBox="0 0 90 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="12" width="90" height="62" rx="7" fill="#2E3541" />
    <circle cx="45" cy="43" r="26" fill="white" fill-opacity="0.2" />
    <circle cx="45" cy="43" r="17" fill="#252A34" />
    <rect
      x="73"
      y="17"
      width="12"
      height="8"
      rx="1"
      fill="white"
      fill-opacity="0.2"
    />
    <circle cx="11" cy="63" r="5" fill="white" fill-opacity="0.2" />
    <path
      d="M31.1312 2.82366C31.6476 1.14529 33.1983 0 34.9543 0H55.0457C56.8017 0 58.3524 1.14529 58.8688 2.82366L62 13H28L31.1312 2.82366Z"
      fill="#2E3541"
    />
    <path
      d="M36.4805 6.21216C36.7957 5.47679 37.5187 5 38.3188 5H51.6812C52.4813 5 53.2043 5.47679 53.5195 6.21216L56 12H34L36.4805 6.21216Z"
      fill="white"
      fill-opacity="0.2"
    />
    <circle cx="38" cy="36" r="3" fill="white" fill-opacity="0.45" />
    <circle cx="42" cy="44" r="4" fill="white" fill-opacity="0.45" />
  </svg>
);
const Pencil = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <GradientIcon name="gr" color1="#C1FF45" color2="#45FF45" />
    <path d="M0 15.46V18.5C0 18.78 0.22 19 0.5 19H3.54C3.67 19 3.8 18.95 3.89 18.85L14.81 7.94L11.06 4.19L0.15 15.1C0.0500001 15.2 0 15.32 0 15.46ZM17.71 5.04C18.1 4.65 18.1 4.02 17.71 3.63L15.37 1.29C14.98 0.899998 14.35 0.899998 13.96 1.29L12.13 3.12L15.88 6.87L17.71 5.04Z" />
  </svg>
);
const TrashBin = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="14"
    height="18"
    viewBox="0 0 14 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <GradientIcon name="gr" color1="#C1FF45" color2="#45FF45" />
    <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" />
  </svg>
);

const Cross = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke={colorIcon}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke={colorIcon}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DeleteOneRow = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9L9 15"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9L15 15"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DoubleArrow = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 17.01V11C16 10.45 15.55 10 15 10C14.45 10 14 10.45 14 11V17.01H12.21C11.76 17.01 11.54 17.55 11.86 17.86L14.65 20.64C14.85 20.83 15.16 20.83 15.36 20.64L18.15 17.86C18.47 17.55 18.24 17.01 17.8 17.01H16ZM8.65003 3.35002L5.86003 6.14002C5.54003 6.45002 5.76003 6.99002 6.21003 6.99002H8.00003V13C8.00003 13.55 8.45003 14 9.00003 14C9.55003 14 10 13.55 10 13V6.99002H11.79C12.24 6.99002 12.46 6.45002 12.14 6.14002L9.35003 3.35002C9.16003 3.16002 8.84003 3.16002 8.65003 3.35002Z"
      fill={colorIcon}
    />
  </svg>
);

const Edit = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Delete = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 6H5H21"
      stroke={colorIcon}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
      stroke={colorIcon}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Check = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    fill="none"
    viewBox="-3 -5 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 1L6 12L1 7"
      stroke="#5cdb47"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NoResult = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="450"
    height="226"
    viewBox="0 0 450 226"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99296 209.057C-12.6386 187.341 9.43162 129.245 42.7105 93.7168C83.178 50.5214 138.967 14.1864 246.801 26.5424C335.846 36.75 391.13 81.5083 409.935 104.565C439.836 141.209 461.44 192.911 443.431 214.294C413.21 250.186 288.347 189.116 220.619 187.871C152.891 186.627 54.0625 257.466 7.99296 209.057Z"
      fill="#E1ECFF"
    />
    <path
      d="M327.977 174.363H92.9338L43.4966 13.2874C43.0236 11.7489 42.9197 10.1213 43.1933 8.53557C43.4668 6.94981 44.1101 5.45022 45.0714 4.15754C46.0327 2.86485 47.2851 1.8152 48.7278 1.09308C50.1705 0.370962 51.7631 -0.00343911 53.3776 2.38046e-05H266.827C269.036 -0.000414439 271.188 0.706271 272.964 2.01601C274.74 3.32576 276.048 5.16934 276.694 7.27532L327.977 174.363Z"
      fill="#535E6C"
    />
    <path
      d="M289.485 155.188H120.861C118.631 155.188 116.46 154.475 114.667 153.154C112.875 151.832 111.555 149.973 110.901 147.848L73.4635 25.8647C73.2253 25.0906 73.1727 24.2717 73.31 23.4737C73.4473 22.6757 73.7705 21.921 74.2538 21.2703C74.7371 20.6195 75.3669 20.0909 76.0926 19.727C76.8182 19.363 77.6194 19.1739 78.4317 19.1749H247.056C249.285 19.175 251.456 19.8881 253.249 21.2093C255.042 22.5305 256.362 24.3901 257.015 26.5147L294.453 148.498C294.691 149.272 294.744 150.091 294.606 150.889C294.469 151.687 294.146 152.442 293.663 153.093C293.179 153.743 292.55 154.272 291.824 154.636C291.098 155 290.297 155.189 289.485 155.188V155.188Z"
      fill="#ECF2FC"
    />
    <path
      d="M92.9341 174.363H408.648C410.778 174.363 412.821 175.206 414.327 176.707C415.833 178.208 416.679 180.244 416.679 182.367V193.058C416.679 195.181 415.833 197.217 414.327 198.718C412.821 200.219 410.778 201.062 408.648 201.062H100.965C99.9101 201.062 98.8658 200.855 97.8915 200.453C96.9172 200.051 96.0319 199.461 95.2862 198.718C94.5405 197.975 93.949 197.092 93.5454 196.121C93.1418 195.15 92.9341 194.109 92.9341 193.058V174.363Z"
      fill="#303439"
    />
    <path
      d="M131.413 93.0575L150.615 71.6973"
      stroke="#5893F9"
      strokeWidth="18"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M155.148 93.0575L126.231 71.6973"
      stroke="#5893F9"
      strokeWidth="18"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M226.235 93.0575L245.437 71.6973"
      stroke="#5893F9"
      strokeWidth="18"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M249.97 93.0575L221.049 71.6973"
      stroke="#5893F9"
      strokeWidth="18"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M178.773 106.557C178.773 106.557 193.825 98.2858 209.114 106.557"
      stroke="#5893F9"
      strokeWidth="18"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M343.164 81.3469C341.424 81.3424 339.738 80.7458 338.384 79.6559C337.03 78.5659 336.091 77.048 335.721 75.3533C334.305 68.9955 333.408 64.1407 334.657 59.5994C336.156 54.2144 340.111 51.0608 344.371 48.0456C347.244 46.017 349.238 42.9372 349.46 40.2078C349.599 38.3959 348.96 36.8698 347.448 35.4129C342.979 31.0929 335.744 37.815 335.42 38.1054C335.42 38.1054 329.342 43.2323 324.827 37.8565C320.312 32.4807 324.827 27.151 324.827 27.151C327.739 24.456 331.09 22.2734 334.736 20.6964C343.428 17.008 351.708 18.3912 358.055 24.5C362.801 29.0828 365.146 35.081 364.661 41.3881C364.101 48.6634 359.813 55.805 353.184 60.48C350.071 62.6792 349.483 63.4583 349.34 63.7073C348.983 64.8323 350.158 70.102 350.603 72.0891C350.848 73.1999 350.84 74.3512 350.581 75.4588C350.321 76.5663 349.816 77.6019 349.103 78.4896C348.39 79.3774 347.486 80.0948 346.458 80.5892C345.431 81.0836 344.305 81.3425 343.164 81.3469V81.3469Z"
      fill="#5893F9"
    />
    <path
      d="M346.842 107.728C351.729 107.728 355.691 103.779 355.691 98.9082C355.691 94.0372 351.729 90.0884 346.842 90.0884C341.954 90.0884 337.992 94.0372 337.992 98.9082C337.992 103.779 341.954 107.728 346.842 107.728Z"
      fill="#5893F9"
    />
  </svg>
);

const BigTrashBin = ({ classes }: IIcon) => (
  <svg
    className={classes}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="57 0 400 550"
  >
    <path
      d="M434.2,101.4h-13.762l5.229-30.72c0-1.707,0-4.267-1.707-5.973c-1.707-1.707-3.413-3.413-5.973-3.413l-64.628-10.44
				L348.867,6.68C348.013,2.413,343.747-1,339.48-1L167.96,13.507c-2.56,0.853-4.267,1.707-5.973,3.413
				c-0.853,1.707-1.707,3.413-1.707,5.973l4.305,39.463L82.627,78.36c-4.267,0.853-7.68,5.12-6.827,9.387l2.438,13.653H75.8
				c-14.507,0-25.6,11.093-25.6,25.6v34.133c0,13.95,10.261,24.739,23.948,25.546l35.785,300.427
				C110.787,500.76,121.88,511,135.533,511h238.933c12.8,0,24.747-10.24,25.6-23.04l35.788-301.281
				c13.686-0.809,23.946-11.596,23.946-25.546V127C459.8,112.493,448.707,101.4,434.2,101.4z M402.627,101.4h-96.398l6.798-40.107
				l93.867,15.36L402.627,101.4z M181.613,58.733L178.2,29.72l153.6-12.8l3.413,30.72l-28.16-4.267
				c-4.267-0.853-8.533,2.56-9.387,6.827l-9.035,51.2h-43.416l-8.135-44.373c0-1.707-1.707-3.413-3.413-5.12
				C231.96,50.2,229.4,50.2,226.84,50.2L181.613,58.733z M94.573,92.867l79.706-15.079c0.33-0.036,0.661-0.098,0.995-0.188
				l6.129-1.16l39.492-7.313l5.945,32.274H95.427L94.573,92.867z M374.467,493.933H135.533c-4.267,0-8.533-3.413-8.533-8.533
				l-0.193-1.61l-3.22-41.057h262.827L383,486.253C383,490.52,378.733,493.933,374.467,493.933z M390.25,425.667H119.832
				L91.16,186.733h327.68L390.25,425.667z M442.733,161.133c0,5.12-3.413,8.533-8.533,8.533h-5.12H81.773H75.8
				c-5.12,0-8.533-3.413-8.533-8.533V127c0-5.12,3.413-8.533,8.533-8.533h12.8h149.333h58.027h114.347H434.2
				c5.12,0,8.533,3.413,8.533,8.533V161.133z"
    />
    <path
      d="M255,391.533c5.12,0,8.533-3.413,8.533-8.533V229.4c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533V383
				C246.467,388.12,249.88,391.533,255,391.533z"
    />
    <path
      d="M167.96,383.853c0.853,4.267,4.267,7.68,8.533,7.68c5.12-0.853,8.533-5.12,8.533-9.387L171.373,229.4
				c-0.853-4.267-4.267-8.533-9.387-7.68c-4.267,0.853-7.68,4.267-7.68,9.387L167.96,383.853z"
    />
    <path
      d="M333.507,391.533c4.267,0,8.533-3.413,8.533-7.68l13.653-152.747c0.853-5.12-2.56-9.387-7.68-9.387
				c-5.12-0.853-9.387,2.56-9.387,7.68l-13.653,152.747C324.973,387.267,328.387,390.68,333.507,391.533z"
    />
  </svg>
);

const AttachmetDoc = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 36 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.4344 47.3891H2.56563C1.48604 47.3891 0.610864 46.514 0.610864 45.4344V2.56563C0.610864 1.48604 1.48604 0.610864 2.56563 0.610864H25.5582C26.0852 0.610864 26.5899 0.823665 26.9578 1.20101L34.834 9.27912C35.1899 9.64419 35.3891 10.1339 35.3891 10.6437V45.4344C35.3891 46.514 34.514 47.3891 33.4344 47.3891Z"
      stroke={colorIcon}
      strokeWidth="1"
    />
    <path
      d="M26 1V8.43437C26 9.85133 27.1487 11 28.5656 11H36"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinejoin="round"
    />
    <mask id="path-3-inside-1_5758_342" fill="white">
      <rect x="10" y="17" width="16" height="8" rx="0.85521" />
    </mask>
    <rect
      x="10"
      y="17"
      width="16"
      height="8"
      rx="0.85521"
      stroke={colorIcon}
      strokeWidth="2.44346"
      strokeLinejoin="round"
      mask="url(#path-3-inside-1_5758_342)"
    />
    <line
      x1="10.6109"
      y1="28.3891"
      x2="18.3891"
      y2="28.3891"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
    />
    <line
      x1="10.6109"
      y1="31.3891"
      x2="25.3891"
      y2="31.3891"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
    />
    <line
      x1="10.6109"
      y1="34.3891"
      x2="21.3891"
      y2="34.3891"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
    />
  </svg>
);
const ArrowDown = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9L12 15L18 9"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Plus = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 5V19"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 12H19"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LilPic = ({ classes }: IIcon) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
      stroke={colorIcon}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 15L16 10L5 21"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Search = ({ classes }: IIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101 101"
    id="search"
    className={classes}
    width="24"
    height="24"
    fill="none"
  >
    <path
      d="M63.3 59.9c3.8-4.6 6.2-10.5 6.2-17 0-14.6-11.9-26.5-26.5-26.5S16.5 28.3 16.5 42.9 28.4 69.4 43 69.4c6.4 0 12.4-2.3 17-6.2l20.6 20.6c.5.5 1.1.7 1.7.7.6 0 1.2-.2 1.7-.7.9-.9.9-2.5 0-3.4L63.3 59.9zm-20.4 4.7c-12 0-21.7-9.7-21.7-21.7s9.7-21.7 21.7-21.7 21.7 9.7 21.7 21.7-9.7 21.7-21.7 21.7z"
      stroke={colorIcon}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Icons = {
  LilPic,
  Point,
  Check,
  NoResult,
  Bagadge,
  Bycicle,
  Seat,
  DoubleArrow,
  DoubleArrows,
  Arrow,
  BigArrow,
  Folder,
  Close,
  BiGCamera,
  Pencil,
  TrashBin,
  Cross,
  DeleteOneRow,
  Edit,
  Delete,
  BigTrashBin,
  AttachmetDoc,
  ArrowDown,
  Touch,
  Plus,
  Search
};
export default Icons;
