import { FC, useEffect } from 'react';
import { orderBy } from 'natural-orderby';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import SelectBaseOnId from 'src/components/Items/select/SelectBaseOnId';
import SublocationsSelect from 'src/components/Items/select/SublocationsSelect';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IPickupDetails } from 'src/models/IPickupDetails';
import { IErrorsPdData } from '../index';

interface IProps {
    pd: IPickupDetails;
    setPd: any;
    check: boolean;
    errors: IErrorsPdData;
    disable: boolean;
}

const PickupDetailsFormModal: FC<IProps> = ({ pd, setPd, check, errors, disable }) => {

    const {
        location,
        host_id,
        description,
        return_at,
        address_take,
        address_drop
    } = pd;

    const { getLocations, getHosts } = useActions();
    const {
        location: { locationList },
        host: { hostList },
    } = useTypedSelector((state) => state);

    useEffect(() => {
        getLocations();
        getHosts();
    }, []);

    const sortedLocation = orderBy(
        locationList,
        [(location) => location.country, (location) => location.name],
        ['asc', 'asc']
    );

    return (
        <div className='col-2' style={{ gap: '15px' }}>
            <SublocationsSelect
                data={location}
                setData={setPd('location')}
                title='location'
                dataArray={sortedLocation}
                isChooseLocations
                check={check}
                type='with location'
            />
            <SelectBaseOnId
                dataId={Number(host_id)}
                setDataId={setPd('host_id')}
                title='host'
                dataArray={hostList}
                propsShow={['first_name', 'last_name']}
                check={check}
                disable={disable}
            />
            <SimpleInput
                data={address_take}
                setData={setPd('address_take')}
                title='pick-up address'
                placeholder="pick-up address"
                check={check}
                error={errors.description}
                multiline={true}
            />
            <SimpleInput
                data={address_drop}
                setData={setPd('address_drop')}
                title='return address'
                placeholder='return address'
                check={check}
                error={errors.return_at}
                multiline={true}
            />
            <SimpleInput
                data={description}
                setData={setPd('description')}
                title='pick-up info'
                placeholder="pick-up info"
                check={check}
                error={errors.description}
                multiline={true}
            />
            <SimpleInput
                data={return_at}
                setData={setPd('return_at')}
                title='return info'
                placeholder='return info'
                check={check}
                error={errors.return_at}
                multiline={true}
            />
        </div>
    );
};

export default PickupDetailsFormModal;
