import { IDriver, IDriverFetch } from 'src/models/IDriver';

export class Driver implements IDriver {
  email = '';
  last_name = '';
  first_name = '';
  phone = '';

  client_id = 0;

  constructor(currentDriver?: IDriverFetch) {
    if (currentDriver) {
      //@ts-ignore
      Object.keys(this).forEach((key) => (this[key] = currentDriver[key]));
    }
  }
}
