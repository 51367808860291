import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { VanActionEnum } from "src/store/reducers/van/types";
import { VanActionCreators } from "src/store/reducers/van/action-creators";

function* fetchAddVanBlock({
  block,
}: ReturnType<typeof VanActionCreators.addVanBlock>) {
  try {
    const { status }: Response = yield instance.post(`van/block`, block);
    yield put(VanActionCreators.resultAddVanBlock(status));
  } catch (e: any) {
    console.log("error", e);
    yield put(VanActionCreators.resultAddVanBlock("ERROR"));
  }
}

export function* actionWatcherAddVanBlock() {
  yield takeLatest(VanActionEnum.REQUEST_ADD_VAN_BLOCK, fetchAddVanBlock);
}
