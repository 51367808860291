import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { LocationActionCreators } from "src/store/reducers/location/action-creators";
import { LocationActionEnum } from "src/store/reducers/location/types";

function* fetchAddLocation({
    data,
}: ReturnType<typeof LocationActionCreators.addLocation>) {
  try {
    const { status }: Response = yield instance.post('location/add', {
        data,
    });
    yield put(LocationActionCreators.resultAddLocation(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddLocation() {
  yield takeLatest(LocationActionEnum.REQUEST_ADD_LOCATION, fetchAddLocation);
}