import { useState, useEffect, FC, useMemo } from "react";
import { Link } from "react-router-dom";
import Table from "src/components/Items/table";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import ActionForm from "src/components/Items/ActionForm";
import { Button, LinearProgress } from "@mui/material";
import { IClientFetch } from "src/models/IClient";
import { useActions } from "src/hooks/useActions";
import AddClientModal from "../Modal/Add";
import UpdateClientModal from "../Modal/Update";
import DeleteModalForm from "src/components/Items/DeleteModalForm";

export interface IClientFilters {
  page: number;
  take: number;
}

const badgeTheme = {
  on_hold: "blue",
  active: "green",
  inactive: "red",
  draft: "gray",
};

const initClientFilters: IClientFilters = {
  take: 500,
  page: 1,
};

const ClientTable: FC = () => {
  const { getClients } = useActions();
  const { role } = useTypedSelector((state) => state.auth);
  const { clientList, total, result, isLoading } = useTypedSelector(
    (state) => state.client
  );
  const [selectedClient, setSelectedClient] = useState({} as IClientFetch);
  const [filter, setFilter] = useState(initClientFilters);

  useEffect(() => {
    !Object.values(isLoading).find((el) => el) && getClients(filter);
  }, [filter, result]);

  const handleSort = (sortColumn: string) => {
    setFilter((f) => ({ ...f, order_by: sortColumn }));
  };

  const onPaginationChange = (page: number) => setFilter({ ...filter, page });
  const onTakeChange = (take: number) => setFilter({ ...filter, take });

  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = (client: IClientFetch) => {
    setOpenEdit(true);
    setSelectedClient(client);
  };
  const handleCloseEdit = () => setOpenEdit(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = (client: IClientFetch) => {
    setOpenDelete(true);
    setSelectedClient(client);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "first_name",
        Cell: ({
          row: {
            original: { id, first_name, last_name },
          },
        }: any) => {
          return (
            <Link
              to={`/client/${id}`}
              className="react-link"
              style={{ textTransform: 'capitalize' }}
            >
              {`${first_name} ${last_name}`}
            </Link>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        accessor: "actions",
        Cell: ({ row: { original } }: any) => (
          <>
            {role === 'admin' ? (
              <ActionForm.IconSet
                title="client"
                classWrapper="action-wrapper"
                onDelete={() => handleOpenDelete(original)}
                onEdit={() => handleOpenEdit(original)}
              />
            ) : (
              <ActionForm.IconSet
                title="client"
                classWrapper="action-wrapper"
                onEdit={() => handleOpenEdit(original)}
              />
            )}
          </>
        ),
      },
    ],
    [role]
  );
  if (isLoading.all && !clientList.length) return <LinearProgress />;
  else
    return (
      <>
        <div className="content__header">
          <Button
            children="+ Add Client"
            variant="contained"
            onClick={handleOpenAdd}
          />
        </div>
        {clientList.length ? (
          <div className="client-table">
            <Table.Base
              data={clientList}
              columns={columns}
              class="react-table"
              total={total!}
              filters={filter}
              onPaginationChange={onPaginationChange}
              onTakeChange={onTakeChange}
            />
            <UpdateClientModal
              open={openEdit}
              handleClose={handleCloseEdit}
              client={selectedClient}
            />
            <DeleteModalForm
              id={selectedClient.id}
              type="client"
              handleClose={handleCloseDelete}
              open={openDelete}
            />
          </div>
        ) : null}
        <AddClientModal open={openAdd} handleClose={handleCloseAdd} />
      </>
    );
};

export default ClientTable;
