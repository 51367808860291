import { FC } from "react";
import { useParams } from "react-router-dom";
import "./styles.scss";

const VanShow: FC = () => {
  const { id } = useParams();

  return <div>Single page of van #{id} </div>;
};

export default VanShow;
