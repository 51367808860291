export enum AuthActionEnum {
  REQUEST_LOGIN = "REQUEST_LOGIN",
  RESULT_LOGIN = "RESULT_LOGIN",
  ERROR_LOGIN = "ERROR_LOGIN",
  EXIT = "EXIT",
}
export type Role = "admin" | "super_host" | "host" | "";
export interface AuthState {
  isLogin: boolean;
  result: number | string;
  data?: {
    email: string;
    password: string;
  };
  role: Role;
  id: number | null;
}
export interface LoginAction {
  type: AuthActionEnum.REQUEST_LOGIN;
  login?: {
    email: string;
    password: string;
  };
  token?: string;
}

export interface ResultLoginAction {
  type: AuthActionEnum.RESULT_LOGIN;
  token: string;
  status: number | string;
  role: Role;
  id: number;
}

export interface ErrorLoginAction {
  type: AuthActionEnum.ERROR_LOGIN;
  result: string;
}

export interface ExitLoginAction {
  type: AuthActionEnum.EXIT;
  result?: string;
}

export type AuthAction =
  | LoginAction
  | ResultLoginAction
  | ErrorLoginAction
  | ExitLoginAction;
