import auth from './auth';
import van from './van';
import host from './host';
import tag from './tag';
import feature from './feature';
import location from './location';
import client from './client';
import order from './order';
import calendar from './calendar';
import extra from './extra';
import global from './global';
import hostReport from './report/host';
import pickupDetails from './pickupDetails';
import oneway from './oneway';
import driver from './driver';
import seasonPrice from './seasonPrice';
import nearVan from './nearVan/inrex';
import hostStaff from './hostStaff';
import sublocation from './sublocation';
import subPrice from './subPrice';

const reducers = {
  auth,
  van,
  host,
  tag,
  feature,
  client,
  order,
  calendar,
  location,
  extra,
  hostReport,
  global,
  pickupDetails,
  oneway,
  driver,
  seasonPrice,
  nearVan,
  hostStaff,
  sublocation,
  subPrice
};
export default reducers;
