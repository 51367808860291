import { FC, useEffect, useMemo, useState } from "react";
import { orderBy } from "natural-orderby";
import { CircularProgress, Tooltip } from "@mui/material";
import Table from 'src/components/Items/table';
import OnewayModal from "src/pages/Admin/Modals/AddOneway";
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import useShowSnackbar from "src/hooks/useShowSnackbar";
import { dineroFormat } from "src/common/utils/dataFormat";
import ActionForm from "src/components/Items/ActionForm";
import { IOnewayFetch } from "src/models/IOneway";

const OnewayTable: FC = () => {

  const { getOneways, clearOnewayResult } = useActions();
  const {
    oneway: { onewaysList, isLoading, result },
  } = useTypedSelector((state) => state);
  useShowSnackbar(isLoading, result, clearOnewayResult, 'Oneway');

  useEffect(() => {
    getOneways();
  }, []);

  useEffect(() => {
    (result.add || result.update) && (
      getOneways()
    );
  }, [result.add, result.update]);

  const [selectedOneway, setSelectedOneway] = useState({} as IOnewayFetch);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = (oneway: IOnewayFetch) => {
    setOpenEdit(true);
    setSelectedOneway(oneway);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Start location',
        accessor: 'start_location',
        Cell: ({
          row: {
            original: { start_location },
          },
        }: any) => {
          return <>{start_location}</>;
        },
      },
      {
        Header: 'End location',
        accessor: 'end_location',
        Cell: ({
          row: {
            original: { end_location },
          },
        }: any) => {
          return <>{end_location}</>;
        },
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({
          row: {
            original: { price, currency },
          },
        }: any) => {
          return <>{dineroFormat(price, currency)}</>;
        },
      },
      {
        accessor: 'actions',
        Cell: ({ row: { original } }: any) => (
          <>
            <div className='flex_CC' style={{ justifyContent: 'right' }}>
              <Tooltip title='Edit order'>
                <>
                  <ActionForm.IconSet
                    title='order'
                    classWrapper='action-wrapper'
                    onEdit={() => handleOpenEdit(original)}
                  />
                </>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    [onewaysList]
  );

  const sortedOneways = orderBy(
    onewaysList,
    [(oneway) => oneway.start_location, (oneway) => oneway.end_location],
    ['asc', 'asc']
  );

  return (
    <>
      <OnewayModal
        open={openEdit}
        editOneway={selectedOneway}
        handleClose={() => setOpenEdit(false)}
      />
      {isLoading.all ? (
        <CircularProgress size={200}
          style={{ position: 'fixed', top: '40%', left: '45%', zIndex: 100 }}
        />
      ) : (
        sortedOneways.length ? (
          <div className='order-table'>
            <Table.Base
              data={sortedOneways}
              columns={columns}
              class='react-table'
              total={0}
            />
          </div>
        ) : null
      )}
    </>
  );
};
export default OnewayTable;
