import { put, takeLatest } from 'redux-saga/effects';
import { DriverActionEnum } from 'src/store/reducers/driver/types';
import instance from '../set';
import { DriverActionCreator } from 'src/store/reducers/driver/action-creators';
import { Driver } from 'src/entity/Driver';

function* fetchUpdateDriver({
  driver, id
}: ReturnType<typeof DriverActionCreator.updateDriver>) {
  try {
    const { status }: Response = yield instance.patch(
      `driver/update/${id}`,
      {
        driver: driver,
      }
    );
    yield put(DriverActionCreator.resultUpdateDriver(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdateDriver() {
  yield takeLatest(DriverActionEnum.REQUEST_UPDATE_DRIVER, fetchUpdateDriver);
}
