import { put, takeLatest } from "redux-saga/effects";
import { SeasonPriceActionCreators } from "src/store/reducers/seasonPrice/action-creators";
import { SeasonPriceActionEnum } from "src/store/reducers/seasonPrice/types";
import instance from "../set";

function* fetchAddSeasonPrice({
  data,
}: ReturnType<typeof SeasonPriceActionCreators.addSeasonPrice>) {
  try {
    const { status }: Response = yield instance.post(`seasonPrice`, data);
    yield put(SeasonPriceActionCreators.resultAddSeasonPrice(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddSeasonPrice() {
  yield takeLatest(SeasonPriceActionEnum.REQUEST_ADD_SEASONPRICE, fetchAddSeasonPrice);
}
