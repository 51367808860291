import moment, { Moment } from "moment";

const dateFormats: string[] = ["DD MM YYYY", "LL", "DD/MM/YYYY", "DD-MM-YYYY"];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// used for daterangepickers
export const locale = {
  format: "DD-MM-YYYY",
  formatDoubleDatapicker: "DD-MM-YYYY hh:mm A",
  storeFormat: "YYYY-MM-DD",
  storeDoubleDatapicker: "YYYY-MM-DD hh:mm A",
  separator: " - ",
};
export const dateNextYear = moment().add(1, "year").format(locale.format);
export const datePrevYear = moment().subtract(1, "year").format(locale.format);
export const dateNextMonth = moment().add(1, "months").format(locale.format);
export const datePrevMonth = moment()
  .subtract(1, "months")
  .format(locale.format);
export const dateToday = moment().format(locale.format);

// Who knows what some of these are used for...
export const capitalize = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1).replace("_", " ");

export const upCaseColumn = (column: string): string =>
  column.toUpperCase().replace("_", " ");

export const capitalizeField = (field: string): string =>
  field
    .split("_")
    .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");

export const dateFormatLL = (date: string | null, UTC?: boolean): string =>
  date ? moment(date).format("LL") : "";

export const dateFormatLLL = (date: string | null): string =>
  date ? moment(date).utc().format("LLL") : "";

export const dateFormatll = (date: string | null): string =>
  date ? moment(date).format("ll") : "";

export const dateFormatNoLocal = (date: string | null): string =>
  date ? moment(date).format("MMMM DD, YYYY") : "";

export const dateToYear = (date: string | Moment): string =>
  moment(date).format("YYYY");

export const dateToMonth = (date: string | Moment): string =>
  moment(date).format("MMM");

export const getPreviousMonth = (date: string): Moment =>
  moment(date).subtract(1, "months");

export const getPreviousYear = (date: string): Moment =>
  moment(date).subtract(1, "years");

export const getLastSixMonths = (date: string): Moment =>
  moment(date).subtract(6, "months");

export const getLastTwoYearsMonths = (date: string): Moment =>
  moment(date).subtract(2, "years");

export const dateToYearMonth = (date: string): string =>
  moment(date).format("YYYY-MM");

export const dateToHour = (date: string): string =>
  moment(date).utc().format("hh-mm A");

export const dateToMounthDay = (date: string): string =>
  moment(date).utc().format("MMMM D");

export const dateToMounthDayHour = (date: string): string =>
  moment(date).utc().format("MMMM D, hh-mm A");

export const columnsFilter = (columns: string[]) =>
  columns.map((column: string) => ({
    label: capitalize(column),
    value: column,
  }));

export const setDateFormat = (
  date: string,
  length: number = 7,
  format: string = "YYYY-MM-DD"
): string => {
  let formattedDate = "";
  dateFormats.forEach((f: string): void => {
    if (moment(date, f).isValid() && date.length === length) {
      formattedDate = moment(date, f).format(format);
    }
  });

  return formattedDate;
};

export const compareDatesIsMoreOrEqualTo = (
  leftOperand: Moment,
  rightOperand: Moment
) => leftOperand.isSameOrAfter(rightOperand);

export const compareDatesIsLessOrEqualTo = (
  leftOperand: Moment,
  rightOperand: Moment
) => leftOperand.isSameOrBefore(rightOperand);

export function toFullName(first_name: string, last_name: string): string {
  return first_name && last_name && `${first_name} ${last_name}`;
}

// The internal inputs all use backend format
export function frontToBackDate(date: string): string {
  return date && moment(date, locale.format).format(locale.storeFormat);
}

// Datepickers internally however convert those to frontend format
export function backToFrontDate(date: string): string {
  return date && moment(date, locale.storeFormat).format(locale.format);
}

export function backToFrontDateDoubleDatapicker(date: string): string {
  return (
    date &&
    moment(date, locale.storeDoubleDatapicker).format(
      locale.formatDoubleDatapicker
    )
  );
}

export function frontToBackDateDoubleDatapicker(date: string): string {
  return (
    date &&
    moment(date, locale.formatDoubleDatapicker).format(
      locale.storeDoubleDatapicker
    )
  );
}

export function isValidDate(date: string): boolean {
  return moment(date, locale.storeFormat).isValid();
}

export const getFormattedDates = (
  startDate: string,
  endDate: string,
  backFormat: string,
  frontFormat: string
) => {
  const startDateMoment = moment(startDate, backFormat);
  const endDateMoment = moment(endDate, backFormat);

  return {
    startDateFront: startDateMoment.isValid()
      ? startDateMoment.format(frontFormat)
      : "",
    endDateFront: endDateMoment.isValid()
      ? endDateMoment.format(frontFormat)
      : "",
  };
};

export const currencyFormat = (
  amount: string | number,
  currency: string = "$"
) => {
  return `${currency} ${Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, `$&,`)}`;
};

export const mobileFormat = (number: string | null): string => {
  if (number === null) {
    return "";
  }
  return number?.length < 13
    ? number?.replace(
        /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2}).*/,
        "$1 ($2) $3-$4-$5"
      )
    : number?.replace(
        /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})(\d{2}).*/,
        "$1 ($2) $3-$4-$5-$6"
      );
};

export const customerCurrencyLabel = (currency: string) => {
  let customer_currency;
  switch (currency) {
    case "$":
      customer_currency = "USD";
      break;
    case "€":
      customer_currency = "EUR";
      break;
    case "₽":
      customer_currency = "RUB";
      break;
  }
  return customer_currency;
};
