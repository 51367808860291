import { FC } from "react";
import { Draggable } from "react-drag-reorder";
import { Backdrop, Modal } from "@mui/material";
import './styles.scss';

interface IProps {
  open: boolean;
  handleClose: () => void;
  name: string;
  items: string[];
  handleChange: (currentPos: number, newPos: number) => void;
}

const ChangeImgsOrderModal: FC<IProps> = ({ open, handleClose, name, items, handleChange }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <div className="modal imgs-order">
        <div className="modal__title">change {name.split('Photos')[0]} photos sequence</div>
        <div className="col-3">
          <Draggable onPosChange={handleChange}>
            {
              items.map((url, index: number) => (
                <div key={index} className="imgs-order__img-container">
                  <img src={url} alt="van" loading='lazy' />
                </div>
              ))
            }
          </Draggable>
        </div>
      </div>
    </Modal>
  );
};
export default ChangeImgsOrderModal;
