import { requestState, responseState } from "../utils";
import { TagAction, TagActionEnum, TagState } from "./types";

const initialState: TagState = {
  tagList: [],
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
  },
  result: {},
};
const tag = (state = initialState, action: TagAction): TagState => {
  switch (action.type) {
    case TagActionEnum.REQUEST_TAGS:
      return { ...state, isLoading: { ...state.isLoading, all: true } };

    case TagActionEnum.RESULT_TAGS:
      return {
        ...state,
        tagList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };

    case TagActionEnum.REQUEST_ADD_TAG:
      return requestState(state, "add");

    case TagActionEnum.RESULT_ADD_TAG:
      return responseState(state, "add", action);

    case TagActionEnum.REQUEST_DELETE_TAG:
      return requestState(state, "delete");

    case TagActionEnum.RESULT_DELETE_TAG:
      return responseState(state, "delete", action);

    default:
      return state;
  }
};
export default tag;
