import { GlobalActionEnum, GlobalState } from './types';

const initialState: GlobalState = {
  hideBarMenu: true,
};

const global = (state = initialState, action: any): GlobalState => {
  switch (action.type) {
    case GlobalActionEnum.INIT_HIDE_BAR:
      return {
        ...state,
        hideBarMenu: false,
      };
    case GlobalActionEnum.TOGGLE_HIDE_BAR:
      return {
        ...state,
        hideBarMenu: !action.statusHideBar,
      };
    case GlobalActionEnum.ERROR:
      return {
        ...state,
        error: { result: action.result, status: action.status },
      };
    default:
      return state;
  }
};

export default global;
