import { put, takeLatest } from 'redux-saga/effects';
import { PickupDetailsActionEnum } from 'src/store/reducers/pickupDetails/types';
import instance from '../set';
import { PickupDetailsActionCreators } from 'src/store/reducers/pickupDetails/action-creators';

function* fetchUpdatePickupDetails({
  currentPickupDetails, id
}: ReturnType<typeof PickupDetailsActionCreators.updatePickupDetails>) {
  try {
    const { status }: Response = yield instance.patch(
      `pickup-details/${id}`,
      {
        pickupDetails: currentPickupDetails,
      }
    );
    yield put(PickupDetailsActionCreators.resultUpdatePickupDetails(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdatePickupDetails() {
  yield takeLatest(PickupDetailsActionEnum.REQUEST_UPDATE_PICKUPDETAILS, fetchUpdatePickupDetails);
}