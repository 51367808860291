import { requestState, responseState } from "../utils";
import { FeatureAction, FeatureActionEnum, FeatureState } from "./types";

const initialState: FeatureState = {
  featureList: [],
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
  },
  result: {},
};
export default function feature(
  state = initialState,
  action: FeatureAction
): FeatureState {
  switch (action.type) {
    case FeatureActionEnum.REQUEST_FEATURES:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case FeatureActionEnum.RESULT_FEATURES:
      return {
        ...state,
        featureList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };
    case FeatureActionEnum.REQUEST_ADD_FEATURE:
      return requestState(state, "add");

    case FeatureActionEnum.RESULT_ADD_FEATURE:
      return responseState(state, "add", action);

    case FeatureActionEnum.REQUEST_DELETE_FEATURE:
      return requestState(state, "delete");

    case FeatureActionEnum.RESULT_DELETE_FEATURE:
      return responseState(state, "delete", action);

    default:
      return state;
  }
}
