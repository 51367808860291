import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { colorSet } from 'src/theme';

const SecondTypeButton = styled(Button)({
  background: colorSet.primaryWhite,
  border: `1px solid ${colorSet.primaryLightBlack}`,
  '&:hover': { backgroundColor: colorSet.primaryLightGrey },
  '&:active': { backgroundColor: colorSet.primaryGrey },
  '&:focus': { backgroundColor: colorSet.primaryLightGrey },
  // marginTop: '30px',
});

export default SecondTypeButton;
