import { OrderActionCreators } from "src/store/reducers/order/action-creators";
import { put, takeLatest } from "redux-saga/effects";
import { OrderActionEnum } from "src/store/reducers/order/types";
import instance from "src/sagas/set";


function* fetchShowLicense({
  key,
}: ReturnType<typeof OrderActionCreators.showLicense>) {
  try {
    const id = key.split('license/')[1];
    const { data } = yield instance.get(`license/show/${id}`);
    yield put(OrderActionCreators.resultShowLicense(data));
  } catch (error: any) {
    console.log(error);
  }
}
export function* actionWatcherShowLicense() {
  yield takeLatest(OrderActionEnum.REQUEST_SHOW_LICENSE, fetchShowLicense);
}
