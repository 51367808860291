import React, { FC, useEffect, useState } from 'react';
import { Currency } from 'dinero.js';
import { useSnackbar } from 'notistack';
import { DateTime } from 'luxon';
import lodash from 'lodash';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HelpIcon from '@mui/icons-material/Help';
import DeleteModalForm from 'src/components/Items/DeleteModalForm';
import CustomCheckbox from 'src/components/Items/button/CustomCheckbox';
import NewPay from './NewPay';
import RefundModal from './RefundModal';
import SmsModal from '../../Notification/Modal/Sms';
import UpdateOrderModal from '../../Modal/Update';
import AfterTripChargesModal from '../../Modal/AfterTripCharges';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import {
  dineroFormat,
  dineroSubtract,
  dineroSubtractAmount,
  fromISOtoFormat,
  fromJSDateToISO,
} from 'src/common/utils/dataFormat';
import { IOrderFetch, IPayOrderFetch } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  order: IOrderFetch;
}

const typesColors = {
  down_payment: 'green',
  due_payment: 'green',
  manual_payment: 'green',
  old_payment: 'grey',
  refund_payment: 'red',
  afterCharge_payment: 'green',
  pending_payment: 'grey',
  host_transfer: 'orange',
};

const Payments: FC<IProps> = ({ order }) => {
  const {
    id,
    price,
    currency,
    partial_paid,
    handle_payments,
    payment_link,
    after_trip_fee,
    stripe_hold_id,
    start_date,
    prev_host,
    host
  } = order;

  const {
    sentEmail,
    getPaymentLink,
    actionOrder,
    afterChargePayment,
    transferPayment
  } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const { role } = useTypedSelector((state) => state.auth);
  const hostId = useTypedSelector((state) => state.auth.id);
  const hostList = useTypedSelector((state) => state.host.hostList);
  const { isLoading, result } = useTypedSelector(
    (state) => state.order.paymentLink
  );
  const isLoadingAction = useTypedSelector(
    (state) => state.order.isLoading.action
  );
  const isLoadingPayment = useTypedSelector(
    (state) => state.order.isLoading.payment
  );
  const isLoadingEmail = useTypedSelector(
    (state) => state.order.notification.isLoading.email
  );
  const isLoadingAfterCharge = useTypedSelector(
    (state) => state.order.isLoading.afterCharge
  );
  const afterChargeResult = useTypedSelector(
    (state) => state.order.result.afterCharge
  );
  const isLoadingTransfer = useTypedSelector(
    (state) => state.order.isLoading.transfer
  );
  const resultTransfer = useTypedSelector(
    (state) => state.order.result.transfer
  );
  const [open, setOpen] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);
  // const [refund, setRefund] = useState({} as IPayOrderFetch);
  // const [openRefund, setOpenRefund] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteLink, setOpenDeleteLink] = useState(false);
  const [openCharge, setOpenCharge] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({} as IOrderFetch);
  const [selectedPay, setSelectedPay] = useState<number | null>(null);
  const [isStripeId, setIsStripeId] = useState(false);
  // const lastTransfer = handle_payments.findLast(el => el.type === 'host_transfer');
  const lastTransfer = lodash.findLast(
    handle_payments,
    el => el.type === 'host_transfer'
  );

  useEffect(() => {
    const host = hostList.find((host) => host.id === hostId);
    host && host.stripe_connect_id && setIsStripeId(true);
  }, [hostList, hostId]);

  useEffect(() => {
    result.get && setOpenSmsModal(true);
  }, [result.get]);

  useEffect(() => {
    if (afterChargeResult) {
      if (afterChargeResult.status === 200) {
        enqueueSnackbar(afterChargeResult.data, {
          variant: 'success',
        });
      } else if (afterChargeResult.data) {
        enqueueSnackbar(afterChargeResult.data, {
          variant: 'error',
        });
      }
    }
  }, [afterChargeResult]);

  useEffect(() => {
    if (resultTransfer) {
      if (resultTransfer.status === 200) {
        enqueueSnackbar(resultTransfer.data, {
          variant: 'success',
        });
      } else if (resultTransfer.data) {
        enqueueSnackbar(resultTransfer.data, {
          variant: 'error',
        });
      }
    }
  }, [resultTransfer]);

  const handleOpenEdit = (order: IOrderFetch) => {
    setOpenEdit(true);
    setSelectedOrder(order);
  };

  const handleDelete = (id: number) => (e: any) => {
    e.stopPropagation();
    setSelectedPay(id);
    setOpenDelete(true);
  };

  function changeCheckbox(actionType: string) {
    actionOrder(order.id, actionType);
  }

  // const handleRefund = (payment: IPayOrderFetch) => {
  //   setRefund(payment);
  //   setOpenRefund(true);
  // };

  return (
    <>
      <div className='order-show__title'>
        Payments
        <Tooltip title='Add payment'>
          <div
            onClick={() => setOpen(true)}
            className={`order-show__title__action icon_stroke ${open ? 'open' : ''
              }`}
          >
            <Icons.Plus />
          </div>
        </Tooltip>
      </div>
      <div className='order-payment__history'>
        {handle_payments.map((payment) => (
          <div
            className={`order-payment__history__item ${payment.createBy === 'system'
              ? payment.note === 'hold amount pending'
                ? 'yellow'
                : typesColors[payment.type as keyof typeof typesColors]
              : payment.createBy === 'admin'
                ? 'admin'
                : 'host'
              }`}
            key={payment.id}
          >
            <div className='flex_CSB'>
              <div
                className={`order-payment__history__item__note ${payment.createBy !== 'system' && 'black-text'
                  }`}
                title={payment.note}
              >
                {payment.note.indexOf('pi_') > 0 ? (
                  <div className='order-payment__history__item__note__container'>
                    <span>{payment.note.split('pi_')[0]}</span>
                    <div className='order-payment__history__item__note__stripe'>
                      <a
                        href={`https://dashboard.stripe.com/payments/pi_${payment.note.split('pi_')[1]
                          }`}
                        target='_blank'
                      >
                        stripe
                      </a>
                    </div>
                  </div>
                ) : payment.note.indexOf('prod_') > 0 ? (
                  <div className='order-payment__history__item__note__container'>
                    <span>{payment.note.split('prod_')[0]}</span>
                    <div className='order-payment__history__item__note__stripe'>
                      <a
                        href={`https://dashboard.stripe.com/products/prod_${payment.note.split('prod_')[1]
                          }`}
                        target='_blank'
                      >
                        stripe
                      </a>
                    </div>
                  </div>
                ) : (
                  payment.note
                )}
              </div>
              <div className='order-payment__history__item__actions'>
                {(payment.type === 'due_payment' ||
                  payment.type === 'down_payment' ||
                  payment.type === 'afterCharge_payment' ||
                  (payment.type === 'manual_payment' && isStripeId)) && (
                    <Tooltip title='Send receipt'>
                      <Button
                        className='order-payment__history__item__actions__receipt'
                        onClick={() =>
                          sentEmail(
                            order.id,
                            'get receipt',
                            undefined,
                            payment.id
                          )
                        }
                      >
                        {isLoadingEmail ? (
                          <CircularProgress size={15} />
                        ) : (
                          '@ receipt'
                        )}
                      </Button>
                    </Tooltip>
                  )}
                {payment.isDelete &&
                  (isLoadingPayment ? (
                    <CircularProgress size={15} />
                  ) : (
                    <div
                      onClick={handleDelete(payment.id)}
                      className='icon_stroke'
                    >
                      <Icons.Delete />
                    </div>
                  ))}
                {/* {payment.type === 'due_payment' && (
                <Tooltip title='Refund'>
                  <div className='icon_stroke'>
                    <CurrencyExchangeIcon 
                      onClick={() => handleRefund(payment)}
                      strokeWidth={0} 
                      sx={{fill: '#4b566b'}}
                    />
                  </div>
                </Tooltip>
              )} */}
              </div>
            </div>
            <div className='flex_CSB'>
              <div className='order-payment__history__item__date'>
                {fromISOtoFormat(fromJSDateToISO(payment.created_at), 'ff')}
              </div>
              <div className='order-payment__history__item__price'>
                {dineroFormat(payment.price, payment.currency as Currency)}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className='order-payment__total'>
        <div className='order-payment__total__item'>
          <div className='order-payment__total__title'>Total incl Fees:</div>
          <div className='order-payment__total__price paid'>
            {dineroFormat(price, currency)}
          </div>
        </div>
        <div className='order-payment__total__item'>
          <div className='order-payment__total__title'>Payed:</div>
          <div className='order-payment__total__price partial'>
            {dineroFormat(partial_paid, currency)}
          </div>
        </div>
        <div className='order-payment__total__item'>
          <div className='order-payment__total__title'>Due:</div>
          <div className='order-payment__total__price full'>
            {dineroSubtract(price, partial_paid, currency)}
          </div>
        </div>
      </div>
      <div className='order-payment__link'>
        {(role === 'admin' || role === 'super_host' || isStripeId) && (
          <div className='order-payment__link__buttons'>
            <Button
              onClick={() => getPaymentLink(id)}
              disabled={
                (start_date < DateTime.now().toISO())
                || !lodash.subtract(price, partial_paid)
              }
            >
              {isLoading.get ? (
                <CircularProgress size={15} />
              ) : (
                'Get Due Paid (be4 trip)'
              )}
            </Button>
            <Button
              onClick={() => (
                afterChargePayment(
                  id,
                  dineroSubtractAmount(price, partial_paid, currency),
                  'After trip charge'
                )
              )}
              disabled={start_date > DateTime.now().toISO()}
            >
              {isLoadingAfterCharge ? (
                <CircularProgress size={15} />
              ) : (
                'Get Addi. Paid (after trip)'
              )}
            </Button>
            <div className='order-payment__link__buttons__subtitle'>
              After trip u can: Fix dates, articles, rates, then use Get Addi.
              Paid
            </div>
          </div>
        )}
        <div className='order-payment__link__title'>
          <div>
            Fix/Trash Link If Amounts Changed
            <div className='order-payment__link__subtitle'>
              (if AMOUNT CHANGED)
            </div>
          </div>
          <div style={{ display: 'flex', gap: '2px' }}>
            <div className='order-payment__link__button__tooltip-container'>
              <Tooltip
                leaveTouchDelay={3000}
                enterTouchDelay={50}
                title={
                  <React.Fragment>
                    <div className='pay-info'>
                      <div className='pay-info__item'>
                        <b>Collect Get Open Amount:</b>
                        <br />
                        The <span>Open Amount Link</span> is automatically
                        generated & emailed to guests 3 days before the trip. It
                        shows $0.00 once paid.
                      </div>
                      <div className='pay-info__item'>
                        <b>To get paid early</b>, or send payment reminders
                        Click the <span>@ Pay Due Now</span> (in Notifications).
                      </div>
                      <div className='pay-info__item'>
                        <b>Open amount changed:</b> Click the trash icon, then
                        click the <span>@ Pay Due Now</span> (in Notifications).
                      </div>
                      <div className='pay-info__item'>
                        <b>Get a partial payment:</b>
                        <br />
                        Click the <span>+ icon in Payments</span>, enter the
                        amount, and send. Guests get an email to pay. Money
                        shows in Payments & goes to your Stripe.
                      </div>
                      <div className='pay-info__item'>
                        Check the <span>Ask Guest For A $250 Hold</span>. Guests
                        are emailed to put a card on hold for incidentals. It’s
                        released 3 days after the trip, you can not charge after
                        that.
                      </div>
                      <div className='pay-info__item'>
                        <b>After trip charges:</b>
                        <br />
                        Click the pen icon, to charge guests for incidentals.
                        You can charge 1 time, it is FINAL. Money goes directly
                        to your Stripe.
                      </div>
                      <div className='pay-info__item'>
                        <b>Bill guest after a trip:</b>
                        <br />
                        You can: Fix dates, articles, & rates, then use the{' '}
                        <span>Get Addi. Paid</span> button to bill the guest
                        after a trip. This payment is not applied to their hold
                        amount and is billed separately.
                      </div>
                    </div>
                  </React.Fragment>
                }
              >
                <HelpIcon
                  style={{ fill: 'rgb(0, 127, 255)', fontSize: '24px' }}
                />
              </Tooltip>
            </div>
            {role === 'admin' && (
              <Tooltip title='Edit payment link'>
                <div
                  onClick={() => handleOpenEdit(order)}
                  className='icon_stroke'
                >
                  <Icons.Edit />
                </div>
              </Tooltip>
            )}
            <Tooltip title='Delete payment link'>
              <div
                onClick={() => setOpenDeleteLink(true)}
                className='icon_stroke'
              >
                <Icons.Delete />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className='order-payment__link__content'>
          <div className='order-payment__link__content__note'>
            <Tooltip title='Open link'>
              <a href={payment_link} target='_blank'>
                {payment_link}
              </a>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className='order-payment__after-trip'>
        <div className='order-payment__link__title'>
          Ask Guest For A $250 Hold
          <div
            className={`order-notification__action__item 
              ${order.stripe_hold_id && 'disabled'}`}
            style={{ cursor: 'pointer' }}
          >
            {isLoadingAction ? (
              <CircularProgress size={15} />
            ) : (
              <Tooltip title='Sent email'>
                <CustomCheckbox
                  onChange={(e) => changeCheckbox(e.target.name)}
                  checked={order.stripe_hold_id ? true : false}
                  disabled={order.stripe_hold_id ? true : false}
                  name={'hold amount'}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className='order-payment__link__subtitle'>
          The card on hold can get charged for additions, after trips.
        </div>
      </div>
      {stripe_hold_id && (
        <div className='order-payment__after-trip'>
          <div className='order-payment__link__title'>
            After trip charges
            <Tooltip title='After trip charges'>
              <div onClick={() => setOpenCharge(true)} className='icon_stroke'>
                <Icons.Edit />
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      {role === 'admin' && (
        <Button
          className='order-payment__transfer'
          onClick={() => transferPayment(id)}
          disabled={
            !prev_host.length || (
              !!lastTransfer
              && lastTransfer.transfer_to === host.id
            )
          }
        >
          {isLoadingTransfer
            ? <CircularProgress size={15} />
            : 'Transfer to current host'
          }
        </Button>
      )}
      <UpdateOrderModal
        order={selectedOrder}
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        fields={['payment_link']}
      />
      <DeleteModalForm
        id={selectedPay!}
        type='payment'
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
      />
      <DeleteModalForm
        id={order.id}
        type='payment link'
        open={openDeleteLink}
        handleClose={() => setOpenDeleteLink(false)}
      />
      <AfterTripChargesModal
        fees={after_trip_fee}
        currency={currency}
        open={openCharge}
        handleClose={() => setOpenCharge(false)}
        order_id={id}
      />
      <NewPay
        orderId={order.id}
        isStripeId={isStripeId}
        handleClose={() => setOpen(false)}
        open={open}
      />
      {/* <NewPay
        orderId={order.id}
        isStripeId={false}
        isOpenCharge
        handleClose={() => setOpenAddPaid(false)}
        open={openAddPaid}
      /> */}
      {/* <RefundModal 
        orderId={order.id}
        refund={refund}
        handleClose={() => setOpenRefund(false)} 
        open={openRefund} 
      /> */}
      {result.get && (
        <SmsModal
          open={openSmsModal}
          handleClose={() => setOpenSmsModal(false)}
          smsText={result.get}
          name={'payment link'}
          disable={true}
        />
      )}
    </>
  );
};

export default Payments;
