import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs } from '@mui/material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SettingsIcon from '@mui/icons-material/Settings';
import LinkTab from '../LinksTab';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { mainColor } from 'src/theme';
import './styles.scss';

interface IProps {
  hideBarMenu: boolean;
}

const TabList: FC<IProps> = ({ hideBarMenu }) => {
  const [value, setValue] = useState(0);
  const { pathname } = useLocation();
  const { role } = useTypedSelector((state) => state.auth);
  const roleLinks = [
    {
      name: 'Chat',
      path: '/host-support',
      icon: <LiveHelpIcon className='icon-tab-chat' />,
    },
    {
      name: 'Calendar',
      path: '/calendar',
      icon: <CalendarMonthIcon className='icon-tab' />,
    },
    {
      name: 'Order',
      path: '/order',
      icon: <AssignmentOutlinedIcon className='icon-tab' />,
    },
    {
      name: 'Next Out',
      path: '/order/next-out',
      icon: <DoubleArrowIcon className='icon-tab' />,
    },
    {
      name: 'Client',
      path: '/client',
      icon: <PeopleAltIcon className='icon-tab' />,
    },
    {
      name: 'Host report',
      path: '/report/host',
      icon: <ReceiptLongIcon className='icon-tab' />,
    },
    {
      name: 'Admin',
      path: '/admin',
      icon: <SettingsIcon className='icon-tab admin-icon' fontSize='medium' />,
    },
    {
      name: 'Van',
      path: '/van',
      icon: <AirportShuttleIcon className='icon-tab' />,
    },
  ];
  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);

  useEffect(() => {
    if (pathname !== '/') {
      const index = pathname.includes('/order/next-out') ? 3 : roleLinks.findIndex((elem) => pathname.includes(elem.path));
      if (index > -1) {
        if (role !== 'admin' && index > 6) setValue(6);
        else setValue(index);
      } else setValue(2);
    } else setValue(2);
  }, [pathname]);

  return (
    <Tabs
      orientation='vertical'
      variant='scrollable'
      value={value}
      onChange={handleChange}
      TabIndicatorProps={{
        style: {
          backgroundColor: mainColor,
          width: 3,
          borderRadius: 3,
        },
      }}
      sx={{
        width: '100%',
      }}
    >
      {roleLinks.map((elem, i) => (
        elem.name === 'Admin' ? (
          role === 'admin' && (
            <LinkTab
              key={i}
              link={elem.path as string}
              label={hideBarMenu ? '' : elem.name}
              title={elem.name}
              icon={elem.icon}
              iconPosition='start'
            />
          )
        ) : (
          <LinkTab
            key={(role === 'admin' && elem.name === 'Van') ? 7 : 6}
            link={elem.path as string}
            label={hideBarMenu ? '' : elem.name}
            title={elem.name}
            icon={elem.icon}
            iconPosition='start'
          />
        )
      ))}
    </Tabs>
  );
};
export default TabList;
