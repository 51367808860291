import { IBlockFetch } from "src/models/IBlock";
import { IVan, IVanFetch } from "src/models/IVan";
import { IVanFilter } from "src/models/IVanFilter";

export enum VanActionEnum {
  REQUEST_VANS = "REQUEST_VANS",
  RESULT_VANS = "RESULT_VANS",
  ERROR_VANS = "ERROR_VANS",

  REQUEST_VAN = "REQUEST_VAN",
  RESULT_VAN = "RESULT_VAN",

  REQUEST_ADD_VAN = "REQUEST_ADD_VAN",
  RESULT_ADD_VAN = "RESULT_ADD_VAN",

  REQUEST_ADD_VAN_BLOCK = "REQUEST_ADD_VAN_BLOCK",
  RESULT_ADD_VAN_BLOCK = "RESULT_ADD_VAN_BLOCK",

  REQUEST_DELETE_VAN = "REQUEST_DELETE_VAN",
  RESULT_DELETE_VAN = "RESULT_DELETE_VAN",

  REQUEST_UPDATE_VAN = "REQUEST_UPDATE_VAN",
  RESULT_UPDATE_VAN = "RESULT_UPDATE_VAN",

  REQUEST_ADD_VAN_IMAGES = "REQUEST_ADD_VAN_IMAGES",
  RESULT_ADD_VAN_IMAGES = "RESULT_ADD_VAN_IMAGES",

  REQUEST_UPDATE_VAN_IMAGES = "REQUEST_UPDATE_VAN_IMAGES",
  RESULT_UPDATE_VAN_IMAGES = "RESULT_UPDATE_VAN_IMAGES",
}
export enum VanFilterActionEnum {
  PAGE = "PAGE",
  TAKE = "TAKE",
  HOST_ID = "HOST_ID",
  LOCATION = "LOCATION",
  HOME_LOCATION = "HOME_LOCATION",
  SEATS = "SEATS",
  TYPE = "TYPE",
  YEAR = "YEAR",
  TAGS = "TAGS",
  FEATURES = "FEATURES",
  START_PRICE = "START_PRICE",
  END_PRICE = "END_PRICE",
  CLEAR = "CLEAR",
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
}
export interface VanState {
  vanList: IVanFetch[];
  van?: IVanFetch;
  total: number | null;
  maxPrice: number | null;
  minPrice: number | null;
  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
    uploadingImage: boolean;
  };
  result: {
    add?: number | string;
    uploadingImage?: number | string;
    update?: number | string;
    delete?: number | string;
  };
  filters: IVanFilter;
}
export interface GetVansAction {
  type: VanActionEnum.REQUEST_VANS;
  filter: IVanFilter;
}
export interface ResultGetVansAction {
  type: VanActionEnum.RESULT_VANS;
  vanList: IVanFetch[];
  count: number;
  maxPrice: number;
  minPrice: number;
}
export interface GetVanAction {
  type: VanActionEnum.REQUEST_VAN;
  id: number;
}
export interface ResultGetVanAction {
  type: VanActionEnum.RESULT_VAN;
  data: IVanFetch;
}
export interface AddVanImageAction {
  type: VanActionEnum.REQUEST_ADD_VAN_IMAGES;
  id: number;
  interiorImages: (string | File)[];
  exteriorImages: (string | File)[];
}
export interface ResultAddVanImageAction {
  type: VanActionEnum.RESULT_ADD_VAN_IMAGES;
  data: IVan;
  status: number | string;
}
export interface UpdateVanImageAction {
  type: VanActionEnum.REQUEST_UPDATE_VAN_IMAGES;
  id: number;
  interiorImages: (string | File)[];
  exteriorImages: (string | File)[];
}
export interface ResultUpdateVanImageAction {
  type: VanActionEnum.RESULT_UPDATE_VAN_IMAGES;
  status: number | string;
}
export interface AddVanAction {
  type: VanActionEnum.REQUEST_ADD_VAN;
  van: IVan;
}
export interface ResultAddVanAction {
  type: VanActionEnum.RESULT_ADD_VAN;
  status: number | string;
  data?: IVanFetch;
}
export interface AddVanBlockAction {
  type: VanActionEnum.REQUEST_ADD_VAN_BLOCK;
  block: IBlockFetch;
}
export interface ResultAddVanBlockAction {
  type: VanActionEnum.RESULT_ADD_VAN_BLOCK;
  status: number | string;
}
export interface UpdateVanAction {
  type: VanActionEnum.REQUEST_UPDATE_VAN;
  van: IVan;
  id: number
}
export interface ResultUpdateVanAction {
  type: VanActionEnum.RESULT_UPDATE_VAN;
  status: number | string;
}
export interface DeleteVanAction {
  type: VanActionEnum.REQUEST_DELETE_VAN;
  id: number;
}
export interface ResultDeleteVanAction {
  type: VanActionEnum.RESULT_DELETE_VAN;
  status: number | string;
}
export type VanAction =
  | GetVansAction
  | ResultGetVansAction
  | GetVanAction
  | ResultGetVanAction
  | AddVanAction
  | ResultAddVanAction
  | UpdateVanAction
  | ResultUpdateVanAction
  | DeleteVanAction
  | ResultDeleteVanAction
  | ResultUpdateVanImageAction
  | UpdateVanImageAction
  | AddVanImageAction
  | ResultAddVanImageAction
  | AddVanBlockAction
  | ResultAddVanBlockAction;

export interface VanFilterFeatureAction {
  type: VanFilterActionEnum.FEATURES;
  features: string[];
}
export interface VanFilterHostAction {
  type: VanFilterActionEnum.HOST_ID;
  hostId: number;
}
export interface VanFilterTypeAction {
  type: VanFilterActionEnum.TYPE;
  vanType: string;
}
export interface VanFilterSeatsAction {
  type: VanFilterActionEnum.SEATS;
  seat?: number;
}
export interface VanFilterStartPriceAction {
  type: VanFilterActionEnum.START_PRICE;
  startPrice: number;
}
export interface VanFilterEndPriceAction {
  type: VanFilterActionEnum.END_PRICE;
  endPrice: number;
}
export interface VanFilterLocationAction {
  type: VanFilterActionEnum.LOCATION;
  location: string;
}
export interface VanFilterStartDateAction {
  type: VanFilterActionEnum.START_DATE;
  startDate: string;
}
export interface VanFilterEndDateAction {
  type: VanFilterActionEnum.END_DATE;
  endDate: string;
}
export interface ClearVanFilterAction {
  type: VanFilterActionEnum.CLEAR;
}
export type VanFilterAction =
  | VanFilterFeatureAction
  | VanFilterTypeAction
  | VanFilterSeatsAction
  | VanFilterStartPriceAction
  | VanFilterEndPriceAction
  | VanFilterLocationAction
  | VanFilterStartDateAction
  | VanFilterEndDateAction
  | VanFilterHostAction
  | ClearVanFilterAction;
