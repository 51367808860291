import { FC, useEffect, useState } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PhoneIcon from '@mui/icons-material/Phone';
import SmsIcon from '@mui/icons-material/Sms';
import SearchClientModal from 'src/pages/Client/Modal/Search';
import UpdateClientModal from 'src/pages/Client/Modal/Update';
import AddClientModal from 'src/pages/Client/Modal/Add';
import useIsMobile from 'src/hooks/useIsMobile';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IHostFetch } from 'src/models/IHost';
import { IOrderFetch } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  order: IOrderFetch;
}

const CustomerData: FC<IProps> = ({ order: { id, van, client } }) => {

  const isMobile = useIsMobile();
  const { first_name, last_name, phone, email, business_name } = client;
  const { role } = useTypedSelector((state) => state.auth);
  const { host: { hostList } } = useTypedSelector((state) => state);

  const [open, setOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [openSearch, setSearchOpen] = useState(false);
  const [currentHost, setCurrentHost] = useState<IHostFetch>();

  useEffect(() => {
    const host = hostList.filter((h) => h.id === van.host_id);
    setCurrentHost(host[0]);
  }, [hostList, id]);

  const handleOpen = (name: string) => {
    switch (name) {
      case 'add':
        setOpen(true);
        break;
      case 'edit':
        setEditOpen(true);
        break;
      case 'search':
        setSearchOpen(true);
        break;
      default:
        break;
    }
  };
  const handleClose = (name: string) => {
    switch (name) {
      case 'add':
        return setOpen(false);
      case 'edit':
        return setEditOpen(false);
      case 'search':
        return setSearchOpen(false);
      default:
        break;
    }
  };

  return (
    <>
      <div className='order-show__title'>
        Customer
        <Tooltip title='Change client'>
          <div
            onClick={() => handleOpen('add')}
            className='order-show__title__action add icon_stroke'
          >
            <Icons.Plus />
          </div>
        </Tooltip>
        <Tooltip title='Search client'>
          <div
            onClick={() => handleOpen('search')}
            className='order-show__title__action find icon_stroke'
          >
            <Icons.Search />
          </div>
        </Tooltip>
        <Tooltip title='Edit client'>
          <div
            onClick={() => handleOpen('edit')}
            className='order-show__title__action icon_stroke'
          >
            <Icons.Edit />
          </div>
        </Tooltip>
      </div>
      <div className='order-contact'>
        <div className='order-contact__item'>
          <div className='order-contact__item__title'>Name</div>
          <div style={{ textTransform: 'capitalize' }}>
            {first_name} {last_name}
          </div>
        </div>
        {business_name && (
          <div className='order-contact__item'>
            <div className='order-contact__item__title'>Business/Org</div>
            <div>{business_name}</div>
          </div>
        )}
        <div className='order-contact__item'>
          <div className='order-contact__item__title'>Phone</div>
          <div style={{ display: 'flex', gap: '15px' }}>
            <div>{phone}</div>
            {isMobile &&
              <>
                <a href={`tel:${phone}`}>
                  <PhoneIcon />
                </a>
                <a href={`sms:${phone}`}>
                  <SmsIcon />
                </a>
              </>
            }
          </div>
        </div>
        <div data-title={email} className='order-contact__item' key={email} style={{ marginBottom: '10px' }}>
          <div className='order-contact__item__title'>email</div>
          <div style={{ display: 'flex', gap: '15px' }}>
            {<div>{email}</div>}
            {role === 'admin' && (
              <Tooltip title='Redirect to Gmail'>
                <a
                  href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${email}&su=info for van rental number ${id}`}
                  target='_blank'
                  style={{ marginTop: '5px' }}
                >
                  <AttachEmailIcon />
                </a>
              </Tooltip>
            )}
          </div>
        </div>
        <div className='order-contact__item'>
          <div className='order-contact__item__title'>Host</div>
          {(currentHost && currentHost.id === van.host_id) ? (
            <div style={{ display: 'flex', gap: '15px' }}>
              <div style={{ textTransform: 'capitalize' }}>
                {currentHost.first_name} {currentHost.last_name}
              </div>
              {role === 'admin' && (
                <Tooltip title='Redirect to Gmail' style={{ gridColumnStart: 2 }}>
                  <a
                    href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${currentHost.email}&su=info for van rental number ${id}`}
                    target='_blank'
                    style={{ marginTop: '2px' }}
                  >
                    <EngineeringIcon />
                  </a>
                </Tooltip>
              )}
            </div>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
      </div>
      <AddClientModal
        open={open}
        handleClose={() => handleClose('add')}
        orderId={id}
      />
      <UpdateClientModal
        open={openEdit}
        handleClose={() => handleClose('edit')}
        client={client}
      />
      <SearchClientModal
        open={openSearch}
        handleClose={() => handleClose('search')}
        orderId={id}
      />
    </>
  );
};

export default CustomerData;
