import { put, takeLatest } from "redux-saga/effects";
import { TagActionCreators } from "src/store/reducers/tag/action-creators";
import { TagActionEnum } from "src/store/reducers/tag/types";
import instance from "../set";

function* fetchDeleteTag({
  id,
}: ReturnType<typeof TagActionCreators.deleteTag>) {
  try {
    const { status }: Response = yield instance.delete(`tag/${id}`);
    yield put(TagActionCreators.resultDeleteTag(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteTag() {
  yield takeLatest(TagActionEnum.REQUEST_DELETE_TAG, fetchDeleteTag);
}
