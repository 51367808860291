import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { HostStaffActionCreators } from "src/store/reducers/hostStaff/action-creators";
import { HostStaffActionEnum } from "src/store/reducers/hostStaff/types";

function* fetchGetAllHostStaff() {
  try {
    const { status,data } = yield instance.get('staff');
    yield put(HostStaffActionCreators.resultGetHostStaff(status,data));
  } catch (error) {
    console.log("error :>> ", error);
    yield put(HostStaffActionCreators.resultGetHostStaff(400));
  }
}
export function* actionWatcherGetAllHostStaff() {
  yield takeLatest(HostStaffActionEnum.REQUEST_HOSTSTAFF, fetchGetAllHostStaff);
}
