import { all, fork } from 'redux-saga/effects';
import { actionWatcherSignIn } from './auth/login';
import { actionWatcherExit } from './auth/logOut';
import { actionWatcherGetVans } from './van/getAll';
import { actionWatcherAddVan } from './van/add';
import { actionWatcherUpdateVan } from './van/update';
import { actionWatcherDeleteVan } from './van/delete';
import { actionWatcherGetFeatures } from './feature/getAll';
import { actionWatcherAddFeature } from './feature/add';
import { actionWatcherDeleteFeature } from './feature/delete';
import { actionWatcherGetTags } from './tag/getAll';
import { actionWatcherAddTag } from './tag/add';
import { actionWatcherDeleteTag } from './tag/delete';
import { actionWatcherGetHosts } from './host/getAll';
import { actionWatcherGetClients } from './client/getAll';
import { actionWatcherAddClient } from './client/add';
import { actionWatcherUpdateClient } from './client/update';
import { actionWatcherDeleteClient } from './client/delete';
import { actionWatcherGetClient } from './client/get';
import { actionWatcherGetOrders } from './order/getAll';
import { actionWatcherAddOrder } from './order/add';
import {
  actionWatcherUpdateExtraOrder,
  actionWatcherUpdateOrder,
} from './order/update';
import { actionWatcherDeleteOrder } from './order/delete';
import { actionWatcherGetOrder } from './order/get';
import { actionWatcherActionOrder } from './order/action';
import { actionWatcherGetSms } from './order/notification/sms';
import { actionWatcherSentEmail } from './order/notification/email';
import { actionWatcherGetCalendar } from './calendar/getAll';
import { actionWatcherGetLocations } from './location/getAll';
import { actionWatcherAddPaymentOrder } from './order/payment/add';
import { actionWatcherDeletePaymentOrder } from './order/payment/delete';
import { actionWatcherUpdatePriceOrder } from './order/updatePrice';
import { actionWatcherGetExtras } from './extra/getAll';
import { actionWatcherUpdateCalendar } from './calendar/update';
import { actionWatcherAddDriver } from './driver/add';
import { actionWatcherUpdateDriver } from './driver/update';
import { actionWatcherGetHostReport } from './report/host/getReport';
import { actionWatcherAddHost } from './host/add';
import { actionWatcherAddOneway } from './oneway/add';
import { actionWatcherGetPickupDetails } from './pickupDetails/getAll';
import { actionWatcherAddPickupDetails } from './pickupDetails/add';
import { actionWatcherGetOneways } from './oneway/getAll';
import { actionWatcherAddLocation } from './location/add';
import { actionWatcherAddVanBlock } from './van/addBlock';
import { actionWatcherGetVan } from './van/get';
import { actionWatcherUpdateHost } from './host/update';
import { actionWatcherUpdatePickupDetails } from './pickupDetails/update';
import { actionWatcherUpdateLocation } from './location/update';
import { actionWatcherGetPaymentLink } from './order/paymentLink/get';
import { actionWatcherUpdatePaymentOrder } from './order/payment/update';
import { actionWatcherGetAfterTripCharges } from './order/payment/getAfterTripCharges';
import { actionWatcherGetSeasonPrice } from './seasonPrice/getAll';
import { actionWatcherAddSeasonPrice } from './seasonPrice/add';
import { actionWatcherUpdateSeasonPrice } from './seasonPrice/update';
import { actionWatcherDeleteSeasonPrice } from './seasonPrice/delete';
import { actionWatcherUpdateOneway } from './oneway/update';
import { actionWatcherAddNearVans } from './nearVan/add';
import { actionWatcherDeleteNearVan } from './nearVan/delete';
import { actionWatcherGetNearVan } from './nearVan/get';
import { actionWatcherGetNearVans } from './nearVan/getAll';
import { actionWatcherUpdateNearVan } from './nearVan/update';
import { actionWatcherAddVanImage } from './van/images/add';
import { actionWatcherUpdateVanImage } from './van/images/update';
import { actionWatcherCopySms } from './order/notification/copySms';
import { actionWatcherRefundPaymentOrder } from './order/payment/refund';
import { actionWatcherAddHostStaff } from './hostStaff/add';
import { actionWatcherDeleteHostStaff } from './hostStaff/delete';
import { actionWatcherGetHostStaff } from './hostStaff/get';
import { actionWatcherGetAllHostStaff } from './hostStaff/getAll';
import { actionWatcherUpdateHostStaff } from './hostStaff/update';
import { actionWatcherAddSublocations } from './sublocation/add';
import { actionWatcherUpdateSublocation } from './sublocation/update';
import { actionWatcherDeleteSublocation } from './sublocation/delete';
import { actionWatcherGetSubPrice } from './subPrice/getAll';
import { actionWatcherAddSubPrice } from './subPrice/add';
import { actionWatcherUpdateSubPrice } from './subPrice/update';
import { actionWatcherDeleteSubPrice } from './subPrice/delete';
import { actionWatcherGetSublocation } from './sublocation/getAll';
import { actionWatcherAddPartialPaymentOrder } from './order/payment/addPartial';
import { actionWatcherDeleteAllSubPrices } from './subPrice/deleteAll';
import { actionWatcherSearchClient } from './client/search';
import { actionWatcherAfterChargePayment } from './order/payment/afterCharge';
import { actionWatcherTransferPayment } from './order/payment/transfer';
import { actionWatcherShowContract } from './order/doc/contract';
import { actionWatcherShowLicense } from './order/doc/license';
import { actionWatcherShowInsurance } from './order/doc/insurance';
import { actionWatcherDeleteHost } from './host/delete';
import { actionWatcherGetNewHostReport } from './report/host/getNewReport';

export default function* rootSaga() {
  yield all([
    fork(actionWatcherExit),
    fork(actionWatcherSignIn),

    fork(actionWatcherGetVans),
    fork(actionWatcherGetVan),
    fork(actionWatcherAddVan),
    fork(actionWatcherUpdateVan),
    fork(actionWatcherDeleteVan),
    fork(actionWatcherAddVanBlock),
    fork(actionWatcherAddVanImage),
    fork(actionWatcherUpdateVanImage),

    fork(actionWatcherGetTags),
    fork(actionWatcherAddTag),
    fork(actionWatcherDeleteTag),

    fork(actionWatcherGetFeatures),
    fork(actionWatcherAddFeature),
    fork(actionWatcherDeleteFeature),

    fork(actionWatcherGetHosts),
    fork(actionWatcherAddHost),
    fork(actionWatcherUpdateHost),
    fork(actionWatcherDeleteHost),

    fork(actionWatcherGetClients),
    fork(actionWatcherAddClient),
    fork(actionWatcherUpdateClient),
    fork(actionWatcherDeleteClient),
    fork(actionWatcherGetClient),
    fork(actionWatcherSearchClient),

    fork(actionWatcherGetOrders),
    fork(actionWatcherAddOrder),
    fork(actionWatcherUpdateOrder),
    fork(actionWatcherDeleteOrder),
    fork(actionWatcherGetOrder),
    fork(actionWatcherUpdatePriceOrder),
    fork(actionWatcherUpdateExtraOrder),
    fork(actionWatcherActionOrder),
    fork(actionWatcherGetAfterTripCharges),
    fork(actionWatcherAfterChargePayment),
    fork(actionWatcherAddOneway),
    fork(actionWatcherGetOneways),
    fork(actionWatcherUpdateOneway),
    fork(actionWatcherTransferPayment),

    fork(actionWatcherAddPaymentOrder),
    fork(actionWatcherAddPartialPaymentOrder),
    fork(actionWatcherUpdatePaymentOrder),
    fork(actionWatcherDeletePaymentOrder),
    fork(actionWatcherRefundPaymentOrder),

    fork(actionWatcherSentEmail),
    fork(actionWatcherGetSms),
    fork(actionWatcherCopySms),

    fork(actionWatcherGetPaymentLink),

    fork(actionWatcherShowContract),
    fork(actionWatcherShowLicense),
    fork(actionWatcherShowInsurance),

    fork(actionWatcherGetCalendar),
    fork(actionWatcherUpdateCalendar),

    fork(actionWatcherGetLocations),
    fork(actionWatcherAddLocation),
    fork(actionWatcherUpdateLocation),

    fork(actionWatcherGetExtras),
    fork(actionWatcherAddDriver),
    fork(actionWatcherUpdateDriver),

    fork(actionWatcherGetHostReport),
    fork(actionWatcherGetNewHostReport),

    fork(actionWatcherGetPickupDetails),
    fork(actionWatcherAddPickupDetails),
    fork(actionWatcherUpdatePickupDetails),

    fork(actionWatcherGetSeasonPrice),
    fork(actionWatcherAddSeasonPrice),
    fork(actionWatcherUpdateSeasonPrice),
    fork(actionWatcherDeleteSeasonPrice),

    fork(actionWatcherAddNearVans),
    fork(actionWatcherDeleteNearVan),
    fork(actionWatcherGetNearVan),
    fork(actionWatcherGetNearVans),
    fork(actionWatcherUpdateNearVan),

    fork(actionWatcherAddHostStaff),
    fork(actionWatcherDeleteHostStaff),
    fork(actionWatcherGetHostStaff),
    fork(actionWatcherGetAllHostStaff),
    fork(actionWatcherUpdateHostStaff),

    fork(actionWatcherAddSublocations),
    fork(actionWatcherUpdateSublocation),
    fork(actionWatcherDeleteSublocation),
    fork(actionWatcherGetSublocation),

    fork(actionWatcherGetSubPrice),
    fork(actionWatcherAddSubPrice),
    fork(actionWatcherUpdateSubPrice),
    fork(actionWatcherDeleteSubPrice),
    fork(actionWatcherDeleteAllSubPrices),
  ]);
}
