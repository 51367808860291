import { ReportAction, ReportActionEnum, ReportState } from './types';
const initialState: ReportState = {
  orderList: [],
  statistic: {
    _sum: { price: 0, partial_paid: 0 },
    _count: { _all: 0 },
    got: 0,
    take: 0,
    offset: 0,
    fee: 0,
  },
  link: '',
  isLoading: {
    old: false,
    new: false
  },
  result: {}
};

const hostReport = (
  state = initialState,
  action: ReportAction
): ReportState => {
  switch (action.type) {
    case ReportActionEnum.REQUEST_REPORT:
      return {
        ...state,
        isLoading: { ...state.isLoading, old: true },
        result: {}
      };
    case ReportActionEnum.RESULT_REPORT:
      return {
        ...state,
        orderList: action.data.orders,
        statistic: action.data.statistic,
        link: action.data.link,
        isLoading: { ...state.isLoading, old: false },
        result: { old: action.status }
      };
    case ReportActionEnum.REQUEST_NEW_REPORT:
      return {
        ...state,
        isLoading: { ...state.isLoading, new: true },
        result: {}
      };
    case ReportActionEnum.RESULT_NEW_REPORT:
      return {
        ...state,
        orderList: action.data.orders,
        statistic: action.data.statistic,
        isLoading: { ...state.isLoading, new: false },
        result: { new: action.status }
      };
    case ReportActionEnum.ERROR_REPORT:
      return {
        ...state,
        isLoading: { old: false, new: false },
      };
    default:
      return state;
  }
};
export default hostReport;
