import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { OnewayActionCreators } from "src/store/reducers/oneway/action-creators";
import { OnewayActionEnum } from "src/store/reducers/oneway/types";

function* fetchAddOneway({
    data,
}: ReturnType<typeof OnewayActionCreators.addOneway>) {
  try {
    const { status }: Response = yield instance.post('oneway/add', {
        data,
    });
    yield put(OnewayActionCreators.resultAddOneway(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddOneway() {
  yield takeLatest(OnewayActionEnum.REQUEST_ADD_ONEWAY, fetchAddOneway);
}