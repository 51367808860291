import { FC, useEffect, useState } from 'react';
import { Backdrop, Modal } from '@mui/material';
import ModalAction from 'src/components/Items/modal/Actions';
import VanFormModal from '../Form';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IVanFetch } from 'src/models/IVan';

interface IProps {
  van: IVanFetch;
  open: boolean;
  handleClose: () => void;
}

const UpdateVanModal: FC<IProps> = ({ van, open, handleClose }) => {
  const { 
    van: { result },
    auth: {role }
   } = useTypedSelector((state) => state);
  const { updateVan, updateVanImage } = useActions();
  const [currentVan, setCurrentVan] = useState({
    ...van,
    price: van.price / 100,
  });
  const [interiorImages, setInteriorImages] = useState<string[]>(van.photos.interior);
  const [exteriorImages, setExteriorImages] = useState<string[]>(van.photos.exterior);
  const [newInteriorImages, setNewInteriorImages] = useState<string[]>([]);
  const [newExteriorImages, setNewExteriorImages] = useState<string[]>([]);
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const hostTags = van.tags.filter((tag) => (tag.category as any) === 'host');

  const handleInput = (name: string) => (value: any) => {
    if (name === 'isVirtual') {
      setCurrentVan({ ...currentVan, 'isVirtual': !currentVan.isVirtual });
    } else if (name === 'exteriorPhotos') {
      setExteriorImages(value);
      !interiorImages.length && setInteriorImages(van.photos.interior);
    } else if (name === 'interiorPhotos') {
      setInteriorImages(value);
      !exteriorImages.length && setExteriorImages(van.photos.exterior);
    } else if (name === 'tags') {
      role === 'admin' ? (
        setCurrentVan({ ...currentVan, 'tags': value })
      ) : (
        setCurrentVan({ ...currentVan, 'tags': [...hostTags, ...value] })
      );
    } else {
      setCurrentVan({ ...currentVan, [name]: value });
    }
  };

  useEffect(() => {
    return () => {
      setExteriorImages([]);
      setInteriorImages([]);
      setNewInteriorImages([]);
      setNewExteriorImages([]);
    };
  }, []);

  useEffect(() => {
    setCurrentVan({
      ...van,
      price: van.price / 100,
    });
    setInteriorImages(van.photos.interior);
    setExteriorImages(van.photos.exterior);
  }, [open]);

  useEffect(() => {
    ((result.update === 200 || result.update === 201) &&
      (newInteriorImages?.length || newExteriorImages?.length)
    ) && (
      updateVanImage(
        van.id,
        newInteriorImages!,
        newExteriorImages!
      ),
      setNewInteriorImages([]),
      setNewExteriorImages([])
    );
  }, [result.update]);

  const vanModel: IVanFetch = {
    ...currentVan,
    price: currentVan.price * 100,
  };

  const handleSubmit = () => {
    const updatedVan = vanModel;
    for (const key in vanModel) {
      vanModel[key as keyof typeof vanModel] === van[key as keyof typeof van] && 
        key !== 'tags' && delete updatedVan[key as keyof typeof updatedVan];
    }
    updateVan(
      {
        ...updatedVan,
        id: van.id,
        photos: {
          interior: interiorImages,
          exterior: exteriorImages
        }
      }, van.id);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal' style={{width: '1000px'}}>
          <div className='modal__title'>Update Van</div>
          <VanFormModal
            id={currentVan.id}
            van={currentVan}
            setVan={handleInput}
            interiorImages={interiorImages}
            setInteriorImages={setInteriorImages}
            exteriorImages={exteriorImages}
            setExteriorImages={setExteriorImages}
            deletedImages={deletedImages}
            setDeletedImages={setDeletedImages}
            newInteriorImages={newInteriorImages}
            setNewInteriorImages={setNewInteriorImages}
            newExteriorImages={newExteriorImages}
            setNewExteriorImages={setNewExteriorImages}
          />
          <ModalAction
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            type='van'
            action='Update'
          />
        </div>
      </>
    </Modal>
  );
};
export default UpdateVanModal;
