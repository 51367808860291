import { IOneway, IOnewayFetch } from "src/models/IOneway";
import { 
  AddOnewayAction, 
  ClearOnewayResultAction, 
  GetOnewaysAction, 
  OnewayActionEnum, 
  ResultAddOnewayAction, 
  ResultGetOnewaysAction, 
  ResultUpdateOnewayAction, 
  UpdateOnewayAction 
} from "./types";

export const OnewayActionCreators = {
  getOneways: (): GetOnewaysAction => ({
    type: OnewayActionEnum.REQUEST_ONEWAYS,
  }),
  resultGetOneways: (data: IOnewayFetch[]): ResultGetOnewaysAction => ({
    type: OnewayActionEnum.RESULT_ONEWAYS,
    data,
  }),
  addOneway: (data: IOneway): AddOnewayAction => ({
    type: OnewayActionEnum.REQUEST_ADD_ONEWAY,
    data,
  }),
  resultAddOneway: (status: number): ResultAddOnewayAction => ({
    type: OnewayActionEnum.RESULT_ADD_ONEWAY,
    status,
  }),
  updateOneway: (currentOneway: IOnewayFetch, id: string | number): UpdateOnewayAction => ({
    type: OnewayActionEnum.REQUEST_UPDATE_ONEWAY,
    currentOneway,
    id
  }),
  resultUpdateOneway: (status: number): ResultUpdateOnewayAction => ({
    type: OnewayActionEnum.RESULT_UPDATE_ONEWAY,
    status,
  }),
  clearOnewayResult: (): ClearOnewayResultAction => ({
    type: OnewayActionEnum.CLEAR_ONEWAY_RESULT
  }),
};
