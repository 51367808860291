import { FC } from 'react';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IClient } from 'src/models/IClient';

interface IProps {
  client: IClient;
  setClient: any;
  isDisableEmail?: boolean;
}

const ClientForm: FC<IProps> = ({ client, setClient, isDisableEmail }) => {
  const { role } = useTypedSelector((state) => state.auth);
  const _renderInput = (
    name: keyof IClient,
    title: string = name,
    length: number = 50
  ) => (
    <SimpleInput
      data={client[name]!}
      setData={setClient(name)}
      title={title}
      length={length}
    />
  );

  return (
    <div className='client-modal__form col-2'>
      {_renderInput('first_name', 'first name')}
      {_renderInput('last_name', 'last name')}
      <SimpleInput
        data={client.email}
        setData={setClient('email')}
        title={'email'}
        length={50}
        disabled={isDisableEmail && role === "host"}

      />
      {_renderInput('phone')}
      {_renderInput('country')}
      {_renderInput('city')}
      {_renderInput('business_name', 'business/org')}
    </div>
  );
};

export default ClientForm;
