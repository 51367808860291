import { IVan, IVanFetch } from "src/models/IVan";
import { IVanFilter } from "src/models/IVanFilter";
import {
  VanFilterFeatureAction,
  VanFilterTypeAction,
  VanActionEnum,
  VanFilterActionEnum,
  VanFilterSeatsAction,
  VanFilterStartPriceAction,
  VanFilterEndPriceAction,
  VanFilterLocationAction,
  VanFilterStartDateAction,
  VanFilterEndDateAction,
  ClearVanFilterAction,
  GetVansAction,
  ResultGetVansAction,
  GetVanAction,
  ResultGetVanAction,
  DeleteVanAction,
  ResultDeleteVanAction,
  VanFilterHostAction,
  AddVanImageAction,
  ResultAddVanImageAction,
  ResultAddVanAction,
  UpdateVanImageAction,
  ResultUpdateVanImageAction,
  ResultUpdateVanAction,
  AddVanAction,
  AddVanBlockAction,
  ResultAddVanBlockAction,
  UpdateVanAction,
} from "./types";
import { IBlockFetch } from "src/models/IBlock";

export const VanActionCreators = {
  getVans: (filter: IVanFilter): GetVansAction => ({
    type: VanActionEnum.REQUEST_VANS,
    filter,
  }),
  resultGetVans: (
    vanList: IVanFetch[],
    count: number,
    maxPrice: number,
    minPrice: number
  ): ResultGetVansAction => ({
    type: VanActionEnum.RESULT_VANS,
    vanList,
    count,
    maxPrice,
    minPrice,
  }),
  getVan: (id: number): GetVanAction => ({
    type: VanActionEnum.REQUEST_VAN,
    id,
  }),
  resultGetVan: (data: IVanFetch): ResultGetVanAction => ({
    type: VanActionEnum.RESULT_VAN,
    data,
  }),
  deleteVan: (id: number): DeleteVanAction => ({
    type: VanActionEnum.REQUEST_DELETE_VAN,
    id,
  }),
  resultDeleteVan: (status: number | string): ResultDeleteVanAction => ({
    type: VanActionEnum.RESULT_DELETE_VAN,
    status,
  }),
  addVanImage: (
    id: number,
    interiorImages: (string | File)[],
    exteriorImages: (string | File)[]
  ): AddVanImageAction => ({
    type: VanActionEnum.REQUEST_ADD_VAN_IMAGES,
    id,
    interiorImages,
    exteriorImages,
  }),
  resultAddVanImage: (
    data: IVan,
    status: number | string
  ): ResultAddVanImageAction => ({
    type: VanActionEnum.RESULT_ADD_VAN_IMAGES,
    data,
    status
  }),
  addVan: (van: IVan): AddVanAction => ({
    type: VanActionEnum.REQUEST_ADD_VAN,
    van,
  }),
  resultAddVan: (status: number | string, data?: IVanFetch): ResultAddVanAction => ({
    type: VanActionEnum.RESULT_ADD_VAN,
    status,
    data
  }),
  updateVanImage: (
    id: number,
    interiorImages: (string | File)[],
    exteriorImages: (string | File)[]
  ): UpdateVanImageAction => ({
    type: VanActionEnum.REQUEST_UPDATE_VAN_IMAGES,
    id,
    interiorImages,
    exteriorImages,
  }),
  resultUpdateVanImage: (
    status: number | string
  ): ResultUpdateVanImageAction => ({
    type: VanActionEnum.RESULT_UPDATE_VAN_IMAGES,
    status
  }),
  updateVan: (van: IVanFetch, id: number): UpdateVanAction => ({
    type: VanActionEnum.REQUEST_UPDATE_VAN,
    van,
    id
  }),
  resultUpdateVan: (status: number | string): ResultUpdateVanAction => ({
    type: VanActionEnum.RESULT_UPDATE_VAN,
    status,
  }),
  addVanBlock: (block: IBlockFetch): AddVanBlockAction => ({
    type: VanActionEnum.REQUEST_ADD_VAN_BLOCK,
    block,
  }),
  resultAddVanBlock: (status: number | string): ResultAddVanBlockAction => ({
    type: VanActionEnum.RESULT_ADD_VAN_BLOCK,
    status,
  }),
};
export const VanFilterActionCreators = {
  setFeatureFilter: (features: string[]): VanFilterFeatureAction => ({
    type: VanFilterActionEnum.FEATURES,
    features,
  }),
  setTypeFilter: (vanType: string): VanFilterTypeAction => ({
    type: VanFilterActionEnum.TYPE,
    vanType,
  }),
  setSeatFilter: (seat?: number): VanFilterSeatsAction => ({
    type: VanFilterActionEnum.SEATS,
    seat,
  }),
  setHostFilter: (hostId: number): VanFilterHostAction => ({
    type: VanFilterActionEnum.HOST_ID,
    hostId,
  }),
  setStartPriceFilter: (startPrice: number): VanFilterStartPriceAction => ({
    type: VanFilterActionEnum.START_PRICE,
    startPrice,
  }),
  setEndPriceFilter: (endPrice: number): VanFilterEndPriceAction => ({
    type: VanFilterActionEnum.END_PRICE,
    endPrice,
  }),
  setLocationFilter: (location: string): VanFilterLocationAction => ({
    type: VanFilterActionEnum.LOCATION,
    location,
  }),
  setStartDateFilter: (startDate: string): VanFilterStartDateAction => ({
    type: VanFilterActionEnum.START_DATE,
    startDate,
  }),
  setEndDateFilter: (endDate: string): VanFilterEndDateAction => ({
    type: VanFilterActionEnum.END_DATE,
    endDate,
  }),
  clearFilter: (): ClearVanFilterAction => ({
    type: VanFilterActionEnum.CLEAR,
  }),
};
