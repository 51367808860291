import { put, takeLatest } from 'redux-saga/effects';
import { SeasonPriceActionEnum } from 'src/store/reducers/seasonPrice/types';
import { SeasonPriceActionCreators } from 'src/store/reducers/seasonPrice/action-creators';
import instance from '../set';

function* fetchUpdateSeasonPrice({
  currentSeasonPrice, id
}: ReturnType<typeof SeasonPriceActionCreators.updateSeasonPrice>) {
  try {
    const { status }: Response = yield instance.patch(
      `seasonPrice/${id}`,
      currentSeasonPrice
    );
    yield put(SeasonPriceActionCreators.resultUpdateSeasonPrice(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdateSeasonPrice() {
  yield takeLatest(SeasonPriceActionEnum.REQUEST_UPDATE_SEASONPRICE, fetchUpdateSeasonPrice);
}