import { Currency } from 'dinero.js';
import {
  ReactCalendarTimelineProps,
  TimelineItemBase,
} from 'react-calendar-timeline';
import { ICalendarFilter, IOrderFetch } from 'src/models/IOrder';

export enum CalendarActionEnum {
  REQUEST_CALENDAR = 'REQUEST_CALENDAR',
  RESULT_CALENDAR = 'RESULT_CALENDAR',
  ERROR_CALENDAR = 'ERROR_CALENDAR',

  REQUEST_UPDATE_CALENDAR = 'REQUEST_UPDATE_CALENDAR',
  RESULT_UPDATE_CALENDAR = 'RESULT_UPDATE_CALENDAR',
}
export interface FetchCalendarDate {
  vanList: {
    id: number;
    model: string;
    make: string;
    seats: number;
    price: number;
    currency: Currency;
  }[];
  orderList: IOrderFetch[];
}

export interface UpdateCalendarData {
  id: number;
  van_id: number;
}
export type CustomCalendar = ReactCalendarTimelineProps<
  TimelineItemBase<moment.Moment>
>;
export interface CalendarState {
  data?: FetchCalendarDate;
  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
  };
}

export interface GetCalendarAction {
  type: CalendarActionEnum.REQUEST_CALENDAR;
  filter: ICalendarFilter
}

export interface ResultGetCalendarAction {
  type: CalendarActionEnum.RESULT_CALENDAR;
  data: FetchCalendarDate;
}

export interface UpdateCalendarAction {
  type: CalendarActionEnum.REQUEST_UPDATE_CALENDAR;
  order: UpdateCalendarData;
  filter?: ICalendarFilter
}

export interface ResultUpdateCalendarAction {
  type: CalendarActionEnum.RESULT_UPDATE_CALENDAR;
  data: FetchCalendarDate;
}
export interface ErrorCalendarAction {
  type: CalendarActionEnum.ERROR_CALENDAR;
  status: number;
}

export type CalendarAction =
  | GetCalendarAction
  | ResultGetCalendarAction
  | UpdateCalendarAction
  | ResultUpdateCalendarAction
  | ErrorCalendarAction;
