import { FC, useCallback, useState } from "react";
import clsx from "clsx";
import Icons from "src/common/icons";
import { capitalizeField, upCaseColumn } from "src/common/utils/formatHelper";

interface IProps {
  columnName: string;
  sortColumn?: string;
  field?: string;
  noSort?: boolean;
  handleSort?: (params: string) => void;
}

const Header: FC<IProps> = ({
  columnName,
  sortColumn,
  field,
  handleSort,
  noSort,
}) => {
  const [direction, setDirection] = useState<"ASC" | "DESC">("DESC");
  const onClick = useCallback(
    (direction: "ASC" | "DESC"): void => {
      handleSort && handleSort(`${field} ${direction}`);
      direction === "ASC" ? setDirection("DESC") : setDirection("ASC");
    },
    [field, handleSort]
  );

  return (
    <div className="react-table-head">
      {columnName ? upCaseColumn(columnName) : capitalizeField(field || "")}
      {noSort ? null : (
        <div
          className="react-table-head-sort"
          onClick={() => onClick(direction)}
        >
          <Icons.DoubleArrow
            classes={clsx(
              "react-table-expand",
              direction === "ASC" && "active"
            )}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
