import { put, takeLatest } from 'redux-saga/effects';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';
import instance from 'src/sagas/set';

function* fetchTransferPayment({
  id
}: ReturnType<typeof OrderActionCreators.transferPayment>) {
  try {
    const { status, data } = yield instance.get(`/stripe/transfer/${id}`);
    yield put(OrderActionCreators.resultTransferPayment(status, data));
  } catch (error: any) {
    console.log(error);
    yield put(
      OrderActionCreators.resultTransferPayment(400)
    );
  }
}

export function* actionWatcherTransferPayment() {
  yield takeLatest(
    OrderActionEnum.REQUEST_TRANSFER_PAYMENT,
    fetchTransferPayment
  );
}
