//@ts-nocheck
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import { Tooltip } from '@mui/material';
import CustomPagination from '../../pagination';
import { IVanFilter } from 'src/models/IVanFilter';
import useIsMobile from 'src/hooks/useIsMobile';
import { dineroIsPositive } from 'src/common/utils/dataFormat';

interface IProps {
  data: {}[];
  columns: any[];
  class?: string;
  filters?: Pick<IVanFilter, 'page' | 'take'>;
  total: number;
  onPaginationChange?: any;
  onTakeChange?: any;
}

const Base: FC<IProps> = ({
  columns,
  data,
  filters,
  total,
  onPaginationChange,
  onTakeChange,
}) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const isMobile = useIsMobile();
  const _renderCells = (cells) => {
    return (
      <>
        {cells.map((cell) => {
          let color = 'black';
          if (cell.column.Header === 'Offset') {
            if (dineroIsPositive(cell.row.original.offset_fee)) {
              color = 'green';
            } else {
              color = 'red';
            }
          }
          return (
            <td
              {...cell.getCellProps({
                style: {
                  minWidth: cell.column.minWidth,
                  width: cell.column.width,
                  color: `${color}`,
                },
              })}
              className='rt-td'
            >
              {cell.render('Cell')}
            </td>
          );
        })}
      </>
    );
  };

  return (
    <>
      {filters && (
        <CustomPagination
          filter={filters}
          total={total}
          onChange={onPaginationChange}
          onTakeChange={onTakeChange}
        />
      )}
      <div className='table-wrapper'>
        <table {...getTableProps()} className='react-table'>
          <thead className='rt-thead'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className='rt-tr'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className='rt-th'>
                    {column.tooltip ? (
                      <Tooltip title=
                        {
                          <>
                            <div className='tooltip-table__help'>
                              {column.tooltip}
                            </div>
                          </>

                        }>
                        <span className='tooltip-table__header'>
                          {column.render('Header')}
                        </span>
                      </Tooltip>
                    ) : (
                      column.render('Header')
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='rt-tbody'>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <tr {...row.getRowProps()} className='rt-tr'>
                    {isMobile ? (
                      <Link to={`/order/${row.cells[0].value}`} className='react-link'>
                        {_renderCells(row.cells)}
                      </Link>
                    ) : (
                      <>{_renderCells(row.cells)}</>
                    )}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
      {filters && (
        <CustomPagination
          filter={filters}
          total={total}
          onChange={onPaginationChange}
          onTakeChange={onTakeChange}
        />
      )}
    </>
  );
};

export default Base;
