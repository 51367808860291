import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { SubPriceActionCreators } from "src/store/reducers/subPrice/action-creators";
import { SubPriceActionEnum } from "src/store/reducers/subPrice/types";

function* fetchDeleteSubPrice({
  id,
}: ReturnType<typeof SubPriceActionCreators.deleteSubPrice>) {
  try {
    const { status }: Response = yield instance.delete(`priceForSub/${id}`);
    yield put(SubPriceActionCreators.resultDeleteSubPrice(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteSubPrice() {
  yield takeLatest(SubPriceActionEnum.REQUEST_DELETE_SUBPRICE, fetchDeleteSubPrice);
}
