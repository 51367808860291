import { put, takeLatest } from "redux-saga/effects";
import { VanActionEnum } from "src/store/reducers/van/types";
import { VanActionCreators } from "src/store/reducers/van/action-creators";
import instance from "../set";

function* fetchGetVans({
  filter,
}: ReturnType<typeof VanActionCreators.getVans>) {
  !filter.features?.length && delete filter.features;

  try {
    const {
      data: { vanList, count, maxPrice, minPrice },
    } = yield instance.post("van", {
      filters: filter,
    });
    yield put(
      VanActionCreators.resultGetVans(vanList, count, maxPrice, minPrice)
    );
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetVans() {
  yield takeLatest(VanActionEnum.REQUEST_VANS, fetchGetVans);
}
