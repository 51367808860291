import { FC, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import DocumentModal from '../../Modal/Document';
import ShowDocModal from './DocModal';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';
import { IOrderFetch } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';

const Docs: FC<{ order: IOrderFetch }> = ({ order: { pdf_order, client } }) => {

  const { showContract, showLicense, showInsurance } = useActions();
  const { order, result } = useTypedSelector((state) => state.order);
  const [open, setOpen] = useState(false);
  const [openDoc, setOpenDoc] = useState(false);
  const [doc, setDoc] = useState('');

  useEffect(() => {
    if (result.doc?.base64) {
      if (result.doc.fileType === 'application/pdf') {
        const pdfWindow = window.open('');
        pdfWindow!.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(result.doc?.base64) + "'></iframe>"
        );
      } else {
        setDoc(result.doc?.base64);
        setOpenDoc(true);
        // const cw = window.open();
        // cw!.document.write(
        //   `<img src="data:image/png;base64, ${result.doc?.base64}"/>`
        // );
      }
    }
  }, [result.doc]);

  const _item = (title: string, links?: string | string[]) => (
    <div className='order-docs__item'>
      <div className='order-docs__item__title'>{`${title} : `}</div>
      {Array.isArray(links) ? (
        links.map((link, index) => (
          <Tooltip title={title} key={index}>
            {title === 'Insurance card' ? (
              <div
                className='order-docs__item__data icon_stroke'
                onClick={() => showInsurance(client!.driver_license!.s3_keys[2])}
              >
                <Icons.LilPic />
              </div>
            ) : (
              <div
                className='order-docs__item__data icon_stroke'
                onClick={() => showLicense(client!.driver_license!.s3_keys[index])}
              >
                <Icons.LilPic />
              </div>
            )}
          </Tooltip>
        ))
      ) : links ? (
        <Tooltip title={title}>
          {links.startsWith('https://') ? (
            <a
              href={links}
              target='_blank'
              className='order-docs__item__data icon_stroke'
            >
              <Icons.LilPic />
            </a>
          ) : (
            <div
              className='order-docs__item__data icon_stroke'
              onClick={() => showContract(links)}
            >
              <Icons.LilPic />
            </div>
          )}
        </Tooltip>
      ) : (
        'missing'
      )}
    </div>
  );

  return (
    <>
      <div className='order-show__title'>
        Documents
        <Tooltip title='Delete document'>
          <div
            onClick={() => setOpen(true)}
            className='order-show__title__action icon_stroke'
          >
            <Icons.Delete />
          </div>
        </Tooltip>
      </div>
      <div className='order-docs'>
        {_item(
          'Drivers license',
          client.driver_license?.photos as string[] | undefined
        )}
        {_item(
          'Insurance card',
          client.driver_license?.insurance as string | undefined
        )}
        {_item('Signed contract', pdf_order)}
      </div>
      <DocumentModal
        open={open}
        handleClose={() => setOpen(false)}
        order={order!}
      />
      <ShowDocModal
        open={openDoc}
        handleClose={() => setOpenDoc(false)}
        doc={doc}
      />
    </>
  );
};

export default Docs;
