import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { SeasonPriceActionCreators } from "src/store/reducers/seasonPrice/action-creators";
import { SeasonPriceActionEnum } from "src/store/reducers/seasonPrice/types";

function* fetchDeleteSeasonPrice({
  id,
}: ReturnType<typeof SeasonPriceActionCreators.deleteSeasonPrice>) {
  try {
    const { status }: Response = yield instance.delete(`seasonPrice/${id}`);
    yield put(SeasonPriceActionCreators.resultDeleteSeasonPrice(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteSeasonPrice() {
  yield takeLatest(SeasonPriceActionEnum.REQUEST_DELETE_SEASONPRICE, fetchDeleteSeasonPrice);
}
