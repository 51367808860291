import { requestState, responseState } from "../utils";
import { SubPriceAction, SubPriceActionEnum, SubPriceState } from "./types";

const initialState: SubPriceState = {
    subPriceList: [],
    isLoading: {
        all: false,
        get: false,
        add: false,
        delete: false,
        update: false,
    },
    result: {},
};
const subPrice = (state = initialState, action: SubPriceAction): SubPriceState => {
  switch (action.type) {
    case SubPriceActionEnum.REQUEST_SUBPRICE:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case SubPriceActionEnum.RESULT_SUBPRICE:
      return {
        ...state,
        subPriceList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };
    case SubPriceActionEnum.REQUEST_ADD_SUBPRICE:
    return requestState(state, "add");

    case SubPriceActionEnum.RESULT_ADD_SUBPRICE:
      return responseState(state, "add", action);

    case SubPriceActionEnum.REQUEST_UPDATE_SUBPRICE:
      return requestState(state, "update");

    case SubPriceActionEnum.RESULT_UPDATE_SUBPRICE:
      return responseState(state, "update", action);
  
    case SubPriceActionEnum.REQUEST_DELETE_SUBPRICE:
      return requestState(state, "delete");

    case SubPriceActionEnum.RESULT_DELETE_SUBPRICE:
      return responseState(state, "delete", action);

    case SubPriceActionEnum.REQUEST_DELETE_ALL_SUBPRICES:
      return requestState(state, "delete");

    case SubPriceActionEnum.RESULT_DELETE_ALL_SUBPRICES:
      return responseState(state, "delete", action);

    case SubPriceActionEnum.CLEAR_SUBPRICE_RESULT:
      return { ...state, result: {} };

    default:
      return state;
  }
};
export default subPrice;
