import { put, takeLatest } from "redux-saga/effects";
import instance from "src/sagas/set";
import { VanActionCreators } from "src/store/reducers/van/action-creators";
import { VanActionEnum } from "src/store/reducers/van/types";

export function* fetchUpdateVanImage({
  interiorImages,
  exteriorImages,
  id,
}: ReturnType<typeof VanActionCreators.updateVanImage>) {

  const formData = new FormData();
  exteriorImages.map((file) => formData.append('exteriorImages', file));
  interiorImages.map((file) => formData.append('interiorImages', file));

  try {
    const { status } = yield instance.post(`/van/imgUpload/${id}`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
  yield put(VanActionCreators.resultUpdateVanImage(status));
  } catch (e) {
    console.log(e);
    yield put(VanActionCreators.resultUpdateVanImage(400));
  }
}
export function* actionWatcherUpdateVanImage() {
  yield takeLatest(VanActionEnum.REQUEST_UPDATE_VAN_IMAGES, fetchUpdateVanImage);
}
