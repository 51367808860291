import { FC } from "react";
import "./styles.scss";

const NoResult: FC = () => (
  <div className="no-result">
    <img src="images/truck.gif" alt="noResult" width={200} loading='lazy' />
    <div>Sorry, there are no results for this date or filters</div>
    <div>Please try date or filters</div>
  </div>
);
export default NoResult;
