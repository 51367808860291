import { Radio } from '@mui/material';
import { Currency } from 'dinero.js';
import { FC, useState } from 'react';
import { dineroFormat } from 'src/common/utils/dataFormat';

interface IProps {
    oldPrice: number;
    newPrice: number;
    currency: Currency;
    setPrice: any;
}

const PriceSelection: FC<IProps> = ({ oldPrice, newPrice, currency, setPrice }) => {

    const [selectedValue, setSelectedValue] = useState('new');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value),
            event.target.value === 'new' ? setPrice(newPrice) : setPrice(oldPrice);
    };

    const controlProps = (item: string) => ({
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    return (
        <div>
            <div className='filter__location'>
                <div>
                    <Radio
                        {...controlProps('new')}
                    />
                    <div className='filter__title'>New rate: {dineroFormat(newPrice, currency)}</div>
                </div>
                <div>
                    <Radio
                        {...controlProps('old')}
                    />
                    <div className='filter__title'>Keep old rate: {dineroFormat(oldPrice, currency)}</div>
                </div>
            </div>
            <div className='filter__title' style={{ color: 'red', margin: '10px' }}>
                This is a beta version. Please check New/Old Rate, then click Edit Order.<br />
                If rates are off Search Help Chat: Manually Calculate Rates
            </div>
        </div>
    );
};

export default PriceSelection;
