import { FC } from 'react';
import './styles.scss';

type Theme = 'blue' | 'green' | 'red' | 'grey' | 'orange' | 'babyblue' | 'pink' | 'dark-green';

interface IProps {
  text: string;
  theme: Theme;
  document?: boolean;
  isSmall?: boolean;
}

const Badge: FC<IProps> = ({ theme, text, document, isSmall }) => {
  return (
    <>
      {isSmall ? (
        <div className={
          `status-badge small-badge-container__item 
          small-badge-container__item__${theme}${document ? '__document' : ''}`}
        />
      ) : (
        <div className={`status-badge ${theme}${document ? '__document' : ''}`}>
          {text === 'gotit' ? 'got it' : text}
        </div>
      )}
    </>
  );
};

export default Badge;
