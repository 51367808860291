import { Backdrop, Button, Modal } from '@mui/material';
import lodash from 'lodash';
import { FC, useState } from 'react';
import ModalAction from 'src/components/Items/modal/Actions';
import SimpleMultiSelect from 'src/components/Items/select/SimpleMultiSelect';
import { Driver } from 'src/entity/Driver';
import { useActions } from 'src/hooks/useActions';
import { IClientFetch } from 'src/models/IClient';
import { IDriverFetch } from 'src/models/IDriver';
import { IOrderFetch } from 'src/models/IOrder';
import AddDriverModal from '../Add';
import DriverForm from '../Form';

interface IProps {
  open: boolean;
  handleClose: () => void;
  order: IOrderFetch;
  client: IClientFetch;
}

const DriverListModal: FC<IProps> = ({ open, handleClose, order, client }) => {
  const { updateOrder } = useActions();
  const [drivers, setDriver] = useState(order.additional_driver);
  const [addOpen, setAddOpen] = useState(false);

  const handleAddOpen = () => {
    setAddOpen(true);
  };
  const handleCheck = (event: any) => {
    const { value } = event.target;
    const newDriverList = lodash.filter(client.drivers, (driver) =>
      value.includes(`${driver.first_name} ${driver.last_name}`)
    );
    setDriver(newDriverList as IDriverFetch[]);
  };

  const handleSubmit = () => {
    updateOrder({ id: order.id, additional_driver: drivers });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal modal-client'>
          <div className='modal__title'>Edit Driver List</div>
          <div className='modal__body'>
            <SimpleMultiSelect
              data={drivers.map(
                (driver) => `${driver.first_name} ${driver.last_name}`
              )}
              dataArray={client.drivers.map(
                (driver) => `${driver.first_name} ${driver.last_name}`
              )}
              setData={handleCheck}
              placeholder='Select an existing driver'
            />
            <Button
              children='Add new driver'
              variant='contained'
              onClick={handleAddOpen}
              sx={{ width: '140px' }}
            />
          </div>

          <ModalAction
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            type='driver'
            action='Save'
          />
        </div>
        <AddDriverModal
          open={addOpen}
          handleClose={() => setAddOpen(false)}
          orderId={order.id}
          client={order.client}
        />
      </>
    </Modal>
  );
};
export default DriverListModal;
