import Base from "./Base";
import Header from "./Header";

import "./styles.scss";

const Table = {
  Base,
  Header,
};

export default Table;
