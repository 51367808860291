import { FC } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";

interface IProps {
  hideBarMenu?: boolean;
}

const Logo: FC<IProps> = ({ hideBarMenu }) => (
  <Link to="/">
    {!hideBarMenu ? (
      <div className="logo">
        <img
          src={`${window.location.origin}/images/logo.png`}
          alt="LOGO"
          width="90px"
        />
        <div className="logo__text">
          SPRINTER <b>RENTALS</b>
        </div>
      </div>
    ) : (
      <div className="logo abbr">SR</div>
    )}
  </Link>
);

export default Logo;
