import { put, takeLatest } from "redux-saga/effects";
import { VanActionEnum } from "src/store/reducers/van/types";
import { VanActionCreators } from "src/store/reducers/van/action-creators";
import instance from "../set";

function* fetchDeleteVan({
  id,
}: ReturnType<typeof VanActionCreators.deleteVan>) {
  try {
    const { status }: Response = yield instance.delete(`van/${id}`);
    yield put(VanActionCreators.resultDeleteVan(status));
  } catch (error: any) {
    console.log("error", error);
    yield put(VanActionCreators.resultDeleteVan("ERROR"));
  }
}
export function* actionWatcherDeleteVan() {
  yield takeLatest(VanActionEnum.REQUEST_DELETE_VAN, fetchDeleteVan);
}
