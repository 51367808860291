import Dinero from 'dinero.js';
import { Currency } from 'dinero.js';
import { DateTime } from 'luxon';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const fromFormat = (data: string, format: string) =>
  DateTime.fromFormat(data, format, {
    zone: timezone,
  });

const fromISOtoFormat = (date: string, format: string) =>
  DateTime.fromISO(date).toFormat(format);

const changeISOset = (date: string, object: {}) =>
  DateTime.fromISO(date).set(object).toISO();

const fromJSDateToISO = (date: any) =>
  DateTime.fromJSDate(new Date(date)).toISO();

const dineroFormat = (amount: number, currency: Currency) => {
  return Dinero({ amount, currency }).toFormat('$0,0.00');
};
const dineroSubtract = (
  firstAmount: number,
  secondAmount: number,
  currency: Currency
) => {
  return Dinero({ amount: firstAmount, currency })
    .subtract(Dinero({ amount: secondAmount, currency }))
    .toFormat('$0,0.00');
};

const dineroSubtractAmount = (
  firstAmount: number,
  secondAmount: number,
  currency: Currency
) => {
  return Dinero({ amount: firstAmount, currency })
    .subtract(Dinero({ amount: secondAmount, currency }))
    .getAmount();
};

const dineroMultiply = (
  firstAmount: number,
  secondAmount: number
) => {
  return Dinero({ amount: firstAmount })
    .multiply(secondAmount).getAmount();
};

const dineroAdd = (
  firstAmount: number,
  secondAmount: number,
  currency: Currency
) => {
  return Dinero({ amount: firstAmount, currency })
    .add(Dinero({ amount: secondAmount, currency })).getAmount();
};

const dineroDivide = (
  firstAmount: number,
  secondAmount: number
) => {
  return Dinero({ amount: firstAmount })
    .divide(secondAmount).getAmount();
};

const dineroGetPercentage = (amount: number, percentage: number) => {
  return Dinero({ amount }).percentage(percentage).toObject().amount;
};
const dineroIsPositive = (amount: number) => Dinero({ amount }).isPositive();
export {
  fromFormat,
  fromISOtoFormat,
  changeISOset,
  fromJSDateToISO,
  timezone,
  dineroFormat,
  dineroSubtract,
  dineroIsPositive,
  dineroGetPercentage,
  dineroMultiply,
  dineroAdd,
  dineroDivide,
  dineroSubtractAmount
};
