import { ISubLocation, ISubLocationFetch } from "src/models/ILocation";


export enum SublocationActionEnum {
  REQUEST_SUBLOCATIONS = "REQUEST_SUBLOCATIONS",
  RESULT_SUBLOCATIONS = "RESULT_SUBLOCATIONS",

  REQUEST_ADD_SUBLOCATIONS = "REQUEST_ADD_SUBLOCATIONS",
  RESULT_ADD_SUBLOCATIONS = "RESULT_ADD_SUBLOCATIONS",

  REQUEST_UPDATE_SUBLOCATION = "REQUEST_UPDATE_SUBLOCATION",
  RESULT_UPDATE_SUBLOCATION = "RESULT_UPDATE_SUBLOCATION",

  REQUEST_DELETE_SUBLOCATION = "REQUEST_DELETE_SUBLOCATION",
  RESULT_DELETE_SUBLOCATION = "RESULT_DELETE_SUBLOCATION",
}

export interface SublocationState {
  subLocationList: ISubLocationFetch[];
  isLoading: {
    all: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
  };
  result: {
    add?: number | string;
    delete?: number | string;
    update?: number | string;
  };
}

export interface GetSublocationsAction {
  type: SublocationActionEnum.REQUEST_SUBLOCATIONS;
}
export interface ResultGetSublocationsAction {
  type: SublocationActionEnum.RESULT_SUBLOCATIONS;
  data: ISubLocationFetch[];
  status: number;
}
export interface AddSublocationsAction {
  type: SublocationActionEnum.REQUEST_ADD_SUBLOCATIONS;
  data: ISubLocation[];
}
export interface ResultAddSublocationsAction {
  type: SublocationActionEnum.RESULT_ADD_SUBLOCATIONS;
  status: number;
}
export interface UpdateSublocationAction {
  type: SublocationActionEnum.REQUEST_UPDATE_SUBLOCATION;
  currentSublocation: string,
  id: string | number
}
export interface ResultUpdateSublocationAction {
  type: SublocationActionEnum.RESULT_UPDATE_SUBLOCATION;
  status: number;
}
export interface DeleteSublocationAction {
  type: SublocationActionEnum.REQUEST_DELETE_SUBLOCATION;
  id: number;
}
export interface ResultDeleteSublocationAction {
  type: SublocationActionEnum.RESULT_DELETE_SUBLOCATION;
  status: number;
}

export type SublocationAction =
  | AddSublocationsAction
  | ResultAddSublocationsAction
  | UpdateSublocationAction
  | ResultUpdateSublocationAction
  | DeleteSublocationAction
  | ResultDeleteSublocationAction
  | GetSublocationsAction
  | ResultGetSublocationsAction;
