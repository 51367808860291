import { FC, useState } from 'react';
import { orderBy } from 'natural-orderby';
import SublocationsSelect from '../../select/SublocationsSelect';
import SimpleSelect from '../../select/SimpleSelect';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IOrderFetch } from 'src/models/IOrder';

interface IProps {
  order: IOrderFetch;
  setOrder: any;
}

const Location: FC<IProps> = ({ order, setOrder }) => {
  const {
    location: { locationList },
  } = useTypedSelector((state) => state);
  const [location, setLocation] = useState({ start: '', sub: '', end: '', sub_end: '' });
  const changeStartLocation = (value: any) => {
    value.name ? (
      setLocation({ ...location, start: value.location, end: '', sub: value.name, sub_end: value.name })
    ) : (
      setLocation({ ...location, start: value, end: '', sub: '', sub_end: '' })
    );
    setOrder({
      ...order,
      location: value.location || value,
      end_location: '',
      sub_location: value.name || '',
      sub_location_end: value.name || '',
      isOneway: false
    });
  };
  const changeEndLocation = (value: any) => {
    value.name ? (
      setLocation({ ...location, end: value.location, sub_end: value.name }),
      setOrder({
        ...order,
        end_location: value.location === order.location ? '' : value.location,
        sub_location_end: value.name,
        isOneway: (value.location === order.location) ? false : true
      })
    ) : (
      setLocation({ ...location, end: value, sub_end: '' }),
      setOrder({
        ...order,
        end_location: value === order.location ? '' : value,
        sub_location_end: '',
        isOneway: (value === order.location) ? false : true
      })
    );
  };

  const sortedLocation = orderBy(
    locationList,
    [(location) => location.country, (location) => location.name],
    ['asc', 'asc']
  );

  return (
    <div className='filter__location'>
      <div>
        <div className='filter__title'>Start location</div>
        <SublocationsSelect
          data={location.sub || location.start || order.sub_location || order.location}
          setData={changeStartLocation}
          dataArray={sortedLocation}
          isChooseLocations
          type='with location'
        />
      </div>
      <div>
        <div className='filter__title'>End location</div>
        <SublocationsSelect
          data={location.sub_end || location.end || order.sub_location_end || order.end_location || order.location}
          setData={changeEndLocation}
          dataArray={sortedLocation}
          isChooseLocations
          type='with location'
        />
      </div>
    </div>
  );
};
export default Location;
