import { put, takeLatest } from 'redux-saga/effects';
import { LocationActionEnum } from 'src/store/reducers/location/types';
import instance from '../set';
import { LocationActionCreators } from 'src/store/reducers/location/action-creators';

function* fetchUpdateLocation({
  currentLocation, id
}: ReturnType<typeof LocationActionCreators.updateLocation>) {
  try {
    const { status }: Response = yield instance.patch(
      `location/${id}`,
      {
        location: currentLocation,
      }
    );
    yield put(LocationActionCreators.resultUpdateLocation(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdateLocation() {
  yield takeLatest(LocationActionEnum.REQUEST_UPDATE_LOCATION, fetchUpdateLocation);
}