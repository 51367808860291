import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { SublocationActionCreators } from "src/store/reducers/sublocation/action-creators";
import { SublocationActionEnum } from "src/store/reducers/sublocation/types";

function* fetchDeleteSublocation({
  id,
}: ReturnType<typeof SublocationActionCreators.deleteSublocation>) {
  try {
    const { status }: Response = yield instance.delete(`sublocation/${id}`);
    yield put(SublocationActionCreators.resultDeleteSublocation(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteSublocation() {
  yield takeLatest(SublocationActionEnum.REQUEST_DELETE_SUBLOCATION, fetchDeleteSublocation);
}
