import { ILocation, ILocationFetch } from "src/models/ILocation";
import {
  AddLocationAction,
  ClearLocationResultAction,
  LocationActionEnum,
  RequestLocationAction,
  ResultAddLocationAction,
  ResultLocationAction,
  ResultUpdateLocationAction,
  UpdateLocationAction,
} from './types';

export const LocationActionCreators = {
  getLocations: (): RequestLocationAction => ({
    type: LocationActionEnum.REQUEST_LOCATIONS,
  }),
  resultGetLocations: (data: ILocationFetch[]): ResultLocationAction => ({
    type: LocationActionEnum.RESULT_LOCATIONS,
    data,
  }),
  addLocation: (data: ILocation): AddLocationAction => ({
    type: LocationActionEnum.REQUEST_ADD_LOCATION,
    data,
  }),
  resultAddLocation: (status: number): ResultAddLocationAction => ({
    type: LocationActionEnum.RESULT_ADD_LOCATION,
    status,
  }),
  updateLocation: (currentLocation: ILocationFetch, id: string | number): UpdateLocationAction => ({
    type: LocationActionEnum.REQUEST_UPDATE_LOCATION,
    currentLocation,
    id
  }),
  resultUpdateLocation: (status: number): ResultUpdateLocationAction => ({
    type: LocationActionEnum.RESULT_UPDATE_LOCATION,
    status,
  }),
  clearLocationResult: (): ClearLocationResultAction => ({
    type: LocationActionEnum.CLEAR_LOCATION_RESULT
  }),
};
