import { put, takeLatest } from 'redux-saga/effects';
import { NearVanActionCreators } from "src/store/reducers/nearVan/action-creators";
import { NearVanActionEnum } from "src/store/reducers/nearVan/types";
import instance from '../set';

function* fetchUpdateNearVan({
  currentNearVan, id
}: ReturnType<typeof NearVanActionCreators.updateNearVan>) {
  try {
    const { status }: Response = yield instance.patch(
      `nearVan/${id}`,
      currentNearVan
    );
    yield put(NearVanActionCreators.resultUpdateNearVan(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdateNearVan() {
  yield takeLatest(NearVanActionEnum.REQUEST_UPDATE_NEARVAN, fetchUpdateNearVan);
}