import { put, takeLatest } from "redux-saga/effects";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";
import { ClientActionEnum } from "src/store/reducers/client/types";
import instance from "../set";

function* fetchGetClient({
  id,
}: ReturnType<typeof ClientActionCreators.getClient>) {
  try {
    const { data } = yield instance.get(`client/${id}`);
    yield put(ClientActionCreators.resultGetClient(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetClient() {
  yield takeLatest(ClientActionEnum.REQUEST_CLIENT, fetchGetClient);
}
