import { FC, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Modal, Tooltip } from "@mui/material";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddTagModal from "../Add";
import SecondTypeButton from "src/components/Items/button/SecondTypeButton";
import DeleteModalForm from "src/components/Items/DeleteModalForm";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { ITag } from "src/store/reducers/tag/types";
import Icons from "src/common/icons";
import './styles.scss';

interface IProps {
  allTags: ITag[];
  tags: ITag[];
  setTags: any;
  handleClose: () => void;
  categories: string[];
}

const AllTagModal: FC<IProps> = ({ allTags, tags, setTags, handleClose, categories }) => {

  const { role } = useTypedSelector((state) => state.auth);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState(0);
  const [unselectedTags, setUnselectedTags] = useState<ITag[]>([]);

  useEffect(() => {
    const difference = allTags.filter((tag1) => {
      return !tags.some((tag2) => tag2.id === tag1.id);
    });
    setUnselectedTags(difference);
  }, [allTags, tags]);

  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);
  const handleAddTag = (tag: ITag) => {
    setTags([...tags, tag]);
  };
  const handleRemoveTag = (tagId: number) => {
    const updatedTags = tags.filter((tag) => tag.id !== tagId);
    setTags(updatedTags);
  };

  const handleDelete = (id: number) => {
    setSelectedTagId(id);
    setOpenDelete(true);
  };

  return (
    <div className="modal tag-modal">
      <div className="modal__title">Tags</div>
      <div className="tag-modal__container">
        <div className="tag-modal__container__tags-remove">
          {tags.map((tag, key) => (
            <div
              className="tag-modal__item tag-modal__item__red modal__item"
              key={key}
              title={tag.name}
            >
              <Tooltip title="unselect tag">
                <div
                  className="icon_stroke"
                  onClick={() => handleRemoveTag(tag.id!)}
                >
                  <RemoveCircleOutlineOutlinedIcon strokeWidth={0.5} />
                </div>
              </Tooltip>
              {tag.name}
            </div>
          )
          )}
        </div>
        <div className="tag-modal__container__tags-add">
          {categories.map((categoryEnum) => (
            <Accordion key={categoryEnum}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
              >
                <div className="modal__items__title">{`${categoryEnum}`}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="modal__items" data-category={categoryEnum}>
                  {unselectedTags.map(
                    ({ name, id, category }) =>
                      (category as any) === categoryEnum && (
                        <div
                          className="tag-modal__item tag-modal__item__green modal__item"
                          key={id}
                          title={name}
                        >
                          <Tooltip title="select tag">
                            <div
                              className="icon_stroke"
                              onClick={() => handleAddTag({ name, id, category })}
                            >
                              <AddCircleOutlineOutlinedIcon strokeWidth={0.5} />
                            </div>
                          </Tooltip>
                          {name}
                          <Tooltip title='delete tag'>
                            <div className='icon_stroke' onClick={() => handleDelete(id!)}>
                              <Icons.Cross />
                            </div>
                          </Tooltip>
                        </div>
                      )
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}</div>
      </div>
      {role === 'admin' && (
        <>
          <SecondTypeButton onClick={handleOpenAdd} children="Add more" style={{ marginTop: '20px' }} />
          <Modal
            open={openAdd}
            onClose={handleCloseAdd}
            BackdropComponent={Backdrop}
            closeAfterTransition
          >
            <>
              <AddTagModal handleClose={handleCloseAdd} categories={categories} />
            </>
          </Modal>
        </>
      )}
      <DeleteModalForm
        id={selectedTagId}
        type='tag'
        handleClose={() => setOpenDelete(false)}
        open={openDelete}
      />
    </div>
  );
};

export default AllTagModal;
