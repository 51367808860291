import { FC, useState } from "react";
import DeleteModalForm from "src/components/Items/DeleteModalForm";
import FormIcon from "src/components/Items/ActionForm/FormIcon";
import EditSublocationModal from "../Edit";
import { ISubLocationFetch } from "src/models/ILocation";
import Icons from "src/common/icons";

interface IProps {
    sublocation: ISubLocationFetch;
}

const SublocationItem: FC<IProps> = ({ sublocation }) => {

    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    return (
        <>
            <div
                className="sublocation-item modal__item"
                title={sublocation.name}
            >
                <div 
                    style={{cursor: 'pointer'}} 
                    onClick={() => setOpenEdit(true)}
                >
                    {sublocation.name}
                </div>
                <FormIcon
                    icon={
                        <Icons.Delete
                            classes="icon_stroke delete-sublocation-icon"
                        />
                    }
                    handleClick={() => setOpenDelete(true)}
                    title={'Delete sublocation'}
                />
            </div>
            <DeleteModalForm
                id={sublocation.id}
                type="sublocation"
                handleClose={() => setOpenDelete(false)}
                open={openDelete}
            />
            <EditSublocationModal
                sublocation={sublocation}
                close={openEdit}
                handleClose={() => setOpenEdit(false)}
            />
        </>
    );
};
export default SublocationItem;
