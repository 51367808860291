import { put, takeLatest } from 'redux-saga/effects';
import { HostActionEnum } from 'src/store/reducers/host/types';
import instance from '../set';
import { HostActionCreators } from 'src/store/reducers/host/action-creators';

function* fetchUpdateHost({
  currentHost, id
}: ReturnType<typeof HostActionCreators.updateHost>) {
  let host;
  currentHost.host_tag ? (
    host = { ...currentHost, host_tag: [currentHost.host_tag] }
  ) : (
    host = currentHost
  );
  try {
    const { status }: Response = yield instance.patch(
      `host/${id}`,
      {
        host: host,
      }
    );
    yield put(HostActionCreators.resultUpdateHost(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdateHost() {
  yield takeLatest(HostActionEnum.REQUEST_UPDATE_HOST, fetchUpdateHost);
}
