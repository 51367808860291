import { FC, useEffect, useState } from "react";
import { FormControl, Select, MenuItem, Checkbox, ListItemText, ThemeProvider, createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import theme, { colorSet, multiSelectItemStyle, typeForm } from "src/theme";
import "./styles.scss";

interface ISelect {
  data: any[];
  setData: (event: any) => any;
  dataArray: any[];
  check?: boolean;
  title?: string;
  maxWidth?: string;
  placeholder?: string;
  variant?: "standard" | "outlined" | "filled";
  propsShow?: string[];
  menuHeight?: string;
}

const SimpleMultiSelect: FC<ISelect> = ({
  data,
  setData,
  dataArray,
  check,
  title,
  placeholder = title,
  variant,
  propsShow,
  menuHeight,
  maxWidth
}) => {

  const themeMultiSelect = createTheme(
    deepmerge(theme, {
      components: {
        MuiInput: {
          styleOverrides: {
            root: {
              maxWidth: maxWidth ? maxWidth : '100%',
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              maxHeight: `${menuHeight ? menuHeight : 'calc(100% - 80px)'} !important`,
              marginTop: 3,
              backgroundColor: colorSet.primaryWhite,
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              padding: 3,
            },
          },
        },
      },
    })
  );
  
  const [renderedNames, setRenderedNames] = useState<string[]>([]);
  useEffect(() => {
    const newArray = [] as string[];
    propsShow &&
      dataArray.map((d) => {
        const name = propsShow.map((prop) => d[prop]).join(" ");
        data.find((el) => el === d.host_tag[0]) && newArray.push(name);
      });
    setRenderedNames(newArray);
  }, [propsShow]);

  return (
    <ThemeProvider theme={themeMultiSelect}>
      {data && (
        <div>
          {title && <div className="filter__title">{title}</div>}
          <FormControl>
            <Select
              variant={variant ?? typeForm}
              multiple
              displayEmpty
              defaultValue={[]}
              value={data ?? []}
              onChange={setData}
              error={data && check}
              renderValue={(n: string[] | number[]) =>
                !n.length || !data.length ? (
                  <div style={{ color: colorSet.primaryGrey }}>
                    {placeholder}
                  </div>
                ) : (
                  propsShow ? renderedNames.join(", ") : n.join(", ")
                )
              }
            >
              {dataArray.map((d, index) => {
                const name = propsShow ? propsShow.map((prop) => d[prop]).join(" ") : '';
                return (
                  <MenuItem
                    sx={multiSelectItemStyle}
                    value={propsShow ? d.host_tag[0] : d}
                    key={index}
                    title={d}
                  >
                    <Checkbox checked={data.includes(propsShow ? d.host_tag[0] : d)} size="small" />
                    <ListItemText primary={propsShow ? name : d} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      )}
    </ThemeProvider>
  );
};

export default SimpleMultiSelect;
