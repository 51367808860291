import { FC, useEffect } from 'react';
import { orderBy } from 'natural-orderby';
import NumberInput from 'src/components/Items/input/NumberInput';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { getNames } from 'src/common/utils/others';
import { Oneway } from 'src/entity/Oneway';
import { IErrorsOnewayData } from '../index';

interface IProps {
  oneway: Oneway;
  setOneway: any;
  check: boolean;
  errors: IErrorsOnewayData;
  isEdit: boolean;
}

const OnewayFormModal: FC<IProps> = ({ oneway, setOneway, check, errors, isEdit }) => {

  const { getLocations } = useActions();
  const {
    location: { locationList },
  } = useTypedSelector((state) => state);

  useEffect(() => {
    getLocations();
  }, []);

  const sortedLocation = orderBy(
    locationList,
    [(location) => location.country, (location) => location.name],
    ['asc', 'asc']
  );

  return (
    <div className='col-2'>
      <SimpleSelect
        data={oneway.start_location}
        setData={setOneway('start_location')}
        dataArray={getNames(sortedLocation)}
        placeholder='start location'
        title='start location'
        check={check}
        error={errors.start_location}
        disabled={isEdit}
      />
      <SimpleSelect
        data={oneway.end_location}
        setData={setOneway('end_location')}
        dataArray={getNames(sortedLocation)}
        placeholder='end location'
        title='end location'
        check={check}
        error={errors.end_location}
        disabled={isEdit}
      />
      <NumberInput
        data={oneway.price}
        setData={setOneway('price')}
        placeholder='Price'
        title='Price'
        unlimit
        check={check}
        error={errors.price}
        text
      />
      <SimpleSelect
        data={oneway.currency}
        setData={setOneway('currency')}
        dataArray={['USD', 'EUR']}
        placeholder='currency'
        title='currency'
        check={check}
        error={errors.price}
      />
    </div>
  );
};

export default OnewayFormModal;