import { put, takeLatest } from "redux-saga/effects";
import { NearVanActionCreators } from "src/store/reducers/nearVan/action-creators";
import { NearVanActionEnum } from "src/store/reducers/nearVan/types";
import instance from "../set";

function* fetchGetNearVans() {
  try {
    const { data } = yield instance.get('nearVan');
    yield put(NearVanActionCreators.resultGetNearVans(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetNearVans() {
  yield takeLatest(NearVanActionEnum.REQUEST_NEARVANS, fetchGetNearVans);
}
