import { IBase } from "src/models/types";

export enum FeatureActionEnum {
  REQUEST_FEATURES = "REQUEST_FEATURES",
  RESULT_FEATURES = "RESULT_FEATURES",
  ERROR_FEATURES = "ERROR_FEATURES",

  REQUEST_FEATURE = "REQUEST_FEATURE",
  RESULT_FEATURE = "RESULT_FEATURE",

  REQUEST_ADD_FEATURE = "REQUEST_ADD_FEATURE",
  RESULT_ADD_FEATURE = "RESULT_ADD_FEATURE",

  REQUEST_UPDATE_FEATURE = "REQUEST_UPDATE_FEATURE",
  RESULT_UPDATE_FEATURE = "RESULT_UPDATE_FEATURE",

  REQUEST_DELETE_FEATURE = "REQUEST_DELETE_FEATURE",
  RESULT_DELETE_FEATURE = "RESULT_DELETE_FEATURE",
}

export interface FeatureState {
  featureList: IBase[];
  total?: number;
  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
  };
  result: {
    add?: number | string;
    delete?: number | string;
  };
}

export interface GetFeaturesAction {
  type: FeatureActionEnum.REQUEST_FEATURES;
  payload?: any;
}

export interface ResultGetFeaturesAction {
  type: FeatureActionEnum.RESULT_FEATURES;
  data: IBase[];
}

export interface ErrorFeatureAction {
  type: FeatureActionEnum.ERROR_FEATURES;
  payload?: any;
}

export interface AddFeatureAction {
  type: FeatureActionEnum.REQUEST_ADD_FEATURE;
  nameArray: IBase[];
}

export interface ResultAddFeatureAction {
  type: FeatureActionEnum.RESULT_ADD_FEATURE;
  status: number;
}
export interface UpdateFeatureAction {
  type: FeatureActionEnum.REQUEST_UPDATE_FEATURE;
  feature: IBase;
}

export interface ResultUpdateFeatureAction {
  type: FeatureActionEnum.RESULT_UPDATE_FEATURE;
  status: number;
}
export interface DeleteFeatureAction {
  type: FeatureActionEnum.REQUEST_DELETE_FEATURE;
  id: number;
}

export interface ResultDeleteFeatureAction {
  type: FeatureActionEnum.RESULT_DELETE_FEATURE;
  status: number;
}

export type FeatureAction =
  | GetFeaturesAction
  | ResultGetFeaturesAction
  | ErrorFeatureAction
  | AddFeatureAction
  | ResultAddFeatureAction
  | UpdateFeatureAction
  | ResultUpdateFeatureAction
  | DeleteFeatureAction
  | ResultDeleteFeatureAction;
