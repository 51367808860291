import { IDriverFetch, IDriver } from 'src/models/IDriver';
import {
  AddDriverAction,
  ClearDriverResultAction,
  DriverActionEnum,
  ResultAddDrivertAction,
  ResultUpdateDriverAction,
  UpdateDriverAction,
} from './types';

export const DriverActionCreator = {
  addDriver: (driver: IDriver, id: number): AddDriverAction => ({
    type: DriverActionEnum.REQUEST_ADD_DRIVER,
    driver,
    id,
  }),
  resultAddDriver: (status: number): ResultAddDrivertAction => ({
    type: DriverActionEnum.RESULT_ADD_DRIVER,
    status,
  }),
  updateDriver: (driver: IDriverFetch, id: string | number): UpdateDriverAction => ({
    type: DriverActionEnum.REQUEST_UPDATE_DRIVER,
    driver,
    id
  }),
  resultUpdateDriver: (status: number): ResultUpdateDriverAction => ({
    type: DriverActionEnum.RESULT_UPDATE_DRIVER,
    status,
  }),
  clearDriverResult: (): ClearDriverResultAction => ({
    type: DriverActionEnum.CLEAR_DRIVER_RESULT
  }),
};
