import { put, takeLatest } from 'redux-saga/effects';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import instance from '../../set';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';

function* fetchAddPartialPaymentOrder({
  price,
  currency,
  note,
  order_id
}: ReturnType<typeof OrderActionCreators.addPartialPaymentOrder>) {
  try {
    const { status } = yield instance.post(`stripe/partial_payment`, {
      data: { price, currency, note, order_id }
    });
    yield put(OrderActionCreators.resultAddPartialPaymentOrder(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddPartialPaymentOrder() {
  yield takeLatest(
    OrderActionEnum.REQUEST_ADD_PARTIAL_PAYMENT_ORDER,
    fetchAddPartialPaymentOrder
  );
}
