interface commonAction {
  status: number | string;
}
type field = "delete" | "add" | "update" | "payment" | "get" | "refund";
type getterField = "get" | "all";
interface commonState {
  isLoading: {
    [key in field]?: boolean;
  };
  result: {
    [key in field]?: string | number;
  };
}

const requestState = <T>(state: commonState & T, type: field) => ({
  ...state,
  isLoading: { ...state.isLoading, [type]: true },
  result: { ...state.result, [type]: undefined },
});
const responseState = <T>(
  state: T & commonState,
  type: field,
  action: commonAction,
  getter?: {
    [key in getterField]?: boolean;
  }
) => {
  return {
    ...state,
    isLoading: {
      ...state.isLoading,
      [type]: !action.status,
      get: getter?.get ? true : false,
      all: getter?.all ? true : false,
    },
    result: { ...state.result, [type]: action.status },
  };
};
export { requestState, responseState };
