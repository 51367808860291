import { Skeleton } from "@mui/material";

const PreviewVan: React.FC = () => {
  const amount = [0, 0, 0, 0];
  return (
    <>
      <div
        style={{
          width: `100%`,
          display: `grid`,
          gridTemplateRows: `repeat(4, 1fr)`,
          gap: `20px`,
          alignItems: `center`,
        }}
      >
        {amount.map((a, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={`100%`}
            height={281}
          />
        ))}
      </div>
    </>
  );
};

export default PreviewVan;
