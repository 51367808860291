import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const SearchOrder = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    padding: '0px',
  },
  input: {
    paddingLeft: '5px !important',
    paddingRight: '0px !important',
    marginBottom: '5px',
    position: 'relative'
  },
  'input::placeholder': {
    position: 'absolute',
    top: 2,
    fontSize: '10px',
    maxWidth: '30px',
    whiteSpace: 'pre-wrap',
  },
  a: {
    marginTop: '5px',
  },
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    'WebkitAppearance': 'none',
    margin: 0
  },
  'input[type=number]': {
    'MozAppearance': 'textfield'
  }
});

export const SmallSearchOrder = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    padding: '0px',
  },
  input: {
    paddingLeft: '5px !important',
    paddingRight: '0px !important',
    marginBottom: '5px',
    position: 'relative'
  },
  'input::placeholder': {
    fontSize: '11px',
  },
  a: {
    marginTop: '5px',
  },
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    'WebkitAppearance': 'none',
    margin: 0
  },
  'input[type=number]': {
    'MozAppearance': 'textfield'
  }
});