import { ISeasonPrice, ISeasonPriceFetch } from "src/models/ISeasonPrice";

export enum SeasonPriceActionEnum {
  REQUEST_SEASONPRICE = "REQUEST_SEASONPRICE",
  RESULT_SEASONPRICE = "RESULT_SEASONPRICE",
  ERROR_SEASONPRICE = "ERROR_SEASONPRICE",

  REQUEST_ADD_SEASONPRICE = "REQUEST_ADD_SEASONPRICE",
  RESULT_ADD_SEASONPRICE = "RESULT_ADD_SEASONPRICE",

  REQUEST_UPDATE_SEASONPRICE = "REQUEST_UPDATE_SEASONPRICE",
  RESULT_UPDATE_SEASONPRICE = "RESULT_UPDATE_SEASONPRICE",

  REQUEST_DELETE_SEASONPRICE = "REQUEST_DELETE_SEASONPRICE",
  RESULT_DELETE_SEASONPRICE = "RESULT_DELETE_SEASONPRICE",

  CLEAR_SEASONPRICE_RESULT = 'CLEAR_SEASONPRICE_RESULT'
}

export interface SeasonPriceState {
    seasonPriceList: ISeasonPriceFetch[];
    isLoading: {
        all: boolean;
        get: boolean;
        add: boolean;
        delete: boolean;
        update: boolean;
    };
    result: {
        add?: number | string;
        update?: number | string;
        delete?: number | string;
    };
}

export interface GetSeasonPriceAction {
  type: SeasonPriceActionEnum.REQUEST_SEASONPRICE;
}

export interface ResultGetSeasonPriceAction {
  type: SeasonPriceActionEnum.RESULT_SEASONPRICE;
  data: ISeasonPriceFetch[];
}

export interface ErrorSeasonPriceAction {
  type: SeasonPriceActionEnum.ERROR_SEASONPRICE;
  payload?: any;
}

export interface AddSeasonPriceAction {
  type: SeasonPriceActionEnum.REQUEST_ADD_SEASONPRICE;
  data: ISeasonPrice;
}
export interface ResultAddSeasonPriceAction {
  type: SeasonPriceActionEnum.RESULT_ADD_SEASONPRICE;
  status: number;
}

export interface UpdateSeasonPriceAction {
  type: SeasonPriceActionEnum.REQUEST_UPDATE_SEASONPRICE;
  currentSeasonPrice: ISeasonPriceFetch;
  id: string | number;
}
export interface ResultUpdateSeasonPriceAction {
  type: SeasonPriceActionEnum.RESULT_UPDATE_SEASONPRICE;
  status: number;
}
export interface DeleteSeasonPriceAction {
  type: SeasonPriceActionEnum.REQUEST_DELETE_SEASONPRICE;
  id: number;
}
export interface ResultDeleteSeasonPriceAction {
  type: SeasonPriceActionEnum.RESULT_DELETE_SEASONPRICE;
  status: number;
}

export interface ClearSeasonPriceResultAction {
  type: SeasonPriceActionEnum.CLEAR_SEASONPRICE_RESULT;
}

export type SeasonPriceAction =
  | GetSeasonPriceAction
  | ResultGetSeasonPriceAction
  | ErrorSeasonPriceAction
  | AddSeasonPriceAction
  | ResultAddSeasonPriceAction
  | UpdateSeasonPriceAction
  | ResultUpdateSeasonPriceAction
  | DeleteSeasonPriceAction
  | ResultDeleteSeasonPriceAction
  | ClearSeasonPriceResultAction;
