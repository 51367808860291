import { FC } from 'react';
import ReportTable from './Table';
import './styles.scss';

const ReportPage: FC = () => {
  return (
    <div className='report'>
      <ReportTable />
    </div>
  );
};
export default ReportPage;
