import { IPickupDetails, IPickupDetailsFetch } from "src/models/IPickupDetails";

export enum PickupDetailsActionEnum {
  REQUEST_PICKUPDETAILS = "REQUEST_PICKUPDETAILS",
  RESULT_PICKUPDETAILS = "RESULT_PICKUPDETAILS",
  ERROR_PICKUPDETAILS = "ERROR_PICKUPDETAILS",

  REQUEST_ADD_PICKUPDETAILS = "REQUEST_ADD_PICKUPDETAILS",
  RESULT_ADD_PICKUPDETAILS = "RESULT_ADD_PICKUPDETAILS",

  REQUEST_UPDATE_PICKUPDETAILS = "REQUEST_UPDATE_PICKUPDETAILS",
  RESULT_UPDATE_PICKUPDETAILS = "RESULT_UPDATE_PICKUPDETAILS",

    CLEAR_PICKUPDETAILS_RESULT = 'CLEAR_PICKUPDETAILS_RESULT'
}

export interface PickupDetailsState {
  pickupDetailsList: IPickupDetailsFetch[];
  total: number | null;
  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
  };
  result: {
    add?: number | string;
    update?: number | string;
  };
}

export interface GetPickupDetailsAction {
  type: PickupDetailsActionEnum.REQUEST_PICKUPDETAILS;
}

export interface ResultGetPickupDetailsAction {
  type: PickupDetailsActionEnum.RESULT_PICKUPDETAILS;
  data: IPickupDetailsFetch[];
}

export interface ErrorPickupDetailsAction {
  type: PickupDetailsActionEnum.ERROR_PICKUPDETAILS;
  payload?: any;
}

export interface AddPickupDetailsAction {
  type: PickupDetailsActionEnum.REQUEST_ADD_PICKUPDETAILS;
  data: IPickupDetails;
}
export interface ResultAddPickupDetailsAction {
  type: PickupDetailsActionEnum.RESULT_ADD_PICKUPDETAILS;
  status: number;
}

export interface UpdatePickupDetailsAction {
  type: PickupDetailsActionEnum.REQUEST_UPDATE_PICKUPDETAILS;
  currentPickupDetails: IPickupDetailsFetch;
  id: string | number;
}
export interface ResultUpdatePickupDetailsAction {
  type: PickupDetailsActionEnum.RESULT_UPDATE_PICKUPDETAILS;
  status: number;
}

export interface ClearPickupDetailsResultAction {
  type: PickupDetailsActionEnum.CLEAR_PICKUPDETAILS_RESULT;
}

export type PickupDetailsAction =
  | GetPickupDetailsAction
  | ResultGetPickupDetailsAction
  | ErrorPickupDetailsAction
  | AddPickupDetailsAction
  | ResultAddPickupDetailsAction
  | UpdatePickupDetailsAction
  | ResultUpdatePickupDetailsAction
  | ClearPickupDetailsResultAction;
