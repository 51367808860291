import { FC, useEffect, useState, useMemo } from "react";
import { orderBy } from "natural-orderby";
import { CircularProgress, Tooltip } from "@mui/material";
import DeleteModalForm from "src/components/Items/DeleteModalForm";
import ActionForm from "src/components/Items/ActionForm";
import Table from 'src/components/Items/table';
import SubPriceModal from "src/pages/Admin/Modals/SubPrice";
import CloneSubPriceModal from "../../Modals/SubPrice/Clone";
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import useShowSnackbar from "src/hooks/useShowSnackbar";
import { dineroFormat } from "src/common/utils/dataFormat";
import { ISubPriceFetch } from "src/models/ISubPrice";
import './styles.scss';

interface IProps {
  search: string;
}

const SubPriceTable: FC<IProps> = ({ search }) => {

  const { addSubPrice, getSubPrice, getLocations, getSublocations, clearSubPriceResult } = useActions();
  const {
    subPrice: { subPriceList, isLoading, result },
    host: { hostList },
    location: { locationList },
    sublocation: { subLocationList },
  } = useTypedSelector((state) => state);
  const sublocationsResult = useTypedSelector((state) => state.sublocation.result);
  useShowSnackbar(isLoading, result, clearSubPriceResult, 'Sub price');

  const [allSubPricesWithLocation, setAllSubPricesWithLocation] = useState<{[key: string]: ISubPriceFetch[]}>();
  const [subPricesWithLocation, setSubPricesWithLocation] = useState<{[key: string]: ISubPriceFetch[]}>();

  useEffect(() => {
    getSubPrice();
    getLocations();
  }, []);

  useEffect(() => {
    (result.add || result.update || result.delete) && (
      getSubPrice(),
      getLocations()
    );
  }, [result.add, result.update, result.delete]);

  useEffect(() => {
    getSublocations();
  }, [sublocationsResult.add]);

  const sortedLocation = orderBy(
    locationList,
    [(location) => location.country, (location) => location.name],
    ['asc', 'asc']
  );

  useEffect(() => {
    const locations = sortedLocation
      .filter((location) => location.sub_location.length)
      .map((location) => location.name);

    const prices = {} as { [key: string]: ISubPriceFetch[] };

    locations.length && locations.map((locationName) => {
      const subPrices = subPriceList.filter((price) => price.sub_location!.main_location_name === locationName);
      subPrices.length && (prices[locationName] = subPrices);
    });

    setAllSubPricesWithLocation(prices);
    setSubPricesWithLocation(prices);
  }, [locationList, subPriceList]);

  useEffect(() => {
    if (search) {
      const prices = {} as { [key: string]: ISubPriceFetch[] };
      Object.keys(allSubPricesWithLocation!).map((location) => {
        location.toLowerCase().includes(search) && (
          prices[location] = allSubPricesWithLocation![location]
        );
      });
      setSubPricesWithLocation(prices);
    } else setSubPricesWithLocation(allSubPricesWithLocation);
  }, [search]);

  const [selectedSubPrice, setSelectedSubPrice] = useState({} as ISubPriceFetch);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = (order: ISubPriceFetch) => {
    setOpenEdit(true);
    setSelectedSubPrice(order);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = (order: ISubPriceFetch) => {
    setOpenDelete(true);
    setSelectedSubPrice(order);
  };

  const [openClone, setOpenClone] = useState(false);
  const [cloneLocation, setCloneLocation] = useState('');
  const [selectLocation, setSelectLocation] = useState('');

  const handleOpenClone = (location: string) => {
    setOpenClone(true);
    setCloneLocation(location);
  };

  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const [deleteLocation, setDeleteLocation] = useState(0);

  const handleOpenDeleteAll = (location: string) => {
    setOpenDeleteAll(true);
    const locationId = sortedLocation.find((l) => l.name === location)?.id;
    setDeleteLocation(locationId!);
  };

  useEffect(() => {
    if (cloneLocation && selectLocation) {
      const subprices = subPricesWithLocation![cloneLocation].map((subPrice) => {
        const sublocation = subLocationList.find((sublocation) => (
          sublocation.main_location_name === selectLocation
          && sublocation.name === subPrice.location
        ));
        return {
          'host_id': subPrice.host_id,
          'location': subPrice.location,
          'price': subPrice.price,
          'currency': subPrice.currency,
          'isDefault': subPrice.isDefault,
          'sub_location_id': sublocation!.id
        };
      });
      subprices.length && addSubPrice(subprices);
      setCloneLocation('');
      setSelectLocation('');
    }
  }, [subLocationList]);

  const columns = useMemo(
    () => [
      // {
      //   Header: 'Host',
      //   accessor: 'host_id',
      //   Cell: ({
      //     row: {
      //       original: { host_id },
      //     },
      //   }: any) => {
      //     const hostName = hostList.find((h) => h.id === host_id);
      //     return <>{`${hostName!.first_name} ${hostName!.last_name}`}</>;
      //   },
      // },
      {
        Header: 'Sublocation',
        accessor: 'location',
        Cell: ({
          row: {
            original: { location },
          },
        }: any) => {
          return <>{location}</>;
        },
        width: '70%' 
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({
          row: {
            original: { price, currency },
          },
        }: any) => {
          return <>{dineroFormat(price, currency)}</>;
        },
        width: '10%' 
      },
      {
        accessor: 'actions',
        Cell: ({ row: { original } }: any) => (
          <>
            <div className='flex_CC' style={{ justifyContent: 'right' }}>
              <Tooltip title='Edit sub price'>
                <>
                  <ActionForm.IconSet
                    title='sub price'
                    classWrapper='action-wrapper'
                    onEdit={() => handleOpenEdit(original)}
                    onDelete={() => handleOpenDelete(original)}
                  />
                </>
              </Tooltip>
            </div>
          </>
        ),
        width: '10%' 
      },
    ],
    [subPriceList]
  );

  return (
    <>
      <SubPriceModal
        open={openEdit}
        editSubPrice={selectedSubPrice}
        handleClose={() => setOpenEdit(false)}
      />
      <DeleteModalForm
        id={selectedSubPrice.id}
        type="sub price"
        handleClose={() => setOpenDelete(false)}
        open={openDelete}
      />
      <DeleteModalForm
        id={deleteLocation}
        type="sub prices"
        handleClose={() => setOpenDeleteAll(false)}
        open={openDeleteAll}
      />
      <CloneSubPriceModal
        open={openClone}
        locations={sortedLocation}
        cloneLocation={cloneLocation}
        handleClose={() => setOpenClone(false)}
        selectLocation={selectLocation}
        setSelectLocation={setSelectLocation}
      />
      {isLoading.all ? (
        <CircularProgress size={200}
          style={{ position: 'fixed', top: '40%', left: '45%', zIndex: 100 }}
        />
      ) : (
        subPricesWithLocation ? (
          <div className='sub-price-table'>
            {Object.keys(subPricesWithLocation).map((location) => (
              <div className='sub-price-table__item'>
                <div className='sub-price-table__item__header'>
                  <div className='vans-header__title'>{location}</div>
                  <div className="sub-price-table__item__header__actions">
                    <div 
                      onClick={() => handleOpenClone(location)} 
                      className="mini-sign" 
                      children="Clone" 
                    />
                    <div
                      onClick={() => handleOpenDeleteAll(location)}
                      className="mini-sign"
                      children="Delete"
                    />
                  </div>
                </div>
                <div className='order-table'>
                  <Table.Base
                    data={subPricesWithLocation[location]}
                    columns={columns}
                    class='react-table'
                    total={0}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : null
      )}
    </>
  );
};
export default SubPriceTable;
