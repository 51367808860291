import { OrderActionCreators } from "src/store/reducers/order/action-creators";
import { put, takeLatest } from "redux-saga/effects";
import { OrderActionEnum } from "src/store/reducers/order/types";
import instance from "src/sagas/set";


function* fetchShowContract({
  key,
}: ReturnType<typeof OrderActionCreators.showContract>) {
  try {
    const id = key.split('orders/')[1];
    const { data } = yield instance.get(`contract/show/${id}`);
    yield put(OrderActionCreators.resultShowContract(data));
  } catch (error: any) {
    console.log(error);
  }
}
export function* actionWatcherShowContract() {
  yield takeLatest(OrderActionEnum.REQUEST_SHOW_CONTRACT, fetchShowContract);
}
