import { FC } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { orderBy } from 'natural-orderby';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { TextField } from '@mui/material';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import SimpleMultiSelect from 'src/components/Items/select/SimpleMultiSelect';
import CustomCheckbox from 'src/components/Items/button/CustomCheckbox';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { getNames } from 'src/common/utils/others';
import { SeasonPrice } from 'src/entity/SeasonPrice';
import { colorSet, typeForm } from 'src/theme';
import { ISeasonPriceFetch } from 'src/models/ISeasonPrice';

interface IProps {
  seasonPrice: ISeasonPriceFetch | SeasonPrice;
  setSeasonPrice: any;
  check: boolean;
  isAll: boolean;
  setIsAll: any;
  isEdit: boolean;
}

const SeasonPriceFormModal: FC<IProps> = ({ 
  seasonPrice, 
  setSeasonPrice, 
  check,
  isAll,
  setIsAll,
  isEdit
}) => {

  const locationList = useTypedSelector((state) => state.location.locationList);
  const sortedLocation = orderBy(
    locationList,
    [(location) => location.country, (location) => location.name],
    ['asc', 'asc']
  );

  const helperError = (date: string) => {
    if (check) {
      if (!date) return true;
      else return false;
    }
  };

  return (
    <div style={{display: 'grid', gap: '30px', marginBottom: '20px'}}>
      <div>
        {isEdit ? (
          <SimpleSelect
            data={seasonPrice.location as string}
            setData={setSeasonPrice('location')}
            dataArray={getNames(sortedLocation)}
            placeholder='location'
            title='location'
            check={check}
          />
        ) : (
          <>
            <div className='filter__title'>Locations</div>
            <SimpleMultiSelect
              data={seasonPrice.location as string[]}
              setData={setSeasonPrice('location')}
              placeholder='Locations'
              dataArray={getNames(sortedLocation)}
              check={check}
              menuHeight={'220px'}
              maxWidth={'370px'}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomCheckbox
                onChange={() => setIsAll(!isAll)}
                checked={isAll}
                name={'select all locations'}
              />
              <p className='filter__title'
                style={{ marginBottom: 0, marginLeft: '10px' }}
              >
                Select All Locations
              </p>
            </div></>
        )}
      </div>
      <div style={{display: 'flex', gap: '10px'}}>
        <div className='data-picker'>
          <div className='filter__title'>Date From</div>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <DatePicker
              value={seasonPrice.start_date}
              onChange={setSeasonPrice('start_date')}
              minDate={moment(new Date())}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    autoComplete='off'
                    variant={typeForm}
                    error={helperError(seasonPrice.start_date)}
                    sx={{
                      input: {
                        '::placeholder': {
                          color:
                            check && !seasonPrice.start_date
                              ? colorSet.primaryRed
                              : colorSet.primaryGrey,
                          opacity: 1,
                        },
                      },
                      '.MuiFormHelperText-root': {
                        display: 'none',
                      },
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </div>
        <div className='data-picker'>
          <div className='filter__title'>Date Drop</div>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <DatePicker
              value={seasonPrice.end_date}
              onChange={setSeasonPrice('end_date')}
              minDate={seasonPrice.start_date
                ? seasonPrice.start_date
                : moment(new Date())
              }
              defaultCalendarMonth={seasonPrice.start_date
                ? seasonPrice.start_date
                : moment(new Date())
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    autoComplete='off'
                    variant={typeForm}
                    error={helperError(seasonPrice.end_date)}
                    sx={{
                      input: {
                        '::placeholder': {
                          color:
                            check && !seasonPrice.end_date
                              ? colorSet.primaryRed
                              : colorSet.primaryGrey,
                          opacity: 1,
                        },
                      },
                      '.MuiFormHelperText-root': {
                        display: 'none',
                      },
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <SimpleInput
        data={seasonPrice.rate === 0 ? '' : seasonPrice.rate}
        setData={setSeasonPrice('rate')}
        type='number'
        title={'Rate'}
        check={check}
        length={10}
        isHelperText={true}
        adornment={'%'}
      />
    </div>
  );
};

export default SeasonPriceFormModal;
