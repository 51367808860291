import { ICalendarFilter } from 'src/models/IOrder';
import {
  CalendarActionEnum,
  FetchCalendarDate,
  GetCalendarAction,
  ResultGetCalendarAction,
  ResultUpdateCalendarAction,
  UpdateCalendarAction,
} from './types';

export const CalendarActionCreators = {
  getCalendar: (filter: ICalendarFilter): GetCalendarAction => ({
    type: CalendarActionEnum.REQUEST_CALENDAR,
    filter
  }),
  resultGetCalendar: (data: FetchCalendarDate): ResultGetCalendarAction => ({
    type: CalendarActionEnum.RESULT_CALENDAR,
    data,
  }),
  updateCalendar: (order: {id: number, van_id: number}, filter?: ICalendarFilter): UpdateCalendarAction => ({
    type: CalendarActionEnum.REQUEST_UPDATE_CALENDAR,
    order,
    filter
  }),

  resultUpdateCalendar: (
    data: FetchCalendarDate
  ): ResultUpdateCalendarAction => ({
    type: CalendarActionEnum.RESULT_UPDATE_CALENDAR,
    data,
  }),
};
