import { put, takeLatest } from 'redux-saga/effects';
import instance from 'src/sagas/set';
import { OrderNotificationActionCreators } from 'src/store/reducers/order/action-creators';
import { OrderNotificationActionEnum } from 'src/store/reducers/order/types';

function* fetchCopySms({
  orderId,
  typeNotification,
}: ReturnType<typeof OrderNotificationActionCreators.copySms>) {
  try {
    const { status, data } = yield instance.post('sms/copy', {
      id: orderId,
      type: typeNotification,
    });
    yield put(
      OrderNotificationActionCreators.resultCopySms(status, data)
    );
  } catch (e) {
    console.log(e);
    yield put(
      OrderNotificationActionCreators.resultCopySms(400)
    );
  }
}

export function* actionWatcherCopySms() {
  yield takeLatest(OrderNotificationActionEnum.REQUEST_COPY_SMS, fetchCopySms);
}
