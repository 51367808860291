import { useState, useEffect, FC } from 'react';
import { DateTime } from 'luxon';
import { Button, LinearProgress, Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import SelectBaseOnId from 'src/components/Items/select/SelectBaseOnId';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import OldReportTable from './Old';
import NewReportTable from './New';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroFormat, dineroIsPositive } from 'src/common/utils/dataFormat';
import { HostReportFilter } from 'src/store/reducers/report/host/types';
import './styles.scss';

export interface IOrderFilters {
  page: number;
  take: number;
  sorted: 'newest' | 'next out' | 'today out' | 'deleted';
}

const initFilter: HostReportFilter = {
  host_id: [],
  period: {
    start: '',
    end: '',
  },
};

const ReportTable: FC = () => {
  const { getHostReport, getNewHostReport, getHosts } = useActions();
  const {
    hostReport: { orderList, isLoading, statistic, link, result },
    host: { hostList },
    auth: { role },
  } = useTypedSelector((state) => state);

  const sortedHosts = hostList.sort(function (a, b) {
    const nameA = a.first_name.toLowerCase(),
      nameB = b.first_name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  const [filter, setFilter] = useState(initFilter);
  const [month, setMonth] = useState('');
  const [allYear, setAllYear] = useState('');

  useEffect(() => {
    getHosts();
  }, []);

  const setHostId = (hostId: number) => {
    setFilter({ ...filter, host_id: [hostId] });
  };
  const year = DateTime.now().get('year');

  const monthNames = [
    'YTD',
    `December ${year - 1}`,
    `January ${year}`,
    `February ${year}`,
    `March ${year}`,
    `April ${year}`,
    `May ${year}`,
    `June ${year}`,
    `July ${year}`,
    `August ${year}`,
    `September ${year}`,
    `October ${year}`,
    `November ${year}`,
    `December ${year}`,
  ];

  const yearNames = ['2023'];

  const setMonthFilter = (value: string) => {
    setMonth(value);
    setAllYear('');
    let monthNumber = monthNames.indexOf(value);
    if (monthNumber === 1) {
      monthNumber = 13;
    }
    const start =
      value === 'YTD'
        ? DateTime.local(new Date().getFullYear(), 1).startOf('month').toISO()
        : DateTime.local(+value.split(' ')[1], monthNumber - 1)
            .startOf('month')
            .toISO();
    const end =
      value === 'YTD'
        ? DateTime.local(new Date().getFullYear(), new Date().getMonth() + 1)
            .endOf('month')
            .toISO()
        : DateTime.local(+value.split(' ')[1], monthNumber - 1)
            .endOf('month')
            .toISO();
    setFilter({ ...filter, period: { start, end } });
  };

  const setYearFilter = (value: string) => {
    setMonth('');
    setAllYear(value);
    const start = DateTime.local(+value, 1).startOf('month').toISO();
    const end = DateTime.local(+value, 12).endOf('month').toISO();
    setFilter({ ...filter, period: { start, end } });
  };

  let reportView;
  if (orderList.length) {
    if (result.old === 200) {
      reportView = [
        {
          title: 'Bookings',
          value: orderList.length,
          column: 'one',
          color: '',
        },
        {
          title: 'SR Collected',
          value: dineroFormat(statistic.fee!, 'USD'),
          column: 'seven',
          color: '',
        },
        {
          title: 'Host Collected',
          value: dineroFormat(statistic.take!, 'USD'),
          column: 'eight',
          color: '',
        },
        {
          title: 'Total Sale',
          value: dineroFormat(statistic._sum.price as number, 'USD'),
          column: 'nine',
          color: '',
        },
        {
          title: '80% for Host',
          value: dineroFormat(statistic.got!, 'USD'),
          column: 'ten',
          color: '',
        },
        {
          title: '20% for SR',
          value: dineroFormat(statistic.fee!, 'USD'),
          column: 'eleven',
          color: '',
        },
        {
          title: 'Running Budget:',
          subtitle: 'Red: U owe SR',
          secSubtitle: 'Green: SR owes U',
          value: dineroFormat(statistic.offset!, 'USD'),
          column: 'twelve',
          color: `${dineroIsPositive(statistic.offset!) ? 'green' : 'red'}`,
        },
      ];
    } else if (result.new === 200) {
      reportView = [
        {
          title: 'Bookings',
          value: orderList.length,
          column: 'one',
          color: '',
        },
        {
          title: 'Total Price',
          value: dineroFormat(statistic._sum.price!, 'USD'),
          column: 'seven',
          color: '',
        },
        {
          title: 'SR Got',
          value: dineroFormat(statistic.srTotal!, 'USD'),
          column: 'eight',
          color: '',
        },
        {
          title: 'Host Got',
          value: dineroFormat(statistic.hostTotal as number, 'USD'),
          column: 'nine',
          color: '',
        },
        {
          title: 'Hosts 80%',
          value: dineroFormat(statistic.hostTotal as number, 'USD'),
          column: 'ten',
          color: '',
        },
        {
          title: 'Running Total',
          value: dineroFormat(statistic.cashFlowTotal as number, 'USD'),
          column: 'eleven',
          color: `${
            dineroIsPositive(statistic.cashFlowTotal!) ? 'green' : 'red'
          }`,
        },
        {
          title: 'Fee Total',
          value: dineroFormat(statistic.stripeFeeTotal as number, 'USD'),
          column: 'twelve',
          color: '',
        },
      ];
    }
  }

  const getOldReport = () => {
    if (filter.host_id[0] === -1) {
      getHostReport({ ...filter, host_id: sortedHosts.map((host) => host.id) });
    } else {
      getHostReport(filter);
    }
  };

  const getNewReport = () => {
    if (filter.host_id[0] === -1) {
      getNewHostReport({
        ...filter,
        host_id: sortedHosts.map((host) => host.id),
      });
    } else {
      getNewHostReport(filter);
    }
  };

  return (
    <>
      <div className='report-table__filter'>
        <div>
          {role === 'admin' && (
            <SelectBaseOnId
              dataId={filter.host_id[0]}
              setDataId={setHostId}
              propsShow={['first_name', 'last_name']}
              dataArray={[{ first_name: 'All', id: -1 }, ...sortedHosts]}
              placeholder='Select host'
              variant='outlined'
              style={{ width: '200px' }}
            />
          )}
          <SimpleSelect
            dataArray={monthNames}
            variant='outlined'
            setData={setMonthFilter}
            data={month}
            placeholder='date'
            style={{ width: '200px' }}
          />
          <SimpleSelect
            dataArray={yearNames}
            variant='outlined'
            setData={setYearFilter}
            data={allYear}
            placeholder='year'
            style={{ width: '100px' }}
          />
          {link && (
            <Tooltip title='Download report'>
              <a href={`${link}`} target='_blank'>
                <Button>
                  <GetAppIcon />
                </Button>
              </a>
            </Tooltip>
          )}
        </div>

        <div className='report-table__filter__buttons'>
          {role === 'admin' && (
            <Button variant='contained' onClick={getNewReport}>
              Generate New Report
            </Button>
          )}
          <Button variant='contained' onClick={getOldReport}>
            Generate Old Report
          </Button>
        </div>
      </div>

      {orderList.length && Array.isArray(reportView) ? (
        <>
          <div className='report-table'>
            {result.old === 200 && <OldReportTable />}
            {result.new === 200 && <NewReportTable />}
          </div>
          <div className='report__card col-10'>
            {reportView.map(
              ({ title, subtitle, secSubtitle, value, column, color }) => {
                return (
                  <div
                    data-title={title}
                    key={title}
                    className={`report__card__item report__card__item__${column}`}
                  >
                    <div>
                      <div className='report__card__item__title'>{title}</div>
                      <div className='report__card__item__subtitle'>
                        {subtitle}
                      </div>
                      <div className='report__card__item__subtitle'>
                        {secSubtitle}
                      </div>
                    </div>
                    <div className={`report__card__item__value__${color}`}>
                      {value}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </>
      ) : isLoading.old || isLoading.new ? (
        <LinearProgress />
      ) : (
        <div className='report__card__item__title__empty'>
          There are no bookings
        </div>
      )}
    </>
  );
};

export default ReportTable;
