import { put, takeLatest } from 'redux-saga/effects';
import instanceNoSnackbar from 'src/sagas/setNoSnackbar';
import { OrderNotificationActionCreators } from 'src/store/reducers/order/action-creators';
import { OrderNotificationActionEnum } from 'src/store/reducers/order/types';

function* fetchGetSms({
  orderId,
  typeNotification,
}: ReturnType<typeof OrderNotificationActionCreators.sendSms>) {
  try {
    const { status } = yield instanceNoSnackbar.post(`sms`, {
      id: orderId,
      type: typeNotification,
    });
    yield put(
      OrderNotificationActionCreators.resultSendSms(status)
    );
  } catch (e) {
    console.log(e);
    yield put(
      OrderNotificationActionCreators.resultSendSms(400)
    );
  }
}

export function* actionWatcherGetSms() {
  yield takeLatest(OrderNotificationActionEnum.REQUEST_SMS, fetchGetSms);
}
