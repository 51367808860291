import { put, takeLatest } from 'redux-saga/effects';
import { SubPriceActionEnum } from 'src/store/reducers/subPrice/types';
import { SubPriceActionCreators } from 'src/store/reducers/subPrice/action-creators';
import instance from '../set';

function* fetchUpdateSubPrice({
  currentSubPrice, id
}: ReturnType<typeof SubPriceActionCreators.updateSubPrice>) {
  try {
    const { status }: Response = yield instance.patch(
      `priceForSub/${id}`,
      {'data': currentSubPrice}
    );
    yield put(SubPriceActionCreators.resultUpdateSubPrice(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherUpdateSubPrice() {
  yield takeLatest(SubPriceActionEnum.REQUEST_UPDATE_SUBPRICE, fetchUpdateSubPrice);
}