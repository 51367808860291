import { IOneway, IOnewayFetch } from "src/models/IOneway";

export enum OnewayActionEnum {
  REQUEST_ONEWAYS = "REQUEST_ONEWAYS",
  RESULT_ONEWAYS = "RESULT_ONEWAYS",
  ERROR_ONEWAYS = "ERROR_ONEWAYS",

  REQUEST_ADD_ONEWAY = "REQUEST_ADD_ONEWAY",
  RESULT_ADD_ONEWAY = "RESULT_ADD_ONEWAY",

  REQUEST_UPDATE_ONEWAY = "REQUEST_UPDATE_ONEWAY",
  RESULT_UPDATE_ONEWAY = "RESULT_UPDATE_ONEWAY",

  CLEAR_ONEWAY_RESULT = 'CLEAR_ONEWAY_RESULT'
}

export interface OnewayState {
  onewaysList: IOnewayFetch[];
  total: number | null;
  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
  };
  result: {
    add?: number | string;
    update?: number | string;
  };
}

export interface GetOnewaysAction {
  type: OnewayActionEnum.REQUEST_ONEWAYS;
}

export interface ResultGetOnewaysAction {
  type: OnewayActionEnum.RESULT_ONEWAYS;
  data: IOnewayFetch[];
}

export interface ErrorOnewayAction {
  type: OnewayActionEnum.ERROR_ONEWAYS;
  payload?: any;
}

export interface AddOnewayAction {
  type: OnewayActionEnum.REQUEST_ADD_ONEWAY;
  data: IOneway;
}
export interface ResultAddOnewayAction {
  type: OnewayActionEnum.RESULT_ADD_ONEWAY;
  status: number;
}

export interface UpdateOnewayAction {
  type: OnewayActionEnum.REQUEST_UPDATE_ONEWAY;
  currentOneway: IOnewayFetch;
  id: string | number;
}
export interface ResultUpdateOnewayAction {
  type: OnewayActionEnum.RESULT_UPDATE_ONEWAY;
  status: number;
}

export interface ClearOnewayResultAction {
  type: OnewayActionEnum.CLEAR_ONEWAY_RESULT;
}

export type OnewayAction =
  | GetOnewaysAction
  | ResultGetOnewaysAction
  | ErrorOnewayAction
  | AddOnewayAction
  | ResultAddOnewayAction
  | UpdateOnewayAction
  | ResultUpdateOnewayAction
  | ClearOnewayResultAction;
