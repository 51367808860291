import { FC, useEffect, useState, ChangeEvent } from 'react';
import { Backdrop, Button, Modal } from '@mui/material';
import SeasonPriceFormModal from './SeasonPriceForm';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import { SeasonPrice } from "src/entity/SeasonPrice";
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { getNames } from 'src/common/utils/others';
import { ISeasonPriceFetch } from 'src/models/ISeasonPrice';

interface IProps {
  open: boolean;
  handleClose: () => void;
  editSeasonPrice?: ISeasonPriceFetch;
}

export type IErrorsSeasonPriceData = {
  location?: string | undefined;
  start_date?: string | undefined;
  end_date?: string | undefined;
  rate?: number | undefined;
};

const SeasonPriceModal: FC<IProps> = ({ open, handleClose, editSeasonPrice }) => {

  const { addSeasonPrice, updateSeasonPrice, getLocations } = useActions();
  const locationList = useTypedSelector((state) => state.location.locationList);
  const [seasonPrice, setSeasonPrice] = useState<ISeasonPriceFetch | SeasonPrice>(new SeasonPrice());
  const [isAllLocations, setIsAllLocations] = useState(false);
  const [disable, setDisable] = useState(true);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (editSeasonPrice !== undefined) {
      setSeasonPrice(editSeasonPrice);
    }
  }, [editSeasonPrice]);

  useEffect(() => {
    isAllLocations ? (
      setSeasonPrice({
        ...seasonPrice,
        location: getNames(locationList)
      })
    ) : (
      setSeasonPrice({
        ...seasonPrice,
        location: []
      })
    );
  }, [isAllLocations]);

  const handleInput = (name: string) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setSeasonPrice({
        ...seasonPrice,
        [name]: (name === 'location' && editSeasonPrice === undefined) ?
          event.target.value : event
      });
    };

  const validateInputs = (): boolean => {
    const location = seasonPrice.location.length ? true : false;
    return [location, seasonPrice.start_date, seasonPrice.end_date].every(
      (el) => el
    );
  };

  useEffect(() => {
    JSON.stringify(editSeasonPrice) === JSON.stringify(seasonPrice) ?
      setDisable(true) : setDisable(false);
  }, [seasonPrice]);

  const handleCloseModal = () => {
    editSeasonPrice ? setSeasonPrice(editSeasonPrice) : setSeasonPrice(new SeasonPrice());
    setCheck(false);
    handleClose();
  };

  const handleSubmit = () => {
    setCheck(true);
    if (validateInputs()) {
      setSeasonPrice(new SeasonPrice());
      setCheck(false);
      setIsAllLocations(false);
      handleCloseModal();
      if (editSeasonPrice) {
        const updatedSeasonPrice = seasonPrice;
        for (const key in seasonPrice) {
          (seasonPrice[key as keyof typeof seasonPrice] === editSeasonPrice[key as keyof typeof editSeasonPrice] || 
            seasonPrice[key as keyof typeof seasonPrice] === ''
          ) && delete updatedSeasonPrice[key as keyof typeof updatedSeasonPrice];
          updatedSeasonPrice.rate && (updatedSeasonPrice.rate = Number(updatedSeasonPrice.rate));
        }
        updateSeasonPrice(updatedSeasonPrice as ISeasonPriceFetch, editSeasonPrice.id);
      } else addSeasonPrice({...seasonPrice, 'rate': Number(seasonPrice.rate)});
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal' style={{width: '450px'}}>
          <div className='modal__title'>{editSeasonPrice ? 'Edit Season Price' : 'Add Season Price'}</div>
          <SeasonPriceFormModal 
            seasonPrice={seasonPrice} 
            setSeasonPrice={handleInput} 
            check={check} 
            isAll={isAllLocations}
            setIsAll={setIsAllLocations}
            isEdit={editSeasonPrice ? true : false}
          />
          <div className='col-2'>
            <SecondTypeButton
              children='Cancel'
              onClick={handleCloseModal}
              sx={{ marginTop: '30px' }}
            />
            <Button
              sx={{ marginTop: 3.5 }}
              variant='contained'
              onClick={handleSubmit}
              children={editSeasonPrice ? 'Edit Season Price' : 'Add Season Price'}
              disabled={editSeasonPrice ? disable : false}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
export default SeasonPriceModal;
