import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

const colorSet = {
  primaryGreen: '#c1ff45',
  primaryRed: '#d24057',
  primaryBiolet: '#c04fff',
  primaryBlue: '#151c97',
  primaryYellow: '#ffff45',
  primaryBlack: '#4b566b',
  primaryPink: '#FF45AE',
  primaryDarkBlack: '#091b3d',
  primaryLightBlack: '#7d879c',
  primaryWhite: '#ffffff',
  primaryGrey: '#ccc',
  primaryLightGrey: 'rgba(204, 204, 204, 0.15)',
  primaryOrange: '#f08e26',
  secondaryGrey: '#fafbfe',
  secondaryGreen: '#01c38d',
  thirdGreen: '#39db39',
  secondaryPink: '#FF99D3',
  opacityPrimaryRed: '#d2405621',
  opacitySecondaryGreen: '#01c38c18',
  palePink: '#edd4c2',
  primaryBabyblue: '#89CFF0',
  primaryShadow: `
    0 0 0 0.5px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.1),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07)
  `,
};
const mainColor = colorSet.primaryBlack;
const secondColor = colorSet.primaryGreen;
const typeForm = 'standard';

const multiSelectItemStyle = {
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: 0,
  margin: '0 3px',
  height: '33px',
  backgroundColor: colorSet.primaryWhite,
  border: `1px solid ${colorSet.primaryGrey} !important`,
  borderRadius: `3px`,
};

const toolTipStyle = {
  borderRadius: '5px',
  bgcolor: colorSet.primaryWhite,
  padding: '10px',
  boxShadow: colorSet.primaryShadow,
};

const theme = createTheme({
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: secondColor,
    },
  },

  typography: {
    fontFamily: 'inherit',
    fontSize: 14,
    h1: {
      fontWeight: 800,
      fontSize: '24px',
      marginBottom: '10px',
      display: 'inline-block',
      '@media (max-width: 1200px)': {
        fontSize: '20px',
      },
    },
    h2: {
      fontWeight: 600,
      fontSize: '16px',
      marginBottom: '10px',
    },
    h3: {
      textTransform: 'uppercase',
      fontWeight: 600,
      fontSize: '16px',
      marginBottom: '20px',
      '@media (max-width: 1200px)': {
        marginBottom: '14px',
      },
    },
    h4: {
      fontWeight: 600,
      fontSize: '16px',
      marginBottom: '15px',
      '@media (max-width: 1200px)': {
        marginBottom: '14px',
      },
    },
    subtitle1: {
      wordWrap: 'break-word',
    },
    subtitle2: {
      lineHeight: '155%',
      wordWrap: 'break-word',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#4b566b',
          fontWeight: 500,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: '40px',
          fontFamily: 'inherit',
          input: {
            padding: '5px 10px',
            transform: 'translateY(2px)',
            boxSizing: 'border-box',
          },
          ['& . {outlinedInputClasses.notchedOutline}']: {
            border: '1px solid #E3E3EA',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '8px',
          transform: 'translateY(2px)',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&::before': {
            backgroundColor: 'rgba(0, 0, 0, 0)'
          }
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          overflowY: 'scroll',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 3,
          '@media (max-width: 991px)': {
            padding: `0 6px`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '6px 20px',
          background: secondColor,
          borderRadius: '4px',
          textTransform: 'none',
          fontWeight: 700,
          fontSize: '14px',
          color: mainColor,
          boxShadow: 'none',
          textWrap: 'nowrap',
          border: '1px solid rgba(75, 86, 107, 1)',
          '.MuiSvgIcon-root ': { fill: mainColor },
          ':active': { backgroundColor: secondColor },
          ':focus': {
            backgroundColor: secondColor,
          },
          ':hover': {
            backgroundColor: secondColor,
          },
          '@media (max-width: 991px)': {
            padding: '5px 18px',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-disabled': {
            'svg': { fill: '#7d879c5c' }
          }
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          svg: { fill: '#c1ff45' },
          '&.Mui-checked': { 'svg': { fill: '#c1ff45' } }
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: colorSet.primaryLightBlack,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colorSet.primaryRed,
          position: 'absolute',
          right: 0,
          bottom: -10,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: '40px',
          width: '100%',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-3px !important',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          Button: {
            display: 'inline-flex',
            background: 'transparent',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0',
          ':last-child': {
            padding: '0',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          borderRadius: '3px',
          padding: 0,
          paddingTop: 5,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          color: mainColor,
          fontSize: '14px',
          borderBottom: '1px solid #E3E3EA',
          padding: '8px 10px',
          ':last-child': {
            borderBottom: 'none',
          },
          ':first-of-type': {
            borderTop: 'none',
          },
          '@media (max-width: 991px)': {
            minHeight: 'unset',
            height: '37px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '3px',
          boxSizing: 'border-box',
          border: '1px solid #E3E3EA',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiSwitch-switchBase': { color: '#e4e4e4' },
          '&.MuiSwitch-switchBase.Mui-checked': { color: colorSet.primaryGreen },
          '&.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': { backgroundColor: '#ccc !important' },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          '.MuiButtonBase-root': {
            marginRight: 0,
          },
          p: {
            fontSize: '20px',
            fontWeight: '700',
            fontFamily: 'initial',
            marginRight: '10px'
          }
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&::before': {
            borderBottom: `1px solid ${colorSet.primaryGrey} !important`,
          },
        },
        input: {
          fontWeight: 500,
          fontSize: '14px',
          padding: '8px',
          color: mainColor,
          minHeight: 'inherit',
          transform: 'translateY(2px)',
          '&:focus': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: colorSet.primaryGreen,
          right: '-5px',
          height: '13px',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: colorSet.primaryGreen,
          '&:hover': {
            backgroundColor: '#9eee00',
          },
          '&.MuiSpeedDialAction-fab': {
            margin: '3px',
            backgroundColor: '#cffc78',
          }
        },
      },
    }
  },
});

const themeMultiSelect = createTheme(
  deepmerge(theme, {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            // maxHeight: `260px !important`,
            maxHeight: 'calc(100% - 80px) !important',
            marginTop: 3,
            backgroundColor: colorSet.primaryWhite,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            padding: 3,
          },
        },
      },
    },
  })
);

export {
  colorSet,
  mainColor,
  secondColor,
  multiSelectItemStyle,
  toolTipStyle,
  typeForm,
  themeMultiSelect,
};
export default theme;
