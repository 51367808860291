import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { dineroFormat, dineroGetPercentage } from 'src/common/utils/dataFormat';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import PreviewExtra from 'src/components/Items/previewPages/PreviewExtra';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IExtra, IOrderFetch } from 'src/models/IOrder';
import './styles.scss';
import { DateTime } from 'luxon';

interface IProps {
  orderData: IOrderFetch;
  handleClose: () => void;
}

const Cart: FC<IProps> = ({ orderData, handleClose }) => {
  const { updateOrder } = useActions();
  const {
    fee_list,
    oneway_details,
    isOneway,
    location,
    end_location,
    start_date,
    end_date,
  } = orderData;
  const { enqueueSnackbar } = useSnackbar();
  const [fee, setFee] = useState(fee_list);
  const {
    extraList,
    isLoading: { all },
  } = useTypedSelector((state) => state.extra);

  const [extras, setExtras] = useState(extraList);

  const isInclude = (name: string, arr: IExtra[]) => {
    return arr.map((i) => i.name).includes(name);
  };

  const handleChangeExtra = (extra: IExtra) => {
    const existOrder = fee.find((ex) => ex.id === extra.id);
    const timeObject = DateTime.fromISO(end_date)
      .diff(DateTime.fromISO(start_date), ['days', 'hours'])
      .toObject();
    const { hours, days } = timeObject;
    if (extra.name === 'Seasonal pricing') {
      isInclude(extra.name, fee)
        ? (extra.price = 0)
        : (extra.price = dineroGetPercentage(orderData.price, 15));
    } else if (extra.name === 'Longer Rental Discount') {
      if (isInclude(extra.name, fee)) {
        extra.price = 0;
      } else if (days) {
        if (days === 7) {
          extra.price = -dineroGetPercentage(orderData.price, 5);
        } else if (days > 7 && days <= 21) {
          extra.price = -dineroGetPercentage(orderData.price, 10);
        } else if (days > 21 && days <= 30) {
          extra.price = -dineroGetPercentage(orderData.price, 20);
        } else if (days > 30) {
          extra.price = -dineroGetPercentage(orderData.price, 40);
        }
      }
    }

    existOrder
      ? setFee(fee.filter((ext) => ext.id !== existOrder?.id))
      : setFee([...fee, extra]);
    enqueueSnackbar("Updating order's fee...");
  };

  const handleAddChildSeat = (extra: IExtra) => setFee([...fee, extra]);

  let changedExtras: IExtra[];
  const handleRemoveAdditionalSeat = (extra: IExtra) => {
    (changedExtras = fee),
      changedExtras.splice(fee.indexOf(extra as IExtra), 1),
      updateOrder({ id: orderData.id, fee_list: changedExtras });
    enqueueSnackbar("Updating order's fee...");
  };

  useMemo(() => {
    (fee !== fee_list) && updateOrder({ id: orderData.id, fee_list: fee });
  }, [fee]);
  useMemo(() => {
    if (isOneway) {
      const extras = extraList.map((extra) => {
        if (extra.name === 'One-way') {
          return {
            ...extra,
            price: oneway_details.price,
            title: extra.title.includes(oneway_details.end_location)
              ? extra.title
              : `${extra.title} ${oneway_details.end_location}`,
          };
        } else {
          return extra;
        }
      });
      setExtras(extras);
    }
  }, [location, end_location, isOneway]);
  const onSubmit = () => {
    handleClose();
  };

  return (
    <div className='order__store'>
      {!all ? (
        <>
          {extras.map((extra) => {
            const { id, logo, name, title, description, price, currency } =
              extra;
            return (
              <div className='extra-card' key={extra.id}>
                <div className='extra-card__logo'>
                  <img src={logo} alt='logo' loading='lazy' />
                </div>
                <div className='extra-card__info'>
                  <div className='extra-card__info__name'>{name}</div>
                  <div className='extra-card__info__title'>{title}</div>
                  <div
                    children={description}
                    className='extra-card__info__description'
                  />
                </div>
                <div className='extra-card__action'>
                  <div className='extra-card__price'>
                    {isInclude(name, fee)
                      ? dineroFormat(
                        fee.filter((i) => i.name === name)[0]
                          ?.price as number,
                        currency
                      )
                      : dineroFormat(price, currency)}
                  </div>
                  <>
                    {fee.find((ex) => ex.id === id) ? (
                      <div
                        className={
                          (extra.id === 12 || extra.id === 41)
                            ? 'extra-card__action__two-buttons'
                            : 'extra-card__action__button'
                        }
                      >
                        {(extra.id === 12 || extra.id === 41) ? (
                          <>
                            <Button
                              children='Add more'
                              variant='outlined'
                              sx={{
                                height: `inherit`,
                                width: `inherit`,
                                padding: 0,
                              }}
                              onClick={() => handleAddChildSeat(extra)}
                            />
                            <SecondTypeButton
                              children='Delete'
                              variant='outlined'
                              sx={{
                                height: `inherit`,
                                width: `inherit`,
                              }}
                              onClick={() => handleRemoveAdditionalSeat(extra)}
                            />
                          </>
                        ) : (
                          <SecondTypeButton
                            children='Delete'
                            variant='outlined'
                            sx={{
                              height: `inherit`,
                              width: `inherit`,
                            }}
                            onClick={() => handleChangeExtra(extra)}
                          />
                        )}
                      </div>
                    ) : (
                      <div className='extra-card__action__button'>
                        <Button
                          children='Select'
                          variant='outlined'
                          sx={{
                            height: `inherit`,
                            width: `inherit`,
                          }}
                          onClick={() => handleChangeExtra(extra)}
                        />
                      </div>
                    )}
                  </>
                </div>
              </div>
            );
          })}
          <Button
            children='Close'
            onClick={onSubmit}
            variant='contained'
            sx={{ width: 200 }}
          />
        </>
      ) : (
        <PreviewExtra />
      )}
    </div>
  );
};

export default Cart;
