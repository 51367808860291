import { put, takeLatest } from "redux-saga/effects";
import instance from "src/sagas/set";
import { VanActionCreators } from "src/store/reducers/van/action-creators";
import { VanActionEnum } from "src/store/reducers/van/types";

function* fetchAddVanImage({
  interiorImages,
  exteriorImages,
  id,
}: ReturnType<typeof VanActionCreators.addVanImage>) {

  const formData = new FormData();
  exteriorImages.map((file) => formData.append('exteriorImages', file));
  interiorImages.map((file) => formData.append('interiorImages', file));

  try {
    const { data, status } = yield instance.post(`/van/imgUpload/${id}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    yield put(VanActionCreators.resultAddVanImage(data, status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherAddVanImage() {
  yield takeLatest(VanActionEnum.REQUEST_ADD_VAN_IMAGES, fetchAddVanImage);
}