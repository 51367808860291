import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';

import { TextField } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import SimpleSelect from '../select/SimpleSelect';
import { fromISOtoFormat } from 'src/common/utils/dataFormat';
import { getListHoursAMPM } from 'src/common/utils/others';
import { colorSet, typeForm } from 'src/theme';
import { DateTime } from 'luxon';

interface IProps {
  date?: string;
  setDate: any;
  minLimit?: string;
  isMobile: boolean;
  check: boolean;
}

const DayHourPicker: FC<IProps> = ({
  date,
  setDate,
  minLimit,
  isMobile,
  check,
}) => {
  const [hour, setHour] = useState('');
  const handleChangeHour = (event: string) => setDate(date, event);
  useMemo(() => {
    date && setHour(fromISOtoFormat(date.slice(0, -1), 't'));
  }, [date]);

  const hourArray = useMemo(() => {
    const ampm = getListHoursAMPM();
    const { c }: any = DateTime.fromISO(DateTime.now().toISO());
    if (date) return ampm;
    else {
      return (
        c && getListHoursAMPM().slice(minLimit ? c.hour * 2 + 4 : c.hour * 2)
      );
    }
  }, [date, minLimit]);

  const helperText = () => {
    if (check) {
      if (!date) return 'Pick the field';
      else return;
    }
  };
  const helperError = () => {
    if (check) {
      if (!date) return true;
      else return false;
    }
  };

  if (date) {
    date = date.slice(0, -1);
  }

  return (
    <div className='data-picker__wrapper'>
      <div className='data-picker__calender'>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <DatePicker
            value={moment.utc(date) ?? ''}
            onChange={(v) => setDate(moment.utc(v).format())}
            minDate={minLimit ? moment(minLimit) : null}
            defaultCalendarMonth={minLimit ? moment(minLimit) : null}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  autoComplete='off'
                  variant={typeForm}
                  helperText={!isMobile && helperText()}
                  error={helperError()}
                  sx={{
                    input: {
                      '::placeholder': {
                        color:
                          check && !date
                            ? colorSet.primaryRed
                            : colorSet.primaryGrey,
                        opacity: 1,
                      },
                    },
                    '.MuiFormHelperText-root': {
                      display: 'none',
                    },
                  }}
                  placeholder={check && !date ? 'Pick the date' : 'mm/dd/yyyy'}
                />
              );
            }}
          />
        </LocalizationProvider>
      </div>
      <div className='data-picker__clock'>
        <SimpleSelect
          data={hour}
          setData={handleChangeHour}
          dataArray={hourArray}
          placeholder='hh/mm'
          notShowSelect
        />
      </div>
    </div>
  );
};

export default DayHourPicker;
