import {
  FormControl,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { FC } from "react";
import theme, { colorSet, typeForm } from "src/theme";
import { deepmerge } from "@mui/utils";

interface ISelect {
  check?: boolean;
  dataId: number | null;
  setDataId: (event: any) => void;
  propsShow: string[];
  title?: string;
  placeholder?: string;
  dataArray: any[];
  style?: {};
  disable?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
}

const SimpleSelect: FC<ISelect> = ({
  dataId,
  setDataId,
  dataArray,
  propsShow,
  check,
  title,
  placeholder = title,
  style,
  disable,
  variant
}) => {
  const themeMultiSelect = createTheme(
    deepmerge(theme, {
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              maxHeight: `260px !important`,
            },
          },
        },
      },
    })
  );

  return (
    <ThemeProvider theme={themeMultiSelect}>
      <div>
        {title && <div className="filter__title">{title}</div>}
        <FormControl sx={style}>
          <Select
            value={dataId ?? ""}
            variant={variant ?? typeForm}
            onChange={(e) => setDataId(e.target.value)}
            defaultValue={null}
            error={dataId === null && check}
            renderValue={(n) =>
              !n || !dataId ? (
                check ? (
                  <div style={{ color: colorSet.primaryRed }}>
                    Must be selected
                  </div>
                ) : (
                  <div style={{ color: colorSet.primaryGrey }}>
                    {placeholder}
                  </div>
                )
              ) : (
                dataArray.map((data) => {
                  const name = propsShow.map((prop) => data[prop]).join(" ");
                  return data.id === dataId && name;
                })
              )
            }
            displayEmpty
            disabled={disable}
          >
            {dataArray.map((data, index) => {
              const name = propsShow.map((prop) => data[prop]).join(" ");
              return (
                <MenuItem key={index} value={data.id}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </ThemeProvider>
  );
};

export default SimpleSelect;
