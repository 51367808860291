import { IClient, IClientFetch } from 'src/models/IClient';

export class Client implements IClient {
  email = '';
  last_name = '';
  first_name = '';
  phone = '';
  country = '';
  city = '';
  driver_license_id = null;

  constructor(currentClient?: IClientFetch) {
    if (currentClient) {
      //@ts-ignore
      Object.keys(this).forEach((key) => (this[key] = currentClient[key]));
    }
  }
}
