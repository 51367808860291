export enum TagCategoryEnum {
  location,
  size,
  color,
  type,
}
export enum TagAdminCategoryEnum {
  location,
  sublocation,
  size,
  color,
  type,
  host,
  region,
  keycode,
}
export interface ITag {
  id?: number;
  name: string;
  category: TagCategoryEnum | TagAdminCategoryEnum;
}

export enum TagActionEnum {
  REQUEST_TAGS = 'REQUEST_TAGS',
  RESULT_TAGS = 'RESULT_TAGS',
  ERROR_TAGS = 'ERROR_TAGS',

  REQUEST_TAG = 'REQUEST_TAG',
  RESULT_TAG = 'RESULT_TAG',

  REQUEST_ADD_TAG = 'REQUEST_ADD_TAG',
  RESULT_ADD_TAG = 'RESULT_ADD_TAG',

  REQUEST_UPDATE_TAG = 'REQUEST_UPDATE_TAG',
  RESULT_UPDATE_TAG = 'RESULT_UPDATE_TAG',

  REQUEST_DELETE_TAG = 'REQUEST_DELETE_TAG',
  RESULT_DELETE_TAG = 'RESULT_DELETE_TAG',
}
export interface TagState {
  tagList: ITag[];
  total?: number;
  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
  };
  result: {
    add?: number | string;
    delete?: number | string;
  };
}

export interface GetTagsAction {
  type: TagActionEnum.REQUEST_TAGS;
  payload?: any;
}

export interface ResultGetTagsAction {
  type: TagActionEnum.RESULT_TAGS;
  data: ITag[];
}

export interface ErrorTagAction {
  type: TagActionEnum.ERROR_TAGS;
  payload?: any;
}

export interface AddTagAction {
  type: TagActionEnum.REQUEST_ADD_TAG;
  data: ITag[];
}

export interface ResultAddTagAction {
  type: TagActionEnum.RESULT_ADD_TAG;
  status: number;
}
export interface UpdateTagAction {
  type: TagActionEnum.REQUEST_UPDATE_TAG;
  tag: ITag;
}

export interface ResultUpdateTagAction {
  type: TagActionEnum.RESULT_UPDATE_TAG;
  status: number;
}
export interface DeleteTagAction {
  type: TagActionEnum.REQUEST_DELETE_TAG;
  id: number;
}

export interface ResultDeleteTagAction {
  type: TagActionEnum.RESULT_DELETE_TAG;
  status: number;
}

export type TagAction =
  | GetTagsAction
  | ResultGetTagsAction
  | ErrorTagAction
  | AddTagAction
  | ResultAddTagAction
  | UpdateTagAction
  | ResultUpdateTagAction
  | DeleteTagAction
  | ResultDeleteTagAction;
