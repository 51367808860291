import { FC, useEffect, useState, useMemo } from "react";
import { orderBy } from "natural-orderby";
import { CircularProgress, Tooltip } from "@mui/material";
import LocationModal from "src/pages/Admin/Modals/AddLocation";
import DeleteModalForm from "src/components/Items/DeleteModalForm";
import ActionForm from "src/components/Items/ActionForm";
import Table from 'src/components/Items/table';
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import useShowSnackbar from "src/hooks/useShowSnackbar";
import { getNamesWithCommas } from "src/common/utils/others";
import { ILocationFetch } from "src/models/ILocation";

const LocationsTable: FC = () => {

  const { getLocations, clearLocationResult, getTags } = useActions();
  const {
    location: { locationList, isLoading, result },
    sublocation
  } = useTypedSelector((state) => state);
  useShowSnackbar(isLoading, result, clearLocationResult, 'Location');

  useEffect(() => {
    getLocations();
    getTags();
  }, []);

  useEffect(() => {
    (
      result.add ||
      result.update ||
      sublocation.result.add ||
      sublocation.result.delete ||
      sublocation.result.update
    ) && (
        getLocations()
      );
  }, [
    result.add,
    result.update,
    sublocation.result.add,
    sublocation.result.delete,
    sublocation.result.update
  ]);

  const [selectedLocation, setSelectedLocation] = useState({} as ILocationFetch);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenEdit = (location: ILocationFetch) => {
    setOpenEdit(true);
    setSelectedLocation(location);
  };

  const handleOpenDelete = (location: ILocationFetch) => {
    setOpenDelete(true);
    setSelectedLocation(location);
  };

  const sortedLocation = orderBy(
    locationList,
    [(location) => location.country, (location) => location.name],
    ['asc', 'asc']
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({
          row: {
            original: { name },
          },
        }: any) => {
          return <>{name}</>;
        },
      },
      {
        Header: 'Review link',
        accessor: 'review_link',
        Cell: ({
          row: {
            original: { review_link },
          },
        }: any) => {
          return (
            <div style={{
              maxWidth: '200px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              lineHeight: 'normal'
            }}>
              <a href={review_link}
                target="_blank"
                style={{ color: '#0000FF' }}
              >
                {review_link}
              </a>
            </div>
          );
        }
      },
      {
        Header: 'Country',
        accessor: 'country',
        Cell: ({
          row: {
            original: { country },
          },
        }: any) => {
          return <>{country}</>;
        },
      },
      {
        Header: 'Regions',
        accessor: 'tags',
        Cell: ({
          row: {
            original: { tags },
          },
        }: any) => {
          return <>{getNamesWithCommas(tags)}</>;
        },
      },
      {
        Header: 'Sublocations',
        accessor: 'sub_location',
        Cell: ({
          row: {
            original: { sub_location },
          },
        }: any) => {
          return <>{getNamesWithCommas(sub_location)}</>;
        },
      },
      {
        accessor: 'actions',
        Cell: ({ row: { original } }: any) => (
          <>
            <div className='flex_CC' style={{ justifyContent: 'right' }}>
              <Tooltip title='Edit order'>
                <>
                  <ActionForm.IconSet
                    title='order'
                    classWrapper='action-wrapper'
                    onEdit={() => handleOpenEdit(original)}
                    onDelete={() => handleOpenDelete(original)}
                  />
                </>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    [locationList]
  );

  return (
    <>
      <LocationModal
        open={openEdit}
        editLocation={selectedLocation}
        handleClose={() => setOpenEdit(false)}
      />
      <DeleteModalForm
        id={selectedLocation.id}
        type="location"
        handleClose={() => setOpenDelete(false)}
        open={openDelete}
      />
      {isLoading.all ? (
        <CircularProgress size={200}
          style={{ position: 'fixed', top: '40%', left: '45%', zIndex: 100 }}
        />
      ) : (
        locationList.length ? (
          <div className='order-table'>
            <Table.Base
              data={sortedLocation}
              columns={columns}
              class='react-table'
              total={0}
            />
          </div>
        ) : null
      )}
    </>
  );
};
export default LocationsTable;
