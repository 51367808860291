import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from 'src/components/MainLayout';
import CalendarPage from 'src/pages/Calendar';
import ErrorPage from 'src/pages/Error';
import OrderPage from 'src/pages/Order';
import VanPage from 'src/pages/Van';
import LoginPage from 'src/pages/Login';
import ClientPage from 'src/pages/Client';
import ClientShow from 'src/pages/Client/Show';
import OrderShow from 'src/pages/Order/Show';
import VanShow from 'src/pages/Van/Show';
import HostShow from 'src/pages/Host/Show';
import ReportPage from 'src/pages/Report';
import AdminPage from 'src/pages/Admin';
import HelpChatPage from 'src/pages/HelpChat';
import PlaygroundSpeedDial from '../Items/SpeedDial';

interface IProps {
  login: boolean;
}

const MainRouters: FC<IProps> = ({ login }) => {

  return (
    <BrowserRouter>
      <Routes>
        {login ? (
          <Route path='/' element={<MainLayout />}>
            <Route path='' element={<OrderPage isNextOut={false} />} />
            <Route path='calendar' element={<CalendarPage />} />
            <Route path='order' element={<OrderPage isNextOut={false} />} />
            <Route path='order/next-out' element={<OrderPage isNextOut={true} />} />
            <Route path='order/:id' element={<OrderShow />} />
            <Route path='van' element={<VanPage />} />
            <Route path='van/:id' element={<VanShow />} />
            <Route path='host/:id' element={<HostShow />} />
            <Route path='client' element={<ClientPage />} />
            <Route path='client/:id' element={<ClientShow />} />
            <Route path='admin' element={<AdminPage />} />
            <Route path='host-support' element={<HelpChatPage />} />
            <Route path='404' element={<ErrorPage />} />

            <Route path='/report/host' element={<ReportPage />} />

            <Route path='*' element={<Navigate to='/404' replace />} />
          </Route>
        ) : (
          <Route path='/' element={<LoginPage />}>
            <Route path='*' element={<Navigate to='/' replace />} />
          </Route>
        )}
      </Routes>
      <PlaygroundSpeedDial />
    </BrowserRouter>
  );
};

export default MainRouters;
