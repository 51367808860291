import { FC } from 'react';
import SimpleSelect from '../../select/SimpleSelect';

interface IProps {
  data?: number[];
  take: number;
  onTakeChange: any;
  total: number;
}

const ItemPerPage: FC<IProps> = ({
  data = [10, 25, 50, 100, 500],
  take,
  onTakeChange,
  total,
}) => {
  const items: number[] = [];
  data.forEach((d) => {
    if (total > d) items.push(d);
  });

  return (
    <>
      {items.length > 1 ? (
        <SimpleSelect data={take} setData={onTakeChange} dataArray={items} />
      ) : null}
    </>
  );
};
export default ItemPerPage;
