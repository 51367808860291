import { FC } from "react";
import CustomPagination from "src/components/Items/pagination";
import { IVanFetch } from "src/models/IVan";
import { IVanFilter } from "src/models/IVanFilter";
import VanItem from "./item";

export interface IProps {
  vans: IVanFetch[];
  filters: IVanFilter;
  onPaginationChange: any;
  onTakeChange: any;
  total: number;
}
const VanItems: FC<IProps> = ({
  vans,
  filters,
  onPaginationChange,
  onTakeChange,
  total,
}) => {
  return (
    <>
      {total > filters.take && (
        <CustomPagination
          filter={filters}
          onChange={onPaginationChange}
          total={total}
          onTakeChange={onTakeChange}
        />
      )}
      {vans.map((van: IVanFetch) => (
          <VanItem van={van} key={van.id} />
        ))
      }
      {total > filters.take && (
        <CustomPagination
          filter={filters}
          onChange={onPaginationChange}
          total={total}
          onTakeChange={onTakeChange}
        />
      )}
    </>
  );
};
export default VanItems;
