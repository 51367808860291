import { DateTime } from 'luxon';
import moment from 'moment';
import { IOrderFetch } from 'src/models/IOrder';
import {
  CustomCalendar,
  FetchCalendarDate,
} from 'src/store/reducers/calendar/types';

export class CalendarGraph implements CustomCalendar {
  groups: { id: number; title: any; model: string; height?: number; make: string; }[] = [];
  items: {
    id: number;
    group: number;
    title: string;
    start_time: moment.Moment;
    end_time: moment.Moment;
    order: IOrderFetch;
  }[];

  constructor(data: FetchCalendarDate, isBigCards: boolean) {

    this.groups = data.vanList.map(({ id, model, make, seats, price, currency }) => {
      return {
        id,
        title: model,
        model,
        make,
        seats,
        price,
        currency,
        height: isBigCards ? 88 : 45
      };
    });

    this.items = data.orderList
      .map((order) => {
        const { id, van_id, client_email, start_date, end_date } = order;
        return {
          id,
          group: van_id,
          title: client_email,
          start_time: moment(start_date).add(order.timeOffset, 'minutes'),
          end_time: moment(end_date).add(order.timeOffset, 'minutes'),
          order,
        };
      })
      .sort();
  }
}