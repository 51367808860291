import { requestState, responseState } from "../utils";
import { HostStaffAction, HostStaffActionEnum, HostStaffState } from "./types";

const initialState: HostStaffState = {
    hostStaffList: [],
    isLoading: {
        all: false,
        get: false,
        add: false,
        delete: false,
        update: false,
    },
    result: {},
};
const hostStaff = (state = initialState, action: HostStaffAction): HostStaffState => {
  switch (action.type) {
    case HostStaffActionEnum.REQUEST_HOSTSTAFF:
      return { ...state, 
        isLoading: { ...state.isLoading, all: true },
        result: {} 
      };
    case HostStaffActionEnum.RESULT_HOSTSTAFF:
      return {
        ...state,
        hostStaffList: action.data ? action.data : [],
        isLoading: { ...state.isLoading, all: !action.status },
        result: {}
      };

    case HostStaffActionEnum.REQUEST_HOSTSTAFF_BY_ID:
    return { ...state, isLoading: { ...state.isLoading, get: true } };
    case HostStaffActionEnum.RESULT_HOSTSTAFF_BY_ID:
    return {
        ...state,
        hostStaff: action.data,
        isLoading: { ...state.isLoading, get: !action.status },
        result: {}
    };

    case HostStaffActionEnum.REQUEST_ADD_HOSTSTAFF:
    return requestState(state, "add");
    case HostStaffActionEnum.RESULT_ADD_HOSTSTAFF:
      return responseState(state, "add", action);

    case HostStaffActionEnum.REQUEST_UPDATE_HOSTSTAFF:
      return requestState(state, "update");
    case HostStaffActionEnum.RESULT_UPDATE_HOSTSTAFF:
      return responseState(state, "update", action);

    case HostStaffActionEnum.REQUEST_DELETE_HOSTSTAFF:
    return requestState(state, "delete");
    case HostStaffActionEnum.RESULT_DELETE_HOSTSTAFF:
    return responseState(state, "delete", action);

    default:
      return state;
  }
};
export default hostStaff;
