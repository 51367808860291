import { FC, useEffect, useState } from 'react';
import { Backdrop, Button, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import OnewayFormModal from './OnewayForm';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { validateEndLocation, validateField } from 'src/common/utils/validation';
import { dineroMultiply } from 'src/common/utils/dataFormat';
import { Oneway } from 'src/entity/Oneway';
import { IOnewayFetch } from 'src/models/IOneway';

interface IProps {
  open: boolean;
  handleClose: () => void;
  editOneway?: IOnewayFetch;
}

export type IErrorsOnewayData = {
  start_location?: string | undefined;
  end_location?: string | undefined;
  price?: string | undefined;
  currency?: string | undefined;
};

const OnewayModal: FC<IProps> = ({ open, handleClose, editOneway }) => {

  const { addOneway, updateOneway } = useActions();
  const onewaysList= useTypedSelector((state) => state.oneway.onewaysList);
  const [oneway, setOneway] = useState(new Oneway());
  const [check, setCheck] = useState(false);
  const [errors, setErrors] = useState<IErrorsOnewayData>({});
  const [isOnewayExist, setIsOnewayExist] = useState(false);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (editOneway !== undefined) {
      setOneway({...editOneway, 'price': editOneway.price / 100});
    }
  }, [editOneway]);

  useEffect(() => {
    JSON.stringify(editOneway) === JSON.stringify({...oneway, 'price': oneway.price*100}) ?
      setDisable(true) : setDisable(false);
  }, [oneway]);

  const handleInput = (name: string) => (value: string | number) =>
    setOneway({ ...oneway, [name]: value });

  const validateInputs = (): boolean => {
    const start_location = validateField(oneway.start_location);
    const end_location = validateEndLocation(oneway.start_location, oneway.end_location);
    const price = (oneway.price === 0) ? 'Field can not be empty' : '';
    const currency = validateField(oneway.currency);

    setErrors({
      start_location,
      end_location,
      price,
      currency
    });
    return [start_location, end_location, currency].every(
      (el) => !el
    );
  };

  const handleCloseModal = () => {
    editOneway ? setOneway(editOneway) : setOneway(new Oneway());
    setCheck(false);
    setErrors({});
    setIsOnewayExist(false);
    handleClose();
  };

  const handleSubmit = () => {
    const searchOneway = onewaysList.find((el) => 
      (el.start_location === oneway.start_location && 
        el.end_location === oneway.end_location
      )
    );
    searchOneway && !editOneway && setIsOnewayExist(true);
    setCheck(true);
    if (validateInputs()) {
      if (editOneway) {
        const updatedOneway = oneway;
        for (const key in oneway) {
          (oneway[key as keyof typeof oneway] === editOneway[key as keyof typeof editOneway] || 
            oneway[key as keyof typeof oneway] === ''
          ) && delete updatedOneway[key as keyof typeof updatedOneway];
        }
        updateOneway(
          {...updatedOneway, 
            'price': dineroMultiply(oneway.price, 100)
          } as IOnewayFetch, editOneway.id
        );
        handleCloseModal();
      } else if (!searchOneway) {
        addOneway({ 
          ...oneway, 
          'price': dineroMultiply(oneway.price, 100) 
        });
        handleCloseModal();
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal' style={{ width: '550px' }}>
          <div className='modal__title'>{editOneway ? 'Edit' : 'Add'} Oneway</div>
          <OnewayFormModal
            oneway={oneway}
            setOneway={handleInput}
            check={check}
            errors={errors}
            isEdit={editOneway ? true : false}
          />
          {isOnewayExist && 
            <div className='filter__title' style={{color: 'red', textAlign: 'center', marginTop: '10px'}}>
              Oneway already exists
            </div>
          }
          <div className='col-2'>
            <SecondTypeButton
              children='Cancel'
              onClick={handleClose}
              sx={{ marginTop: '30px' }}
            />
            <Button
              sx={{ marginTop: 3.5 }}
              variant='contained'
              onClick={handleSubmit}
              children={`${editOneway ? 'Edit' : 'Add'} Oneway`}
              disabled={editOneway ? disable : false}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
export default OnewayModal;
