import { put, takeLatest } from "redux-saga/effects";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";
import { ClientActionEnum } from "src/store/reducers/client/types";
import instance from "../set";

function* fetchSearchClients({
  search,
}: ReturnType<typeof ClientActionCreators.searchClients>) {
  try {
    const { data } = yield instance.get(`client/search/${search}`);
    yield put(ClientActionCreators.resultSearchClients(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherSearchClient() {
  yield takeLatest(ClientActionEnum.REQUEST_SEARCH_CLIENTS, fetchSearchClients);
}
