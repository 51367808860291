import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";
import { ClientActionEnum } from "src/store/reducers/client/types";

function* fetchDeleteClient({
  id,
}: ReturnType<typeof ClientActionCreators.deleteClient>) {
  try {
    const { status }: Response = yield instance.delete(`client/${id}`);
    yield put(ClientActionCreators.resultDeleteClient(status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteClient() {
  yield takeLatest(ClientActionEnum.REQUEST_DELETE_CLIENT, fetchDeleteClient);
}
