import { FC } from 'react';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import { IDriver } from 'src/models/IDriver';
import { IErrorsDriverData } from '../Add';

interface IProps {
  setDriver: any;
  data: IDriver;
  errors: IErrorsDriverData;
}

const DriverForm: FC<IProps> = ({ setDriver, data, errors }) => {

  return (
    <div className='col-2'>
      <SimpleInput
        data={data.first_name}
        setData={setDriver('first_name')}
        title={'first name'}
        length={30}
        error={errors.first_name}
        isHelperText={!!errors.first_name}
      />
      <SimpleInput
        data={data.last_name}
        setData={setDriver('last_name')}
        title={'last name'}
        length={30}
        error={errors.last_name}
        isHelperText={!!errors.last_name}
      />
      <SimpleInput
        data={data.email}
        setData={setDriver('email')}
        title={'email'}
        length={30}
        error={errors.email}
        isHelperText={!!errors.email}
      />
      <SimpleInput
        data={data.phone}
        setData={setDriver('phone')}
        title={'phone'}
        length={30}
      />
    </div>
  );
};

export default DriverForm;
