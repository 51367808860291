import { put, takeLatest } from "redux-saga/effects";
import { OrderActionEnum } from "src/store/reducers/order/types";
import instance from "../set";
import { OrderActionCreators } from "src/store/reducers/order/action-creators";

function* fetchUpdatePriceOrder({
  data,
}: ReturnType<typeof OrderActionCreators.updatePriceOrder>) {
  const { order_id, type, ...item } = data;
  try {
    const { status } = yield instance.post(`order/price`, {
      order_id,
      type,
      item,
    });
    yield put(OrderActionCreators.resultUpdateOrder(status));
  } catch (e: any) {
    console.log(e);
    yield put(OrderActionCreators.resultUpdateOrder(e));
  }
}

export function* actionWatcherUpdatePriceOrder() {
  yield takeLatest(
    OrderActionEnum.REQUEST_UPDATE_PRICE_ORDER,
    fetchUpdatePriceOrder
  );
}
