export enum GlobalActionEnum {
  ERROR = "ERROR",
  INIT_HIDE_BAR = "INIT_HIDE_BAR",
  TOGGLE_HIDE_BAR = "TOGGLE_HIDE_BAR",
}

export interface GlobalState {
  hideBarMenu: boolean;
  error?: {
    result: string | number;
    status: string | number;
  };
}
