import { Tooltip } from "@mui/material";
import { FC } from "react";

export interface IProps {
  handleClick?: () => void;
  icon?: JSX.Element;
  title?: string;
}

const FormIcon: FC<IProps> = ({ handleClick, icon, title }) => (
  <Tooltip title={title ?? ""}>
    <div className="action-box" onClick={handleClick}>
      {icon}
    </div>
  </Tooltip>
);

export default FormIcon;
