import ButtonSet from "./Set/ButtonSet";
import IconSet from "./Set/IconSet";
import "./styles.scss";

const ActionForm = {
  ButtonSet,
  IconSet,
};

export default ActionForm;
