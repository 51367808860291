import { Button } from "@mui/material";
import { FC } from "react";

export interface IProps {
  classWrapper?: string;
  onEdit?: () => void;
  onDelete?: () => void;
}

const ButtonSet: FC<IProps> = ({ classWrapper, onEdit, onDelete }) => (
  <div className={classWrapper}>
    {onEdit && (
      <Button
        children="Edit"
        type="button"
        className="action-button"
        onClick={onEdit}
      />
    )}
    {onDelete && (
      <Button
        children="Delete"
        type="button"
        className="action-button"
        onClick={onDelete}
      />
    )}
  </div>
);
export default ButtonSet;
