import { put, takeLatest } from "redux-saga/effects";
import { HostActionCreators } from "src/store/reducers/host/action-creators";
import { HostActionEnum } from "src/store/reducers/host/types";
import instance from "../set";

function* fetchAddHost({
  data,
}: ReturnType<typeof HostActionCreators.addHost>) {
  const host = { ...data, host_tag: [data.host_tag], permission: 'host', scores: 0 };
  try {
    const { status }: Response = yield instance.post(`host/add`, {
      host,
    });
    yield put(HostActionCreators.resultAddHost(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddHost() {
  yield takeLatest(HostActionEnum.REQUEST_ADD_HOST, fetchAddHost);
}
