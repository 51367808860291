import { put, takeLatest } from "redux-saga/effects";
import { ClientActionCreators } from "src/store/reducers/client/action-creators";
import { ClientActionEnum } from "src/store/reducers/client/types";
import instance from "../set";

function* fetchAddClient({
  client,
}: ReturnType<typeof ClientActionCreators.addClient>) {
  try {
    const { status }: Response = yield instance.post(`client/add`, {
      client,
    });
    yield put(ClientActionCreators.resultAddClient(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddClient() {
  yield takeLatest(ClientActionEnum.REQUEST_ADD_CLIENT, fetchAddClient);
}
