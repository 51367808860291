import { requestState, responseState } from "../utils";
import { PickupDetailsAction, PickupDetailsActionEnum, PickupDetailsState } from "./types";

const initialState: PickupDetailsState = {
  pickupDetailsList: [],
  total: null,
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
  },
  result: {},
};
const pickupDetails = (state = initialState, action: PickupDetailsAction): PickupDetailsState => {
  switch (action.type) {
    case PickupDetailsActionEnum.REQUEST_PICKUPDETAILS:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case PickupDetailsActionEnum.RESULT_PICKUPDETAILS:
      return {
        ...state,
        pickupDetailsList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };
    case PickupDetailsActionEnum.REQUEST_ADD_PICKUPDETAILS:
    return requestState(state, "add");

    case PickupDetailsActionEnum.RESULT_ADD_PICKUPDETAILS:
      return responseState(state, "add", action);

    case PickupDetailsActionEnum.REQUEST_UPDATE_PICKUPDETAILS:
      return requestState(state, "update");

    case PickupDetailsActionEnum.RESULT_UPDATE_PICKUPDETAILS:
      return responseState(state, "update", action);
  
    case PickupDetailsActionEnum.CLEAR_PICKUPDETAILS_RESULT:
      return { ...state, result: {} };

    default:
      return state;
  }
};
export default pickupDetails;
