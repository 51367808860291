import { put, takeLatest } from "redux-saga/effects";
import { FeatureActionEnum } from "src/store/reducers/feature/types";
import instance from "../set";
import { FeatureActionCreators } from "src/store/reducers/feature/action-creators";

function* fetchAddFeature({
  nameArray,
}: ReturnType<typeof FeatureActionCreators.addFeature>) {
  try {
    const { status }: Response = yield instance.post(`feature/add`, {
      nameArray,
    });
    yield put(FeatureActionCreators.resultAddFeature(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddFeature() {
  yield takeLatest(FeatureActionEnum.REQUEST_ADD_FEATURE, fetchAddFeature);
}
