import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { HostStaffActionCreators } from "src/store/reducers/hostStaff/action-creators";
import { HostStaffActionEnum } from "src/store/reducers/hostStaff/types";

function* fetchGetHostStaffById({
  id,
}: ReturnType<typeof HostStaffActionCreators.getHostStaffById>) {
  try {
    const { status, data } = yield instance.get(`staff/${id}`);
    yield put(HostStaffActionCreators.resultGetHostStaffById(status, data));
  } catch (error) {
    console.log("error :>> ", error);
    yield put(HostStaffActionCreators.resultGetHostStaffById(400));
  }
}
export function* actionWatcherGetHostStaff() {
  yield takeLatest(HostStaffActionEnum.REQUEST_HOSTSTAFF_BY_ID, fetchGetHostStaffById);
}
