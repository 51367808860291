import { FC, useEffect, useState } from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import DayHourPicker from 'src/components/Items/input/DayHourPicker';

import {
  changeISOset,
  fromJSDateToISO,
} from 'src/common/utils/dataFormat';
import { DateTime, Settings } from 'luxon';
import { IOrderFetch } from 'src/models/IOrder';

Settings.defaultLocale = 'UTC';
Settings.defaultZone = 'UTC';
Settings.defaultOutputCalendar = 'UTC';

interface IProps {
  check: boolean;
  order: IOrderFetch;
  setOrder: any;
}

const Times: FC<IProps> = ({ check, order, setOrder }) => {
  const isMobile = useIsMobile();
 
  const checkLess = (start?: string, end?: string) => {
    if (start && end) {
      return start >= end;
    } else return false;
  };

  const setStartDate = (date?: string, hour?: string) => {
    if (date && checkLess(fromJSDateToISO(date), order.end_date!)) {
      const startObj = DateTime.fromISO(date)
        .plus({ hours: 2 })
        .set({ second: 0 })
        .toISO();
      setOrder({
        ...order,
        start_date: startObj,
      });
    }
    if (date && !hour) {
      const isoDate = DateTime.fromISO(date)
        .minus({ minutes: order.timeOffset })
        .toISO();
      setOrder({ ...order, start_date: isoDate });
    }
    if (hour) {
      const isoDate = DateTime.fromISO(date ?? DateTime.now().toISO()).toISO();
      let time = hour;
      if (time === 'Noon') {
        time = '00:00 PM';
      }
      if (time === 'Midnight') {
        time = '00:00 AM';
      }
      const dayPart = time.split(' ');
      if (dayPart[1] === 'AM') {
        const time = dayPart[0].split(':');
        const changeHour = DateTime.fromISO(
          changeISOset(isoDate, {
            hour:  (time[0] === '12') ? (+time[0] - 12): (+time[0]),
            minute: +time[1],
            second: 0,
          })
        )
          .minus({ minutes: order.timeOffset })
          .toISO();

        setOrder({ ...order, start_date: changeHour });
      } else {
        const time = dayPart[0].split(':');
        const changeHour = DateTime.fromISO(
          changeISOset(isoDate, {
            hour: (time[0] === '12') ? (+time[0]) : (+time[0] + 12),
            minute: +time[1],
            second: 0,
          })
        )
          .minus({ minutes: order.timeOffset })
          .toISO();
        setOrder({ ...order, start_date: changeHour });
      }
    }
  };
  const setEndDate = (date?: string, hour?: string) => {
    if (checkLess(order.start_date, fromJSDateToISO(date)) && !hour) {
      const startObj = DateTime.fromISO(order.start_date!)
        .plus({ hours: 2 })
        .set({ second: 0 })
        .toISO();
      setOrder({ ...order, end_date: startObj });
    } else {
      if (date && !hour) {
        const isoDate = DateTime.fromISO(date)
          .minus({ minutes: order.timeOffset })
          .toISO();
        setOrder({
          ...order,
          end_date: isoDate,
        });
      }
      if (hour) {
        const isoDate = DateTime.fromISO(
          date ?? DateTime.now().toISO()
        ).toISO();
        let time = hour;
        if (time === 'Midnight') {
          time = '00:00 AM';
        }
        if (time === 'Noon') {
          time = '00:00 PM';
        }
        const dayPart = time.split(' ');
        if (dayPart[1] === 'AM') {
          const time = dayPart[0].split(':');
          const changeHour = DateTime.fromISO(
            changeISOset(isoDate, {
              hour: (time[0] === '12') ? (+time[0] - 12): (+time[0]),
              minute: +time[1],
              second: 0,
            })
          )
            .minus({ minutes: order.timeOffset })
            .toISO();
          setOrder({ ...order, end_date: changeHour });
        } else {
          const time = dayPart[0].split(':');
          const changeHour = DateTime.fromISO(
            changeISOset(isoDate, {
              hour: (time[0] === '12') ? (+time[0]) : (+time[0] + 12),
              minute: +time[1],
              second: 0,
            })
          )
            .minus({ minutes: order.timeOffset })
            .toISO();
          setOrder({ ...order, end_date: changeHour });
        }
      }
    }
  };
  return (
    <div className='filter__time'>
      <div className='data-picker'>
        <div className='filter__title'>Date From</div>
        <DayHourPicker
          date={DateTime.fromISO(order.start_date!, { zone: 'UTC' })
            .plus({
              minutes: order.timeOffset,
            })
            .toISO()}
          setDate={setStartDate}
          isMobile={isMobile}
          check={false}
        />
      </div>

      <div className='data-picker'>
        <div className='filter__title'>Date Drop</div>
        <DayHourPicker
          date={DateTime.fromISO(order.end_date!, { zone: 'UTC' })
            .plus({
              minutes: order.timeOffset,
            })
            .toISO()}
          setDate={setEndDate}
          minLimit={order.start_date!}
          isMobile={isMobile}
          check={check}
        />
      </div>
    </div>
  );
};

export default Times;
