import { INearVan, INearVanFetch } from "src/models/INearVan";

export enum NearVanActionEnum {
  REQUEST_NEARVANS = "REQUEST_NEARVANS",
  RESULT_NEARVANS = "RESULT_NEARVANS",
  ERROR_NEARVANS = "ERROR_NEARVANS",

  REQUEST_NEARVAN = "REQUEST_NEARVAN",
  RESULT_NEARVAN = "RESULT_NEARVAN",

  REQUEST_ADD_NEARVANS = "REQUEST_ADD_NEARVANS",
  RESULT_ADD_NEARVANS = "RESULT_ADD_NEARVANS",

  REQUEST_UPDATE_NEARVAN = "REQUEST_UPDATE_NEARVAN",
  RESULT_UPDATE_NEARVAN = "RESULT_UPDATE_NEARVAN",

  REQUEST_DELETE_NEARVAN = "REQUEST_DELETE_NEARVAN",
  RESULT_DELETE_NEARVAN = "RESULT_DELETE_NEARVAN",
}

export interface NearVanState {
  nearVanList: INearVanFetch[];
  nearVan?: INearVanFetch;
  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
  };
  result: {
    add?: number | string;
    delete?: number | string;
    update?: number | string;
  };
}
export interface GetNearVansAction {
  type: NearVanActionEnum.REQUEST_NEARVANS;
}
export interface ResultGetNearVansAction {
  type: NearVanActionEnum.RESULT_NEARVANS;
  data: INearVanFetch[];
}
export interface GetNearVanAction {
  type: NearVanActionEnum.REQUEST_NEARVAN;
  id: number;
}
export interface ResultGetNearVanAction {
  type: NearVanActionEnum.RESULT_NEARVAN;
  data: INearVanFetch;
}
export interface AddNearVansAction {
  type: NearVanActionEnum.REQUEST_ADD_NEARVANS;
  data: INearVan[];
}
export interface ResultAddNearVansAction {
  type: NearVanActionEnum.RESULT_ADD_NEARVANS;
  status: number;
}
export interface UpdateNearVanAction {
  type: NearVanActionEnum.REQUEST_UPDATE_NEARVAN;
  currentNearVan: Partial<INearVanFetch>,
  id: string | number
}
export interface ResultUpdateNearVanAction {
  type: NearVanActionEnum.RESULT_UPDATE_NEARVAN;
  status: number;
}
export interface DeleteNearVanAction {
  type: NearVanActionEnum.REQUEST_DELETE_NEARVAN;
  id: number;
}
export interface ResultDeleteNearVanAction {
  type: NearVanActionEnum.RESULT_DELETE_NEARVAN;
  status: number;
}

export type NearVanAction =
  | GetNearVansAction
  | ResultGetNearVansAction
  | GetNearVanAction
  | ResultGetNearVanAction
  | AddNearVansAction
  | ResultAddNearVansAction
  | UpdateNearVanAction
  | ResultUpdateNearVanAction
  | DeleteNearVanAction
  | ResultDeleteNearVanAction;
