import { IPagination } from "src/models/IPagination";
import { IClient, IClientFetch, ISearchClient } from "src/models/IClient";

export enum ClientActionEnum {
  REQUEST_CLIENTS = "REQUEST_CLIENTS",
  RESULT_CLIENTS = "RESULT_CLIENTS",
  ERROR_CLIENTS = "ERROR_CLIENTS",

  REQUEST_CLIENT = "REQUEST_CLIENT",
  RESULT_CLIENT = "RESULT_CLIENT",

  REQUEST_ADD_CLIENT = "REQUEST_ADD_CLIENT",
  RESULT_ADD_CLIENT = "RESULT_ADD_CLIENT",

  REQUEST_UPDATE_CLIENT = "REQUEST_UPDATE_CLIENT",
  RESULT_UPDATE_CLIENT = "RESULT_UPDATE_CLIENT",

  REQUEST_DELETE_CLIENT = "REQUEST_DELETE_CLIENT",
  RESULT_DELETE_CLIENT = "RESULT_DELETE_CLIENT",

  REQUEST_SEARCH_CLIENTS = 'REQUEST_SEARCH_CLIENTS',
  RESULT_SEARCH_CLIENTS = 'RESULT_SEARCH_CLIENTS'
}

export interface ClientState {
  clientList: IClient[];
  client?: IClient;
  total: number | null;
  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
    search: boolean;
  };
  result: {
    add?: number | string;
    delete?: number | string;
    update?: number | string;
    search?: ISearchClient[];
  };
}
export interface GetClientsAction {
  type: ClientActionEnum.REQUEST_CLIENTS;
  filter: IPagination;
}
export interface ResultGetClientsAction {
  type: ClientActionEnum.RESULT_CLIENTS;
  clientList: IClient[];
  count: number;
}
export interface GetClientAction {
  type: ClientActionEnum.REQUEST_CLIENT;
  id: number;
}
export interface ResultGetClientAction {
  type: ClientActionEnum.RESULT_CLIENT;
  data: IClient;
}
export interface AddClientAction {
  type: ClientActionEnum.REQUEST_ADD_CLIENT;
  client: IClient;
}
export interface ResultAddClientAction {
  type: ClientActionEnum.RESULT_ADD_CLIENT;
  status: number;
}
export interface UpdateClientAction {
  type: ClientActionEnum.REQUEST_UPDATE_CLIENT;
  currentClient: Partial<IClientFetch>;
}
export interface ResultUpdateClientAction {
  type: ClientActionEnum.RESULT_UPDATE_CLIENT;
  status: number;
}
export interface DeleteClientAction {
  type: ClientActionEnum.REQUEST_DELETE_CLIENT;
  id: number;
}
export interface ResultDeleteClientAction {
  type: ClientActionEnum.RESULT_DELETE_CLIENT;
  status: number;
}

export interface SearchClientsAction {
  type: ClientActionEnum.REQUEST_SEARCH_CLIENTS;
  search: string;
}
export interface ResultSearchClientsAction {
  type: ClientActionEnum.RESULT_SEARCH_CLIENTS;
  clients: ISearchClient[];
}

export type ClientAction =
  | GetClientsAction
  | ResultGetClientsAction
  | GetClientAction
  | ResultGetClientAction
  | AddClientAction
  | ResultAddClientAction
  | UpdateClientAction
  | ResultUpdateClientAction
  | DeleteClientAction
  | ResultDeleteClientAction
  | SearchClientsAction
  | ResultSearchClientsAction;
