import { put, takeLatest } from "redux-saga/effects";
import { SubPriceActionCreators } from "src/store/reducers/subPrice/action-creators";
import { SubPriceActionEnum } from "src/store/reducers/subPrice/types";
import instance from "../set";

function* fetchAddSubPrice({
  data,
}: ReturnType<typeof SubPriceActionCreators.addSubPrice>) {
  try {
    const { status }: Response = yield instance.post(`priceForSub`, {
      data
    });
    yield put(SubPriceActionCreators.resultAddSubPrice(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddSubPrice() {
  yield takeLatest(SubPriceActionEnum.REQUEST_ADD_SUBPRICE, fetchAddSubPrice);
}
