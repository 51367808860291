import { useSnackbar } from "notistack";
import { FC, useEffect } from "react";
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import MainRouters from "../Routes/MainRouters";

const Navigator: FC = () => {

  const { login, initHideBar } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const {
    auth: { isLogin },
    global: { error },
  } = useTypedSelector((state) => state);

  useEffect(() => {
    error &&
      enqueueSnackbar(`${error?.status} ${error?.result}`, {
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isHideNavbar = localStorage.getItem("isNavBar");
    token && login();
    isHideNavbar === "true" && initHideBar();
  }, []);

  return (
    <>
      <MainRouters login={isLogin} />
    </>
  );
};
export default Navigator;
