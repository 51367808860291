import { Backdrop, Modal } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import ModalAction from 'src/components/Items/modal/Actions';
import { useActions } from 'src/hooks/useActions';
import { IClientFetch } from 'src/models/IClient';
import ClientForm from '../Form';

interface IProps {
  client: IClientFetch;
  open: boolean;
  handleClose: () => void;
  orderId?: number;
}

const UpdateClientModal: FC<IProps> = ({
  client,
  open,
  handleClose,
  orderId,
}) => {
  const { updateClient, updateOrder } = useActions();

  const [currentClient, setCurrentClient] = useState(client);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    (
      currentClient.first_name === client.first_name &&
      currentClient.last_name === client.last_name &&
      currentClient.email === client.email &&
      currentClient.phone === client.phone &&
      currentClient.country === client.country &&
      currentClient.city === client.city &&
      currentClient.business_name === client.business_name
    ) ? setDisable(true) : setDisable(false);
  }, [currentClient]);

  const handleInput = (name: string) => (value: string | number) => {
    if (name === 'email') {
      setCurrentClient({ ...currentClient, 'email': value.toString().toLowerCase() });
    } else {
      setCurrentClient({ ...currentClient, [name]: value });
    }
  };

  useEffect(() => {
    setCurrentClient(client);
  }, [open]);

  const handleSubmit = () => {
    const updatedClient = currentClient;
    for (const key in currentClient) {
      currentClient[key as keyof typeof currentClient] === client[key as keyof typeof client] &&
        delete updatedClient[key as keyof typeof updatedClient];
    }
    orderId
      ? updateOrder({ id: orderId, client: updatedClient })
      : updateClient({...updatedClient, id: client.id });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal modal-client'>
          <div className='modal__title'>Edit Client</div>
          <ClientForm 
            client={currentClient} 
            setClient={handleInput} 
            isDisableEmail={true}
          />
          <ModalAction
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            disable={disable}
            type='client'
            action='Update'
          />
        </div>
      </>
    </Modal>
  );
};
export default UpdateClientModal;
