import { put, takeLatest } from "redux-saga/effects";
import { VanActionEnum } from "src/store/reducers/van/types";
import { VanActionCreators } from "src/store/reducers/van/action-creators";
import instance from "../set";

function* fetchUpdateVan({
  van, id
}: ReturnType<typeof VanActionCreators.updateVan>) {
  try {
    const { status }: Response = yield instance.patch(`van/${id}`, {
      van,
    });
    yield put(VanActionCreators.resultUpdateVan(status));
  } catch (e: any) {
    console.log("error", e);
    yield put(VanActionCreators.resultUpdateVan("ERROR"));
  }
}

export function* actionWatcherUpdateVan() {
  yield takeLatest(VanActionEnum.REQUEST_UPDATE_VAN, fetchUpdateVan);
}
