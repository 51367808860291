import { FC, useState } from 'react';
import { Currency } from 'dinero.js';
import { dineroFormat } from 'src/common/utils/dataFormat';
import NumberInput from 'src/components/Items/input/NumberInput';
import { ICharge } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import FormIcon from 'src/components/Items/ActionForm/FormIcon';
import './styles.scss';

interface IProps {
  item: ICharge;
  currency: Currency;
  index: number;
  setCharge: (index: number, price: number) => void;
  deleteCharge: (index: number) => void;
}

const ChargeItem: FC<IProps> = ({ item, currency, index, setCharge, deleteCharge }) => {

  const [price, setPrice] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = () => {
    setCharge(index, price);
    handleClose();
  };
  const handleDeleteChange = () => {
    deleteCharge(index);
    handleClose();
  };

  const _renderPrice = (item: ICharge) =>
    open ? (
      <div className='order-cart__item__price'>
        <div
          className='order-cart__item__price__action'
          onClick={handleClose}
        >
          <Icons.Cross />
        </div>
        <NumberInput data={price} setData={setPrice} variant='outlined' text />
        <div className='order-cart__item__price__action' onClick={handleChange}>
          <Icons.ArrowDown />
        </div>
      </div>
    ) : (
      <div className='order-cart__item__price' onClick={handleOpen}>
        {dineroFormat(item.amount, currency)}
      </div>
    );

  return (
    <div className='charge-item'>
      <div className='charge-item__title'>{item.title}</div>
      <div className='charge-item__container'>
        {_renderPrice(item
        )}<FormIcon
          icon={<Icons.Delete classes="icon_stroke" />}
          handleClick={handleDeleteChange}
          title='Delete charge'
        />
      </div>
    </div>
  );
};
export default ChargeItem;
