import { IDriverFetch, IDriver } from 'src/models/IDriver';

export enum DriverActionEnum {
  REQUEST_DRIVERS = 'REQUEST_DRIVERS',
  RESULT_DRIVERS = 'RESULT_DRIVERS',
  ERROR_DRIVERS = 'ERROR_DRIVERS',

  REQUEST_ADD_DRIVER = 'REQUEST_ADD_DRIVER',
  RESULT_ADD_DRIVER = 'RESULT_ADD_DRIVER',

  REQUEST_UPDATE_DRIVER = 'REQUEST_UPDATE_DRIVER',
  RESULT_UPDATE_DRIVER = 'RESULT_UPDATE_DRIVER',

  CLEAR_DRIVER_RESULT = 'CLEAR_DRIVER_RESULT'
}
export interface DriverState {
  driverList: IDriverFetch[];

  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
  };
  result: {
    add?: number | string;
    update?: number | string;
  };
}

export interface AddDriverAction {
  type: DriverActionEnum.REQUEST_ADD_DRIVER;
  driver: IDriver;
  id: number;
}
export interface ResultAddDrivertAction {
  type: DriverActionEnum.RESULT_ADD_DRIVER;
  status: number;
}
export interface UpdateDriverAction {
  type: DriverActionEnum.REQUEST_UPDATE_DRIVER;
  driver: IDriverFetch;
  id: string | number;
}
export interface ResultUpdateDriverAction {
  type: DriverActionEnum.RESULT_UPDATE_DRIVER;
  status: number;
}
export interface ClearDriverResultAction {
  type: DriverActionEnum.CLEAR_DRIVER_RESULT;
}

export type DriverAction =
  | AddDriverAction
  | ResultAddDrivertAction
  | UpdateDriverAction
  | ResultUpdateDriverAction
  | ClearDriverResultAction;
