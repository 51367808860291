import { requestState, responseState } from '../utils';
import { DriverAction, DriverActionEnum, DriverState } from './types';
const initialState: DriverState = {
  driverList: [],
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
  },
  result: {},
};
const driver = (state = initialState, action: DriverAction): DriverState => {
  switch (action.type) {
    case DriverActionEnum.REQUEST_ADD_DRIVER:
      return requestState(state, 'add');

    case DriverActionEnum.RESULT_ADD_DRIVER:
      return responseState(state, 'add', action);

    case DriverActionEnum.REQUEST_UPDATE_DRIVER:
      return requestState(state, "update");

    case DriverActionEnum.RESULT_UPDATE_DRIVER:
      return responseState(state, "update", action);

    case DriverActionEnum.CLEAR_DRIVER_RESULT:
      return { ...state, result: {} };

    default:
      return state;
  }
};
export default driver;
