import { put, takeLatest } from "redux-saga/effects";
import { SubPriceActionCreators } from "src/store/reducers/subPrice/action-creators";
import { SubPriceActionEnum } from "src/store/reducers/subPrice/types";
import instance from "../set";

function* fetchGetSubPrice() {
  try {
    const { data } = yield instance.get('priceForSub');
    yield put(SubPriceActionCreators.resultGetSubPrice(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetSubPrice() {
  yield takeLatest(SubPriceActionEnum.REQUEST_SUBPRICE, fetchGetSubPrice);
}
