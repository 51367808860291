import { put, takeLatest } from "redux-saga/effects";
import { PickupDetailsActionCreators } from "src/store/reducers/pickupDetails/action-creators";
import { PickupDetailsActionEnum } from "src/store/reducers/pickupDetails/types";
import instance from "../set";

function* fetchAddPickupDetails({
  data,
}: ReturnType<typeof PickupDetailsActionCreators.addPickupDetails>) {
  try {
    const { status }: Response = yield instance.post(`pickup-details/add`, data);
    yield put(PickupDetailsActionCreators.resultAddPickupDetails(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddPickupDetails() {
  yield takeLatest(PickupDetailsActionEnum.REQUEST_ADD_PICKUPDETAILS, fetchAddPickupDetails);
}
