import { put, takeLatest } from "redux-saga/effects";
import { PickupDetailsActionCreators } from "src/store/reducers/pickupDetails/action-creators";
import { PickupDetailsActionEnum } from "src/store/reducers/pickupDetails/types";
import instance from "../set";

function* fetchGetPickupDetails() {
  try {
    const { data } = yield instance.get('pickup-details');
    yield put(PickupDetailsActionCreators.resultGetPickupDetails(data));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetPickupDetails() {
  yield takeLatest(PickupDetailsActionEnum.REQUEST_PICKUPDETAILS, fetchGetPickupDetails);
}
