import { put, takeLatest } from "redux-saga/effects";
import { HostActionCreators } from "src/store/reducers/host/action-creators";
import { HostActionEnum } from "src/store/reducers/host/types";
import instance from "../set";

function* fetchGetHosts() {
  try {
    const { data, status } = yield instance.post(`host`);
    yield put(HostActionCreators.resultGetHosts(data, status));
  } catch (error) {
    console.log("error :>> ", error);
  }
}
export function* actionWatcherGetHosts() {
  yield takeLatest(HostActionEnum.REQUEST_HOSTS, fetchGetHosts);
}
