import { FC } from 'react';
import { Tooltip } from '@mui/material';
import OrderNotification from '../../../Notification';
import {
    OrderNotificationToGuestAdditionEnum,
    OrderNotificationToLeadsEnum,
    OrderNotificationToGuestAutomaticallyEnum,
    OrderNotificationToHostsEnum,
} from 'src/store/reducers/order/types';
import { toolTipStyle } from 'src/theme';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
    title: string;
    orderId: number;
    status: string;
    orderNotifications: string[];
    OrderNotificationEnum: typeof OrderNotificationToLeadsEnum
    | typeof OrderNotificationToGuestAdditionEnum
    | typeof OrderNotificationToHostsEnum
    | typeof OrderNotificationToGuestAutomaticallyEnum;
}

const NotificationMenuItem: FC<IProps> = ({
    title,
    orderId,
    status,
    orderNotifications,
    OrderNotificationEnum
}) => {

    return (
        <div className={`notification-menu ${title !== 'To Host & Team' && 'underline'}`}>
            <div className='notification-menu__title'>{title}</div>
            <Tooltip
                placement='right'
                componentsProps={{
                    tooltip: {
                        sx: { ...toolTipStyle, maxWidth: '350px' }
                    },
                }}
                title={
                    <div className='order-notification__list'>
                        <OrderNotification
                            orderId={orderId}
                            order={orderNotifications}
                            status={status}
                            OrderNotificationEnum={OrderNotificationEnum}
                        />
                    </div>
                }
            >
                <div className='order-notification'>
                    <Icons.ArrowDown />
                </div>
            </Tooltip>
        </div>
    );
};

export default NotificationMenuItem;
