import { FC, useEffect, useState, useMemo } from "react";
import { orderBy } from "natural-orderby";
import { CircularProgress, Tooltip } from "@mui/material";
import ActionForm from "src/components/Items/ActionForm";
import Table from 'src/components/Items/table';
import EditPickupDetailsModal from "src/pages/Admin/Modals/EditPickupDetails";
import useShowSnackbar from "src/hooks/useShowSnackbar";
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { IPickupDetailsFetch } from "src/models/IPickupDetails";
import './style.scss';

const PickupDetailsTable: FC = () => {

  const { getLocations, getPickupDetails, clearPickupDetailsResult } = useActions();
  const {
    host: { hostList },
    pickupDetails: { pickupDetailsList, isLoading, result },
    location: { locationList },
  } = useTypedSelector((state) => state);
  useShowSnackbar(isLoading, result, clearPickupDetailsResult, 'Pickup details');

  useEffect(() => {
    getPickupDetails();
    getLocations();
  }, []);

  useEffect(() => {
    (result.add || result.update) && (
      getPickupDetails(),
      getLocations()
    );
  }, [result.add, result.update]);

  const [selectedPickupDetails, setSelectedPickupDetails] = useState({} as IPickupDetailsFetch);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = (pd: IPickupDetailsFetch) => {
    setOpenEdit(true);
    setSelectedPickupDetails(pd);
  };

  const sortedLocation = orderBy(
    locationList,
    [(location) => location.country, (location) => location.name],
    ['asc', 'asc']
  );

  const sortedPD = pickupDetailsList.sort(function (a, b) {
    const nameA = sortedLocation.findIndex((l) => l.name === a.location),
      nameB = sortedLocation.findIndex((l) => l.name === b.location);
    if (nameA! < nameB!)
      return -1;
    if (nameA! > nameB!)
      return 1;
    return 0;
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'location',
        Cell: ({
          row: {
            original: { location },
          },
        }: any) => {
          return <>{location}</>;
        },
        width: '10%'
      },
      {
        Header: 'Host',
        accessor: 'host_id',
        Cell: ({
          row: {
            original: { host_id },
          },
        }: any) => {
          const hostName = hostList.find((h) => h.id === host_id);
          const name = hostName ? `${hostName.first_name} ${hostName.last_name}` : 'Default';
          return <>{name}</>;
        },
        width: '5%'
      },
      {
        Header: 'Pick-up address',
        accessor: 'address_take',
        Cell: ({
          row: {
            original: { address_take },
          },
        }: any) => {
          return (
            <div className='cutText'>
              {address_take}
            </div>
          );
        },
        width: '20%'
      },
      {
        Header: 'Return address',
        accessor: 'address_drop',
        Cell: ({
          row: {
            original: { address_drop },
          },
        }: any) => {
          return (
            <div className='cutText'>
              {address_drop}
            </div>
          );
        },
        width: '20%'
      },
      {
        Header: 'Pick-up info',
        accessor: 'description',
        Cell: ({
          row: {
            original: { description },
          },
        }: any) => {
          return (
            <div className='cutText'>
              {description}
            </div>
          );
        },
        width: '20%'
      },
      {
        Header: 'Return info',
        accessor: 'return_at',
        Cell: ({
          row: {
            original: { return_at },
          },
        }: any) => {
          return (
            <div className='cutText'>
              {return_at}
            </div>
          );
        },
        width: '20%'
      },
      {
        accessor: 'actions',
        Cell: ({ row: { original } }: any) => (
          <>
            <div className='flex_CC' style={{ justifyContent: 'right' }}>
              <Tooltip title='Edit order'>
                <>
                  <ActionForm.IconSet
                    title='order'
                    classWrapper='action-wrapper'
                    onEdit={() => handleOpenEdit(original)}
                  />
                </>
              </Tooltip>
            </div>
          </>
        ),
        width: '5%'
      },
    ],
    [pickupDetailsList]
  );

  return (
    <>
      <EditPickupDetailsModal
        open={openEdit}
        editPickupDetails={selectedPickupDetails}
        handleClose={() => setOpenEdit(false)}
      />
      {isLoading.all ? (
        <CircularProgress size={200}
          style={{ position: 'fixed', top: '40%', left: '45%', zIndex: 100 }}
        />
      ) : (
        sortedPD.length ? (
          <div className='order-table'>
            <Table.Base
              data={sortedPD}
              columns={columns}
              class='react-table'
              total={0}
            />
          </div>
        ) : null
      )}
    </>
  );
};
export default PickupDetailsTable;
