import { requestState, responseState } from "../utils";
import { SublocationAction, SublocationActionEnum, SublocationState } from "./types";

const initialState: SublocationState = {
    subLocationList: [],
    isLoading: {
        all: false,
        add: false,
        delete: false,
        update: false,
    },
    result: {},
};

const sublocation = (state = initialState, action: SublocationAction): SublocationState => {
  switch (action.type) {
    case SublocationActionEnum.REQUEST_SUBLOCATIONS:
      return { 
        ...state, 
        isLoading: { ...state.isLoading, all: true },
        result: {}
      };
      
    case SublocationActionEnum.RESULT_SUBLOCATIONS:
      return {
        ...state,
        subLocationList: action.data,
        isLoading: { ...state.isLoading, all: !action.data }
      };
    case SublocationActionEnum.REQUEST_DELETE_SUBLOCATION:
      return requestState(state, "delete");

    case SublocationActionEnum.RESULT_DELETE_SUBLOCATION:
      return responseState(state, "delete", action);

    case SublocationActionEnum.REQUEST_UPDATE_SUBLOCATION:
      return requestState(state, "update");

    case SublocationActionEnum.RESULT_UPDATE_SUBLOCATION:
      return responseState(state, "update", action);

    case SublocationActionEnum.REQUEST_ADD_SUBLOCATIONS:
      return requestState(state, "add");

    case SublocationActionEnum.RESULT_ADD_SUBLOCATIONS:
      return responseState(state, "add", action);

    default:
      return state;
  }
};
export default sublocation;
