import { requestState, responseState } from "../utils";
import { ClientAction, ClientActionEnum, ClientState } from "./types";

const initialState: ClientState = {
  clientList: [],
  total: null,
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
    search: false,
  },
  result: {},
};

const client = (state = initialState, action: ClientAction): ClientState => {
  switch (action.type) {
    case ClientActionEnum.REQUEST_CLIENTS:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case ClientActionEnum.RESULT_CLIENTS:
      return {
        ...state,
        clientList: action.clientList,
        total: action.count,
        isLoading: { ...state.isLoading, all: !action.clientList },
      };
    case ClientActionEnum.REQUEST_CLIENT:
      return {
        ...state,
        isLoading: { ...state.isLoading, get: true },
      };
    case ClientActionEnum.RESULT_CLIENT:
      return {
        ...state,
        client: action.data,
        isLoading: { ...state.isLoading, get: !action.data },
      };
    case ClientActionEnum.REQUEST_DELETE_CLIENT:
      return requestState(state, "delete");

    case ClientActionEnum.RESULT_DELETE_CLIENT:
      return responseState(state, "delete", action);

    case ClientActionEnum.REQUEST_UPDATE_CLIENT:
      return requestState(state, "update");

    case ClientActionEnum.RESULT_UPDATE_CLIENT:
      return responseState(state, "update", action);

    case ClientActionEnum.REQUEST_ADD_CLIENT:
      return requestState(state, "add");

    case ClientActionEnum.RESULT_ADD_CLIENT:
      return responseState(state, "add", action);

    case ClientActionEnum.REQUEST_SEARCH_CLIENTS:
      return { ...state, isLoading: { ...state.isLoading, search: true } };
    case ClientActionEnum.RESULT_SEARCH_CLIENTS:
      return {
        ...state,
        isLoading: { ...state.isLoading, search: false },
        result: { ...state.result, search: action.clients }
      };

    default:
      return state;
  }
};
export default client;
