import { FC } from "react";
import AnotherFilter from "../Items/filters/Another";
import "./styles.scss";

interface IProps {
  handleClearFilter: () => any;
  handleSetFilter: () => any;
}

const Aside: FC<IProps> = ({ handleClearFilter, handleSetFilter }) => (
  <AnotherFilter
    handleClearFilter={handleClearFilter}
    handleSetFilter={handleSetFilter}
  />
);

export default Aside;
