import { put, takeLatest } from 'redux-saga/effects';
import instance from 'src/sagas/set';
import { OrderNotificationActionCreators } from 'src/store/reducers/order/action-creators';
import { OrderNotificationActionEnum } from 'src/store/reducers/order/types';

function* fetchSentEmail({
  orderId,
  typeNotification,
  driverId,
  handlePaymentId
}: ReturnType<typeof OrderNotificationActionCreators.sentEmail>) {
  try {
    const { status } = yield instance.post(`email`, {
      id: orderId,
      type: typeNotification,
      driverId,
      handlePaymentId
    });
    yield put(
      OrderNotificationActionCreators.resultSentEmail(
        status,
        orderId,
        typeNotification
      )
    );
  } catch (e) {
    console.log(e);
    yield put(
      OrderNotificationActionCreators.resultSentEmail(
        400,
        orderId,
        typeNotification
      )
    );
  }
}

export function* actionWatcherSentEmail() {
  yield takeLatest(OrderNotificationActionEnum.REQUEST_EMAIL, fetchSentEmail);
}
