import { put, takeLatest } from 'redux-saga/effects';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import instance from '../../set';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';

function* fetchAddPaymentOrder({
  order_id,
  price,
  currency,
  note,
  isDelete,
  paymentType,
  createBy
}: ReturnType<typeof OrderActionCreators.addPaymentOrder>) {
  try {
    const { status } = yield instance.post(`order/history`, {
      order_id,
      price,
      currency,
      note,
      isDelete,
      'type': paymentType,
      createBy
    });
    yield put(OrderActionCreators.resultAddPaymentOrder(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddPaymentOrder() {
  yield takeLatest(
    OrderActionEnum.REQUEST_ADD_PAYMENT_ORDER,
    fetchAddPaymentOrder
  );
}
