import { FC, useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useSnackbar } from "notistack";
import { FormHelperText } from "@mui/material";
import ChangeImgsOrderModal from "../modal/ChangeImgsOrder";
import Icons from "src/common/icons";
import "./styles.scss";

interface IProps {
  previousFiles?: string[];
  onChangeField: any;
  changeOrder: any;
  handleDeleteFile: any;
  nameUploader: string;
  limitImages?: number;
  error?: string;
  title?: string;
  label?: string;
}
const fileTypes = ["SVG", "JPG", "JPEG", "GIF", "PNG"];

const MultiplyUploadFiles: FC<IProps> = (props) => {
  const { nameUploader, onChangeField, previousFiles, handleDeleteFile, changeOrder } =
    props;
  const { enqueueSnackbar } = useSnackbar();
  const [gallery, setGallery] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [block, setBlock] = useState(false);

  useEffect(() => {
    setGallery([]);
    if (previousFiles) {
      setGallery([...previousFiles]);
    }
  }, [previousFiles]);

  const uploadFileS = (files: File[]) => {
    const newGallery = [...gallery];
    let limitFiles: File[];
    if (props.limitImages) {
      limitFiles = Array.from([...files]).slice(
        0,
        props.limitImages - newGallery.length
      );
    } else limitFiles = [...files];
    [...limitFiles].forEach((x) => {
      newGallery.push(URL.createObjectURL(x));
      setGallery(newGallery);
      setBlock(true);
    });
    onChangeField(limitFiles);
  };

  const deleteFile = (url: string, index: number) => {
    setGallery([...gallery.slice(0, index), ...gallery.slice(index + 1)]);
    handleDeleteFile(url, index);
  };

  const getChangedPos = (currentPos: number, newPos: number) => {
    const element = gallery[currentPos];
    const arrayWithoutElement = gallery.filter((el,i) => i !== currentPos);
    const newArray = [
      ...arrayWithoutElement.slice(0, newPos),
      element,
      ...arrayWithoutElement.slice(newPos)
    ];
    setGallery(newArray);
    changeOrder(newArray);
  };

  const attachementCards = () => {
    return (
      gallery.map((url, index: number) => (
        <div className={`multi-upload__card-preview ${nameUploader === 'exteriorPhotos' && 'small-img'}`} key={index}>
          <img
            src={url}
            alt="van"
            style={{ cursor: 'pointer' }}
            onClick={block ? (
              () => enqueueSnackbar(
                "you cannot change the sequence of photos after adding new ones",
                { variant: 'info' }
              )
            ) : (
              () => setOpen(true)
            )}
          />
          <span
            className="multi-upload__btn_delete"
            onClick={() => deleteFile(url, index)}
          >
            <Icons.Close classes="multi-upload__icon" />
          </span>
        </div>
      ))
    );
  };

  const _uploader = () => (
    <FileUploader
      handleChange={uploadFileS}
      name={nameUploader}
      types={fileTypes}
      multiple={true}
      maxSize={51e6}
      classes={!!gallery.length && "short"}
      children={
        <div className="uploader__wrapper">
          <Icons.Folder />
          <div className="uploader__btn">
            <div className="uploader__btn__label">
              {props.label ?? "Browse image"}
            </div>
          </div>
        </div>
      }
    />
  );

  return (
    <>
      {props.title && <div className="multi-upload__title">{props.title}</div>}
      <div className={`multi-upload__wrapper ${props.error ? "error" : ""}`}>
        {props.limitImages
          ? gallery.length >= props.limitImages
            ? null
            : _uploader()
          : _uploader()}
        {props.error && (
          <FormHelperText children={props.error} sx={{ left: 15 }} />
        )}
        {gallery.length ? (
          <div className="attachments__wrapper col-2">{attachementCards()}</div>
        ) : null}
      </div>
      <ChangeImgsOrderModal 
      open={open} 
      handleClose={() => setOpen(false)} 
      name={nameUploader}
      items={gallery}
      handleChange={getChangedPos}
      />
    </>
  );
};

export default MultiplyUploadFiles;
