import { requestState, responseState } from "../utils";
import { LocationAction, LocationActionEnum, LocationState } from "./types";

const initialState: LocationState = {
  locationList: [],
  isLoading: {
    all: false,
    add: false,
    update: false,
  },
  result: {},
};

const location = (
  state = initialState,
  action: LocationAction
): LocationState => {
  switch (action.type) {
    case LocationActionEnum.REQUEST_LOCATIONS:
      return { 
        ...state, 
        isLoading: { ...state.isLoading, all: true },
        result: {}
      };
      
    case LocationActionEnum.RESULT_LOCATIONS:
      return {
        ...state,
        locationList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
        result: {...state.result, all: !!action.data}
      };

    case LocationActionEnum.REQUEST_ADD_LOCATION:
      return requestState(state, "add");
  
    case LocationActionEnum.RESULT_ADD_LOCATION:
      return responseState(state, "add", action);
        
    case LocationActionEnum.REQUEST_UPDATE_LOCATION:
      return requestState(state, "update");

    case LocationActionEnum.RESULT_UPDATE_LOCATION:
      return responseState(state, "update", action);

    case LocationActionEnum.CLEAR_LOCATION_RESULT:
      return { ...state, result: {} };

    default:
      return state;
  }
};
export default location;
