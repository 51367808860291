import {
  TagActionEnum,
  ResultAddTagAction,
  ResultDeleteTagAction,
  ResultUpdateTagAction,
  GetTagsAction,
  ResultGetTagsAction,
  DeleteTagAction,
  UpdateTagAction,
  AddTagAction,
  ITag,
} from "./types";

export const TagActionCreators = {
  getTags: (): GetTagsAction => ({
    type: TagActionEnum.REQUEST_TAGS,
  }),
  resultGetTags: (data: ITag[]): ResultGetTagsAction => ({
    type: TagActionEnum.RESULT_TAGS,
    data,
  }),

  deleteTag: (id: number): DeleteTagAction => ({
    type: TagActionEnum.REQUEST_DELETE_TAG,
    id,
  }),
  resultDeleteTag: (status: number): ResultDeleteTagAction => ({
    type: TagActionEnum.RESULT_DELETE_TAG,
    status,
  }),

  updateTag: (tag: ITag): UpdateTagAction => ({
    type: TagActionEnum.REQUEST_UPDATE_TAG,
    tag,
  }),
  resultUpdateTag: (status: number): ResultUpdateTagAction => ({
    type: TagActionEnum.RESULT_UPDATE_TAG,
    status,
  }),

  addTag: (data: ITag[]): AddTagAction => ({
    type: TagActionEnum.REQUEST_ADD_TAG,
    data,
  }),
  resultAddTag: (status: number): ResultAddTagAction => ({
    type: TagActionEnum.RESULT_ADD_TAG,
    status,
  }),
};
