import { INearVan, INearVanFetch } from "src/models/INearVan";
import { AddNearVansAction, DeleteNearVanAction, GetNearVanAction, GetNearVansAction, NearVanActionEnum, ResultAddNearVansAction, ResultDeleteNearVanAction, ResultGetNearVanAction, ResultGetNearVansAction, ResultUpdateNearVanAction, UpdateNearVanAction } from "./types";


export const NearVanActionCreators = {
  getNearVans: (): GetNearVansAction => ({
    type: NearVanActionEnum.REQUEST_NEARVANS,
  }),
  resultGetNearVans: (data: INearVanFetch[]): ResultGetNearVansAction => ({
    type: NearVanActionEnum.RESULT_NEARVANS,
    data,
  }),
  getNearVan: (id: number): GetNearVanAction => ({
    type: NearVanActionEnum.REQUEST_NEARVAN,
    id,
  }),
  resultGetNearVan: (data: INearVanFetch): ResultGetNearVanAction => ({
    type: NearVanActionEnum.RESULT_NEARVAN,
    data,
  }),
  addNearVans: (data: INearVan[]): AddNearVansAction => ({
    type: NearVanActionEnum.REQUEST_ADD_NEARVANS,
    data,
  }),
  resultAddNearVans: (status: number): ResultAddNearVansAction => ({
    type: NearVanActionEnum.RESULT_ADD_NEARVANS,
    status,
  }),
  updateNearVan: (currentNearVan: INearVanFetch, id: string | number): UpdateNearVanAction => ({
    type: NearVanActionEnum.REQUEST_UPDATE_NEARVAN,
    currentNearVan,
    id
  }),
  resultUpdateNearVan: (status: number): ResultUpdateNearVanAction => ({
    type: NearVanActionEnum.RESULT_UPDATE_NEARVAN,
    status,
  }),
  deleteNearVan: (id: number): DeleteNearVanAction => ({
    type: NearVanActionEnum.REQUEST_DELETE_NEARVAN,
    id,
  }),
  resultDeleteNearVan: (status: number): ResultDeleteNearVanAction => ({
    type: NearVanActionEnum.RESULT_DELETE_NEARVAN,
    status,
  }),
};
