import { FC } from "react";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, styled } from "@mui/material";
import Navigator from "src/components/Navigator";
import SnackbarIcon from "../Items/SnackbarIcon/insex";
import theme from "src/theme";
import "src/fonts/stylesheet.css";
import "./styles.scss";

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarContent-root {
    min-width: 220px;
  },
  &.SnackbarItem-contentRoot {
    background-color: #24242e;
    border-radius: 13px;
    padding-left: 16px;
  }
`;

const App: FC = () => (
  <StyledSnackbarProvider
    maxSnack={4}
    anchorOrigin={{ horizontal: "right", vertical: "top" }}
    autoHideDuration={3000}
    iconVariant={{
      success: <SnackbarIcon type={'success'} />,
      error: <SnackbarIcon type={'error'} />,
      warning: <SnackbarIcon type={'warning'} />,
      info: <SnackbarIcon type={'info'} />
    }}
  >
    <div className="App">
      <ThemeProvider theme={theme}>
        <Navigator />
      </ThemeProvider>
    </div>
  </StyledSnackbarProvider>
);

export default App;
