import { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Backdrop, Button, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import DayHourPicker from 'src/components/Items/input/DayHourPicker';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import useIsMobile from 'src/hooks/useIsMobile';
import { changeISOset, fromISOtoFormat, fromJSDateToISO } from 'src/common/utils/dataFormat';
import { Block } from "src/entity/Block";
import { ICalendarFilter } from 'src/models/IOrder';

interface IProps {
  open: boolean;
  handleClose: () => void;
  van: number;
  filter: ICalendarFilter;
}

const AddBlockModal: FC<IProps> = ({ open, handleClose, van, filter }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addVanBlock, getCalendar } = useActions();
  const isMobile = useIsMobile();
  const [block, setBlock] = useState(new Block());
  const [check, setCheck] = useState(false);
  const {
    van: { isLoading, result },
  } = useTypedSelector((state) => state);

    useEffect(() => {
      (isLoading.add === false && result.add) && (
        result.add === 200 ? (
          getCalendar({
            ...filter,
            order_status: 'default'
          }),
          enqueueSnackbar("Block successfully added", {
            variant: 'success',
            style: { color: `white !important` },
          })
        ) : (
          enqueueSnackbar("Block didn't added", { variant: 'error' })
        )
      );
    }, [isLoading.add, result]);

  const checkLess = (start?: string, end?: string) => {
    if (start && end) {
      return start >= end;
    } else return false;
  };

  const setStartDate = (date?: string, hour?: string) => {
    if (date && !hour) {
      let isoDate = fromJSDateToISO(date);
      const nowTime = DateTime.now().toFormat('D');
      if (fromISOtoFormat(isoDate, 'D') === nowTime) {
        setBlock({ ...block, start_date: moment(new Date).set({ second: 0 }).format('YYYY-MM-DDTHH:mm:ss[Z]') });
      } else {
        isoDate = changeISOset(isoDate, {
          hour: 10,
          minute: 0,
          second: 0,
        });
        setBlock({ ...block, start_date: isoDate });
        if (checkLess(isoDate, block.end_date!)) {
          const startObj = DateTime.fromISO(isoDate)
            .plus({ hours: 1 })
            .set({ second: 0 })
            .toISO();
          setBlock({ ...block, start_date: isoDate, end_date: startObj });
        }
      }
    }
    if (hour) {
      const isoDate = DateTime.fromISO(date ?? DateTime.now().toISO()).toISO();
      let time = hour;
      if (time === 'Noon') {
        time = '00:00 PM';
      }
      if (time === 'Midnight') {
        time = '00:00 AM';
      }

      const dayPart = time.split(' ');
      if (dayPart[1] === 'AM') {
        const time = dayPart[0].split(':');
        const changeHour = DateTime.fromISO(
          changeISOset(isoDate, {
            hour: (time[0] === '12') ? (+time[0] - 12) : (+time[0]),
            minute: +time[1],
            second: 0,
          })
        ).toISO();
        setBlock({ ...block, start_date: changeHour });
        if (checkLess(changeHour, block.end_date!)) {
          const startObj = DateTime.fromISO(changeHour)
            .plus({ hours: 1 })
            .set({ second: 0 })
            .toISO();
          setBlock({ ...block, start_date: changeHour, end_date: startObj });
        }
      } else {
        const time = dayPart[0].split(':');
        const changeHour = DateTime.fromISO(
          changeISOset(isoDate, {
            hour: (time[0] === '12') ? (+time[0]) : (+time[0] + 12),
            minute: +time[1],
            second: 0,
          })
        ).toISO();
        setBlock({ ...block, start_date: changeHour });
        if (checkLess(changeHour, block.end_date!)) {
          const startObj = DateTime.fromISO(changeHour)
            .plus({ hours: 1 })
            .set({ second: 0 })
            .toISO();
          setBlock({ ...block, start_date: changeHour, end_date: startObj });
        }
      }
    }
  };

  const setEndDate = (date?: string, hour?: string) => {

    if (checkLess(block.start_date, fromJSDateToISO(date)) && !hour) {
      const startObj = DateTime.fromISO(block.start_date!)
        .plus({ hours: 1 })
        .set({ second: 0 })
        .toISO();
      setBlock({ ...block, start_date: block.start_date, end_date: startObj });
    } else {
      if (date && !hour) {
        let isoDate = fromJSDateToISO(date);
        const nowTime = DateTime.now().toFormat('D');
        if (fromISOtoFormat(isoDate, 'D') === nowTime) {
          if (block.start_date) {
            setBlock({
              ...block, end_date: DateTime.fromISO(block.start_date!)
                .plus({ hours: 1 })
                .set({ second: 0 })
                .toISO()
            });
          } else
            setBlock({
              ...block, end_date: DateTime.now()
                .plus({ hours: 1 })
                .set({ second: 0 })
                .toISO()
            });
        } else if (fromISOtoFormat(isoDate, 'D') === fromISOtoFormat(block.start_date!, 'D')) {
          setBlock({
            ...block, end_date: DateTime.fromISO(block.start_date!)
              .plus({ hours: 1 })
              .set({ second: 0 })
              .toISO()
          });
        } else {
          isoDate = changeISOset(isoDate, {
            hour: 10,
            minute: 0,
            second: 0,
          });
          setBlock({ ...block, end_date: isoDate });
        }
      }
      if (hour) {
        const isoDate = DateTime.fromISO(date ?? DateTime.now().toISO()).toISO();
        let time = hour;
        if (time === 'Midnight') {
          time = '00:00 AM';
        }
        if (time === 'Noon') {
          time = '00:00 PM';
        }
        const dayPart = time.split(' ');
        if (dayPart[1] === 'AM') {
          const time = dayPart[0].split(':');
          const changeHour = DateTime.fromISO(
            changeISOset(isoDate, {
              hour: (time[0] === '12') ? (+time[0] - 12) : (+time[0]),
              minute: +time[1],
              second: 0,
            })
          ).toISO();
          setBlock({ ...block, end_date: changeHour });
          if (checkLess(block.start_date, changeHour)) {
            const startObj = DateTime.fromISO(block.start_date!)
              .plus({ hours: 1 })
              .set({ second: 0 })
              .toISO();
            setBlock({ ...block, start_date: block.start_date, end_date: startObj });
          }
        } else {
          const time = dayPart[0].split(':');
          const changeHour = DateTime.fromISO(
            changeISOset(isoDate, {
              hour: (time[0] === '12') ? (+time[0]) : (+time[0] + 12),
              minute: +time[1],
              second: 0,
            })
          ).toISO();
          setBlock({ ...block, end_date: changeHour });
          if (checkLess(block.start_date, changeHour)) {
            const startObj = DateTime.fromISO(block.start_date!)
              .plus({ hours: 1 })
              .set({ second: 0 })
              .toISO();
            setBlock({ ...block, start_date: block.start_date, end_date: startObj });
          }
        }
      }
    }
  };

  const handleSubmit = () => {
    setCheck(true);
    addVanBlock({id: van, date: {start_date: block.start_date, end_date: block.end_date}});
    setBlock(new Block());
    setCheck(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <div className='modal'style={{width: '700px'}}>
        <div className='modal__title'>Add Block</div>
        <div className='filter__time'>
          <div className='data-picker'>
            <div className='filter__title'>Date From</div>
            <DayHourPicker
              date={DateTime.fromISO(block.start_date, { zone: 'UTC' }).toISO()}
              setDate={setStartDate}
              minLimit={moment(new Date()).format()}
              isMobile={isMobile}
              check={check}
            />
          </div>
          <div className='data-picker'>
            <div className='filter__title'>Date Drop</div>
            <DayHourPicker
              date={DateTime.fromISO(block.end_date, { zone: 'UTC' }).toISO()}
              setDate={setEndDate}
              minLimit={block.start_date!}
              isMobile={isMobile}
              check={check}
            />
          </div>
        </div>
        <div className='col-2'>
          <SecondTypeButton
            children='Cancel'
            onClick={handleClose}
            sx={{ marginTop: '30px' }}
          />
          <Button
            sx={{ marginTop: 3.5 }}
            variant='contained'
            onClick={handleSubmit}
            children='Add Block'
          />
        </div>
      </div>
    </Modal>
  );
};
export default AddBlockModal;
