import { FC, useEffect } from 'react';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import SelectBaseOnId from '../../select/SelectBaseOnId';

const Host: FC = () => {
  const { setHostFilter } = useActions();
  const {
    host: { hostList },
    van: { filters },
  } = useTypedSelector((state) => state);

  useEffect(() => {
    hostList.sort(function (a, b) {
      const nameA = a.first_name.toLowerCase(), nameB = b.first_name.toLowerCase();
      if (nameA < nameB)
        return -1;
      if (nameA > nameB)
        return 1;
      return 0;
    });
  }, [hostList]);

  const setHostId = (hostId: number) => setHostFilter(hostId);

  return (
    <div className='filter-type'>
      <div style={{ display: `flex`, flexDirection: `column`, gap: `15px` }}>
        <SelectBaseOnId
          dataId={filters.host_id!}
          setDataId={setHostId}
          propsShow={['first_name', 'last_name']}
          dataArray={hostList}
          title='host'
        />
      </div>
    </div>
  );
};

export default Host;
