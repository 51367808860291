import { IHost } from "src/models/IHost";

export class Host implements IHost {
  email = "";
  city = "";
  phone = "";
  last_name = "";
  first_name = "";
  country = "";
  company_name = "";
  nearest_airport = "";
  home_address = "";
  state = "";
  zip_code = "";
  delivers = "";
  rents_at_home = "";
  bookimg_free = "";
  host_tag = "";
  password = "";
}