import { FC, useRef, useState } from "react";
import { Backdrop, Modal, Tooltip } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AllTagModal from "./All";
import { ITag, TagAdminCategoryEnum, TagCategoryEnum } from "src/store/reducers/tag/types";
import { EnumHelpers } from "src/common/utils/others";
import Icons from "src/common/icons";
import './styles.scss';

interface IProps {
  tags: ITag[];
  setTags: any;
  tagList: ITag[];
  role: string;
}

const TagModule: FC<IProps> = ({ tags, setTags, tagList, role }) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const handleClick = () => {
    anchorRef.current!.classList.toggle('rotate');
    setExpand(!expand);
  };

  const categories = EnumHelpers.getNames(
    role === "admin" ? TagAdminCategoryEnum : TagCategoryEnum
  );

  return (
    <>
      <div className='tag-module'>
        <div className='filter__title tag-module__title'>
          Tags
          <Tooltip title="Add tags">
            <div
              className="icon_stroke"
              onClick={handleOpenModal}
            >
              <Icons.Plus />
            </div>
          </Tooltip>
        </div>
        <div className='tag-module__container'>
          <div className={
            `tag-module__items 
            ${expand ? 'tag-module__items__show'
              : 'tag-module__items__hide'
            }`}
          >
            {tags.map((tag, key) => (
              <div
                className="modal__item van-card__tags__item"
                key={key}
                title={tag.name}
              >
                {tag.name}
              </div>
            ))}
          </div>
          <div
            ref={anchorRef}
            onClick={handleClick}
            className='tag-module__container__expand-icon'
          >
            <ExpandMoreIcon />
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        BackdropComponent={Backdrop}
        closeAfterTransition
      >
        <>
          <AllTagModal
            allTags={tagList}
            tags={tags}
            setTags={setTags}
            handleClose={handleCloseModal}
            categories={categories}
          />
        </>
      </Modal>
    </>
  );
};

export default TagModule;
