import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Table from 'src/components/Items/table';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroFormat, dineroIsPositive, dineroSubtract, fromISOtoFormat } from 'src/common/utils/dataFormat';

const OldReportTable: FC = () => {

  const {
    hostReport: { orderList, statistic },
    host: { hostList },
  } = useTypedSelector((state) => state);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => (
          <Link to={`/order/${id}`} className='react-link'>
            {id}
          </Link>
        ),
        width: 25,
      },
      {
        Header: 'Email',
        accessor: 'client_email',
        Cell: ({
          row: {
            original: { client_email },
          },
        }: any) => {
          return <div className='flex_CS'>{client_email}</div>;
        },
      },

      {
        Header: 'Start',
        accessor: 'start_date',
        Cell: ({
          row: {
            original: { start_date },
          },
        }) => <>{fromISOtoFormat(start_date, 'ff')}</>,
      },
      {
        Header: 'End',
        accessor: 'end_date',
        Cell: ({
          row: {
            original: { end_date },
          },
        }) => <>{fromISOtoFormat(end_date, 'ff')}</>,
      },
      {
        Header: 'Host',
        accessor: 'host_id',
        Cell: ({
          row: {
            original: {
              host: { first_name, last_name },
            },
          },
        }) => <>{`${first_name} ${last_name}`}</>,
      },
      {
        Header: 'Booked',
        accessor: 'created_at',
        Cell: ({
          row: {
            original: { created_at },
          },
        }) => <>{fromISOtoFormat(created_at, 'ff')}</>,
      },
      {
        Header: 'SR Collected',
        accessor: 'fee',
        Cell: ({
          row: {
            original: { fee, currency },
          },
        }) => <>{dineroFormat(fee, currency)}</>,
      },
      {
        Header: 'Host Collected',
        accessor: 'open',
        Cell: ({
          row: {
            original: { price, currency, fee },
          },
        }) => <>{dineroSubtract(price, fee, currency)}</>,
      },
      {
        Header: 'Total Sale',
        accessor: 'price',
        Cell: ({
          row: {
            original: { price, currency },
          },
        }) => <>{dineroFormat(price, currency)}</>,
      },

      {
        Header: '80% for Host',
        accessor: 'host_amount',
        Cell: ({
          row: {
            original: { host_amount, currency },
          },
        }) => <>{dineroFormat(host_amount, currency)}</>,
      },
      {
        Header: '20% for SR',
        accessor: 'fee',
        Cell: ({
          row: {
            original: { fee, currency },
          },
        }) => <>{dineroFormat(fee, currency)}</>,
      },
      {
        Header: 'Running Budget',
        accessor: 'offset_fee',
        Cell: ({
          row: {
            original: { offset_fee, currency },
          },
        }) => (
          <div
            style={{
              color: `${dineroIsPositive(offset_fee) ? 'green' : 'red'}`,
            }}
          >
            {dineroFormat(offset_fee, currency)}
          </div>
        ),
      },
    ],
    [hostList]
  );

  return (
    <Table.Base
      data={orderList}
      columns={columns}
      class='react-table'
      total={statistic._count._all || 0}
    />
  );
};

export default OldReportTable;
