import { put, takeLatest } from "redux-saga/effects";
import { OrderActionCreators } from "src/store/reducers/order/action-creators";
import { OrderActionEnum } from "src/store/reducers/order/types";
import instance from "../set";

function* fetchActionOrder({
  id,
  actionType,
}: ReturnType<typeof OrderActionCreators.actionOrder>) {
  try {
    const { status, data } = yield instance.post(`order/action/${id}`, {
      actionType
    });
    yield put(OrderActionCreators.resultActionOrder(status, data));
  } catch (e) {
    yield put(OrderActionCreators.resultActionOrder(400));
    console.log(e);
  }
}
export function* actionWatcherActionOrder() {
  yield takeLatest(OrderActionEnum.REQUEST_ACTION_ORDER, fetchActionOrder);
}
