import { ILocation, ILocationFetch } from "src/models/ILocation";

export enum LocationActionEnum {
  REQUEST_LOCATIONS = 'REQUEST_LOCATIONS',
  RESULT_LOCATIONS = 'RESULT_LOCATIONS',
  ERROR_LOCATIONS = 'ERROR_LOCATIONS',

  REQUEST_ADD_LOCATION = "REQUEST_ADD_LOCATION",
  RESULT_ADD_LOCATION = "RESULT_ADD_LOCATION",

  REQUEST_UPDATE_LOCATION = "REQUEST_UPDATE_LOCATION",
  RESULT_UPDATE_LOCATION = "RESULT_UPDATE_LOCATION",

  CLEAR_LOCATION_RESULT = 'CLEAR_LOCATION_RESULT'
}

export interface LocationState {
  locationList: ILocationFetch[];
  isLoading: {
    all: boolean;
    add: boolean;
    update: boolean;
  };
  result: {
    all?: boolean;
    add?: number | string;
    update?: number | string;
  };
}

export interface RequestLocationAction {
  type: LocationActionEnum.REQUEST_LOCATIONS;
}

export interface ResultLocationAction {
  type: LocationActionEnum.RESULT_LOCATIONS;
  data: ILocationFetch[];
}

export interface ErrorLocationAction {
  type: LocationActionEnum.ERROR_LOCATIONS;
  payload?: any;
}

export interface AddLocationAction {
  type: LocationActionEnum.REQUEST_ADD_LOCATION;
  data: ILocation;
}
export interface ResultAddLocationAction {
  type: LocationActionEnum.RESULT_ADD_LOCATION;
  status: number;
}

export interface UpdateLocationAction {
  type: LocationActionEnum.REQUEST_UPDATE_LOCATION;
  currentLocation: ILocationFetch;
  id: string | number;
}
export interface ResultUpdateLocationAction {
  type: LocationActionEnum.RESULT_UPDATE_LOCATION;
  status: number;
}

export interface ClearLocationResultAction {
  type: LocationActionEnum.CLEAR_LOCATION_RESULT;
}

export type LocationAction =
  | RequestLocationAction
  | ResultLocationAction
  | ErrorLocationAction
  | AddLocationAction
  | ResultAddLocationAction
  | UpdateLocationAction
  | ResultUpdateLocationAction
  | ClearLocationResultAction;
