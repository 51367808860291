import { Currency } from "dinero.js";
import { IVan, IVanFetch } from "src/models/IVan";

export class Van implements IVan {
  seats = 0;
  cargo_space = "";
  description = "";
  features = [];
  host_id = null;
  license_plate = "";
  mark = "";
  model = "";
  photos = {
    exterior: [],
    interior: [],
  };
  price = 0;
  currency = "USD" as Currency;
  status = "free";
  location = "";
  tags = [];
  type = "";
  vin_code = "";
  year = "";
  link = "";
  isVirtual = false;
  
  constructor(currentVan?: IVanFetch) {
    if (currentVan) {
      //@ts-ignore
      Object.keys(this).forEach((key) => (this[key] = currentVan[key]));
    }
  }
}
