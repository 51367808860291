import { FC } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SecondTypeButton from '../../button/SecondTypeButton';

interface IProps {
  handleClose: () => void;
  handleSubmit: () => void;
  type: string;
  action: string;
  disable?: boolean;
  smsText?: string;
}

const ModalAction: FC<IProps> = ({
  handleClose,
  handleSubmit,
  type,
  action,
  disable,
  smsText
}) => {
  const setAction = (action: string) => {
    const last = action[action.length - 1];
    if (last === 'e') return action.slice(0, -1) + 'ing';
    else return `${action}ing`;
  };

  const { enqueueSnackbar } = useSnackbar();
  const clipboard = useClipboard();
  const handleFullSumbit = () => {
    handleSubmit();
    enqueueSnackbar(type === 'Send & Charge' ? 'Sending after trip charges...' : `${setAction(action)} ${type}...`);
    handleClose();
  };
  const handleCloseForm = () => handleClose();
  
  return (
    <>
      {(type === 'sms' || type === 'payment link') ? (
        <div className='col-5'>
          <SecondTypeButton
            children='Cancel'
            onClick={handleCloseForm}
            sx={{ marginTop: '30px', gridColumn: '1/3' }}
          />
          <Button
            onClick={() => clipboard.copy(smsText)}
            style={{
              background: 'none',
              color: 'inherit',
              border: 'none',
              cursor: 'pointer',
              marginTop: '25px'
            }}
          >
            <ContentCopyIcon fontSize="large" />
          </Button>
          <Button
            sx={{ marginTop: 3.5, gridColumn: '4/6' }}
            variant='contained'
            onClick={handleFullSumbit}
            children={`${action} ${type}`}
            disabled={disable}
          />
        </div>
      ) : (
        <div className={'col-2'}>
          <SecondTypeButton
            children='Cancel'
            onClick={handleCloseForm}
            sx={{ marginTop: '30px' }}
          />
          <Button
            sx={{ marginTop: 3.5 }}
            variant='contained'
            onClick={handleFullSumbit}
            children={type === 'Send & Charge' ? `${type}` : `${action} ${type}`}
            disabled={disable}
          />
        </div>
      )
      }
    </>
  );
};
export default ModalAction;
