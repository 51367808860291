import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { store } from "src/store";
import { AuthActionEnum } from "src/store/reducers/auth/types";

const config: AxiosRequestConfig = {
    baseURL: `${process.env.REACT_APP_API}/admin/`,
};
const instanceNoSnackbar: AxiosInstance = axios.create(config);

instanceNoSnackbar.interceptors.request.use((config) => {
    config.headers!.Authorization = `${localStorage.getItem("token")}`;
    return config;
});

instanceNoSnackbar.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log("error", error);
        error.response.data === "Unauthorized"
            && store.dispatch({
                type: AuthActionEnum.EXIT,
                result: error.response.data,
            });
    }
);

export default instanceNoSnackbar;
