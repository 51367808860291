import { FC } from "react";
import clsx from "clsx";
import "./styles.scss";

interface IProps {
  className?: string;
  onClick: () => void;
}

const ClearFilters: FC<IProps> = ({ onClick, className }) => (
  <div className={clsx("clear-filters", className)} onClick={onClick}>
    Clear Filters
  </div>
);

export default ClearFilters;
