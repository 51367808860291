import { IPickupDetails, IPickupDetailsFetch } from "src/models/IPickupDetails";
import { AddPickupDetailsAction, ClearPickupDetailsResultAction, GetPickupDetailsAction, PickupDetailsActionEnum, ResultAddPickupDetailsAction, ResultGetPickupDetailsAction, ResultUpdatePickupDetailsAction, UpdatePickupDetailsAction } from "./types";

export const PickupDetailsActionCreators = {
  getPickupDetails: (): GetPickupDetailsAction => ({
    type: PickupDetailsActionEnum.REQUEST_PICKUPDETAILS,
  }),
  resultGetPickupDetails: (data: IPickupDetailsFetch[]): ResultGetPickupDetailsAction => ({
    type: PickupDetailsActionEnum.RESULT_PICKUPDETAILS,
    data,
  }),
  addPickupDetails: (data: IPickupDetails): AddPickupDetailsAction => ({
    type: PickupDetailsActionEnum.REQUEST_ADD_PICKUPDETAILS,
    data,
  }),
  resultAddPickupDetails: (status: number): ResultAddPickupDetailsAction => ({
    type: PickupDetailsActionEnum.RESULT_ADD_PICKUPDETAILS,
    status,
  }),
  updatePickupDetails: (currentPickupDetails: IPickupDetailsFetch, id: string | number): UpdatePickupDetailsAction => ({
    type: PickupDetailsActionEnum.REQUEST_UPDATE_PICKUPDETAILS,
    currentPickupDetails,
    id
  }),
  resultUpdatePickupDetails: (status: number): ResultUpdatePickupDetailsAction => ({
    type: PickupDetailsActionEnum.RESULT_UPDATE_PICKUPDETAILS,
    status,
  }),
  clearPickupDetailsResult: (): ClearPickupDetailsResultAction => ({
    type: PickupDetailsActionEnum.CLEAR_PICKUPDETAILS_RESULT
  }),
};
