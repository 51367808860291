import { IHostStaff, IHostStaffFetch } from "src/models/IHostStaff";
import { AddHostStaffAction, DeleteHostStaffAction, GetHostStaffAction, GetHostStaffByIdAction, HostStaffActionEnum, ResultAddHostStaffAction, ResultDeleteHostStaffAction, ResultGetHostStaffAction, ResultGetHostStaffByIdAction, ResultUpdateHostStaffAction, UpdateHostStaffAction } from "./types";

export const HostStaffActionCreators = {
  getHostStaff: (): GetHostStaffAction => ({
    type: HostStaffActionEnum.REQUEST_HOSTSTAFF
  }),
  resultGetHostStaff: (status: number, data?: IHostStaffFetch[]): ResultGetHostStaffAction => ({
    type: HostStaffActionEnum.RESULT_HOSTSTAFF,
    data,
    status
  }),
  getHostStaffById: (id: string | number): GetHostStaffByIdAction => ({
    type: HostStaffActionEnum.REQUEST_HOSTSTAFF_BY_ID,
    id
  }),
  resultGetHostStaffById: (status: number, data?: IHostStaffFetch): ResultGetHostStaffByIdAction => ({
    type: HostStaffActionEnum.RESULT_HOSTSTAFF_BY_ID,
    data,
    status
  }),
  addHostStaff: (data: IHostStaff): AddHostStaffAction => ({
    type: HostStaffActionEnum.REQUEST_ADD_HOSTSTAFF,
    data,
  }),
  resultAddHostStaff: (status: number): ResultAddHostStaffAction => ({
    type: HostStaffActionEnum.RESULT_ADD_HOSTSTAFF,
    status,
  }),
  updateHostStaff: (currentHostStaff: IHostStaffFetch, id: string | number): UpdateHostStaffAction => ({
    type: HostStaffActionEnum.REQUEST_UPDATE_HOSTSTAFF,
    currentHostStaff,
    id
  }),
  resultUpdateHostStaff: (status: number): ResultUpdateHostStaffAction => ({
    type: HostStaffActionEnum.RESULT_UPDATE_HOSTSTAFF,
    status,
  }),
  deleteHostStaff: (id: number): DeleteHostStaffAction => ({
    type: HostStaffActionEnum.REQUEST_DELETE_HOSTSTAFF,
    id,
  }),
  resultDeleteHostStaff: (status: number): ResultDeleteHostStaffAction => ({
    type: HostStaffActionEnum.RESULT_DELETE_HOSTSTAFF,
    status,
  }),
};
