import { FC, useEffect, useState } from 'react';
import lodash from 'lodash';
import { Backdrop, Button, Modal } from '@mui/material';
import SubPriceFormModal from './SubPriceForm';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import { validateField } from 'src/common/utils/validation';
import { dineroMultiply } from 'src/common/utils/dataFormat';
import { SubPrice } from "src/entity/SubPrice";
import { ISubPriceFetch } from 'src/models/ISubPrice';

interface IProps {
  open: boolean;
  handleClose: () => void;
  editSubPrice?: ISubPriceFetch;
}

const SubPriceModal: FC<IProps> = ({ open, handleClose, editSubPrice }) => {

  const { addSubPrice, updateSubPrice } = useActions();
  const [subPrice, setSubPrice] = useState<ISubPriceFetch | SubPrice>(new SubPrice());
  const [disable, setDisable] = useState(true);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (editSubPrice !== undefined) (
      setSubPrice(
        { 
          ...editSubPrice, 
          'price': lodash.divide(editSubPrice.price, 100),
        })
    );
  }, [open, editSubPrice]);

  const handleInput = (name: string) => (value: any) => {
    if (name === 'location') {
      setSubPrice({ 
        ...subPrice, 
        'location': value.name, 
        'sub_location_id': value.id 
      });
    } else setSubPrice({ ...subPrice, [name]: value });
  };

  const validateInputs = (): boolean => {
    const location = validateField(subPrice.location);
    const price = (subPrice.price === 0) ? 'Field can not be empty' : '';
    // const host_id = (subPrice.host_id === null ? 'Field can not be empty' : '');
    return [location, price].every((el) => !el);
  };

  useEffect(() => {
    JSON.stringify(editSubPrice) === JSON.stringify({ ...subPrice, 'price': lodash.multiply(subPrice.price, 100) }) ?
      setDisable(true) : setDisable(false);
  }, [subPrice]);

  const handleCloseModal = () => {
    editSubPrice ? setSubPrice(editSubPrice) : setSubPrice(new SubPrice());
    setCheck(false);
    handleClose();
  };

  const handleSubmit = () => {
    setCheck(true);
    if (validateInputs()) {
      setSubPrice(new SubPrice());
      setCheck(false);
      handleCloseModal();
      if (editSubPrice) {
        const updatedSubPrice = subPrice;
        for (const key in subPrice) {
          (subPrice[key as keyof typeof subPrice] === editSubPrice[key as keyof typeof editSubPrice] ||
            subPrice[key as keyof typeof subPrice] === ''
          ) && delete updatedSubPrice[key as keyof typeof updatedSubPrice];
        }
        updateSubPrice(
          {
            ...updatedSubPrice,
            'price': dineroMultiply(subPrice.price, 100)
          } as ISubPriceFetch, editSubPrice.id
        );
      } else addSubPrice([{
        ...subPrice,
        'price': dineroMultiply(subPrice.price, 100) 
      }]);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal' style={{ width: '450px' }}>
          <div className='modal__title'>{editSubPrice ? 'Edit Sub Price' : 'Add Sub Price'}</div>
          <SubPriceFormModal
            subPrice={subPrice}
            setSubPrice={handleInput}
            check={check}
          />
          <div className='col-2'>
            <SecondTypeButton
              children='Cancel'
              onClick={handleCloseModal}
              sx={{ marginTop: '30px' }}
            />
            <Button
              sx={{ marginTop: 3.5 }}
              variant='contained'
              onClick={handleSubmit}
              children={editSubPrice ? 'Edit Sub Price' : 'Add Sub Price'}
              disabled={editSubPrice ? disable : false}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
export default SubPriceModal;
