import { useEffect } from "react";
import { useSnackbar } from "notistack";

const useShowSnackbar = (isLoading: any, result: any, clean: any, name: string) => {

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isLoading.add === false && result.add) {
            (result.add === 200 || result.add === 201) ? (
                enqueueSnackbar(`${name} successfully added`, {
                    variant: 'success'
                })
            ) : enqueueSnackbar(`${name} didn't added`, { variant: 'error' });
            clean();
        } else if (isLoading.update === false && result.update) {
            (result.update === 200 || result.update === 201) ? (
                enqueueSnackbar(`${name} successfully updated`, {
                    variant: 'success'
                })
            ) : enqueueSnackbar(`${name} didn't updated`, { variant: 'error' });
            clean();
        } else if (isLoading.delete === false && result.delete) {
            (result.delete === 200 || result.delete === 201) ? (
                enqueueSnackbar(`${name} successfully deleted`, {
                    variant: 'success'
                })
            ) : enqueueSnackbar(`${name} didn't deleted`, { variant: 'error' });
            clean();
        }
    }, [isLoading, result]);

};
export default useShowSnackbar;
