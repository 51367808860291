import { OrderActionCreators } from "src/store/reducers/order/action-creators";
import { put, takeLatest } from "redux-saga/effects";
import { OrderActionEnum } from "src/store/reducers/order/types";
import instance from "../set";

function* fetchGetOrder({
  id,
}: ReturnType<typeof OrderActionCreators.getOrder>) {
  try {
    const { data } = yield instance.get(`order/${id}`);
    yield put(OrderActionCreators.resultGetOrder(data));
  } catch (error: any) {
    yield put(OrderActionCreators.errorGetOrder(400));
  }
}
export function* actionWatcherGetOrder() {
  yield takeLatest(OrderActionEnum.REQUEST_ORDER, fetchGetOrder);
}
