import { FC, useEffect, useState } from 'react';
import Decimal from 'decimal.js';
import { useSnackbar } from 'notistack';
import { Button, Modal, Switch } from '@mui/material';
import NumberInput from 'src/components/Items/input/NumberInput';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IPayOrder } from 'src/models/IOrder';

interface IProps {
  orderId: number;
  isStripeId: boolean;
  isOpenCharge?: boolean;
  open: boolean;
  handleClose: () => void;
}

const NewPay: FC<IProps> = ({ orderId, isStripeId, isOpenCharge, open, handleClose }) => {

  const { addPaymentOrder, addPartialPaymentOrder, afterChargePayment } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const { role } = useTypedSelector((state) => state.auth);
  const [pay, setPay] = useState<IPayOrder>({
    currency: 'USD',
  } as IPayOrder);
  const [errors, setErrors] = useState({ price: true, note: true });
  const [check, setCheck] = useState(false);
  const [isStripe, setIsStripe] = useState(false);

  const handleChange = (name: string) => (value: string) => {
    setPay({ ...pay, [name]: value });
    isStripe && name === 'price' && value ? (
      setErrors({ ...errors, price: Number(value) > 0 ? false : true })
    ) : (setErrors({ ...errors, [name]: !value }));
  };

  const handleAddPay = () => {
    setCheck(false);
    if (!Object.values(errors).find((err) => err)) {
      if (isStripe) {
        enqueueSnackbar('Adding a partial payment...');
        addPartialPaymentOrder(
          Number(Decimal.mul(pay.price, 100)),
          pay.currency,
          pay.note,
          orderId,
        );
      } else if (isOpenCharge) {
        enqueueSnackbar('Adding a after charge payment...');
        afterChargePayment(
          orderId,
          Number(Decimal.mul(pay.price, 100)),
          pay.note
        );
      } else {
        enqueueSnackbar('Adding a payment...');
        addPaymentOrder(
          orderId,
          Number(Decimal.mul(pay.price, 100)),
          pay.currency,
          pay.note,
          true,
          'manual_payment',
          role === 'admin' ? 'admin' : 'host'
        );
      }
      handleClose();
      setIsStripe(false);
    } else setCheck(true);
  };

  useEffect(() => {
    setPay({
      currency: 'USD',
    } as IPayOrder);
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <>
        <div className='order-show__new-pay'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='order-show__new-pay__title'>
              {isOpenCharge
                ? 'After trip charges'
                : 'Adding a payment'
              }
            </div>
            {!isOpenCharge && (isStripeId || role === 'admin') && (
              <div className='order-show__new-pay__stripe'>
                <Switch onChange={() => setIsStripe(!isStripe)} />
                <p className='filter__title'>Stripe</p>
              </div>
            )}
          </div>
          <div className='col-2'>
            <NumberInput
              data={pay.price}
              setData={handleChange('price')}
              placeholder='Price'
              unlimit
              check={check}
              error={(isStripe && pay.price < 0) ? "The price can't be negative" : ''}
              text
            />
            <SimpleSelect
              data={pay.currency}
              setData={handleChange('currency')}
              dataArray={['USD', 'EUR']}
              placeholder='currency'
            />
          </div>
          <SimpleInput
            data={pay.note}
            setData={handleChange('note')}
            placeholder='Your description'
            rows={2}
            check={check}
            multiline
            variant='outlined'
          />
          {isOpenCharge && (
            <div className='order-show__new-pay__alert'>
              Careful, it's final.
            </div>
          )}
          <div className='col-2'>
            <SecondTypeButton
              children='Cancel'
              onClick={() => handleClose()}
            />
            <Button
              onClick={handleAddPay}
              children={isOpenCharge ? 'Pay' : 'Add pay'}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};

export default NewPay;
