import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { SublocationActionCreators } from "src/store/reducers/sublocation/action-creators";
import { SublocationActionEnum } from "src/store/reducers/sublocation/types";

function* fetchAddSublocations({
    data,
}: ReturnType<typeof SublocationActionCreators.addSublocations>) {
  try {
    const { status }: Response = yield instance.post('subLocation', { 
      data 
    });
    yield put(SublocationActionCreators.resultAddSublocations(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddSublocations() {
  yield takeLatest(SublocationActionEnum.REQUEST_ADD_SUBLOCATIONS, fetchAddSublocations);
}