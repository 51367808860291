import { put, takeLatest } from "redux-saga/effects";
import { OrderPaymentLinkActionCreators } from "src/store/reducers/order/action-creators";
import { OrderPaymentLinkActionEnum } from "src/store/reducers/order/types";
import instance from "src/sagas/set";

function* fetchGetPaymentLink({
  orderId,
}: ReturnType<typeof OrderPaymentLinkActionCreators.getPaymentLink>) {
  try {
    const { data } = yield instance.get(`stripe/create_pl/${orderId}`);
    yield put(OrderPaymentLinkActionCreators.resultPaymentLink(data));
  } catch (error: any) {
    console.log("error :>> ", error);
    yield put(OrderPaymentLinkActionCreators.resultPaymentLink(''));
  }
}
export function* actionWatcherGetPaymentLink() {
  yield takeLatest(OrderPaymentLinkActionEnum.REQUEST_PAYMENTLINK, fetchGetPaymentLink);
}
