import { CalendarAction, CalendarActionEnum, CalendarState } from './types';

const initialState: CalendarState = {
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
  },
};
const calendar = (
  state = initialState,
  action: CalendarAction
): CalendarState => {
  switch (action.type) {
    case CalendarActionEnum.REQUEST_CALENDAR:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case CalendarActionEnum.RESULT_CALENDAR:
      return {
        ...state,
        data: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };
    case CalendarActionEnum.REQUEST_UPDATE_CALENDAR:
      return { ...state, isLoading: { ...state.isLoading, update: true } };
    case CalendarActionEnum.RESULT_UPDATE_CALENDAR:
      return {
        ...state,
        data: action.data,
        isLoading: { ...state.isLoading, update: !action.data },
      };

    default:
      return state;
  }
};
export default calendar;
