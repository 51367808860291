import { BadgeThemeOrderEnum, IPayOrderFetch } from "src/models/IOrder";

export enum ReportActionEnum {
  REQUEST_REPORT = 'REQUEST_REPORT',
  RESULT_REPORT = 'RESULT_REPORT',
  REQUEST_NEW_REPORT = 'REQUEST_NEW_REPORT',
  RESULT_NEW_REPORT = 'RESULT_NEW_REPORT',
  ERROR_REPORT = 'ERROR_REPORT',
}
export interface ReportHostOrder {
  id: number;
  partial_paid: number;
  price: number;
  currency: string;
  client_email: string;
  host: any;
  start_date: string | Date;
  end_date: string | Date;
  created_at: string | Date;
  fee: number;
  host_amount: number;
  offset_fee: number;
}
export interface ReportHostStatistic {
  _sum: { price: number | null; partial_paid: number | null };
  _count: { _all: number | null };
  got?: number;
  take?: number;
  offset?: number;
  fee?: number;
  srTotal?: number
  hostTotal?: number
  stripeFeeTotal?: number;
  cashFlowTotal?: number;
}
export interface HostReportFilter {
  period: {
    start: string;
    end: string;
  };
  host_id: number[];
}

export interface NewReportHostOrder {
  id: number;
  partial_paid: number;
  price: number;
  currency: string;
  start_date: string | Date;
  end_date: string | Date;
  van: { host_id: number; host: { stripe_connect_id: string } };
  handle_payments: IPayOrderFetch[];
  status: keyof typeof BadgeThemeOrderEnum;
  guestPaid: number;
  unpaid: number;
  srGot: number;
  hostGot: number;
  onStripe: number;
  transfer: string;
  stripeId: string;
  stripeMatch: string;
  host80: number;
  sr20: number;
  debitCredit: number;
  stripeFee: number;
  otherHostPay: number;
}

export interface ReportState {
  orderList: ReportHostOrder[] | NewReportHostOrder[];
  statistic: ReportHostStatistic;
  isLoading: {
    old: boolean;
    new: boolean;
  };
  result: {
    old?: number | string;
    new?: number | string;
  }
  link: string;
}
export interface RequestReport {
  type: ReportActionEnum.REQUEST_REPORT;
  filter: HostReportFilter;
}
export interface ResultReport {
  type: ReportActionEnum.RESULT_REPORT;
  data: {
    orders: ReportHostOrder[];
    statistic: ReportHostStatistic;
    link: string;
  };
  status: number;
}
export interface RequestNewReport {
  type: ReportActionEnum.REQUEST_NEW_REPORT;
  filter: HostReportFilter;
}
export interface ResultNewReport {
  type: ReportActionEnum.RESULT_NEW_REPORT;
  data: {
    orders: NewReportHostOrder[];
    statistic: ReportHostStatistic;
  };
  status: number;
}
export interface ErrorReport {
  type: ReportActionEnum.ERROR_REPORT;
}
export type ReportAction =
  RequestReport
  | ResultReport
  | ErrorReport
  | RequestNewReport
  | ResultNewReport;
