import { put, takeLatest } from "redux-saga/effects";
import { OrderActionCreators } from "src/store/reducers/order/action-creators";
import { OrderActionEnum } from "src/store/reducers/order/types";
import instance from "../set";

function* fetchAddOrder({
  currentOrder,
}: ReturnType<typeof OrderActionCreators.addOrder>) {
  const { id, ...order } = currentOrder;
  try {
    const { status }: Response = yield instance.post(`order/add`, {
      order,
    });
    yield put(OrderActionCreators.resultAddOrder(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherAddOrder() {
  yield takeLatest(OrderActionEnum.REQUEST_ADD_ORDER, fetchAddOrder);
}
