import {
  FormControl,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
  FormHelperText,
} from '@mui/material';
import { FC } from 'react';
import { deepmerge } from '@mui/utils';
import theme, { colorSet, typeForm } from 'src/theme';

interface ISelect {
  check?: boolean;
  data?: string | number;
  setData: (event: any) => void;
  title?: string;
  dataArray: string[] | number[];
  style?: {};
  placeholder?: string;
  notShowSelect?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  maxHeight?: string;
  error?: string;
  disabled?: boolean;
}

const SimpleSelect: FC<ISelect> = ({
  data,
  setData,
  dataArray,
  check,
  title,
  style,
  placeholder = title,
  variant,
  notShowSelect,
  maxHeight,
  error,
  disabled
}) => {
  const themeMultiSelect = createTheme(
    deepmerge(theme, {
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              maxHeight: maxHeight ? maxHeight : '260px !important',
            },
          },
        },
      },
    })
  );

  return (
    <ThemeProvider theme={themeMultiSelect}>
      <div>
        {title && <div className='filter__title'>{title}</div>}
        <FormControl sx={style}>
          <Select
            displayEmpty
            value={data ?? ''}
            variant={variant ?? typeForm}
            disabled={disabled}
            onChange={(e) => setData(e.target.value)}
            renderValue={(n) =>
              !n || !data ? (
                check ? (
                  <div style={{ color: colorSet.primaryRed }}>
                    Must be selected
                  </div>
                ) : (
                  <div style={{ color: colorSet.primaryGrey }}>
                    {!notShowSelect && 'Select'} {placeholder}
                  </div>
                )
              ) : (
                n
              )
            }
            title={`${data!}`}
          >
            {dataArray.map((d, i) => (
              <MenuItem key={i} value={d}>
                {d}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </div>
    </ThemeProvider>
  );
};

export default SimpleSelect;
