import { IBase } from "src/models/types";
import {
  AddFeatureAction,
  DeleteFeatureAction,
  FeatureActionEnum,
  GetFeaturesAction,
  ResultAddFeatureAction,
  ResultDeleteFeatureAction,
  ResultGetFeaturesAction,
  ResultUpdateFeatureAction,
  UpdateFeatureAction,
} from "./types";

export const FeatureActionCreators = {
  getFeatures: (): GetFeaturesAction => ({
    type: FeatureActionEnum.REQUEST_FEATURES,
  }),
  resultGetFeatures: (data: IBase[]): ResultGetFeaturesAction => ({
    type: FeatureActionEnum.RESULT_FEATURES,
    data,
  }),

  deleteFeature: (id: number): DeleteFeatureAction => ({
    type: FeatureActionEnum.REQUEST_DELETE_FEATURE,
    id,
  }),
  resultDeleteFeature: (status: number): ResultDeleteFeatureAction => ({
    type: FeatureActionEnum.RESULT_DELETE_FEATURE,
    status,
  }),

  updateFeature: (feature: IBase): UpdateFeatureAction => ({
    type: FeatureActionEnum.REQUEST_UPDATE_FEATURE,
    feature,
  }),
  resultUpdateFeature: (status: number): ResultUpdateFeatureAction => ({
    type: FeatureActionEnum.RESULT_UPDATE_FEATURE,
    status,
  }),

  addFeature: (nameArray: IBase[]): AddFeatureAction => ({
    type: FeatureActionEnum.REQUEST_ADD_FEATURE,
    nameArray,
  }),
  resultAddFeature: (status: number): ResultAddFeatureAction => ({
    type: FeatureActionEnum.RESULT_ADD_FEATURE,
    status,
  }),
};
