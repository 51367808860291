import { AuthActionEnum } from 'src/store/reducers/auth/types';
import { takeLatest } from 'redux-saga/effects';

function* fetchExit() {
  yield localStorage.clear();
  yield location.reload();
}
export function* actionWatcherExit() {
  yield takeLatest(AuthActionEnum.EXIT, fetchExit);
}
