import { DateTime } from 'luxon';
import { IVanFilter } from 'src/models/IVanFilter';
import { requestState, responseState } from '../utils';
import {
  VanAction,
  VanActionEnum,
  VanFilterAction,
  VanFilterActionEnum,
  VanState,
} from './types';

export const initFilter: IVanFilter = {
  page: 1,
  take: 10,
  location: '',
  features: [],
  period: {
    start_date: DateTime.now().toISO(),
    end_date: DateTime.now().toISO(),
  },
};
const initialState: VanState = {
  vanList: [],
  total: null,
  minPrice: null,
  maxPrice: null,
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
    uploadingImage: false,
  },
  result: {},
  filters: { ...initFilter },
};
const van = (
  state = initialState,
  action: VanAction | VanFilterAction
): VanState => {
  switch (action.type) {
    case VanActionEnum.REQUEST_VANS:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case VanActionEnum.RESULT_VANS:
      return {
        ...state,
        vanList: action.vanList,
        total: action.count,
        minPrice: action.minPrice / 100,
        maxPrice: action.maxPrice / 100,
        isLoading: { ...state.isLoading, all: !action.vanList },
      };
    case VanActionEnum.REQUEST_ADD_VAN_IMAGES:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadingImage: true },
        result: { ...state.result, add: undefined },
      };
    case VanActionEnum.RESULT_ADD_VAN_IMAGES:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadingImage: !action.status },
        result: { uploadingImage: action.status }
      };
    case VanActionEnum.REQUEST_ADD_VAN:
      return requestState(state, 'add');

    case VanActionEnum.RESULT_ADD_VAN:
      return {
        ...state,
        van: action.data,
        isLoading: { ...state.isLoading, add: !action.data },
        result: { ...state.result, add: action.status }
      };

    case VanActionEnum.REQUEST_VAN:
      return {
        ...state,
        isLoading: { ...state.isLoading, get: true },
      };
    case VanActionEnum.RESULT_VAN:
      return {
        ...state,
        van: action.data,
        isLoading: { ...state.isLoading, get: !action.data },
      };

    case VanActionEnum.REQUEST_ADD_VAN_BLOCK:
      return requestState(state, 'add');

    case VanActionEnum.RESULT_ADD_VAN_BLOCK:
      return responseState(state, 'add', action);

    case VanActionEnum.REQUEST_UPDATE_VAN_IMAGES:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadingImage: true },
        result: { ...state.result, update: undefined },
      };
    case VanActionEnum.RESULT_UPDATE_VAN_IMAGES:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadingImage: !action.status },
        result: { uploadingImage: action.status }
      };

    case VanActionEnum.REQUEST_UPDATE_VAN:
      return requestState(state, 'update');

    case VanActionEnum.RESULT_UPDATE_VAN:
      return responseState(state, 'update', action);

    case VanActionEnum.REQUEST_DELETE_VAN:
      return requestState(state, 'delete');

    case VanActionEnum.RESULT_DELETE_VAN:
      return responseState(state, 'delete', action);

    case VanFilterActionEnum.HOST_ID:
      return {
        ...state,
        filters: { ...state.filters, host_id: action.hostId },
      };
    case VanFilterActionEnum.FEATURES:
      return {
        ...state,
        filters: { ...state.filters, features: action.features },
      };
    case VanFilterActionEnum.TYPE:
      return {
        ...state,
        filters: { ...state.filters, type: action.vanType },
      };
    case VanFilterActionEnum.SEATS:
      return {
        ...state,
        filters: { ...state.filters, seats: Number(action.seat) },
      };
    case VanFilterActionEnum.START_PRICE:
      return {
        ...state,
        filters: {
          ...state.filters,
          price: {
            ...state.filters.price,
            startPrice:
              action.startPrice !== null
                ? Number(action.startPrice)
                : undefined,
          },
        },
      };
    case VanFilterActionEnum.END_PRICE:
      return {
        ...state,
        filters: {
          ...state.filters,
          price: {
            ...state.filters.price,
            endPrice:
              action.endPrice !== null ? Number(action.endPrice) : undefined,
          },
        },
      };
    case VanFilterActionEnum.START_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          period: {
            ...state.filters.period,
            start_date: action.startDate,
          },
        },
      };
    case VanFilterActionEnum.END_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          period: {
            ...state.filters.period,
            end_date: action.endDate,
          },
        },
      };

    case VanFilterActionEnum.CLEAR:
      return {
        ...state,
        filters: { ...initFilter },
      };
    default:
      return state;
  }
};
export default van;
