import { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import sumBy from 'lodash/sumBy';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import PriceSelection from './PriceSelection';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import Times from 'src/components/Items/filters/Item/Times';
import Location from 'src/components/Items/filters/Item/Location';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import CustomCheckbox from 'src/components/Items/button/CustomCheckbox';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroAdd, dineroDivide, dineroMultiply, dineroSubtractAmount } from 'src/common/utils/dataFormat';
import { IOrderFetch } from 'src/models/IOrder';
import './styles.scss';

interface IProps {
  handleClose: () => void;
}

const MainOrderForm: FC<IProps> = ({ handleClose }) => {
  const { updateOrder } = useActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const currentOrder = useTypedSelector((state) => state.order.order!);
  const pds = useTypedSelector((state) => state.pickupDetails.pickupDetailsList);

  const [changedOrder, setChangedOrder] = useState<IOrderFetch>(currentOrder);
  const [price, setPrice] = useState<number>();
  const [disable, setDisable] = useState(true);
  const [showPrice, setShowPrice] = useState(false);
  const [defaultStart, setDefaultStart] = useState(false);
  const [defaultEnd, setDefaultEnd] = useState(false);

  useEffect(() => {
    setChangedOrder(currentOrder);
    !currentOrder!.return_at &&
      setChangedOrder({
        ...currentOrder,
        return_at: 'Same as delivery'
      });
  }, [currentOrder]);

  useEffect(() => {
    changedOrder.location && setDefaultStart(false);
    changedOrder.end_location && setDefaultEnd(false);
  }, [changedOrder.location, changedOrder.end_location]);

  useEffect(() => {
    if (defaultStart) {
      if (changedOrder.sub_location) {
        const pickups = pds.filter((pd) => (
          pd.location === `${changedOrder.location}__${changedOrder.sub_location}`
        ));
        const pdWithHostId = pickups.find((pd) => (
          pd.host_id && pd.host_id === changedOrder.van.host_id
        ));
        if (pdWithHostId) {
          setChangedOrder({
            ...changedOrder,
            delivery_address: pdWithHostId.address_take
          });
        } else if (pickups.length) {
          setChangedOrder({
            ...changedOrder,
            delivery_address: pickups[0].address_take
          });
        } else {
          setChangedOrder({
            ...changedOrder,
            delivery_address: ''
          });
          enqueueSnackbar('No suitable default start locations found', {
            variant: 'info',
            persist: true,
          });
          setTimeout(() => closeSnackbar(), 7000);
        }
      } else {
        const pickups = pds.filter((pd) => pd.location === changedOrder.location);
        const pdWithHostId = pickups.find((pd) => (
          pd.host_id && pd.host_id === changedOrder.van.host_id
        ));
        if (pdWithHostId) {
          setChangedOrder({
            ...changedOrder,
            delivery_address: pdWithHostId.address_take
          });
        } else if (pickups.length) {
          setChangedOrder({
            ...changedOrder,
            delivery_address: pickups[0].address_take
          });
        } else {
          setChangedOrder({
            ...changedOrder,
            delivery_address: ''
          });
          enqueueSnackbar('No suitable default start locations found', {
            variant: 'info',
            persist: true,
          });
          setTimeout(() => closeSnackbar(), 7000);
        }
      }
    } else {
      setChangedOrder({
        ...changedOrder,
        delivery_address: currentOrder.delivery_address
      });
    }
  }, [defaultStart]);

  useEffect(() => {
    if (defaultEnd) {
      if (changedOrder.sub_location_end) {
        const pickups = pds.filter((pd) => (
          pd.location === `${changedOrder.end_location
            ? changedOrder.end_location
            : changedOrder.location
          }__${changedOrder.sub_location_end}`
        ));
        const pdWithHostId = pickups.find((pd) => (
          pd.host_id && pd.host_id === changedOrder.van.host_id
        ));
        if (pdWithHostId) {
          setChangedOrder({
            ...changedOrder,
            return_at: pdWithHostId.address_drop
          });
        } else if (pickups.length) {
          setChangedOrder({
            ...changedOrder,
            return_at: pickups[0].address_drop
          });
        } else {
          setChangedOrder({
            ...changedOrder,
            return_at: ''
          });
          enqueueSnackbar('No suitable default end locations found', {
            variant: 'info',
            persist: true,
          });
          setTimeout(() => closeSnackbar(), 7000);
        }
      } else {
        const pickups = pds.filter((pd) => (
          changedOrder.end_location
            ? pd.location === changedOrder.end_location
            : pd.location === changedOrder.location
        ));
        const pdWithHostId = pickups.find((pd) => (
          pd.host_id && pd.host_id === changedOrder.van.host_id
        ));
        if (pdWithHostId) {
          setChangedOrder({
            ...changedOrder,
            return_at: pdWithHostId.address_drop
          });
        } else if (pickups.length) {
          setChangedOrder({
            ...changedOrder,
            return_at: pickups[0].address_drop
          });
        } else {
          setChangedOrder({
            ...changedOrder,
            return_at: ''
          });
          enqueueSnackbar('No suitable default end locations found', {
            variant: 'info',
            persist: true,
          });
          setTimeout(() => closeSnackbar(), 7000);
        }
      }
    } else {
      setChangedOrder({
        ...changedOrder,
        return_at: currentOrder.return_at
      });
    }
  }, [defaultEnd]);

  const handleChangePrice = () => {
    const newDate = DateTime.fromISO(changedOrder.end_date!)
      .diff(DateTime.fromISO(changedOrder.start_date!), ['days', 'hours'])
      .toObject();

    const { hours, days } = newDate;
    let vanPrice = dineroMultiply(currentOrder.van.price, newDate.days!);

    if (hours && days) {
      if (hours <= 6 && hours > 0) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroDivide(currentOrder.van?.price!, 4),
          currentOrder.currency!
        );
      } else if (hours > 6 && hours <= 12) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroDivide(currentOrder.van?.price!, 2),
          currentOrder.currency!
        );
      } else if (hours > 12 && hours <= 18) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroSubtractAmount(
            currentOrder.van?.price!,
            dineroDivide(currentOrder.van?.price!, 4),
            currentOrder.currency!
          ),
          currentOrder.currency!
        );
      } else if (hours > 18 && hours <= 24) {
        vanPrice = dineroAdd(
          vanPrice,
          currentOrder.van?.price!,
          currentOrder.currency!
        );
      }
    }

    if (!vanPrice) vanPrice = currentOrder.van.price;

    const extrasPrice = sumBy(currentOrder.fee_list, 'price');
    setChangedOrder({
      ...changedOrder,
      price: dineroAdd(
        vanPrice,
        extrasPrice,
        currentOrder.currency!
      )
    });
    setPrice(
      dineroAdd(
        vanPrice,
        extrasPrice,
        currentOrder.currency!
      )
    );
  };

  const handleChange = (name: string) => (value: string) =>
    setChangedOrder({ ...changedOrder, [name]: value });

  useEffect(() => {
    const sameReturn = (changedOrder.return_at === 'Same as delivery')
      ? '' : changedOrder.return_at;
    (changedOrder.start_date === currentOrder.start_date &&
      changedOrder.end_date === currentOrder.end_date &&
      changedOrder.location === currentOrder.location &&
      changedOrder.sub_location === currentOrder.sub_location &&
      changedOrder.sub_location_end === currentOrder.sub_location_end &&
      changedOrder.end_location === currentOrder.end_location &&
      changedOrder.delivery_address === currentOrder.delivery_address &&
      sameReturn === currentOrder.return_at
    ) ? setDisable(true) : setDisable(false);
  }, [changedOrder.start_date,
  changedOrder.end_date,
  changedOrder.location,
  changedOrder.end_location,
  changedOrder.sub_location,
  changedOrder.sub_location_end,
  changedOrder.delivery_address,
  changedOrder.return_at
  ]);

  useEffect(() => {
    (changedOrder.start_date === currentOrder.start_date &&
      changedOrder.end_date === currentOrder.end_date) ?
      setShowPrice(false) : (
        setShowPrice(true),
        handleChangePrice()
      );
  }, [changedOrder.start_date, changedOrder.end_date]);

  const changeOrder = () => {
    const updatedOrder = changedOrder;
    for (const key in changedOrder) {
      (
        changedOrder[key as keyof typeof changedOrder] === currentOrder[key as keyof typeof currentOrder]
        || (key === 'return_at' && changedOrder.return_at === 'Same as delivery')
      ) && delete updatedOrder[key as keyof typeof updatedOrder];
      currentOrder[key as keyof typeof currentOrder] === price &&
        delete updatedOrder[key as keyof typeof updatedOrder];
    }
    setDisable(true);
    updateOrder({ ...updatedOrder, id: currentOrder.id });
    handleClose();
  };

  return (
    <div className='order-form'>
      <Times check={false} order={changedOrder} setOrder={setChangedOrder} />
      <Location order={changedOrder} setOrder={setChangedOrder} />
      <div>
        <SimpleInput
          data={changedOrder.delivery_address!}
          setData={handleChange('delivery_address')}
          title='Delivery to'
          variant='outlined'
          length={200}
          multiline
        />
        <div className='order-form__checkbox'>
          <CustomCheckbox
            onChange={() => setDefaultStart(!defaultStart)}
            checked={defaultStart}
            name='Check to use default: Start Location'
          />
          <span>Check to use default: Start Location</span>
        </div>
      </div>
      <div>
        <SimpleInput
          data={changedOrder.return_at!}
          setData={handleChange('return_at')}
          title='Return at'
          variant='outlined'
          length={200}
          multiline
        />
        <div className='order-form__checkbox'>
          <CustomCheckbox
            onChange={() => setDefaultEnd(!defaultEnd)}
            checked={defaultEnd}
            name='Check to use default: End Location'
          />
          <span>Check to use default: End Location</span>
        </div>
      </div>
      {showPrice &&
        <PriceSelection
          oldPrice={currentOrder.price}
          newPrice={changedOrder.price!}
          currency={currentOrder.currency!}
          setPrice={setPrice}
        />
      }
      <div className='col-2'>
        <SecondTypeButton
          children='Cancel'
          onClick={handleClose}
        />
        <Button
          variant='contained'
          onClick={changeOrder}
          children={'Edit Order'}
          disabled={disable}
        />
      </div>
    </div>
  );
};

export default MainOrderForm;
