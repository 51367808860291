import { Slider } from "@mui/material";
import { FC } from "react";
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import NumberInput from "../../input/NumberInput";

const Price: FC = () => {
  const { setStartPriceFilter, setEndPriceFilter } = useActions();
  const {
    filters: { price },
    minPrice,
    maxPrice,
  } = useTypedSelector((state) => state.van);

  const setStartPrice = (startPrice: number) => setStartPriceFilter(startPrice);
  const setEndPrice = (endPrice: number) => setEndPriceFilter(endPrice);

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      return;
    } else {
      setStartPriceFilter(newValue[0]);
      setEndPriceFilter(newValue[1]);
    }
  };

  return (
    <div className="filter-price">
      <div className="filter__title">Price</div>
      <div className="filter-price__wrapper">
        <NumberInput
          data={price?.startPrice!}
          setData={setStartPrice}
          placeholder="From"
          min={minPrice!}
          max={maxPrice!}
          text
        />
        <NumberInput
          data={price?.endPrice!}
          setData={setEndPrice}
          placeholder="To"
          min={price?.startPrice!}
          max={maxPrice!}
          text
        />
      </div>
      <Slider
        sx={{ mt: 1 }}
        value={[price?.startPrice! ?? 0, price?.endPrice! ?? 0]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        step={10}
        min={minPrice!}
        max={maxPrice!}
      />
    </div>
  );
};
export default Price;
