import { FC } from "react";
import Pagination from "react-js-pagination";
import Icons from "src/common/icons";
import { IVanFilter } from "src/models/IVanFilter";
import ItemPerPage from "./ItemPerPage";
import "./styles.scss";

interface IProps {
  filter: Pick<IVanFilter, "page" | "take">;
  total: number;
  onChange: any;
  onTakeChange: any;
}

const CustomPagination: FC<IProps> = (props) => (
  <div className="pagination__wrapper">
    <Pagination
      hideFirstLastPages={true}
      activePage={props.filter.page}
      itemsCountPerPage={props.filter.take}
      totalItemsCount={props.total}
      onChange={props.onChange}
      itemClass="pagination-item"
      linkClass="pagination-link"
      activeLinkClass="active-link"
      prevPageText={
        <div className="pagination-arrow prev">
          <Icons.Arrow classes="pagination__icon_left" />
        </div>
      }
      nextPageText={
        <div className="pagination-arrow">
          <Icons.Arrow classes="pagination__icon_right" />
        </div>
      }
    />
    <ItemPerPage
      take={props.filter.take}
      onTakeChange={props.onTakeChange}
      total={props.total!}
    />
  </div>
);

export default CustomPagination;
