import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import lodash from "lodash";
import { Tooltip } from '@mui/material';
import DeleteModalForm from 'src/components/Items/DeleteModalForm';
import BookDateOrderModal from '../../Modal/BookDate';
import EditBage from './EditBage';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroDivide, dineroFormat } from 'src/common/utils/dataFormat';
import { IOrderFetch } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  order: IOrderFetch;
}

const CommonData: FC<IProps> = ({
  order: {
    id,
    created_at,
    start_date,
    end_date,
    location,
    sub_location,
    sub_location_end,
    amount_seats,
    stripe_pi_id,
    end_location,
    timeOffset,
    delivery_address,
    return_at,
    status,
    price,
    currency,
    pdf_order,
    isOneway,
    client: { driver_license }
  },
}) => {
  const { role } = useTypedSelector((state) => state.auth);

  const timeObject = DateTime.fromISO(end_date)
    .diff(DateTime.fromISO(start_date), ['days', 'hours'])
    .toObject();

  const partOfDay = timeObject.hours ? lodash.divide(Math.ceil(timeObject.hours), 24) : 0;
  const duration = lodash.round((timeObject.days! + partOfDay), 2);

  const commonData = {
    Booked: `${DateTime.fromISO(created_at, { zone: "UTC" })
      .plus({ minutes: timeOffset })
      .toFormat("ff")}`,
    From: `${DateTime.fromISO(start_date, { zone: "UTC" })
      .plus({ minutes: timeOffset })
      .toFormat("ff")} | ${location} ${sub_location && ` | ${sub_location}`}`,
    To: `${DateTime.fromISO(end_date, { zone: "UTC" })
      .plus({ minutes: timeOffset })
      .toFormat("ff")} | ${end_location || location} ${sub_location_end && ` | ${sub_location_end}`}`,

    "Number of people": amount_seats,
    "Duration": `${timeObject.days}days  ${Math.ceil(
      timeObject.hours as number
    )}hours`,
    "Aver. Day Rate": dineroFormat(dineroDivide(price, duration), currency),
    "deliver to": delivery_address!,
    "return at": return_at ? return_at : delivery_address && "same as delivery",
  };

  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [openEditBookDate, setOpenEditBookDate] = useState(false);

  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => {
    setOpenDelete(false);
    navigate('/order');
  };
  const handleOpenEditBookDate = () => setOpenEditBookDate(true);
  const handleCloseEditBookDate = () => setOpenEditBookDate(false);

  return (
    <>
      <div className='order-show__header'>
        <div className='order-show__header__container'>
          <div className='order-show__header__container__title'>
            <h3>
              Order
              <span>{` #${id}`}</span>
            </h3>
            <div className='order-show__header__container__title__actions icon_stroke'>
              <Tooltip title='Edit date'>
                <div className='action-box' onClick={handleOpenEditBookDate}>
                  <Icons.Edit />
                </div>
              </Tooltip>
              <Tooltip title='Delete order'>
                <div className='action-box' onClick={handleOpenDelete}>
                  <Icons.Delete />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className='order-show__header__container__badges'>
            <EditBage order={id} status={status} />
            {isOneway &&
              <div
                className='order-show__header__container__badges__oneway-badge'
              >
                1way
              </div>
            }
            {(pdf_order && status === 'paid' && driver_license) && (
              <div
                className='order-show__header__container__badges__ready-badge status-badge'
              >
                Ready
              </div>
            )}
          </div>
        </div>
        <div className='order-show__header__actions icon_stroke'>
          <Tooltip title='Edit date'>
            <div className='action-box' onClick={handleOpenEditBookDate}>
              <Icons.Edit />
            </div>
          </Tooltip>
          <Tooltip title='Delete order'>
            <div className='action-box' onClick={handleOpenDelete}>
              <Icons.Delete />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className='order-common'>
        {Object.keys(commonData).map(
          (key) =>
            commonData[key as keyof typeof commonData] && (
              <>
                <div data-title={key} className='order-common__item' key={key}>
                  {key === "Aver. Day Rate" ? (
                    <div className='order-common__item__title'>
                      {`${key} `}
                      <span className='order-common__item__subtitle'>
                        incl all services
                      </span>
                      :
                    </div>
                  ) : (
                    <div className='order-common__item__title'>{`${key} :`}</div>
                  )}
                  <div className='order-common__item__data'>
                    {commonData[key as keyof typeof commonData]}
                  </div>
                </div>
              </>
            )
        )}
      </div>

      <DeleteModalForm
        id={id!}
        type='order'
        open={openDelete}
        handleClose={handleCloseDelete}
      />
      <BookDateOrderModal
        handleClose={handleCloseEditBookDate}
        open={openEditBookDate}
      />
    </>
  );
};

export default CommonData;
