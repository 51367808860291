import { put, takeLatest } from 'redux-saga/effects';
import instance from 'src/sagas/set';
import { ReportActionCreators } from 'src/store/reducers/report/host/action-creators';
import { ReportActionEnum } from 'src/store/reducers/report/host/types';

function* getNewHostReport({
  filter,
}: ReturnType<typeof ReportActionCreators.getNewHostReport>) {
  try {
    const { data, status } = yield instance.post('/report/host/new', { filter });
    yield put(ReportActionCreators.resultNewHostReport(data, status));
  } catch (error) {
    yield put(ReportActionCreators.errorHostReport());
    console.log('error :>> ', error);
  }
}
export function* actionWatcherGetNewHostReport() {
  yield takeLatest(ReportActionEnum.REQUEST_NEW_REPORT, getNewHostReport);
}
