import { put, takeLatest } from 'redux-saga/effects';
import instance from '../set';
import { HostStaffActionCreators } from 'src/store/reducers/hostStaff/action-creators';
import { HostStaffActionEnum } from 'src/store/reducers/hostStaff/types';

function* fetchUpdateHostStaff({
  currentHostStaff, id
}: ReturnType<typeof HostStaffActionCreators.updateHostStaff>) {
  try {
    const { status }: Response = yield instance.patch(
      `staff/${id}`,
      currentHostStaff
    );
    yield put(HostStaffActionCreators.resultUpdateHostStaff(status));
  } catch (e) {
    console.log(e);
    yield put(HostStaffActionCreators.resultUpdateHostStaff(400));
  }
}

export function* actionWatcherUpdateHostStaff() {
  yield takeLatest(HostStaffActionEnum.REQUEST_UPDATE_HOSTSTAFF, fetchUpdateHostStaff);
}