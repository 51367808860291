import { requestState, responseState } from "../utils";
import { NearVanAction, NearVanActionEnum, NearVanState } from "./types";

const initialState: NearVanState = {
  nearVanList: [],
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
  },
  result: {},
};

const nearVan = (state = initialState, action: NearVanAction): NearVanState => {
  switch (action.type) {
    case NearVanActionEnum.REQUEST_NEARVANS:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case NearVanActionEnum.RESULT_NEARVANS:
      return {
        ...state,
        nearVanList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
      };
    case NearVanActionEnum.REQUEST_NEARVAN:
      return {
        ...state,
        isLoading: { ...state.isLoading, get: true },
      };
    case NearVanActionEnum.RESULT_NEARVAN:
      return {
        ...state,
        nearVan: action.data,
        isLoading: { ...state.isLoading, get: !action.data },
      };
    case NearVanActionEnum.REQUEST_DELETE_NEARVAN:
      return requestState(state, "delete");

    case NearVanActionEnum.RESULT_DELETE_NEARVAN:
      return responseState(state, "delete", action);

    case NearVanActionEnum.REQUEST_UPDATE_NEARVAN:
      return requestState(state, "update");

    case NearVanActionEnum.RESULT_UPDATE_NEARVAN:
      return responseState(state, "update", action);

    case NearVanActionEnum.REQUEST_ADD_NEARVANS:
      return requestState(state, "add");

    case NearVanActionEnum.RESULT_ADD_NEARVANS:
      return responseState(state, "add", action);

    default:
      return state;
  }
};
export default nearVan;
