import { IHostStaff, IHostStaffFetch } from "src/models/IHostStaff";

export enum HostStaffActionEnum {
    REQUEST_HOSTSTAFF = "REQUEST_HOSTSTAFF",
    RESULT_HOSTSTAFF = "RESULT_HOSTSTAFF",
    REQUEST_HOSTSTAFF_BY_ID = 'REQUEST_HOSTSTAFF_BY_ID',
    RESULT_HOSTSTAFF_BY_ID = 'RESULT_HOSTSTAFF_BY_ID',
    REQUEST_ADD_HOSTSTAFF = 'REQUEST_ADD_HOSTSTAFF',
    RESULT_ADD_HOSTSTAFF = 'RESULT_ADD_HOSTSTAFF',
    REQUEST_UPDATE_HOSTSTAFF = 'REQUEST_UPDATE_HOSTSTAFF',
    RESULT_UPDATE_HOSTSTAFF = 'RESULT_UPDATE_HOSTSTAFF',
    REQUEST_DELETE_HOSTSTAFF = 'REQUEST_DELETE_HOSTSTAFF',
    RESULT_DELETE_HOSTSTAFF = 'RESULT_DELETE_HOSTSTAFF'
}

export interface HostStaffState {
    hostStaffList: IHostStaffFetch[];
    hostStaff? : IHostStaffFetch;
    isLoading: {
        all: boolean;
        get: boolean;
        add: boolean;
        delete: boolean;
        update: boolean;
    };
    result: {
        all?: number | string;
        get?: number | string;
        delete?: number | string;
        add?: number | string;
        update?: number | string;
    };
}

export interface GetHostStaffAction {
    type: HostStaffActionEnum.REQUEST_HOSTSTAFF;
}

export interface ResultGetHostStaffAction {
    type: HostStaffActionEnum.RESULT_HOSTSTAFF;
    status: number;
    data?: IHostStaffFetch[];
}

export interface GetHostStaffByIdAction {
    type: HostStaffActionEnum.REQUEST_HOSTSTAFF_BY_ID;
    id: string | number
}

export interface ResultGetHostStaffByIdAction {
    type: HostStaffActionEnum.RESULT_HOSTSTAFF_BY_ID;
    status: number;
    data?: IHostStaffFetch;
}

export interface AddHostStaffAction {
    type: HostStaffActionEnum.REQUEST_ADD_HOSTSTAFF;
    data: IHostStaff;
}

export interface ResultAddHostStaffAction {
    type: HostStaffActionEnum.RESULT_ADD_HOSTSTAFF;
    status: number;
}

export interface UpdateHostStaffAction {
    type: HostStaffActionEnum.REQUEST_UPDATE_HOSTSTAFF;
    currentHostStaff: IHostStaffFetch;
    id: string | number;
}

export interface ResultUpdateHostStaffAction {
    type: HostStaffActionEnum.RESULT_UPDATE_HOSTSTAFF;
    status: number;
}

export interface DeleteHostStaffAction {
    type: HostStaffActionEnum.REQUEST_DELETE_HOSTSTAFF;
    id: string | number;
}

export interface ResultDeleteHostStaffAction {
    type: HostStaffActionEnum.RESULT_DELETE_HOSTSTAFF;
    status: number;
}

export type HostStaffAction =
    | GetHostStaffAction
    | ResultGetHostStaffAction
    | AddHostStaffAction
    | ResultAddHostStaffAction
    | UpdateHostStaffAction
    | ResultUpdateHostStaffAction
    | GetHostStaffByIdAction
    | ResultGetHostStaffByIdAction
    | DeleteHostStaffAction
    | ResultDeleteHostStaffAction;
