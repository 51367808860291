import { FC, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Tooltip } from '@mui/material';
import Icons from 'src/common/icons';
import { useActions } from 'src/hooks/useActions';
import { BadgeThemeOrderEnum } from 'src/models/IOrder';
import '../styles.scss';

interface IProps {
    order: number;
    status: keyof typeof BadgeThemeOrderEnum;
  }
const EditBage: FC<IProps> = ({ order, status }) => {
  
  const { updateOrder } = useActions();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChangeStatus = (event: any) => {
    updateOrder({ id: order, status: event.target.id });
    setOpen(false);
  };

  return (
    <div className={`edit-status-badge status-badge ${BadgeThemeOrderEnum[status]}`}>
      {status === 'gotit' ? 'got it' : status}
      <Tooltip title='Edit date'>
        <>
          <div className='edit-status-badge__icon'
            onClick={handleToggle}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
          >
            <Icons.Edit />
          </div>
          <Popper open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom"
            sx={{
              position: 'fixed !important',
              transform: 'translate3d(242px, 65.6px, 0px) !important'
            }}>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      {Object.keys(BadgeThemeOrderEnum).map(
                        (key) =>
                          <MenuItem key={key} id={key} onClick={handleChangeStatus}>
                            {key === 'gotit' ? 'got it' : key}
                          </MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      </Tooltip>
    </div>
  );
};

export default EditBage;
