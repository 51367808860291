import { FC, useState } from "react";
import { CircularProgress } from "@mui/material";
import CustomSwiper from "src/components/Items/CustomSwiper";
import UpdateVanModal from "../../Modal/Update";
import CloneVanModal from "../../Modal/Clone";
import GalleryModal from "../../Modal/Gallery";
import DeleteModalForm from "src/components/Items/DeleteModalForm";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { cutText } from "src/common/utils/others";
import { dineroFormat } from "src/common/utils/dataFormat";
import { IVanFetch } from "src/models/IVan";
import Icons from "src/common/icons";
import "./styles.scss";

interface IProps {
  van: IVanFetch;
}

const VanItem: FC<IProps> = ({ van }) => {
  const {
    van: { isLoading },
    global: { hideBarMenu },
  } = useTypedSelector((state) => state);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openG, setOpenG] = useState(false);
  const [index, setIndex] = useState<number | null>(null);
  const handleOpenGallery = (index: number) => {
    setIndex(index);
    setOpenG(true);
  };

  const handleCloseGallery = () => setOpenG(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const handleDelete = (e: any) => {
    e.stopPropagation();
    handleOpenDelete();
  };

  const [openClone, setOpenClone] = useState(false);
  const handleOpenClone = () => setOpenClone(true);
  const handleCloseClone = () => setOpenClone(false);

  const { seats, description, mark, photos, link, price, currency, tags, vin_code, license_plate, keycode } = van;

  return (
    <div className="van-card">
      <div className="van-card__header">
        <div className="van-card__header__mark">{mark}</div>
        <div className="action-form">
          <div onClick={() => handleOpenClone()} className="mini-sign">
            Clone
          </div>
          {(isLoading.update || isLoading.uploadingImage) ? (
            <div className="mini-sign" style={{ minWidth: '53px', cursor: 'auto' }}>
              <CircularProgress size={10} />
            </div>
          ) : (
            <div
              onClick={() => handleOpen()}
              className="mini-sign"
              style={{ minWidth: '53px' }}
            >
              Edit
            </div>
          )}
          <div
            onClick={!isLoading.delete ? handleDelete : () => {}}
            className="mini-sign"
            children="Delete"
          />
        </div>
      </div>
      <div className="van-card__tags" style={{flexWrap: 'wrap', justifyContent: 'flex-start'}}>
        {tags.map(({ name }, index) => (
          <div className="van-card__tags__item" key={index}>
            {name}
          </div>
        ))}
      </div>
      <div className="van-card__info">
        <div className="van-card__info__important">
          <div className="van-card__info__price">
            {dineroFormat(price!, currency)}
          </div>
          <div className="van-card__info__capacity">
            <div className="van-card__info__capacity__item">
              {seats}x<Icons.Seat />
            </div>
            <div className="van-card__info__capacity__item">
              {seats}x<Icons.Bagadge />
            </div>
          </div>
          <div className="van-card__info__extra">
            <div className="van-card__info__extra__item">Delivered to you</div>
            <div className="van-card__info__extra__item">
              Unmarked/ No Logos
            </div>
            <div className="van-card__info__extra__item">
              {vin_code}
            </div>
            <div className="van-card__info__extra__item">
              {license_plate}
            </div>
            {keycode &&
              <div className="van-card__info__extra__item">
                {keycode}
              </div>
            }
          </div>
        </div>

        <div className="van-card__info__description">
          {cutText(description, 450)}
          <a
            href={`http://${link}`}
            className="van-card__info__description_more"
            target="_blank"
          >
            more
          </a>
        </div>
      </div>
      <div className={`van-card__imgs ${hideBarMenu ? "active" : ""}`}>
        <CustomSwiper
          data={[...photos.exterior, ...photos.interior]}
          onClick={handleOpenGallery}
        />
      </div>
      <DeleteModalForm
        id={van.id}
        type="van"
        open={openDelete}
        handleClose={handleCloseDelete}
      />
      <UpdateVanModal van={van} handleClose={handleClose} open={open} />
      <CloneVanModal
        van={van}
        handleClose={handleCloseClone}
        open={openClone}
      />
      <GalleryModal
        open={openG}
        handleClose={handleCloseGallery}
        imgs={[...photos.exterior, ...photos.interior]}
        index={index!}
      />
    </div>
  );
};
export default VanItem;
