import { AxiosError } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { CalendarActionCreators } from 'src/store/reducers/calendar/action-creators';
import {
  CalendarActionEnum,
  FetchCalendarDate,
} from 'src/store/reducers/calendar/types';
import instance from '../set';

function* updateCalendar({
  order, filter
}: ReturnType<typeof CalendarActionCreators.updateCalendar>) {
  try {
    const { data }: { data: FetchCalendarDate } = yield instance.patch(
      `calendar/update`,
      { order, filter }
    );
    yield put(CalendarActionCreators.resultUpdateCalendar(data));
  } catch (error: any) {
    yield put(CalendarActionCreators.getCalendar(filter!));
  }
}
export function* actionWatcherUpdateCalendar() {
  yield takeLatest(CalendarActionEnum.REQUEST_UPDATE_CALENDAR, updateCalendar);
}
