import { FC } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import "./styles.scss";

export interface IProps {
  search: string;
  holder: string;
  handleSearch: (value: string) => void;
}

const StyledTextField = styled(TextField)({
  color: "inherit",
  "& .MuiInputBase-input": {
    paddingLeft: `34px`,
  },
});

const Search: FC<IProps> = ({ search, holder, handleSearch }) => {
  return (
    <>
      <div className="search">
        <SearchIcon className="search__icon" />
        <StyledTextField
          placeholder={`Search${holder}`}
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          autoComplete="off"
        />
      </div>
    </>
  );
};
export default Search;
